<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // * Get equities light
      getEquities(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equities`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Get all active and candidate equities
      getAllActiveAndCandidateEquityISINs() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equities/isins`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Get an equity chart data
      getEquityChartData(equityId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equity/history/chart/${equityId}`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Update equity data
      updateEquity(equityId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equity/${equityId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // * Get equity candidates paginated
      getEquityCandidates(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equities/candidates`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Update equity candidate
      updateEquityCandidate(equityId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equity/candidate/${equityId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // * Get retired equities paginated
      getRetiredEquities(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equities/retired`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Update retired equity
      updateRetiredEquity(equityId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equity/retired/${equityId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // * GET stock exchanges
      getExchanges(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/stock/exchanges`, {
            method: 'POST',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      }, 
      updateExchange(exchangeId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/stock/exchange/${exchangeId}`, {
            method: 'PATCH',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Multiplies historical data by factor between 2 dates
      multiplyPricesBetween(startDate, endDate, factor, equityId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/equity/history/chart/multiply-row/${startDate}/${endDate}/${factor}/${equityId}`, {
            method: 'GET'
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },
    }
  }
</script>
