<template>
  <div class="knowledge">
    <div class="new-item">
      <button class="transition" @click="createCoursePopup = true">
        <img class="transition" :src="$s3Url + 'svgs/icons/plus.svg'">
        <span>New course</span>
      </button>
    </div>

    <template v-if="courses && courses.length > 0">
      <CourseItem
        v-for="course in courses"
        :key="course.id"
        :course="course"
        @edit-course="editCourseHandler"
        @new-module="createModuleHandler"
        @edit-module="editModuleHandler"
        @new-section="createSectionHandler"
        @new-session="createSessionHandler"
        @edit-section="editSectionHandler"
        @delete-module="deleteModuleHandler"
        @delete-section="deleteSectionHandler"
        @delete-session="deleteSessionHandler"
      />
    </template>

    <NewCourse
      v-if="createCoursePopup"
      @close="createCoursePopup = false"
      @created="getCoursesHandler(); createCoursePopup = false"
    />

    <EditCourse
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="getCoursesHandler(); editItem = null"
    />

    <NewModule
      v-if="newModulesCourse"
      :courseId="+newModulesCourse"
      @close="newModulesCourse = null"
      @created="getCoursesHandler(); newModulesCourse = null"
    />

    <NewSection
      v-if="newSectionData"
      :courseId="+newSectionData.courseId"
      :moduleId="+newSectionData.moduleId"
      @close="newSectionData = null"
      @created="getCoursesHandler(); 
      newSectionData = null"
    />

    <EditModule
      v-if="editModuleItem"
      :item="editModuleItem"
      :courseId="editModuleData.courseId"
      @close="editModuleItem = null; editModuleData = null;"
      @updated="getCoursesHandler(); editModuleItem = null; editModuleData = null;"
    />

    <EditSection
      v-if="editSectionItem"
      :item="editSectionItem"
      :sectionData="editSectionData"
      @close="editSectionItem = null; editSectionData = null;"
      @updated="getCoursesHandler(); editSectionItem = null; editSectionData = null;"
    />
    
    <NewSession
      v-if="newSessionData"
      :courseId="+newSessionData.courseId"
      :moduleId="+newSessionData.moduleId"
      :sectionId="+newSessionData.sectionId"
      @close="newSessionData = null"
      @created="getCoursesHandler(); 
      newSessionData = null"
    />

    <DeleteModule
      v-if="deleteModuleData"
      :deleteData="deleteModuleData"
      @close="deleteModuleData = null"
      @deleted="deleteModuleData = null; getCoursesHandler()"
    />

    <DeleteSection
      v-if="deleteSectionData"
      :deleteData="deleteSectionData"
      @close="deleteSectionData = null"
      @deleted="deleteSectionData = null; getCoursesHandler()"
    />

    <DeleteSession
      v-if="deleteSessionData"
      :deleteData="deleteSessionData"
      @close="deleteSessionData = null"
      @deleted="deleteSessionData = null; getCoursesHandler()"
    />
  </div>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'
  import userMixin from '@/mixins/user'

  import CourseItem from './CourseItem'
  import NewCourse from '@/components/knowledge/popups/NewCourse'
  import EditCourse from '@/components/knowledge/popups/EditCourse'
  import NewModule from '@/components/knowledge/popups/NewModule'
  import EditModule from '@/components/knowledge/popups/EditModule'
  import NewSection from '@/components/knowledge/popups/NewSection'
  import EditSection from '@/components/knowledge/popups/EditSection'
  import NewSession from '@/components/knowledge/popups/NewSession'
  import DeleteModule from './popups/DeleteModule'
  import DeleteSection from './popups/DeleteSection'
  import DeleteSession from './popups/DeleteSession'

  export default {
    name: 'Knowledge',
    mixins: [knowledgeMixin, userMixin],
    components: {
      CourseItem,
      NewCourse,
      EditCourse,
      NewModule,
      EditModule,
      NewSection,
      EditSection,
      NewSession,
      DeleteModule,
      DeleteSection,
      DeleteSession,
    },
    data() {
      return {
        createCoursePopup: false,
        courses: null,
        editItem: null,
        newModulesCourse: null,
        editModuleData: null,
        editModuleItem: null,
        newSectionData: null,
        editSectionData: null,
        editSectionItem: null,
        newSessionData: null,
        deleteModuleData: null,
        deleteSectionData: null,
        deleteSessionData: null,
      }
    },
    methods: {
      getCoursesHandler() {
        this.courses = null;
        this.getCourses()
          .then(res => {
            this.courses = res.courses;
          })
          .catch(err => {
            if (err.code == 401) {
              this.logout();
            }
          })
      },
      editCourseHandler(courseId) {
        this.getCourse(courseId)
          .then(res => {
            this.editItem = res.course;
          })
      },
      createModuleHandler(courseId) {
        this.newModulesCourse = courseId;
      },
      editModuleHandler(data) {
        this.editModuleData = data;
        this.getModule(data.courseId, data.moduleId)
          .then(res => {
            this.editModuleItem = res.module;
          })
      },
      createSectionHandler(data) {
        this.newSectionData = data;
      },
      createSessionHandler(data) {
        this.newSessionData = data;
      },
      editSectionHandler(data) {
        this.editSectionData = data;
        this.getSection(data.courseId, data.moduleId, data.sectionId)
          .then(res => {
            this.editSectionItem = res.section;
          })
      },
      deleteModuleHandler(deleteData) {
        this.deleteModuleData = deleteData;
      },
      deleteSectionHandler(deleteData) {
        this.deleteSectionData = deleteData;
      },
      deleteSessionHandler(deleteData) {
        this.deleteSessionData = deleteData;
      }
    },
    mounted() {
      this.getCoursesHandler()
    }
  }
</script>

<style scoped>
  .new-item button {
    display: flex;
    align-items: center;
    background: #FFF;
    color: #3c6e71;
    border: 1px solid #3c6e71;
    font-weight: 600;
    border-radius: 6px;
    margin-top: 10px;
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .new-item button:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .new-item button img {
    margin-right: 5px;
    filter: invert(41%) sepia(8%) saturate(2190%) hue-rotate(135deg) brightness(89%) contrast(82%);
  }

  .new-item button:hover img {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(105%) contrast(104%);
  }
</style>
