<template>
  <div class="quiz-participants">
    <h1>Quizzes</h1>
    <div v-if="isDataLoading" class="loading-overlay">
      <div class="loading-indicator">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <div v-if="quizzes && quizzes.length > 0" class="quiz-names-holder">
      <div v-for="(quiz, index) in quizzes" :key="index">
        <button class="quiz-names" :class="{ selected: selectedQuiz.id === quiz.id }" @click="selectQuiz(quiz)">
          <span v-html="quiz.quizName['en']['name']"></span>
        </button>
      </div>
    </div>

    <div v-if="selectedQuiz?.questions" class="filters-holder">
      <div class="field">
        <label class="filter-label" for="user-filter">Participants</label>
        <select class="filter-select" v-model="usersFilter" @change="filterChangeHandler">
          <option value="null">All</option>
          <option value="true">Users</option>
          <option value="false">Guests</option>
        </select>
      </div>
      <div class="field">
        <label class="filter-label" for="completion-filter">Completion Status</label>
        <select class="filter-select" v-model="completionFilter" @change="filterChangeHandler">
          <option value="null">All</option>
          <option value="true">Completed</option>
          <option value="false">Incomplete</option>
        </select>
      </div>
    </div>
    
    <h1 v-if="participants">Quiz Participants</h1>
    <DataTableHolder
      v-if="selectedQuiz?.questions"
      :columns="columns"
      :rows="participants"
      :totalPages="pages"
      :actions="['view', 'delete']"
      page="Quiz Participants"
      @update-table="getQuizParticipantsHandler"
      @view="openQuizParticipant"
      @delete="deleteHandler"
    />

    <ParticipantAnswers
      v-if="viewItem"
      :answers="viewItem"
      @close="viewItem = null"
    />

    <DeleteSubmission
      v-if="deleteItem"
      :item="deleteItem"
      @close="deleteItem = null"
      @deleted=" deleteItem = null; getQuizParticipantsHandler();"
    />
  </div>
</template>

<script>
import quizMixin from "@/mixins/quiz";

import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
import ParticipantAnswers from "./popups/ParticipantAnswers";
import DeleteSubmission from "./popups/DeleteSubmission";

export default {
  name: "QuizParticipants",
  mixins: [quizMixin],
  components: {
    DataTableHolder,
    ParticipantAnswers,
    DeleteSubmission,
  },
  data() {
    return {
      quizzes: [],
      selectedQuiz: { id: null },
      participants: null,
      pages: 0,
      participantsCopy: null,
      usersFilter: null,
      completionFilter: null,
      columns: [
        {
          title: 'Started On',
          name: 'createdOn',
          sortable: true,
        },
        {
          title: 'Finished On',
          name: 'submittedOn',
          sortable: true,
        },
        {
          title: "Full Name",
          name: "fullName",
          sortable: false,
        },
        {
          title: "Progress",
          name: "progressBar",
          sortable: false,
        },
        {
          title: "Last Question",
          name: "last_question",
          sortable: false,
        },
        {
          title: "id",
          name: "id",
          sortable: false,
          customClass: 'hidden',
        },
      ],
      payLoadData: {},
      defaultSorting: {
        itemsPerPage: 100,
        page: 1,
        sortBy: {
          column: "createdOn",
          order: "DESC",
        },
        filter: [],
      },
      viewItem: null,
      deleteItem: null,
      isDataLoading: false,
    };
  },
  methods: {
    filterChangeHandler() {
      this.getQuizParticipantsHandler(this.payLoadData);
    },
    resetFiltersAndPayloadData () {
      this.usersFilter = null;
      this.completionFilter = null;
      this.payLoadData = {};
    },
    selectQuiz(quiz) {
      if (quiz.id === this.selectedQuiz.id) {
        return;
      }

      this.resetFiltersAndPayloadData();      
      this.selectedQuiz = quiz;
      this.getQuizParticipantsHandler(this.defaultSorting);
    },
    getQuizParticipantsHandler(data) {
      if (data) {
        this.payLoadData = data;
      }
      this.isDataLoading = true;
      const selectedQuizQuestionsCount = this.getSelectedQuizActiveQuestionsCounts();

      const filters = {
        usersFilter: this.usersFilter,
        completionFilter: this.completionFilter
      };
      this.payLoadData = {... this.payLoadData, filter: filters};
      this.getQuizParticipants(this.selectedQuiz.id, this.payLoadData)
        .then((res) => {
          let formattedParticipants = [];
          this.pages = res.pagesCount;

          for (const participant of res.participants) {
            let progressBarValue = Math.round((participant.answered_questions_count / selectedQuizQuestionsCount) * 100);
            progressBarValue = Math.min(progressBarValue, 100);

            let formattedParticipant = {
              createdOn: participant.created_on,
              submittedOn: participant.submitted_on,
              full_name: participant.first_name && participant.last_name ? participant.first_name + ' ' + participant.last_name : participant.full_name,
              progressBar: progressBarValue,
              last_question: this.getQuestionNameById(participant.last_question),
              hidden: participant.id,
            };

            formattedParticipants.push(formattedParticipant);
          }
          this.participants = formattedParticipants;
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    getQuestionNameById(id) {
      if (!id) {
        return '';
      }

      const quizQuestions = this.selectedQuiz.questions;
      const foundQuestion = quizQuestions.find((question) => question.id == id);
      const questionName = foundQuestion?.content?.translations?.en?.title ?? foundQuestion?.id ?? '-';
      return questionName;
    },
    getSelectedQuizActiveQuestionsCounts() {
      let quizQuestionsCount = 0;
      if (!this.selectedQuiz?.questions) {
        return quizQuestionsCount;
      }

      const quizQuestions = this.selectedQuiz.questions;
      for (const question of quizQuestions) {
        if (question.status != 0) {
          quizQuestionsCount ++;
        }
      }

      return quizQuestionsCount;
    },
    openQuizParticipant(participant) {
      const participantId = participant.hidden;
      this.getParticipantAnswers(participantId).then((res) => {
        this.viewItem = res.answers;
      });
    },
    deleteHandler(participant) {
      const participantId = participant.hidden;
      this.deleteItem = participantId;
    },
    getAllQuizzes() {
      this.isDataLoading = true;
      this.getQuizzes()
        .then((res) => {
          this.quizzes = res.quizzes;
          if (this.quizzes.length > 0) {
            // this.selectQuiz(this.quizzes[0]);
            this.selectedQuiz = this.quizzes[0];
          }
        })
        .catch((error) => {
          console.error("Error fetching quizzes:", error);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
  },
  created() {
    this.getAllQuizzes();
  },
};
</script>

<style scoped>
.quiz-names-holder {
  display: block;
  max-width: 60%;
}

.quiz-names-holder div {
  margin: 0px 10px 10px 0px;
  display: inline-block;
}

.quiz-names {
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  padding: 8px 16px;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.quiz-names:hover {
  background-color: #ddd;
}

.quiz-names.selected {
  background: var(--dark-green);
  color: #fff;
}

.filters-holder {
  display: flex;
  margin: 20px 0px;
  flex-direction: column;
  gap: 11px;
}

.filter-label {
  margin-right: 10px;
  font-weight: bold;
}

.filter-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
</style>