<template>
  <!-- Fund Characteristics Page -->
  <div class="fund-umbrella-characteristics-page">
    <h1>{{ parseFundType() }} Characteristics <small v-if="total">({{ total }})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      :columnSearch="true"
      @update-table="getFundCharacteristicsHandler"
      @edit="editHandler"
    />

    <EditFundCharacteristics
      v-if="editItem"
      :item="editItem"
      :fundCategories="fundCategories"
      :sectors="sectors"
      :styles="styles"
      :geographies="geographies"
      @updated="umbrellaUpdatedHandler"
      @close="editItem = null"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundCharacteristics from './popups/EditFundCharacteristics'

  export default {
    name: 'FundCharacteristics',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundCharacteristics
    },
    data() {
      return {
        fundType: this.$route.params.fundType,
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Fund',
            name: 'fundName',
            sortable: true
          },
          {
            title: 'HF Strategy',
            name: 'hasHedgeFundStrategy',
            sortable: true
          },
          {
            title: 'MDS Category',
            name: 'mainEFCCategory',
            sortable: true
          },
          {
            title: 'Category ID',
            name: 'fundCategoryId',
            sortable: true
          },
          {
            title: 'Category',
            name: 'fundCategoryName',
            sortable: true
          },
          {
            title: 'MDS Focus',
            name: 'activeEFCClassification',
            sortable: true
          },
          {
            title: 'Sector',
            name: 'sector',
            sortable: true
          },
          {
            title: 'Style',
            name: 'style',
            sortable: true
          },
          {
            title: 'Geography',
            name: 'geography',
            sortable: true
          },
          {
            title: 'Search score',
            name: 'searchScore',
            sortable: true,
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        updateResults: true,
        addStylePopup: false,
        pages: 0,
        sectors: [],
        styles: [],
        geographies: [],
        fundCategories: [],
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get fund umbrella characteristics from API
      getFundCharacteristicsHandler(data) {
        let foundFundTypeFilter = data.filter.filter(function(item){
          return item.name == 'fundType';
        });

        if (foundFundTypeFilter.length == 0) {
          data.filter.push({
            name: 'fundType',
            term: this.fundType
          });
        }
        
        this.getFundCharacteristics(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let rowsToAssign = [];
            res.investmentFunds.forEach(fund => {
              let obj = {
                id: fund.id,
                fundName: fund.fundName,
                hasHedgeFundStrategy: fund.hasHedgeFundStrategy,
                mainEFCCategory: fund.mainEFCCategory,
                fundCategoryId: fund.fundCategoryId,
                fundCategoryName: fund.fundCategoryName,
                activeEFCClassification: fund.activeEFCClassification,
                sector: fund.sectorName,
                style: fund.styleName,
                geography: fund.geographyName,
                searchScore: fund.searchScore,
                addedOn: fund.addedOn,
                updatedOn: fund.updatedOn
              };

              rowsToAssign.push(obj);
            })
            this.rows = rowsToAssign;
            this.fullData = res.investmentFunds;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Add new sector handler. Triggered when user click the "+ New" button
      addNewHandler() {
        this.addStylePopup = true;
      },
      styleCreatedHandler() {
        this.addStylePopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        // this.editItem = item;
        this.editItem = this.fullData.find(umbrella => {
          return umbrella.id == item.id
        });
      },
      // * Triggered when user updates item from the edit popup
      umbrellaUpdatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      getAllCharsOfUmbrellas() {
        this.getAllFundUmbrellaSectors()
          .then(res => {
            this.sectors = res.sectors;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
        this.getAllFundUmbrellaStyles()
          .then(res => {
            this.styles = res.styles;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
        this.getAllFundUmbrellaGeographies()
          .then(res => {
            this.geographies = res.geographies;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },

      parseFundType(){
        const fundType = this.fundType.toLowerCase();
        if (fundType == 'funds') {
          return 'Fund';
        } else if (fundType == 'etfs') {
          return 'ETF';
        }
      }
    },
    mounted() {
      this.getAllCharsOfUmbrellas();
      // * Put the Fund Categories in a list for the autocomplete field in the edit popup
      this.fundCategories = [
        {
          id: 0,
          name: "Other"
        },
        {
          id: 1,
          name: "Equity"
        },
        {
          id: 2,
          name: "Bond"
        },
        {
          id: 3,
          name: "Multi-Asset"
        },
        {
          id: 4,
          name: "Money Market"
        },
        {
          id: 5,
          name: "Commodity"
        },
        {
          id: 6,
          name: "Crypto"
        },
        {
          id: 7,
          name: "Property"
        }
      ];
    },
    watch: {
      $route (to){
        this.$router.push(to).then(() => {
          location.reload();
        });
      }
    },
  }
</script>

<style scoped>

</style>