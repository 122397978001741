<template>
  <div class="course">
    <div class="course-title" @click="toggleOpenCourse()">
      <div>
        <font-awesome-icon v-if="isCourseOpened" icon="minus" />
        <font-awesome-icon v-else icon="plus" />
        <h3>{{course.title.en.title}}</h3>
        <div class="new-item">
          <button class="transition" @click.stop="$emit('new-module', course.id)">
            <img class="transition" :src="$s3Url + 'svgs/icons/plus.svg'">
            <span>New module</span>
          </button>
        </div>
      </div>
      <font-awesome-icon icon="edit" @click="$emit('edit-course', course.id)" />
    </div>
    <div class="course-content" v-if="isCourseOpened">
      <draggable v-model="courseItem.modules" group="modules" item-key="id">
        <template #item="{ element }">
          <ModuleItem
            :moduleItem="element"
            :courseId="course.id"
            @new-section="newSectionHandler"
            @new-session="newSessionHandler"
            @edit-module="editModuleHandler"
            @edit-section="editSectionHandler"
            @delete-module="$emit('delete-module', $event)"
            @delete-section="$emit('delete-section', $event)"
            @delete-session="$emit('delete-session', $event)"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Draggable from "vuedraggable";
  import ModuleItem from './ModuleItem'

  export default {
    name: 'CourseItem',
    mixins: [knowledgeMixin],
    components: {
      Draggable,
      ModuleItem
    },
    props: {
      course: Object
    },
    data() {
      return {
        courseOpen: false,
        courseItem: this.course
      }
    },
    computed: {
      isCourseOpened() {
        const openedCourses = this.$store.getters['knowledge/getOpenedCourses'];
        return openedCourses.includes(this.course.id);
      },
    },
    methods: {
      toggleOpenCourse() {
        this.courseOpen = !this.isCourseOpened;

        const isOpen = this.courseOpen;
        const courseId = this.courseItem.id;
        this.$store.commit('knowledge/updateOpenedCourses', { courseId, isOpen });
      },
      newSectionHandler(moduleId) {
        const data = {
          courseId: this.course.id,
          moduleId
        }

        this.$emit('new-section', data)
      },
      newSessionHandler(data) {
        this.$emit('new-session', data);
      },
      editModuleHandler(moduleId) {
        const data = {
          courseId: this.course.id,
          moduleId
        }

        this.$emit('edit-module', data)
      },
      editSectionHandler(sectionData) {
        const data = {
          ...sectionData,
          courseId: this.course.id
        }

        this.$emit('edit-section', data)
      },
    },
    watch: {
      courseItem: {
        handler(newCourse) {
          const dataToSend = {
            items: newCourse.modules,
            parentId: newCourse.id
          }

          this.reorderItems('modules', dataToSend)
            .then()
            .catch(err => {
              console.log(err);
            })
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  .course {
    margin-top: 20px;
    padding: 10px 20px;
  }

  .course-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid rgb(146, 146, 146);
  }

  .course-title div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .course-title h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
  }

  .course-title > svg {
    font-size: 20px;
    cursor: pointer;
  }

  .new-item button {
    display: flex;
    align-items: center;
    background: #FFF;
    color: #3c6e71;
    border: 1px solid #3c6e71;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
  }

  .new-item button:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .new-item button img {
    margin-right: 5px;
    filter: invert(41%) sepia(8%) saturate(2190%) hue-rotate(135deg) brightness(89%) contrast(82%);
  }

  .new-item button:hover img {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(105%) contrast(104%);
  }
</style>
