<template>
  <popup
    :title="`Delete user ${item.id} ${item.firstName} ${item.lastName}`"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <img :src="$s3Url + 'svgs/icons/delete-x.svg'">
        <h4>Are you sure?</h4>
        <p class="remove-warning" >User is set to Disabled, Birthday is reset to 01-01-1900, Name, urlName and email are randomized. Panels, Portfolios and Articles are disabled. Social Login accounts are disabled. Payment plan is reset to the lowest Explore. Name and email in Subscriptions and PaymentLogs are randomized. Payrexx gateway is deleted. GetResponse entries are deleted.</p>
        <button class="delete-btn transition" @click="deleteHandler()">
          Delete
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import usersMixin from '@/mixins/users'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'DeleteUser',
    mixins: [usersMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    methods: {
      deleteHandler() {
        this.deleteUser(this.item.id)
          .then(() => {
            this.$emit('deleted');
          })
      }
    }
  }
</script>

<style scoped>
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup-content img {
    margin-top: 100px;
  }

  .popup-content h4 {
    color: #171725;
    font-size: 32px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 130px;
  }

  .popup-content p {
    color: #696974;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
  }

  @media (max-width: 1550px) {
    .popup-content img {
      margin-top: 30px;
    }

    .popup-content h4 {
      margin-bottom: 30px;
    }
  }
</style>