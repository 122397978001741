<template>
  <!-- New Command Schedule Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="New Command Schedule"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Command:</label>
        <vue3-simple-typeahead
          placeholder="Type command..."
          :items="commands"
          :minInputLength="1"
          :itemProjection="(item) => item.command"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
      </div>

      <!-- Schedule field -->
      <div class="field">
        <label for="schedule">Command Schedule:</label>
        <input type="text" id="schedule" v-model="schedule" placeholder="Command Schedule...">
      </div>

      <!-- Status -->
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="status">
          <span class="checkmark"></span>
          Status
        </label>
      </div>

      <!-- Arguments -->
      <div class="field">
        <label>Arguments:</label>
      </div>

      <template v-if="argumentsArr.length > 0">
        <div class="field argument-fields" v-for="(argument, index) in argumentsArr" :key="index">
          <input type="text" v-model="argument.name" placeholder="Name...">
          <input type="text" v-model="argument.value" placeholder="Value...">
        </div>
      </template>

      <button class="add-argument transition" @click="addArgument">
        New argument
      </button>

      <button class="transition save-btn" @click="createCommandScheduleHandler()" :disabled="addLoading">
        <template v-if="!addLoading">
          Add
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewCommandSchedule',
    mixins: [systemMixin, userMixin],
    props: {
      item: Object,
      commands: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        selectedCommand: null,
        addLoading: false,
        name: null,
        schedule: null,
        status: true,
        argumentsArr: []
      }
    },
    methods: {
      // * Triggers on save button click and updates command schedule
      createCommandScheduleHandler() {
        // * Start update loading (for the save button)
        this.addLoading = true;
        const dataToSend = {
          name: this.name,
          schedule: this.schedule,
          status: this.status,
          arguments: this.argumentsArr
        };
        
        // * If user has selected micro service from the list add it to the request data else send null
        if (this.selectedCommand) {
          dataToSend.commandId = this.selectedCommand.id;
        } else {
          dataToSend.commandId = null;
        }

        this.createCommandSchedule(dataToSend)
          .then(() => {
            this.$emit('added');
            this.addLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.addLoading = false;
          })
      },
      // * Triggered when user selects group from the autocomplete. Updates the selected group
      submit(result) {
        this.selectedCommand = result;
      },
      // * Nulls group if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedCommand = null;
        }
      },
      addArgument() {
        this.argumentsArr.push({name: '', value: ''});
      }
    }
  }
</script>

<style scoped>
  .command-name {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
  }

  .argument-fields:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
    margin: 20px 0;
    padding-bottom: 15px;
  }

  .argument-fields {
    display: flex;
  }

  .argument-fields input {
    margin-bottom: 5px;
  }

  .argument-fields input:first-child {
    margin-right: 5px;
  }

  .argument-fields input:last-child {
    margin-left: 5px;
  }

  .add-argument {
    background: #3c6e71;
    color: #fff;
    border-radius: 8px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 15px;
    cursor: pointer;
    outline: none;
  }

  .add-argument:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    background: #fff;
    color: #3c6e71;
  }
</style>
