<template>
  <div class="am-marketing">
    <h1>Asset Managers Marketing</h1>

    <div class="field">
      <label>Report Date Period:</label>
      <div class="date-filter">
        <Datepicker v-model="reportDates" range multi-calendars :enable-time-picker="false" />
        <!-- <button class="green-btn filter-btn" @click="getUsersPerformanceHandler()">Filter</button> -->
      </div>
    </div>
    <div class="field">
      <label>Previous Date Period:</label>
      <div class="date-filter">
        <Datepicker v-model="previousDates" range multi-calendars :enable-time-picker="false" />
        <!-- <button class="green-btn filter-btn" @click="getUsersPerformanceHandler()">Filter</button> -->
      </div>
    </div>
    <div class="field autocomplete" v-if="assetManagers">
      <label>Asset Manager:</label>
      <vue3-simple-typeahead
        placeholder="Type asset manager..."
        :items="assetManagers"
        :minInputLength="1"
        :itemProjection="(item) => item.name"
        @selectItem="selectAM"
        @onInput="keyUpEvent"
      ></vue3-simple-typeahead>
    </div>

    <button class="green-btn" @click="excelExportHandler()">Excel Export</button>

    <!-- <InspectMarketing
      v-if="selectedAM"
      :user="selectedAM"
      :startDate="dates[0]"
      :endDate="dates[1]"
      userType="fundGroup"
    /> -->
  </div>
</template>

<script>
  import marketingMixin from '@/mixins/marketing'
  import datesMixin from '@/mixins/dates'

  // import InspectMarketing from './popups/InspectMarketing'

  export default {
    name: 'AssetManagersMarketing',
    mixins: [marketingMixin, datesMixin],
    // components: {
    //   InspectMarketing
    // },
    data() {
      return {
        assetManagers: null,
        selectedAM: null,
        reportDates: null,
        previousDates: null
      }
    },
    methods: {
      getAssetManagersHandler() {
        this.getAssetManagers()
          .then(res => {
            this.assetManagers = res.assetManagers;
          })
      },
      selectAM(am) {
        this.selectedAM = null;
        setTimeout(() => {
          this.selectedAM = am;
        }, 100);
      },
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedAM = null;
        }
      },
      excelExportHandler() {
        function formatDateDashes(date) {
          let formattedDate;
          formattedDate = date.getFullYear() + '-';
          formattedDate += date.getMonth() + 1 + '-';
          formattedDate += date.getDate();
          return formattedDate;
        }

        const dataToSend = {
          objectType: 'assetManager',
          objectId: this.selectedAM.id,
          reportPeriodFrom: formatDateDashes(this.reportDates[0]),
          reportPeriodTo: formatDateDashes(this.reportDates[1]),
          previousPeriodFrom: formatDateDashes(this.previousDates[0]),
          previousPeriodTo: formatDateDashes(this.previousDates[1])
        }

        this.getExcelExport(dataToSend)
          .then(response => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${this.selectedAM.name}_analytics_${formatDateDashes(this.reportDates[1])}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          })
      }

    },
    mounted() {
      this.getAssetManagersHandler();
    }
  }
</script>

<style scoped>
  .field {
    margin: 15px 0;
    display: flex;
    align-items: center;
  }

  .date-filter {
    display: flex;
    align-items: center;
  }

  .filter-btn {
    height: 34px;
    width: 70px;
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
  }

  .field label:not(.custom-check) {
    color: #44444f;
    font-size: 16px;
    font-weight: 500;
    width: 200px;
  }

  .field input,
  .field :deep(input) {
    width: 400px;
    height: auto;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #44444f;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  .field input:focus,
  .field :deep(input:focus) {
    border-color: #3C6E71;
  }

  .field button {
    background: #3c6e71;
    color: #FFF;
    font-weight: 500;
    font-size: 18px;
    width: 100px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
  }

  .field button:hover {
    background: #FFF;
    color: #3c6e71;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
</style>
