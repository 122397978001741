<template>
  <!-- Edit Advisor Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Fund Advisor"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Name <small>(not editable)</small>:</label>
        <input type="text" id="name" v-model="fundAdvisor.name" placeholder="Name" disabled>
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Parent Group:</label>
        <vue3-simple-typeahead
          placeholder="Type parent group..."
          :items="parentGroups"
          :minInputLength="1"
          :itemProjection="(item) => item.name"
          @selectItem="submit"
          @onInput="keyUpEvent"
        >
        </vue3-simple-typeahead>
        <span class="remove-warning" v-if="fundAdvisor.parentGroupId && !selectedGroup">
          Are you sure you want to remove the parent group?
        </span>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPaid">
          <span class="checkmark"></span>
          Is Paid
        </label>
      </div>

      <button class="transition save-btn" @click="updateFundAdvisorHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundAdvisor',
    mixins: [fundsMixin, userMixin],
    props: {
      item: Object,
      parentGroups: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        parentSelect: false,
        selectedGroup: null,
        updateLoading: false,
        isPaid: this.item.isPaid,
        fundAdvisor: this.item
      }
    },
    methods: {
      // * Triggers on save button click and updates advisor
      updateFundAdvisorHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          name: this.fundAdvisor.name,
          isPaid: this.isPaid
        }
        
        // * If user has selected group from the list add it to the request data else send null
        if (this.selectedGroup) {
          dataToSend.fundGroupId = this.selectedGroup.id;
        } else {
          dataToSend.fundGroupId = null;
        }

        this.editFundAdvisor(this.fundAdvisor.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      // * Triggered when user selects group from the autocomplete. Updates the selected group
      submit(result) {
        this.selectedGroup = result;
      },
      // * Nulls group if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedGroup = null;
        }
      }
    },
    mounted() {
      // * If fundAdvisor has parent group set it on component load
      if (this.fundAdvisor.parentGroupId) {
        this.selectedGroup = {
          id: this.fundAdvisor.parentGroupId,
          name: this.fundAdvisor.parentGroupName
        }
      }
    }
  }
</script>

<style scoped>
  
</style>
