<template>
  <!-- New Glossary Category popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="New Glossary Category"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label for="nameEn">Category Name EN:</label>
        <input type="text" id="nameEn" v-model="nameEn" placeholder="Category Name EN...">
      </div>
      <div class="field">
        <label for="nameDe">Category Name DE:</label>
        <input type="text" id="nameDe" v-model="nameDe" placeholder="Category Name DE...">
      </div>

      <button class="transition save-btn" @click="addGlossaryCategoryHandler()" :disabled="addLoading">
        <template v-if="!addLoading">
          Add
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewGlossaryCategory',
    mixins: [systemMixin, userMixin],
    components: {
      Popup
    },
    data() {
      return {
        nameEn: null,
        nameDe: null,
        addLoading: false
      }
    },
    methods: {
      addGlossaryCategoryHandler() {
        this.addLoading = true;
        const dataToSend = {
          languages: {
            en: {
              name: this.nameEn
            },
            de: {
              name: this.nameDe
            }
          }
        }

        this.addGlossaryCategory(dataToSend)
          .then(() => {
            this.$emit('added')
            this.addLoading = false;
          })
          .catch(err => {
            this.addLoading = false;
            if (err == 401) {
              this.logout();
            }
          })
      }
    }
  }
</script>

<style scoped>
  
</style>
