<template>
  <div class="umu-benchmarks-by-share-classes-page">
    <h1>Umu Benchmarks By Share Classes<small v-if="total">({{total}})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :column-search="true"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="[]"
        @update-table="getUmuBenchmarksByShareClassesHandler"
        @edit="editHandler"
    />

  </div>
</template>

<script>
import userMixin from '@/mixins/user.vue'
import DataTableHolder from '@/components/shared/datatable/DataTableHolder.vue'
import systemMixin from "@/mixins/system.vue";
export default {
  name: 'UmuBenchmarksByShareClasses',
  mixins: [userMixin, systemMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'Security ID',
          name: 'securityId',
          sortable: true
        },
        {
          title: 'ISIN',
          name: 'isin',
          sortable: true
        },
        {
          title: 'Security Name',
          name: 'securityName',
          sortable: true
        },
        {
          title: 'UmuBenchmark ID',
          name: 'umuBenchmarkId',
          sortable: true
        },
        {
          title: 'Umu Benchmark',
          name: 'umuBenchmark',
          sortable: true
        },


      ],
      editItem: null,
      pages: 0,
      updateResults: true,
      total: null,
      fullData: null,
    }
  },
  methods: {
    getUmuBenchmarksByShareClassesHandler(data) {

      this.getUmuBenchmarksByShareClassesPaginated(data)
          .then(res => {
            this.pages = res.pagesCount;
            let resultsFormatted = [];

            for (const results of res.results) {
              let resultsObj = {
                securityId: results.security_id,
                isin: results.isin,
                securityName: results.security_name,
                umuBenchmarkId: results.umu_benchmark_id,
                umuBenchmark: results.umu_benchmark,
              }
              resultsFormatted.push(resultsObj);
            }
            this.rows = resultsFormatted;
            this.fullData = res.results;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },

    editHandler(item) {
      this.editItem = this.fullData.find(results => {
        return results.results_id === item.resultsId;
      });
    },
    updatedHandler() {
      this.editItem = null;
      this.updateResults = !this.updateResults;
    },

  },

  computed: {
  },

}
</script>

<style scoped>


</style>