<template>
  <!-- Fund Market Data Page -->
  <div class="fund-market-data-page">
    <h1>Countries for Sale <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="[]"
      :columnSearch="true"
      @update-table="getSecuritiesHandler"
    />

  </div>
</template>

<script>
  import userMixin from '@/mixins/user'
  import securitiesMixin from '@/mixins/securities'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'FundCountries',
    mixins: [userMixin, securitiesMixin],
    components: {
      DataTableHolder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true,
            notSearchable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Name',
            name: 'securityName',
            sortable: true
          },
          {
            title: 'CH',
            name: 'countryCH',
            sortable: true,
            notSearchable: true
          },
          {
            title: 'AT',
            name: 'countryAT',
            sortable: true,
            notSearchable: true
          },
          {
            title: 'DE',
            name: 'countryDE',
            sortable: true,
            notSearchable: true
          },
          {
            title: 'UK',
            name: 'countryUK',
            sortable: true,
            notSearchable: true
          },
          {
            title: 'NL',
            name: 'countryNL',
            sortable: true,
            notSearchable: true
          },
          {
            title: 'ES',
            name: 'countryES',
            sortable: true,
            notSearchable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        total: null,
        fullData: null
      }
    },
    methods: {
      getSecuritiesHandler(data) {
        data.overwriteSecurityType = [2,3];
        this.getSecurities(data, 2)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let securitiesFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const security of res.securities) {
              let securityObj = {
                id: security.id,
                isin: security.isin,
                securityName: security.name,
                countryCH: security.fund_data ? security.fund_data['country_of_sale']['countries']['ch'] : '-',
                countryAT: security.fund_data ? security.fund_data['country_of_sale']['countries']['at'] : '-',
                countryDE: security.fund_data ? security.fund_data['country_of_sale']['countries']['de'] : '-',
                countryUK: security.fund_data ? security.fund_data['country_of_sale']['countries']['uk'] : '-',
                countryNL: security.fund_data ? security.fund_data['country_of_sale']['countries']['nl'] : '-',
                countryES: security.fund_data ? security.fund_data['country_of_sale']['countries']['es'] : '-'
              };
              // * Add formatted fund to formatted funds array
              securitiesFormatted.push(securityObj);
            }
            // * Assign table rows to be the formatted funds array
            this.rows = securitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
    },
    mounted() {
    }
  }
</script>

<style scoped>


</style>