<template>
  <!-- Fund Umbrella Sectors Page -->
  <div class="fund-umbrella-sectors-page">
    <h1>Fund Umbrella Sectors</h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :updateResults="updateResults"
      :actions="['edit', 'new']"
      @update-table="getFundUmbrellaSectorsHandler"
      @add-new="addNewHandler"
      @edit="editHandler"
    />

    <NewFundUmbrellaSector
      v-if="addSectorPopup"
      @created="sectorCreatedHandler"
      @close="addSectorPopup = false"
    />

    <EditFundUmbrellaSector
      v-if="editItem"
      :sector="editItem"
      @updated="sectorUpdatedHandler"
      @close="editItem = null"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewFundUmbrellaSector from './popups/NewFundUmbrellaSector'
  import EditFundUmbrellaSector from './popups/EditFundUmbrellaSector'

  export default {
    name: 'FundUmbrellaSectors',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      NewFundUmbrellaSector,
      EditFundUmbrellaSector
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Sector',
            name: 'sector',
            sortable: false
          },
          {
            title: 'Other',
            name: 'other',
            sortable: false
          },
          {
            title: 'Equity',
            name: 'equity',
            sortable: false
          },
          {
            title: 'Bond',
            name: 'bond',
            sortable: false
          },
          {
            title: 'Multi-Asset',
            name: 'multiAsset',
            sortable: false
          },
          {
            title: 'Money-Market',
            name: 'moneyMarket',
            sortable: false
          },
          {
            title: 'Commodity',
            name: 'commodity',
            sortable: false
          },
          {
            title: 'Crypto',
            name: 'crypto',
            sortable: false
          },
          {
            title: 'Property',
            name: 'property',
            sortable: false
          },
          {
            title: 'UMU Sector',
            name: 'umuSector',
            sortable: false
          },
          {
            title: 'UMU Sector ID',
            name: 'umuSectorId',
            sortable: false
          },
        ],
        editItem: null,
        updateResults: true,
        addSectorPopup: false
      }
    },
    methods: {
      // * Get fund umbrella sectors from API
      getFundUmbrellaSectorsHandler(data) {
        this.getAllFundUmbrellaSectors(data)
          .then(res => {

            let sectorsFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const sector of res.sectors) {
              let Sector = {
                id: sector.id,
                sector: sector.sector,
                other: sector.other,
                equity: sector.equity,
                bond: sector.bond,
                multiAsset: sector.multiAsset,
                moneyMarket: sector.moneyMarket,
                commodity: sector.commodity,
                crypto: sector.crypto,
                property: sector.property,
                umuSector: sector.translationData?.en?.name || null,
                umuSectorId: sector.umuSectorId || null,
              }
              // * Add formatted fund to formatted funds array
              sectorsFormatted.push(Sector);
            }
            // * After receiving response update data
            this.rows = sectorsFormatted;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Add new sector handler. Triggered when user click the "+ New" button
      addNewHandler() {
        this.addSectorPopup = true;
      },
      sectorCreatedHandler() {
        this.addSectorPopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      sectorUpdatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      
    }
  }
</script>

<style scoped>

</style>