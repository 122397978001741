<template>
  <div>
    <apexchart v-if="showChart" width="100%" :options="chartOptions" :series="series" height="500"></apexchart>
  </div>
</template>

<script>
import quizMixin from "@/mixins/quiz";

export default {
  name: "QuizNonCompletedQuestionsBar",
  mixins: [quizMixin],
  props: {
    questions: Array
  },
  data() {
    return {
      showChart: false,
      series: [{ data: [] }],
      chartOptions: {
        chart: {
          type: "bar",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: 15,
        },
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
        },
        yaxis: {},
        legend: {
          show: false,
        },
        tooltip: {
          y: {
            formatter: function ($val) {
              return $val;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    if (this.questions) {
      let maxTotalSubmissions = Math.max(...this.questions.map((question) => question.totalSubmissions));
      this.chartOptions.yaxis.max = maxTotalSubmissions;

      for (const questionObj of this.questions) {
        this.chartOptions.xaxis.categories.push(questionObj.question);
        this.series[0].data.push({
          x: questionObj.question,
          y: questionObj.totalSubmissions,
        });
      }

      this.showChart = true;
      this.$emit("loaded");
    }
  },
};
</script>

<style scoped>
</style>
