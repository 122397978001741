<template>
  <!-- Inspect Portfolio Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Portfolio"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <span class="portfolio-id">Portfolio ID: {{item.id}}</span>
      <span class="portfolio-id">Portfolio Name: {{item.portfolioName}}</span>

      <h4>Transactions</h4>
      <div class="transactions">
        <div class="header">
          <span class="date">Date</span>
          <span class="description">Description</span>
          <span class="value">Value</span>
          <span class="balance">Balance</span>
        </div>
        <div class="content">
          <div class="transaction-row" v-for="(transaction, index) in transactions" :key="index">
            <span class="date">{{ $filters.formatDate(transaction.transactionDate) }}</span>
            <div class="actions">
              <div v-for="(row, index) in transaction.transactions" :key="index">
                <span class="description">{{row.description}}</span>
                <span class="value">{{row.value.toFixed(2)}}</span>
              </div>
            </div>
            <span class="balance">{{transaction.balance.toFixed(2)}}</span>
          </div>
        </div>
      </div>

      <h4>Performance</h4>
      <ChartBox
        v-if="graphData"
        :graph-data="graphData"
        :item="item"
        type="portfolio"
        currency="USD"
      />
    </template>
  </popup>
</template>

<script>
  import portfoliosMixin from '@/mixins/portfolios'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  import ChartBox from '@/components/shared/charts/ChartBox'
  
  export default {
    name: 'InspectPortfolioPerformance',
    mixins: [portfoliosMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup,
      ChartBox
    },
    data() {
      return {
        transactions: null,
        graphData: null
      }
    },
    methods: {
      getPortfolioTransactionsHandler() {
        this.getPortfolioTransactions(this.item.id)
          .then(res => {
            this.transactions = res.userPortfolioTransactions;
          })
      },
      getPortfolioChartHandler() {
        this.getPortfolioChart(this.item.id)
          .then(res => {
            this.graphData = res;
          })
      }
    },
    mounted() {
      this.getPortfolioTransactionsHandler();
      this.getPortfolioChartHandler();
    }
  }
</script>

<style scoped>
  .portfolio-id {
    font-weight: 700;
    margin-top: 10px;
    display: block;
  }

  input:disabled {
    cursor: text !important;
  }

  .transactions {
    margin-top: 30px;
  }

  .transactions .header,
  .transaction-row,
  .actions div {
    display: flex;
    justify-content: space-between;
  }

  .transactions .header,
  .transaction-row {
    border-bottom: 1px solid #171725;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .transactions .header span {
    font-weight: 600;
  }

  .date,
  .balance,
  .value {
    flex: 0 0 120px;
  }

  .balance {
    text-align: right;
  }

  .content .date,
  .content .balance {
    padding: 5px 0;
  }

  .actions {
    flex: 0 0 calc(100% - 240px)
  }

  .actions div {
    align-items: center;
    padding: 5px 0;
  }

  .actions div:not(:last-child) {
    border-bottom: 1px solid rgb(201, 201, 201);
  }

  .value {
    padding-left: 10px;
    text-align: right;
  }

  .description {
    flex: 1 0 calc(100% - 360px);
    padding-left: 10px;
  }
</style>
