<template>
  <popup customClass="new-session-popup" title="Add new Lecture" @close="$emit('close')">
    <template v-slot:content>
      <div class="popup-content">
        <div class="content">
          <!-- Language switcher -->
          <div class="language-switcher">
            <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">EN</button>
            <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">DE</button>
          </div>

          <!-- Session title -->
          <div class="field">
            <label for="session-title">Lecture Name</label>
            <input type="text" id="session-title" placeholder="Lecture name" v-model="session.title[currentLanguage].title">
          </div>

          <!-- Session Description -->
          <div class="field">
            <label>Lecture Description</label>
            <!-- RTE (Rich text editor) field (using external library - https://tiptap.dev/) -->
            <TextEditor
              :body="session.description[currentLanguage].description"
              @set-content="setDescription"
            />
          </div>

          <!-- Video data -->
          <!-- Displayed only if selected type is video type(1) -->
          <div v-if="session.type == '1'">
            <div class="field">
              <label for="video-id">Video ID</label>
              <input type="text" id="video-id" placeholder="Video ID" v-model="session.videoData[currentLanguage].videoId">
            </div>

            <div class="field">
              <label for="video-thumbnail">Video Thumbnail</label>
              <input type="text" id="video-thumbnail" placeholder="Video Thumbnail" v-model="session.videoData[currentLanguage].thumbnail">
            </div>

            <div class="field">
              <label for="video-previewId">Video Preview Id</label>
              <input type="text" id="video-previewId" placeholder="Video Preview Id" v-model="session.videoData[currentLanguage].previewId">
            </div>

            <div class="field">
              <label for="video-duration">Video Duration</label>
              <input type="number" id="video-duration" placeholder="Video Duration" v-model="session.videoData[currentLanguage].duration">
            </div>
          </div>

          <!-- Document data -->
          <div class="field">
            <label class="document-data-label" for="document-data">Document data</label>
            <button class="add-document-btn" @click="addDocumentField()">+</button>
            <div class="document-fields-container">
              <div v-for="(document, index) in session.documentData[currentLanguage]" :key="index" class="document-field">
                <input type="text" v-model="document.url" placeholder="Document URL">
                <input type="text" v-model="document.title" placeholder="Document Title">
              </div>
            </div>
          </div>
        </div>

        <div class="right-sidebar">
          <!-- Status -->
          <div class="field status-type-field">
            <label>Status:</label>
            <select v-model="session.status">
              <option value="0" :selected="session.status === '0'">Draft</option>
              <option value="1" :selected="session.status === '1'">Active</option>
              <option value="-1" :selected="session.status === '-1'">Hidden</option>
            </select>
          </div>

          <!-- Content type -->
          <div class="field content-type-field">
            <label>Type:</label>
            <select v-model="session.type">
              <option value="0" :selected="session.type === '0'">Text</option>
              <option value="1" :selected="session.type === '1'">Video</option>
              <option value="2" :selected="session.type === '2'">Quiz</option>
            </select>
          </div>

          <!-- Quiz data -->
          <!-- Displayed only if type is quiz type(1) -->
          <div v-if="session.type == '2'">
            <div class="field quiz-field">
              <label>Select Quiz:</label>
              <select @change="updateQuizId($event.target.value)">
                <option value="" selected disabled hidden>Select Quiz</option>
                <option v-for="quiz in quizzes" :key="quiz.id" :value="quiz.id" :selected="quiz.id === session.quizId">
                  {{ quiz.quizName.en.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="field task-type-field">
            <label>Task Type:</label>
            <select @change="updateTaskTypeId($event.target.value)">
              <option class="default" value="null" selected>Select Task Type</option>
              <option v-for="taskType in taskTypes" :key="taskType.id" :value="taskType.id" :selected="taskType.id === session.taskType">
                {{ taskType.title }}
              </option>
            </select>
          </div>

          <div class="field active-from-field">
            <label for="session-active-from">Active From:</label>
            <Datepicker v-model="session.activeFrom" format="yyyy-MM-dd" :enable-time-picker="false" />
          </div>

          <div class="field">
            <label class="input-with-tooltip" for="bonus-points-end-date">Availability:
              <span class="tooltip">
                This session will be available for the selected plans
              </span>
            </label>
            <div class="availability-options">
              <div class="option">
                <label class="custom-check green">
                  <label for="explore">Explore</label>
                  <input type="checkbox" id="explore" v-model="session.explore">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="option">
                <label class="custom-check green">
                  <label for="learn">Learn</label>
                  <input type="checkbox" id="learn" v-model="session.learn">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="option">
                <label class="custom-check green">
                  <label for="grow">Grow</label>
                  <input type="checkbox" id="grow" v-model="session.grow">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="field points-field">
            <label for="session-points">Points:</label>
            <input type="number" id="session-points" v-model="session.pointsData.points">
          </div>

          <div class="field bonus-points-end-date">
            <label class="input-with-tooltip" for="bonus-points-end-date">Bonus End Date:
              <span class="tooltip">
                The date when the bonus points will no longer be available
              </span>
            </label>
            <Datepicker v-model="session.pointsData.bonusPointsEndDate" format="yyyy-MM-dd" :enable-time-picker="false" />
          </div>

          <div class="field points-field">
            <label class="input-with-tooltip" for="max-bonus-points-percentage">Max Bonus %:
              <span class="tooltip">
                Maximum percentage of bonus points that can be earned
              </span>
            </label>
            <input type="number" id="max-bonus-points-percentage" v-model="session.pointsData.maxBonusPointsPercentage"/>
          </div>

          <div class="centered">
            <button class="save-btn transition" @click="createSessionHandler()">
              Create
            </button>
          </div>
          <p v-if="errorMsg" class="error-message">{{errorMsg}}</p>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import knowledgeMixin from "@/mixins/knowledge";
import quizMixin from "@/mixins/quiz";
import Popup from "@/components/shared/Popup";
import TextEditor from '@/components/shared/TextEditor'
import dayjs from 'dayjs';

export default {
  name: "NewSession",
  mixins: [knowledgeMixin, quizMixin],
  components: {
    Popup,
    TextEditor,
  },
  props: {
    courseId: Number,
    moduleId: Number,
    sectionId: Number,
  },
  data() {
    return {
      session: {
        title: {
          en: {
            title: "",
          },
          de: {
            title: "",
          },
        },
        description: {
          en: {
            description: "",
          },
          de: {
            description: "",
          },
        },
        videoData: {
          en: {
            videoId: "",
            thumbnail: "",
            previewId: '',
            duration: null,
          },
          de: {
            videoId: "",
            thumbnail: "",
            previewId: '',
            duration: null,
          }
        },
        documentData: {
          en: [],
          de: []
        },
        quizId: null,
        status: 1,
        type: 0,
        taskType: null,
        activeFrom: null,
        explore: null,
        learn: null,
        grow: null,
        pointsData: {
          points: null,
          bonusPointsEndDate: null,
          maxBonusPointsPercentage: null
        }
      },
      currentLanguage: 'en',
      videoStyleSelect: false,
      quizzes: null,
      taskTypes: null,
      quizSelect: false,
      errorMsg: ''
    };
  },
  methods: {
    createSessionHandler() {
      if (this.session.activeFrom) {
        const date = dayjs(this.session.activeFrom);
        this.session.activeFrom = date.format('YYYY-MM-DD');
      }

      if (!this.validateSessionInputs()) {
        setTimeout(() => {
          this.errorMsg = '';
        }, 3000);
        return;
      }

      this.createSession(
        this.courseId,
        this.moduleId,
        this.sectionId,
        this.session
      ).then(() => {
        this.$emit("created");
      });
    },
    validateSessionInputs() {
      const activeFrom = !!this.session.activeFrom;
      const points = !!this.session.pointsData.points;
      const bonusPointsEndDateExists = !!this.session.pointsData.bonusPointsEndDate;
      const maxBonusPointsExists = !!this.session.pointsData.maxBonusPointsPercentage;
      if ((bonusPointsEndDateExists && !maxBonusPointsExists) || (!bonusPointsEndDateExists && maxBonusPointsExists)) {
        this.errorMsg = "Both Bonus End Date and Max Bonus Percentage are required together.";
        return false;
      }

      if ((bonusPointsEndDateExists && !activeFrom)) {
        this.errorMsg = "You cannot set Bonus End Date without Active From";
        return false;
      }

      if (!points && (bonusPointsEndDateExists || maxBonusPointsExists)) {
        this.errorMsg = "Points must be set to add Bonus End Date or Max Bonus Percentage.";
        return false;
      }

      if (this.session.activeFrom && this.session.pointsData.bonusPointsEndDate) {
        const activeFromDate = dayjs(this.session.activeFrom);
        const bonusPointsEndDate = dayjs(this.session.pointsData.bonusPointsEndDate);
        if (bonusPointsEndDate.isBefore(activeFromDate)) {
          this.errorMsg = "Bonus Points End Date should be after the Active From date";
          return false;
        }

        this.session.pointsData.bonusPointsEndDate = bonusPointsEndDate.format('YYYY-MM-DD');
      }

      if (this.session.pointsData.maxBonusPointsPercentage && this.session.pointsData.maxBonusPointsPercentage < 0) {
        this.errorMsg = "Bonus Points cannot be a negative number";
        return false;
      }

      if ((this.session.pointsData.points !== null && this.session.pointsData.points != '') && this.session.pointsData.points < 1) {
        this.errorMsg = "Session points should be a positive number";
        return false;
      }

      return true;
    },
    videoStyleAway() {
      this.videoStyleSelect = false;
    },
    getAllQuizzesHandler() {
      this.getQuizzes().then((res) => {
        this.quizzes = res.quizzes;
      });
    },
    quizAway() {
      this.quizSelect = false;
    },
    getQuizName(quizId) {
      let selectedQuiz = this.quizzes.find((quiz) => {
        return quiz.id == quizId;
      });

      return selectedQuiz.quizName.en.name;
    },
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    setDescription(body) {
      this.session.description[this.currentLanguage].description = body;
    },
    addDocumentField() {
      this.session.documentData[this.currentLanguage].push({ url: '', title: '' });
    },
    updateQuizId(selectedValue) {
      this.session.quizId = selectedValue;
    },
    updateTaskTypeId(selectedValue) {
      this.session.taskType = selectedValue === "null" ? null : selectedValue;
    },
    fetchTaskTypes() {
      this.getTaskTypes()
        .then((taskTypes) => {
          if(taskTypes) {
            this.taskTypes = taskTypes;
          }
        })
        .catch((error) => {
          console.error("Error fetching task types:", error);
        });
    }
  },
  mounted() {
    this.getAllQuizzesHandler();
    this.fetchTaskTypes();
  },
};
</script>

<style scoped>
  .availability-options {
    display: flex;
    width: 100%;
    padding: 2% 0px 1% 4%;
    flex-direction: column;
    align-items: flex-start !important;
    border: 1px solid #a7a7a7;
    border-radius: 8px;
  }

  .availability-options .option {
    margin-bottom: 0px;
  }

  .availability-options .option .custom-check {
    margin-bottom: 0px;
  }
  
  label {
    color: #44444f;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    flex: 0 0 100px;
    text-align: left;
    margin-right: 7px;
  }

  input {
    border: 1px solid #e2e2ea;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-size: 20px;
    outline: none;
  }

  .language-switcher button.active {
    background-color: #3c6e71;
    color: white;
  }

  .document-field {
    width: 100%;
  }

  .document-field input {
    margin-bottom: 0;
    width: 49%;
  }

  .document-field input:first-child {
    margin-right: 2%;
  }

  .add-document-btn {
    background-color: #3c6e71;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    padding: 6px 14px;
    margin-left: 15px;
  }

  .document-data-label {
    margin-bottom: 25px;
  }

  .document-data-label, .content-type-field > label, .quiz-field > label, .status-type-field > label, .active-from-field > label, .points-field > label, .task-type-field > label {
    flex: 0 0 100px;
    display: inline-block;
  }
  
  .quiz-field select, .task-type-field select{
    max-width: 215px;
  }

  .right-sidebar select {
    padding: 3px;
    color: #696974;
    font-weight: 500;
    border: 1px solid #e2e2ea;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
  }

  .knowledge-session {
    display: flex;
  }

  .content {
    flex-grow: 1;
    margin-left: 5%;
    flex-basis: 70%;
    padding: 30px 60px 20px 0px;
  }

  .content .field {
    margin-bottom: 25px;
  }

  .right-sidebar {
    overflow: auto;
    padding: 18px;
    flex-basis: 24%;
    background-color: #FAFAFB;
  }

  .right-sidebar div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .save-btn {
    background: #3c6e71;
    color: #fff;
    border-radius: 8px;
    outline: none;
    border: 2px solid #3c6e71;
    cursor: pointer;
    padding: 5px 15px;
    font-size: 16px;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .page-title {
    display: inline-block;
  }

  .language-switcher {
    float: right;
  }

  .language-switcher button {
    border: none;
    padding: 9px 15px;
    font-weight: 600;
  }

  .popup-content {
    display: flex;
  }

  .field.points-field input {
    background: white;
    max-height: 40px;
  }

  .input-with-tooltip {
    position: relative;
  }
  
  .tooltip {
    display: none;
    position: absolute;
    background: #f9f9f9;
    color: #333;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    bottom: 120%;
    width: 200px;
    z-index: 3;
  }

  .input-with-tooltip:hover .tooltip {
    display: block;
  }

  .bold {
    font-weight: bold;
  }

  .task-type-field .default {
    color: #999;
  }
</style>
