<template>
  <div class="crypto-overview">
    <h1>Cryptos <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getCryptoHandler"
      @edit="editHandler" 
    />

    <!-- Inspect Popup -->
    <InspectCrypto
      v-if="editItem"
      :item="editItem"
      :categories="categories"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import cryptoMixin from '@/mixins/crypto'
  import securityMixin from '@/mixins/securities'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectCrypto from './popups/InspectCrypto'

  export default {
    name: 'CryptoOverview',
    mixins: [securityMixin, cryptoMixin, userMixin],
    components: {
      DataTableHolder,
      InspectCrypto
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Parent Categories',
            name: 'parentCategories',
            sortable: false
          },
          {
            title: 'Price',
            name: 'price',
            sortable: true
          },
          {
            title: 'Price Date',
            name: 'priceDate',
            sortable: true
          },
          {
            title: '24h Return',
            name: 'return1d',
            sortable: true
          },
          {
            title: 'Mkt Cap Ranking',
            name: 'marketCapRanking',
            sortable: true
          },
          {
            title: 'Mkt Cap Cat Ratio',
            name: 'marketCapCatRatio',
            sortable: true
          },
          {
            title: 'Cons. Mech.',
            name: 'consensusMechanism',
            sortable: true
          },
          {
            title: 'Parent Crypto',
            name: 'parentCrypto',
            sortable: false
          },
          {
            title: 'Layer',
            name: 'layer',
            sortable: true
          },
          {
            title: 'CMC ID',
            name: 'coinMarketCapId',
            sortable: true
          },
          {
            title: 'Sentifi OK',
            name: 'sentifiOk',
            sortable: true
          },
          {
            title: 'Sentifi Topic Id',
            name: 'sentifiTopicId',
            sortable: true
          },
          {
            title: 'Is Active',
            name: 'isActive',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null,
        categories: null
      }
    },
    methods: {
      // * Get crypto from API
      getCryptoHandler(data) {
        this.getSecurities(data, 4)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let securitiesFormatted = [];
            // * Loop through cryptos and add only the needed properties
            for (const security of res.securities) {
              let securityObj = {
                id:                 security.id,
                name:               security.name,
                parentCategories:   security.classification_data.parent_categories && security.classification_data.parent_categories.length > 0 ? security.classification_data.parent_categories.join(', ') : null,
                price:              security.price_data.price.toFixed(2),
                priceDate:          security.price_data.price_date,
                return1d:           security.performance_data.return_1d ? (parseFloat(security.performance_data.return_1d) * 100).toFixed(2) + '%' : null,
                marketCapRanking:   security.market_cap_data.market_cap_ranking,
                marketCapCatRatio:  security.market_cap_data.market_cap_cat_ratio,
                consensusMechanism: security.crypto_data.consensus_mechanism == "n/a" ? null : 
                                    security.crypto_data.consensus_mechanism == "Proof Of Work" ? "POW" :
                                    security.crypto_data.consensus_mechanism == "Proof Of Stake" ? "POS" : "Other",
                parentCrypto:       security.crypto_data.parent_crypto_id ? security.crypto_data.parent_crypto_id.name : null,
                layer:              security.crypto_data && typeof security.crypto_data.l1_l2 == 'string' ? security.crypto_data.l1_l2 : null,
                coinMarketCapId:    security.coin_market_cap_id,
                sentifiOk:           security.sentiment_data.sentifi_ok,
                sentifiTopicId:      security.sentiment_data.sentifi_topic_id,
                isActive:           security.is_active,
                addedOn:            security.added_on,
                updatedOn:          security.updated_on
              }
              // * Add formatted crypto to formatted equities array
              securitiesFormatted.push(securityObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = securitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(security => {
          return security.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      getCryptoCategoriesHandler() {
        this.getCryptoCategories()
          .then(res => {
            this.categories = {
              coinCategories: res.coinCategories,
              tokenCategories: res.tokenCategories
            };
          })
      }
    },
    mounted() {
      this.getCryptoCategoriesHandler();
    }
  }
</script>

<style scoped>

</style>