<template>
  <popup
    title="Preview Lecture"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div style="position: relative; padding-top: 56.25%;">
        <iframe
          :src="`${iframeUrl}`"
          loading="lazy"
          style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"
        ></iframe>
      </div>
    </template>
  </popup>
</template>

<script>
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'PreviewVideo',
    components: {
      Popup
    },
    props: {
      sessionUrl: String
    },
    data() {
      return {
        iframeUrl: null
      }
    },
    mounted() {
      this.iframeUrl = this.sessionUrl.replace('https://video.bunnycdn.com/play/', 'https://iframe.mediadelivery.net/embed/');
    }
  }
</script>

<style scoped>

</style>