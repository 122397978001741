<template>
  <div class="quiz-questions" v-if="quiz">
    <h1>Quiz Questions <small v-if="quiz.questions">({{quiz.questions.length}})</small></h1>
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :actions="quizId == 1 ? ['new', 'edit', 'delete', 'answers'] : ['new', 'edit', 'delete']"
      page="Quiz Questions"
      @add-new="newQuestionPopup = true"
      @edit="editHandler"
      @delete="deleteHandler"
      @view-answers="viewAnswersHandler"
    />
      <!-- :updateResults="updateResults" -->
      <!-- @update-table="getOrdersHandler" -->

    <NewQuestion
      v-if="newQuestionPopup"
      :quizId="+quizId"
      :quizImages="quizImages"
      @close="newQuestionPopup = false"
      @added="questionUpdated"
    />
    <EditQuestion
      v-if="editItem"
      :questionId="editItem.id"
      :quizImages="quizImages"
      @close="editItem = null"
      @updated="questionUpdated"
    />
    <DeleteQuestion
      v-if="deleteItem"
      :questionId="deleteItem.id"
      @close="deleteItem = null"
      @deleted="questionUpdated"
    />
    <ViewAnswers
      v-if="viewAnswers"
      :item="viewAnswers"
      @close="viewAnswers = null"
    />
  </div>
</template>

<script>
  import quizMixin from '@/mixins/quiz'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewQuestion from './popups/NewQuestion'
  import EditQuestion from './popups/EditQuestion'
  import DeleteQuestion from './popups/DeleteQuestion'
  import ViewAnswers from './popups/ViewAnswers'

  export default {
    name: 'QuizQuestions',
    mixins: [quizMixin],
    components: {
      DataTableHolder,
      NewQuestion,
      EditQuestion,
      DeleteQuestion,
      ViewAnswers
    },
    data() {
      return {
        quizId: this.$route.params.id,
        quiz: null,
        newQuestionPopup: false,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Title',
            name: 'title',
            sortable: false
          },
          {
            title: 'Subtitle',
            name: 'subtitle',
            sortable: false
          },
          {
            title: 'Type',
            name: 'type',
            sortable: false
          },
          {
            title: 'Is Required',
            name: 'isRequired',
            sortable: false
          },
          {
            title: 'Status',
            name: 'status',
            sortable: false
          },
          {
            title: 'Order',
            name: 'order',
            sortable: false
          }
        ],
        editItem: null,
        deleteItem: null,
        quizImages: {
          backgrounds: null,
          sides: null,
          icons: null,
          sliderIcons: null,
        },
        viewAnswers: null
      }
    },
    methods: {
      getQuizHandler() {
        this.getQuiz(this.quizId)
          .then(res => {
            this.quiz = res.quiz;
            this.rows = [];
            
            for (const question of res.quiz.questions) {
              let formattedQuestion = {
                id: question.id,
                title: question.content.translations.en.title,
                subtitle: question.content.translations.en.subtitle,
                type: question.type,
                isRequired: question.isRequired,
                status: question.status,
                order: question.sort
              }
              
              this.rows.push(formattedQuestion);
            }
          })
      },
      questionUpdated() {
        this.newQuestionPopup = false;
        this.editItem = null;
        this.deleteItem = null;
        this.getQuizHandler();
      },
      editHandler(item) {
        this.editItem = this.quiz.questions.find(question => {
          return question.id == item.id;
        });
      },
      deleteHandler(item) {
        this.deleteItem = this.quiz.questions.find(question => {
          return question.id == item.id;
        });
      },
      getQuizImages() {
        this.getImages()
          .then(res => {
            this.quizImages = res.quizImages;
          })
      },
      viewAnswersHandler(item) {
        this.viewAnswers = item;
      }
    },
    mounted() {
      this.getQuizHandler();

      this.getQuizImages();
    }
  }
</script>

<style scoped>

</style>