<template>
  <!-- Inspect Security Market Data Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
      :title="securityTitle"
      @close="$emit('close')"
  >
    <template v-slot:content>

      <span class="info-text">
        {{ securityTypeName }} ID: {{item.id}}
      </span>

      <span class="info-text">
        {{ securityTypeName }} ISIN: {{item.isin}}
      </span>

      <span class="info-text">
        {{ securityTypeName }} Name: {{item.name}}
      </span>

      <a :href="getSecurityUrl()" target="_blank">Go to the {{ securityTypeName }} on the main app</a>

      <ChartBox
        v-if="graphData"
        :graph-data="graphData"
        :item="item"
        type="security"
        :currency="item.code"
      />

<!--      Delete historical data between startDate and endDate-->
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPriceToBeDeleted" :disabled="updateLoading">
          <span class="checkmark"></span>
          Delete Prices Between:
        </label>
        <input type="text" id="startDateDelete" v-model="startDateDelete" placeholder="Start included (default)">
        <input v-if="secondPriceDate" type="text" id="endDateDelete" v-model="endDateDelete" :placeholder="secondPriceDate + ' (default)'">
        <input v-else type="text" id="endDateDelete" v-model="endDateDelete" placeholder="Type in end date...">
        <span v-if="secondPriceDate == null" class="green-message">
          No big price gap detected
        </span>
        <span v-if="(startDateDelete && !isDateFormat(startDateDelete)) || (endDateDelete && !isDateFormat(endDateDelete))" class="remove-warning">
          Format : dd Mmm yyyy
        </span>
        <span v-if="(startDateDelete && endDateDelete && isEarlierThan(endDateDelete, startDateDelete)) || (startDateDelete && secondPriceDate && !endDateDelete && isEarlierThan(secondPriceDate, startDateDelete))" class="remove-warning">
          End date is earlier than start date !
        </span>
      </div>

<!--      Multiply historical prices by factor between startDate and endDate-->
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPriceToBeMultiplied" :disabled="updateLoading">
          <span class="checkmark"></span>
          Multiply Prices by Factor Between:
        </label>
        <input type="text" id="startDateMultiply" v-model="startDateMultiply" placeholder="Start included (default)">
        <input type="text" id="endDateMultiply" v-model="endDateMultiply" placeholder="End included (default)">
        <span v-if="(startDateMultiply && !isDateFormat(startDateMultiply)) || (endDateMultiply && !isDateFormat(endDateMultiply))" class="remove-warning">
          Format : dd Mmm yyyy
        </span>
        <span v-if="startDateMultiply && endDateMultiply && isEarlierThan(endDateMultiply, startDateMultiply)" class="remove-warning">
          End date is earlier than start date !
        </span>
        <div class="field">
          <input type="text" id="factor" v-model="factor" :placeholder="defaultFactor + ' (default factor)'">
        </div>
      </div>

      <div class="field" v-if="security.is_active">
        <label for="active-comment">Active Comment:</label>
        <textarea id="active-comment" v-model="security.active_comment" placeholder="Active Comment..."></textarea>
      </div>

<!--      Deactivate security permanently-->
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="security.permanent_deactivation">
          <span class="checkmark"></span>
          Permanent Deactivation
        </label>
      </div>

<!--      Admin comment-->
      <div class="field" v-if="security.permanent_deactivation != item.permanent_deactivation">
        <label for="admin-comment">Admin Comment:</label>
        <textarea id="admin-comment" v-model="security.admin_comment" placeholder="Give a reason for permanent deletion..."></textarea>
      </div>

<!--      Confirmation-->
      <div class="field" v-if="(security.permanent_deactivation != item.permanent_deactivation) || isPriceToBeDeleted || isPriceToBeMultiplied">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" :placeholder="securityConfirmComment" v-model="confirm">
      </div>

<!--      Save button-->
      <button class="transition save-btn" @click="updateSecurityHandler()" :disabled="
        updateLoading ||
        isEarlierThan(endDateDelete, startDateDelete) ||
        // isEarlierThan(secondPriceDate, startDateDelete) ||
        isEarlierThan(endDateMultiply, startDateMultiply) ||
        permanentDeletionNotConfirmed ||
        priceChangesNotConfirmed">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
import securitiesMixin from '@/mixins/securities.vue'
import userMixin from '@/mixins/user.vue'

import Popup from '@/components/shared/Popup.vue'
import ChartBox from '@/components/shared/charts/ChartBox.vue'

export default {
    name: 'InspectMarketDataMds',
    mixins: [securitiesMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup,
      ChartBox
    },
    data() {
      return {
        securityUrlName: this.item.url_name,
        securityType: this.item.type,

        securityTypeName: null,
        securityTitle: null,
        securityConfirmComment: null,

        selectedSecurity: null,
        updateLoading: false,
        graphData: null,
        security: {...this.item},
        confirm: null,

        firstPriceDate: null,
        secondPriceDate: null,

        isPriceToBeDeleted: false,
        startDateDelete: null,
        endDateDelete: null,

        isPriceToBeMultiplied: false,
        factor: null,
        defaultFactor: 0.01,
        startDateMultiply: null,
        endDateMultiply: null,

        defaultStartDate: '2000-01-01',
        defaultEndDate: '2999-01-01',

        longIntervals: [],

      }
    },
    methods: {
      getSecurityUrl() {
        return "https://umushroom.com/en/" + this.getSecurityTypeName().toLowerCase() + "/" + this.securityUrlName;
      },
      getSecurityTypeName() {
        switch (this.securityType) {
          case 1:
            return 'Equity';
          case 2:
            return 'Fund';
          case 3:
            return 'ETF';
          case 4:
            return 'Crypto';
        }
      },
      getSecurityTitle() {
        return "Inspect " + this.securityTypeName + " Market Data (Mds)";
      },
      getSecurityConfirmComment() {
        return "Type \"CONFIRM\" to be able to change the " + this.securityTypeName + " status";
      },
      // * Get security chart data
      getSecurityChartDataHandler() {
        this.getSecurityChartData(this.item.id)
          .then(res => {
            this.graphData = res;
            this.firstPriceDate = res.datasets.Max.labels[0];
            this.secondPriceDate = null;

            // Find long intervals
            this.longIntervals = this.findLongIntervals(res.datasets.Max.labels);
            if (this.longIntervals.length > 0) {
              this.secondPriceDate = this.longIntervals[0].endDate;
            }
          })
      },
      updateSecurityHandler() {
        this.updateLoading = true;
        let dataForStatusChange = {
          permanentDeactivation: this.security.permanent_deactivation,
          candidate: this.security.candidate,
          isActive: this.security.is_active,
          adminComment: this.security.admin_comment,
          activeComment: this.security.active_comment,
          count: 0,
          updatedOn: '2000-01-01'
        }

        if (!this.startDateDelete) {
          this.startDateDelete = this.defaultStartDate;
        } else {
          this.formatDateForBE(this.startDateDelete);
        }
        if (!this.endDateDelete) {
          this.endDateDelete = this.formatDateForBE(this.secondPriceDate);
        } else {
          this.formatDateForBE(this.endDateDelete);
        }
        if (!this.startDateMultiply) {
          this.startDateMultiply = this.defaultStartDate;
        } else {
          this.formatDateForBE(this.startDateMultiply);
        }
        if (!this.endDateMultiply) {
          this.endDateMultiply = this.defaultEndDate;
        } else {
          this.formatDateForBE(this.endDateMultiply);
        }
        if (!this.factor) {
          this.factor = this.defaultFactor;
        }

        Promise.all([
          this.isPriceToBeDeleted ?
            this.deletePricesBetween(this.startDateDelete, this.endDateDelete, this.security.id)
                .then(() => {
                  // this.$emit('updated')
                })
                .catch((err) => {
                  if (err.code == 401) {
                    this.logout();
                  }
                })
              : Promise.resolve(), // if not deleting, resolve a dummy Promise
          this.isPriceToBeMultiplied ?
              this.multiplyPricesBetween(this.formatDateForBE(this.startDateMultiply), this.formatDateForBE(this.endDateMultiply), this.factor, this.security.id)
                  .then(() => {
                    // this.$emit('updated')
                  })
                  .catch((err) => {
                    if (err.code == 401) {
                      this.logout();
                    }
                  })
              : Promise.resolve(), // if not deleting, resolve a dummy Promise
          this.updateSecurity(dataForStatusChange, this.security.id)
              .then(() => {
                this.$emit('updated')
              })
              .catch((err) => {
                if (err.code == 401) {
                  this.logout();
                }
              })
        ]).then(() => {
          // execute code after all promises complete
          this.updateLoading = false;
        })
      },

      formatDateForBE(inputDate) {
        // Parse the input date string using Date.parse() method
        let date = new Date(Date.parse(inputDate));

        // Extract the year, month and day components from the Date object
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is 0-indexed
        let day = date.getDate();

        // Convert month and day to zero-padded strings
        let monthStr = month.toString().padStart(2, '0');
        let dayStr = day.toString().padStart(2, '0');

        // Return the formatted date string in "yyyy-mm-dd" format
        return `${year}-${monthStr}-${dayStr}`;
      },

      isEarlierThan(d1, d2) {
        const date1 = new Date(this.formatDateForBE(d1));
        const date2 = new Date(this.formatDateForBE(d2));

        return date1 < date2;
      },

      isDateFormat(dateString) {
        const pattern1 = /^\d{2} (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;
        const pattern2 = /^\d{4}-\d{2}-\d{2}$/;
        return pattern1.test(dateString) || pattern2.test(dateString);
      },

      findLongIntervals(dates) {
        // Helper function to convert date string to Date object
        function parseDate(dateStr) {
          const [day, month, year] = dateStr.split(' ');
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const monthIndex = monthNames.indexOf(month);
          return new Date(year, monthIndex, day);
        }

        // Parse the date strings into Date objects
        const parsedDates = dates.map(dateStr => parseDate(dateStr));

        let threshold;
        switch (this.item.quality_control_data.nav) {
          // case 'daily':
          //   threshold = 30;
          //   break;
          // case 'weekly':
          //   threshold = 30;
          //   break;
          // case 'monthly':
          //   threshold = 90;
          //   break;
          // case 'quarterly':
          //   threshold = 100;
          //   break;
          default:
            threshold = this.security.quality_control_data.max_interval;
        }

        // Initialize an empty array to store intervals greater than
        const longIntervals = [];

        for (let i = 0; i < parsedDates.length - 1; i++) {
          const startDate = parsedDates[i];
          const endDate = parsedDates[i + 1];
          const diffTime = endDate - startDate;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays >= threshold) {
            longIntervals.push({ startDate: dates[i], endDate: dates[i + 1], diffDays });
          }
        }

        return longIntervals;
      }

    },
    filters: {
      // * Date format filter
      formatDate(dateInput) {
        // * Parse the date
        let date = new Date(dateInput);
        let dateString = '';

        // * Get the day and add 0 if less than 10
        if (date.getDate() < 10) {
          dateString += '0' + date.getDate() + '.';
        } else {
          dateString += date.getDate() + '.';
        }
        
        // * Get the month and add 0 if less than 10

        // * Add to parsed month 1 because in JS months start from 0 :)
        let realMonth = +date.getMonth() + 1;
        if (realMonth < 10) {
          dateString += '0' + realMonth + '.';
        } else {
          dateString += realMonth + '.';
        }

        // * Get the year
        dateString += date.getFullYear();

        return dateString;
      },
      formatDateTime(dateInput) {
        // * Parse the date
        let date = new Date(dateInput);
        let dateString = '';

        // * Get the day and add 0 if less than 10
        if (date.getDate() < 10) {
          dateString += '0' + date.getDate() + '.';
        } else {
          dateString += date.getDate() + '.';
        }
        
        // * Get the month and add 0 if less than 10

        // * Add to parsed month 1 because in JS months start from 0 :)
        let realMonth = +date.getMonth() + 1;
        if (realMonth < 10) {
          dateString += '0' + realMonth + '.';
        } else {
          dateString += realMonth + '.';
        }

        // * Get the year
        dateString += date.getFullYear();

        let time = new Date(date).toLocaleTimeString();

        dateString += ' | ';
        dateString += time;

        return dateString;
      }
    },

  watch: {
    'equity.is_active': function(newVal) {
      if (newVal) {
        this.equity.permanent_deactivation = false;
        this.equity.candidate = false;
      }
    },
    'equity.candidate': function(newVal) {
      if (newVal) {
        this.equity.permanent_deactivation = false;
        this.equity.is_active = false;
      }
    },
    'equity.permanent_deactivation': function(newVal) {
      if (newVal) {
        this.equity.is_active = false;
        this.equity.candidate = false;
      }
    },
  },

  computed: {
    permanentDeletionNotConfirmed() {
      return ((this.security.permanent_deactivation !== this.item.permanent_deactivation) && this.confirm !== 'CONFIRM')
        || ((this.security.permanent_deactivation !== this.item.permanent_deactivation) && !this.security.admin_comment);
    },
    priceChangesNotConfirmed(){
      return (this.isPriceToBeDeleted || this.isPriceToBeMultiplied) && this.confirm !== 'CONFIRM';
    }
  },
    mounted() {
      this.getSecurityChartDataHandler();
      this.securityTypeName = this.getSecurityTypeName();
      this.securityTitle = this.getSecurityTitle();
      this.securityConfirmComment = this.getSecurityConfirmComment();
    }
  }
</script>

<style scoped>
  .info-text {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
  
  .history-item {
    display: block;
  }

  .green-message {
    color: green;
  }
</style>
