<template>
  <popup title="Add Scheduled Notification" @close="$emit('close')">
    <template v-slot:content>
      <div class="language-switch">
        <a :class="{active: activeLang == 'en'}" @click="activeLang = 'en'">EN</a>
        <a :class="{active: activeLang == 'de'}" @click="activeLang = 'de'">DE</a>
      </div>
      <NotificationEditor
        v-if="editModeActive"
        :notificationTypeId="67"
        :data="notification"
        :contentData="notification.messageData"
        :language="activeLang"
        @update-items="updateItems"
        @update-actions="updateActions"
      />
      <div class="field" v-if="activeLang == 'en'">
        <label for="message-en">Message</label>
        <div class="message-preview">
          <span v-for="(item, index) in notification.messageData.en.items" :key="index">
            {{ item.text || item.title }}
          </span>
        </div>
      </div>
      <div class="field" v-if="activeLang == 'de'">
        <label for="message-de">Message DE</label>
        <div class="message-preview">
          <span v-for="(item, index) in notification.messageData.de.items" :key="index">
            {{ item.text || item.title }}
          </span>
        </div>
      </div>
      <div class="plans">
        <div class="field title-field">
          <label>Plans</label>
          <label class="custom-check green">
            <input type="checkbox" @click="checkAllPlans()" :checked="notification.planData.length == 3">
            <span class="checkmark"></span>
            All
          </label>
        </div>
        <div class="three-cols">
          <div class="field">
            <label class="custom-check green">
              <input type="checkbox" v-model="notification.planData" :value="0">
              <span class="checkmark"></span>
              Explore
            </label>
          </div>
          <div class="field">
            <label class="custom-check green">
              <input type="checkbox" v-model="notification.planData" :value="1">
              <span class="checkmark"></span>
              Learn
            </label>
          </div>
          <div class="field">
            <label class="custom-check green">
              <input type="checkbox" v-model="notification.planData" :value="2">
              <span class="checkmark"></span>
              Grow
            </label>
          </div>
        </div>
      </div>
      <div class="field">
        <label for="organization">Organization ID</label>
        <input type="number" id="organization" placeholder="If empty all users meeting the other parameters will be notified" v-model="notification.organization">
      </div>
      <div class="roles" v-if="notification.organization">
        <div class="field title-field">
          <label>Roles</label>
          <label class="custom-check green">
            <input type="checkbox" @click="checkAllRoles()" :checked="notification.roleData.length == 4">
            <span class="checkmark"></span>
            All
          </label>
        </div>
        <div class="four-cols">
          <div class="field">
            <label class="custom-check green">
              <input type="checkbox" v-model="notification.roleData" :value="1">
              <span class="checkmark"></span>
              Admin
            </label>
          </div>
          <div class="field">
            <label class="custom-check green">
              <input type="checkbox" v-model="notification.roleData" :value="2">
              <span class="checkmark"></span>
              Editor
            </label>
          </div>
          <div class="field">
            <label class="custom-check green">
              <input type="checkbox" v-model="notification.roleData" :value="6">
              <span class="checkmark"></span>
              Member
            </label>
          </div>
          <div class="field">
            <label class="custom-check green">
              <input type="checkbox" v-model="notification.roleData" :value="9">
              <span class="checkmark"></span>
              Prospect
            </label>
          </div>
        </div>
      </div>
      <div class="two-cols">
        <div class="field">
          <label>Send Date (UTC time)</label>
          <Datepicker
            v-model="notification.sendDate"
            format="dd.MM.yyyy,HH:mm"
            :enable-time-picker="true"
            :minutes-increment="15"
            :start-time="{hours: 12, minutes: 0}"
            :utc="true"
            :min-date="new Date(new Date().toISOString().replace('Z', ''))"
          />
          <span class="zurich-time" v-if="notification.sendDate">Zurich time: {{ convertToZurichTime(notification.sendDate) }}</span>
        </div>
        <div class="field">
          <label>Expiration Date (UTC time)</label>
          <Datepicker
            v-model="notification.expirationDate"
            format="dd.MM.yyyy,HH:mm"
            :enable-time-picker="true"
            :minutes-increment="15"
            :start-time="{hours: 12, minutes: 0}"
            :utc="true"
            :min-date="new Date(notification.sendDate)"
            :disabled="!notification.sendDate"
          />
          <span class="zurich-time" v-if="notification.expirationDate">Zurich time: {{ convertToZurichTime(notification.expirationDate) }}</span>
        </div>
      </div>
      <div class="two-cols">
        <div class="field">
          <label class="push-label">Push Notification</label>
          <label class="custom-check green">
            <input type="checkbox" v-model="notification.isPush">
            <span class="checkmark"></span>
            Is Push
          </label>
        </div>
        <div class="field" v-if="notification.status < 1">
          <label>Status</label>
          <div class="custom-select" :class="{open: statusSelect}" @click="statusSelect = !statusSelect" v-click-away="() => statusSelect = false">
            <span v-if="notification.status != null">{{statuses.find(status => status.id == notification.status).name}}</span>
            <span v-else>Select Status</span>
            <div class="select-dropdown" v-if="statusSelect">
              <a
                v-for="(status, index) in statuses"
                :key="index"
                @click="notification.status = status.id"
              >
                {{status.name}}
              </a>
            </div>
          </div>
        </div>
        <div class="field" v-else>
          <label>Status</label>
          <input type="text" disabled value="Started" v-if="notification.status == 1">
          <input type="text" disabled value="Completed" v-if="notification.status == 2">
        </div>
      </div>
      <div class="field">
        <label for="notes">Notes</label>
        <textarea class="auto-textarea" id="notes" v-model="notification.notes" rows="1"></textarea>
      </div>
      <button v-if="notification.status < 1" class="save-btn transition" @click="saveHandler()">Save</button>

      <button v-if="notificationToEdit" @click="sendTestNotification()" class="save-btn">Send test notification</button>
      <p v-if="testSent">Test notification sent</p>
    </template>
  </popup>
</template>
  
<script>
  import marketingMixin from '@/mixins/marketing'

  import Popup from '@/components/shared/Popup'
  import NotificationEditor from '@/components/translations/NotificationEditor.vue';
  
  export default {
    name: 'NewScheduledNotification',
    mixins: [marketingMixin],
    components: {
      Popup,
      NotificationEditor
    },
    props: {
      notificationToEdit: Object
    },
    data() {
      return {
        activeLang: 'en',
        statusSelect: false,
        statuses: [
          {
            id: -1,
            name: 'Draft',
          },
          {
            id: 0,
            name: 'Active',
          }
        ],
        notification: {
          messageData: {
            en: {
              items: this.notificationToEdit?.messageData.en.items ?? null,
              actions: null
            },
            de: {
              items: this.notificationToEdit?.messageData.de.items ?? null,
              actions: null
            },
          },
          organization: null,
          planData: [],
          roleData: [],
          isPush: false,
          sendDate: null,
          expirationDate: null,
          status: -1,
          notes: null,
        },
        editModeActive: true,
        testSent: false,
      }
    },
    methods: {
      checkAllPlans() {
        if (this.notification.planData.length < 3) {
          this.notification.planData = [0,1,2];
        } else {
          this.notification.planData = [];
        }
      },
      checkAllRoles() {
        if (this.notification.roleData.length < 4) {
          this.notification.roleData = [1,2,6,9];
        } else {
          this.notification.roleData = [];
        }
      },
      convertToZurichTime(utcDatetimeInput) {
        let utcDatetime = new Date(utcDatetimeInput);
        
        const utcDate = new Date(Date.UTC(utcDatetime.getFullYear(), utcDatetime.getMonth(), utcDatetime.getDate(), utcDatetime.getHours(), utcDatetime.getMinutes()));
        
        // Create a formatter for Zurich time
        const zurichFormatter = new Intl.DateTimeFormat('en-GB', {
            timeZone: 'Europe/Zurich',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        // Format the date to Zurich time
        const parts = zurichFormatter.formatToParts(utcDate);
        const zurichTime = {};

        parts.forEach(({ type, value }) => {
            zurichTime[type] = value;
        });

        return `${zurichTime.day}.${zurichTime.month}.${zurichTime.year}, ${zurichTime.hour}:${zurichTime.minute}`;
      },
      formatDate(dateInput) {
        let dateNew = dateInput;
        let date = new Date(dateNew);

        const day = date.getDate();
        const month = date.getMonth()+1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minutes = date.getMinutes();

        return `${year}-${month}-${day} ${hour}:${minutes}:00`;
      },
      saveHandler() {
        if (this.notificationToEdit) {
          this.updateNotification();
        } else {
          this.createNotification();
        }
      },
      sendTestNotification() {
        const dataToSend = {
          notificationTypeId: 67,
          language: this.activeLang,
          campaignId: this.notificationToEdit.id,
        }

        this.sendTestScheduledNotification(dataToSend)
          .then(() => {
            this.testSent = true;
            setTimeout(() => {
              this.testSent = false;
            }, 5000);
          });
      },
      updateNotification() {
        let dataToSend = {
          ...this.notification
        }

        if (dataToSend.organization == '') {
          dataToSend.organization = null;
        }

        dataToSend.sendDate = this.formatDate(this.notification.sendDate);
        dataToSend.expirationDate = this.formatDate(this.notification.expirationDate);

        this.updateScheduledNotification(dataToSend)
          .then(() => {
            this.$emit('close');
          })
          .catch(() => {
            this.$emit('close');
          })
      },
      createNotification() {
        let dataToSend = {
          ...this.notification
        }

        dataToSend.sendDate = this.formatDate(this.notification.sendDate);
        dataToSend.expirationDate = this.formatDate(this.notification.expirationDate);

        this.createScheduledNotification(dataToSend)
          .then(() => {
            this.$emit('close');
          })
          .catch(() => {
            this.$emit('close');
          })
      },
      clearNotification() {
        this.notification = {
          messageData: {
            en: null,
            de: null,
          },
          organization: null,
          planData: [],
          roleData: [],
          isPush: false,
          sendDate: null,
          expirationDate: null,
          status: -1,
          notes: null,
        };
      },
      updateItems(data) {
        let items = data.items;
        let language = data.language;
        this.notification.messageData[language].items = items;
      },
      updateActions(data) {
        let actions = data.actions;
        let language = data.language;
        this.notification.messageData[language].actions = actions;
      },
    },
    mounted() {
      Date.prototype.addHours = function(h) {
        this.setTime(this.getTime() + (h*60*60*1000));
        return this;
      }
      
      if (this.notificationToEdit) {
        this.notification = {
          ...this.notificationToEdit,
          sendDate: this.notificationToEdit.sendDate?.date,
          expirationDate: this.notificationToEdit.expirationDate?.date,
        };
      }
    }
  }
</script>
  
<style scoped>
  .language-switch {
    display: flex;
    justify-content: flex-end;
  }

  .language-switch a {
    background: #ddd;
    padding: 5px 10px;
    cursor: pointer;
  }

  .language-switch a.active {
    background: var(--dark-green);
    color: #FFF;
  }

  .title-field {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 0;
  }

  .title-field label:first-child {
    padding-bottom: 10px;
  }

  .message-preview {
    width: 100%;
    height: auto;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 5px 0 0 0;
    outline: none;
    font-size: 16px;
    color: #171725;
    min-height: 51px;
  }

  .message-preview span {
    padding-right: 5px;
  }

  .push-label {
    margin-bottom: 15px;
    display: block;
  }

  :deep(.dp__input_icon_pad) {
    padding-left: 35px !important;
  }

  .zurich-time {
    color: #757575;
    font-size: 14px;
    margin-top: 5px;
    display: block;
    font-weight: 500;
  }

  .auto-textarea {
    height: auto !important;
  }
</style>
  
  
  
  