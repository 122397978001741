<template>
  <div class="comment-item" :class="{ nested: comment.parentComment != 0 }">
    <div class="comment-box">
      <div class="comment-header">
        <div class="header-wrapper">
          <img v-if="comment.userProfileImage" :src="getMediaThumb(comment.userProfileImage, 'xs')">
          <img v-else :src="$s3Url + 'placeholders/person-image.png'">
          <div class="username-date">
            <span class="user-name">
              {{ comment.username }}
            </span>
            <span class="date">{{ $filters.formatDate(comment.addedOn.date) }}</span>
          </div>
        </div>

        <div class="comment-actions">
          <a @click="commentToDelete = comment" class="delete">
            <font-awesome-icon icon="trash" />
          </a>
        </div>
      </div>

      <div class="comment-body">
        {{ comment.comment }}
      </div>
    </div>

    <DeleteComment
      v-if="commentToDelete"
      :comment="commentToDelete"
      @close="commentToDelete = null"
      @deleted="commentToDelete = null; $emit('update-comments');"
    />
  </div>
</template>

<script>
  import mediaMixin from '@/mixins/media'
  import knowledgeMixin from '@/mixins/knowledge'
  import DeleteComment from './popups/DeleteComment'

  export default {
    name: 'CommentItem',
    mixins: [mediaMixin, knowledgeMixin],
    components: {
      DeleteComment
    },
    props: {
      comment: Object
    },
    data() {
      return {
        commentToDelete: null
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
  .comment-item {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .comment-item.nested {
    margin-left: 80px;
  }

  .comment-box {
    border-radius: 10px;
    padding: 15px 15px 5px 15px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }

  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .comment-header .header-wrapper {
    display: flex;
    gap: 15px;
  }
  
  .comment-header img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  .comment-header  .username-date {
    display: flex;
    flex-direction: column;
  }

  .comment-header .user-name {
    font-size: 13px;
    font-weight: 500;
  }

  .comment-header .user-label {
    color: #767676;
    font-style: italic;
    font-size: 11px;
  }

  .comment-header .date {
    font-size: 11px;
    color: #767676;
  }

  .comment-body {
    margin-top: 10px;
    font-size: 14px;
    padding-left: 50px;
  }

  .comment-actions {
    margin-top: 5px;
    display: flex;
    margin-right: 10px;
  }

  .comment-actions a {
    margin-top: 100%;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .comment-actions a:hover {
    text-decoration: underline;
  }

  .comment-actions .delete {
    color: #ff4e4e;
  }

  @media (max-width: 767px) {
    .comment.nested {
      margin-left: 40px;
    }
  }
</style>
