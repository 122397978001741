<template>
  <popup
    title="Add new quiz"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <!-- <div class="field">
          <label for="quiz-name-en">Quiz Name EN:</label>
          <input type="text" id="quiz-name-en" v-model="quizNameEn" placeholder="Quiz Name EN">
        </div>
        <div class="field">
          <label for="quiz-name-de">Quiz Name DE:</label>
          <input type="text" id="quiz-name-de" v-model="quizNameDe" placeholder="Quiz Name DE">
        </div> -->
        <div class="two-cols">
          <div class="field">
            <label for="quiz-name-en">Quiz Name EN:</label>
            <TextEditor @input="quizData.en.name = $event"/>
          </div>
          <div class="field">
            <label for="quiz-name-de">Quiz Name DE:</label>
            <TextEditor @input="quizData.de.name = $event"/>
          </div>
        </div>

        <div class="two-cols">
          <div class="field">
            <label for="quiz-name-en">Quiz Start Message EN:</label>
            <TextEditor  @input="quizData.en.startMessage = $event"/>
          </div>
          <div class="field">
            <label for="quiz-name-de">Quiz Start Message DE:</label>
            <TextEditor  @input="quizData.de.startMessage = $event" />
          </div>
        </div>

        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="quizData.isPublic">
            <span class="checkmark"></span>
            Is Public
          </label>
        </div>

        <button class="save-btn transition" @click="createQuiz()" :disabled="buttonLoading">
          <font-awesome-icon v-if="buttonLoading" icon="spinner" spin />
          <template v-else>
            Create
          </template>
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import quizMixin from '@/mixins/quiz'

  import Popup from '@/components/shared/Popup'
  import TextEditor from '../elements/TextEditor.vue'

  export default {
    name: 'NewQuiz',
    mixins: [quizMixin],
    components: {
      Popup,
      TextEditor
    },
    data() {
      return {
        quizData: {
          en: {
            name: null,
            startMessage: null,
          },
          de: {
            name: null,
            startMessage: null,
          },
          isPublic: false,
        },
        buttonLoading: false
      }
    },
    methods: {
      createQuiz() {
        this.buttonLoading = true;
        const dataToSend = {
          quizName: this.quizData
        }

        this.newQuiz(dataToSend)
          .then(() => {
            this.$emit('update-quizzes')
            this.buttonLoading = false;
          })
      }
    }
  }
</script>

<style scoped>

</style>