<template>
  <!-- Security Pub Descr Data Page -->
  <div class="umu-company-descriptions-page">
    <h1>UMu Company Descriptions <small v-if="total">({{total}})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['edit']"
        @update-table="getSecuritiesHandler"
        @edit="editHandler"
    />

<!--     Inspect Popup-->
    <InspectUmuCompanyDescription
        v-if="editItem"
        :item="editItem"
        @close="editItem = null"
        @updated="updatedHandler"
    />
  </div>
</template>

<script>
import securitiesMixin from '@/mixins/securities.vue'
import userMixin from '@/mixins/user.vue'

import DataTableHolder from '@/components/shared/datatable/DataTableHolder.vue'
import InspectUmuCompanyDescription from "@/components/equities/popups/InspectUmuCompanyDescription.vue";

export default {
  name: 'UmuCompanyDescriptions',
  mixins: [securitiesMixin, userMixin],
  components: {
    InspectUmuCompanyDescription,
    DataTableHolder,
    // InspectPublicDescription,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'descriptionId',
          sortable: true
        },
        {
          title: 'ISIN',
          name: 'isin',
          sortable: true
        },
        {
          title: 'Name',
          name: 'name',
          sortable: true
        },
        {
          title: 'Market Cap (bn)',
          name: 'marketCap',
          sortable: true
        },
        {
          title: 'Source',
          name: 'hostName',
          sortable: false
        },
        {
          title: 'Is From Source',
          name: 'isFromSource',
          sortable: true
        },
        {
          title: 'Updated On',
          name: 'updatedOn',
          sortable: true
        },

      ],
      editItem: null,
      pages: 0,
      updateResults: true,
      total: null,
      fullData: null,
    }
  },
  methods: {
    // * Get Securities from API
    getSecuritiesHandler(data) {

      this.getUmuCompanyDescriptions(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let securitiesFormatted = [];
            // * Loop through securities and add only the needed properties

            for (const security of res.securities) {
              let securityObj = {
                descriptionId: security.id,
                isin: security.isin,
                name: security.name,
                marketCap: security.market_cap,
                hostName: security.host_name,
                isFromSource: security.is_from_source,
                updatedOn: security.updated_on,
              }
              // * Add formatted security to formatted securities array
              securitiesFormatted.push(securityObj);
            }
            // * Assign table rows to be the formatted securities array
            this.rows = securitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },

    // * Triggered when user click the pen icon on a row
    editHandler(item) {
      this.editItem = this.fullData.find(security => {
        return security.id === item.descriptionId;
      });
    },
    // * Triggered when user updates item from the edit popup
    updatedHandler() {
      // * Null the editing item
      this.editItem = null;
      this.updateResults = !this.updateResults;
    },

  },

  computed: {
  },

}
</script>

<style scoped>


</style>