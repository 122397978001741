<template>
  <div class="banner-list">
    <h1>Banners</h1>
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :actions="['exportData']"
      page="Banners"
      @update-table="getAllBanners"
      @exportData="exportDataHandler"
    />
  </div>
</template>

<script>
import bannerMixin from "@/mixins/banner";
import DataTableHolder from "@/components/shared/datatable/DataTableHolder";

export default {
  name: "BannerList",
  mixins: [bannerMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      banners: null,
      columns: [
        {
          title: "ID",
          name: "id",
          sortable: false,
        },
        {
          title: "Name",
          name: "name",
          sortable: false,
        },
        {
          title: "Added On",
          name: "addedOn",
          sortable: false,
        },
        {
          title: "Status",
          name: "isActive",
          sortable: false,
        },
      ],
      rows: null,
      updateResults: false,
    };
  },
  methods: {
    getAllBanners() {
      this.getBanners().then((res) => {
        this.banners = res.banners;
        this.rows = [];

        for (const banner of res.banners) {
          let row = {
            id: banner.id,
            name: banner.name,
            addedOn: banner.addedOn.date,
            isActive: banner.isActive ? "Active" : "Inactive",
          };

          this.rows.push(row);
        }
      });
    },
    updateBanners() {
      this.updateResults = !this.updateResults;
      this.getAllBanners();
    },
    exportDataHandler(item) {
      this.getBannerExcelReport("banner", item.id).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${item.name}-Report.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
      });
    },
  },
};
</script>

<style scoped>
</style>
