<template>
  <popup
    title="Participant Answers"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <ul>
        <li
          v-for="(answer, index) in answers"
          :key="index"
        >
          {{ answer.answer }}
        </li>
      </ul>
    </template>
  </popup>
</template>

<script>
  import quizMixin from '@/mixins/quiz'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'ViewAnswers',
    mixins: [quizMixin],
    components: {
      Popup
    },
    props: {
      item: Object
    },
    data() {
      return {
        answers: null
      }
    },
    methods: {
      getQuestionAnswersHandler() {
        this.getQuizSummaryQuestion(this.item.id) 
          .then(res => {
            this.answers = res.results;
          })
      }
    },
    mounted() {
      this.getQuestionAnswersHandler();
    }
  }
</script>

<style scoped>

</style>