<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // * Get users marketing from API
      getUsersMarketing(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/marketing/performance`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      getUsersPerformance(data, type) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/marketing/user-performance/${type}`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      getAssetManagers() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/asset-managers`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      getExcelExport(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/excel-report`, {
            method: 'POST',
            data,
            responseType: 'blob'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      getUserChartInfo(data, type, userId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/marketing/user-performance/${type}/${userId}`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      getScheduledNotifications() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/scheduled-notifications`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      createScheduledNotification(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/scheduled-notification`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      updateScheduledNotification(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/scheduled-notification/${data.id}`, {
            method: 'PATCH',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      sendTestScheduledNotification(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/send-notification`, {
            method: "POST",
            data,
          })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err.response.data.code);
            });
        });
      },
      cloneScheduledNotification(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/clone-scheduled-notification/${data.id}`, {
            method: 'POST'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      }
    }
  }
</script>
