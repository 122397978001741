<template>
  <!-- Edit Exchange Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Exchange"
    @close="$emit('close')"
  >
    <template v-slot:content>

      <span class="exchange-id">
        Exchange ID: {{item.id}}
      </span>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="exchange.enabled">
          <span class="checkmark"></span>
          Enabled
        </label>
      </div>

      <div class="field">
        <label for="start-time">Start Time:</label>
        <input type="text" id="start-time" v-model="exchange.startTime">
      </div>

      <div class="field">
        <label for="end-time">End Time:</label>
        <input type="text" id="end-time" v-model="exchange.endTime">
      </div>

      <div class="field">
        <label for="threshold">Threshold</label>
        <input type="text" id="threshold" v-model="exchange.threshold">
      </div>
      
      <button class="transition save-btn" @click="updateExchangeHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

      <span class="error-message" v-if="error">
        {{error}}
      </span>
    </template>
  </popup>
</template>

<script>
  import equitiesMixin from '@/mixins/equities'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  
  export default {
    name: 'EditExchange',
    mixins: [equitiesMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        exchange: this.item,
        updateLoading: false,
        error: null
      }
    },
    methods: {
      updateExchangeHandler() {
        this.updateLoading = true;

        const dataToSend = {
          threshold: this.exchange.threshold,
          startTime: this.exchange.startTime,
          endTime: this.exchange.endTime,
          enabled: this.exchange.enabled,
        }

        this.updateExchange(this.item.id, dataToSend)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code == 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
      }
    }
  }
</script>

<style scoped>
  .mx-datepicker {
    display: block;
    width: 100%;
  }

  .exchange-id {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
</style>
