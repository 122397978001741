<template>
  <div class="header relative" :class="{logged: isLogged}">
    <div class="header-inner">
      <!-- Logo -->
      <div class="logo">
        <router-link to="/">
          <img class="icon" :src="$s3Url + 'svgs/logo.svg'">
          <span class="text">UMA Analytics</span>
        </router-link>
      </div>
      <!-- Main Navigation -->
      <div class="navigation" v-if="isLogged">
        <div class="public-nav">
          <!-- Main item: Translations -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="translationsDrop = !translationsDrop">
              <span>Translations</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: translationsDrop }"
              />
            </a>
            <!-- Sub-menu: Translations -->
            <div
              class="dropdown"
              v-if="translationsDrop"
              v-click-away="closeTranslations"
            >
              <div class="links" @click="closeTranslations">
                <div class="links-group">
                  <router-link to="/translations/notification-types">Notifications</router-link>
                  <router-link to="/translations/mobile-app">Mobile App</router-link>
                  <router-link to="/translations/front-end">Front-End</router-link>
                  <router-link to="/translations/back-end">Back-End</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Item with dropdown (Marketing) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="marketingDrop = !marketingDrop">
              <span>Marketing</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: marketingDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="marketingDrop"
              v-click-away="closeMarketing"
            >
              <div class="links" @click="closeMarketing">
                <div class="links-group">
                  <div class="sub-sub-menu relative">
                    <a class="title">Users</a>
                    <div class="side-menu">
                      <router-link to="/marketing/users/charts">Charts</router-link>
                      <router-link to="/marketing/users">Performance</router-link>
                      <router-link to="/marketing/users/filter-by">Filter By</router-link>
                      <router-link to="/marketing/users/utm">UTM</router-link>
                    </div>
                  </div>
                  <div class="sub-sub-menu relative">
                    <a class="title">WMs & AMs</a>
                    <div class="side-menu">
                      <router-link to="/marketing/wealth-managers">Financial Partners</router-link>
                      <router-link to="/marketing/asset-managers">Asset Managers</router-link>
                    </div>
                  </div>
                  <div class="sub-sub-menu relative">
                    <router-link to="/marketing/contest-users">Contest Users</router-link>
                  </div>
                </div>
                <div class="links-group">
                  <div class="sub-sub-menu relative">
                    <router-link class="title" to="/quizzes">Quizzes</router-link>
                    <div class="side-menu">
                      <router-link to="/quizzes/participants">Participants</router-link>
                      <router-link to="/quizzes/summary">Summary</router-link>
                    </div>
                  </div>
                  <div class="sub-sub-menu relative">
                    <router-link class="title" to="/banners">Banners</router-link>
                  </div>
                  <div class="sub-sub-menu relative">
                    <router-link to="/scheduled-notifications">Scheduled Notifications</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Item with dropdown (System) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="systemDrop = !systemDrop">
              <span>System</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: systemDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="systemDrop"
              v-click-away="closeSystem"
            >
              <div class="col">
                <div class="links" @click="closeSystem">
                  <div class="links-group">
                    <router-link to="/system/commands">Commands</router-link>
                    <router-link to="/system/commands-log">Commands Log</router-link>
                    <router-link to="/system/commands-schedule">Commands Schedule</router-link>
                    <router-link to="/system/commands-chart-view">Commands Chart View</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/system/faqs">FAQs</router-link>
                    <router-link to="/system/faq-categories">FAQ Categories</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/system/glossary">Glossary</router-link>
                    <router-link to="/system/glossary-categories">Glossary Categories</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/system/achievements">Achievements</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/system/old-url-names">Old Url Names</router-link>
                  </div>
                  <div class="links-group">
                    <div class="sub-sub-menu relative">
                      <a class="title">Tags</a>
                        <div class="side-menu">
                          <router-link to="/system/tags">Tags</router-link>
                          <router-link to="/system/tags-by-instruments">Tags By Instruments</router-link>
                        </div>
                    </div>
                  </div>
                  <div class="links-group">
                    <router-link to="/system/umu-social-media-sentiment">Umu Sentiment</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/system/pages">Pages</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/system/settings">Settings</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Item with no dropdown (Users) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="usersDrop = !usersDrop">
              <span>Users</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: usersDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="usersDrop"
              v-click-away="closeUsers"
            >
              <div class="col">
                <div class="links" @click="closeUsers">
                  <div class="links-group">
                    <div class="sub-sub-menu relative">
                      <a class="title">Users</a>
                      <div class="side-menu">
                        <router-link to="/users">Basic Data</router-link>
                        <router-link to="/users/subscriptions">Subscriptions</router-link>
                        <router-link to="/users/subscribers-payments">Payments</router-link>
                        <router-link to="/users/plans">Plans</router-link>
                        <router-link to="/users/achievements">Achievements</router-link>
                        <router-link to="/users/notifications">Notifications</router-link>
                        <router-link to="/users/promo-codes">Promo Codes</router-link>
                      </div>
                    </div>
                    <router-link to="/users/permissions">Permissions</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/users/wealth-managers-new">Financial Partners</router-link>
                    <router-link to="/users/community-groups">Community Groups</router-link>
                    <router-link to="/users/contests">Contests</router-link>
                    <router-link to="/users/brokers">Brokers</router-link>
                    <router-link to="/users/collections">Collections</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Item (Knowledge) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="knowledgeDrop = !knowledgeDrop">
              <span>Knowledge</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: knowledgeDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="knowledgeDrop"
              v-click-away="closeKnowledge"
            >
              <div class="col">
                <div class="links" @click="closeKnowledge">
                  <router-link to="/knowledge">Knowledge</router-link>
                  <router-link to="/knowledge/latest-unlocked-sessions">Latest Unlocked Videos</router-link>
                </div>
              </div>
            </div>
          </div>

          <!-- Item with dropdown (Equities) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="equitiesDrop = !equitiesDrop">
              <span>Equities</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: equitiesDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="equitiesDrop"
              v-click-away="closeEquities"
            >
              <div class="col">
                <div class="links" @click="closeEquities">
                  <div class="links-group">
                    <router-link to="/equities/candidate-new">Candidates</router-link>
                    <router-link to="/equities/market-data-candidate">Candidate Market Data</router-link>
                    <router-link to="/equities/risk-scores-candidate">Candidate Risk Scores</router-link>
                    <router-link to="/equities/returns-candidate">Candidate Returns</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/equities/active-new">Active</router-link>
                    <router-link to="/equities/market-data-active">Active Market Data</router-link>
                    <router-link to="/equities/risk-scores-active">Active Risk Scores</router-link>
                    <router-link to="/equities/returns-active">Active Returns</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/equities/retired-new">Retired</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/equities/exchanges">Exchanges</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/equities/stock-splits">Stock Splits</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/equities/umu-company-descriptions">Company Descriptions</router-link>
                    <router-link to="/equities/financial-results">Financial Results</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Item with dropdown (Crypto) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="cryptoDrop = !cryptoDrop">
              <span>Crypto</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: cryptoDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="cryptoDrop"
              v-click-away="closeCrypto"
            >
              <div class="col">
                <div class="links" @click="closeCrypto">
                  <router-link to="/crypto/overview">Overview</router-link>
                  <router-link to="/crypto/market-data">Market Data</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Item with dropdown (Funds) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="fundsDrop = !fundsDrop">
              <span>Funds</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: fundsDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="fundsDrop"
              v-click-away="closeFunds"
            >
              <div class="col">
                <div class="links" @click="closeFunds">
                  <!--<div class="links-group">
                    <router-link to="/funds/fund-advisors">Advisors</router-link>
                    <router-link to="/funds/group-consolidations">Groups</router-link>
                    <router-link to="/funds/umbrella-managers">Umbrella Managers</router-link>
                  </div>
                  -->
                  <div class="links-group">
                    <router-link to="/funds/fund-advisors-mds">Advisors</router-link>
                    <router-link to="/funds/group-consolidations-mds">Groups</router-link>
                    <router-link to="/funds/investment-fund-managers-mds">Fund Managers</router-link>
                    <router-link to="/funds/asset-managers">Asset Managers</router-link>
                  </div>
                  <div class="links-group">
                    <!--<router-link to="/funds/market-data">Market Data</router-link>-->
                    <router-link to="/funds/market-data-mds-funds">Fund Market Data</router-link>
                    <router-link to="/funds/market-data-mds-etfs"> ETFs Market Data</router-link>
                    <!-- <router-link to="/funds/umbrella-funds-count">Umbrella Funds Count</router-link> -->
                  </div>
                  <div class="links-group">
                    <!--<router-link to="/funds/umbrella-characteristics">Umbrella Characteristics</router-link>-->
                    <router-link to="/funds/characteristics">Fund Characteristics</router-link>
                    <router-link to="/etfs/characteristics">ETF Characteristics</router-link>
                    <router-link to="/funds/umbrella-sectors">Sectors</router-link>
                    <router-link to="/funds/umbrella-styles">Styles</router-link>
                    <router-link to="/funds/umbrella-geographies">Geographies</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/funds/benchmarks">Benchmarks</router-link>
                    <router-link to="/funds/benchmark/mappings">Benchmark Mappings</router-link>
                    <div class="links-group">
                      <div class="sub-sub-menu relative">
                        <a class="title">Umu Benchmarks</a>
                        <div class="side-menu">
                          <router-link to="/system/umu-benchmarks">Umu Benchmarks</router-link>
                          <router-link to="/system/umu-benchmarks/by-share-classes">Umu Benchmarks By Share Classes</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="links-group">
                    <router-link to="/funds/suitabilities">Suitabilities</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/funds/fund-countries">Countries for Sale</router-link>
                  </div>
                  <div class="links-group">
                    <router-link to="/funds/public-descriptions">Public Descriptions</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Item with dropdown (Portfolios) -->
          <div class="item-with-submenu relative">
            <a class="main-item-link" @click="portfoliosDrop = !portfoliosDrop">
              <span>Portfolios</span>
              <font-awesome-icon
                :icon="['fas', 'caret-down']"
                class="transition"
                :class="{ rotate: portfoliosDrop }"
              />
            </a>
            <!-- Item dropdown -->
            <div
              class="dropdown"
              v-if="portfoliosDrop"
              v-click-away="closePortfolios"
            >
              <div class="col">
                <div class="links" @click="closePortfolios">
                  <router-link to="/portfolios/summary">Summary</router-link>
                  <router-link to="/portfolios/orders">Orders</router-link>
                  <router-link to="/portfolios/performance">Performance</router-link>
                  <router-link to="/portfolios/transactions-by-security">Transactions By Security</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Right header part (Login button / Account info) -->
      <div class="langs-account-holder">
        <!-- Login button (showed if user is not logged in) -->
        <template v-if="!isLogged">
          <router-link to="/login" custom v-slot="{ navigate }">
            <button class="login-btn transition" @click="navigate" @keypress.enter="navigate" role="link">Login</button>
          </router-link>
        </template>
        <!-- Account Info (showed if user is logged in) -->
        <template v-if="isLogged">
          <div class="profile-holder relative" v-if="userInfo">
            <a @click="toggleProfileDrop()">
              <img class="profile-img" v-if="userInfo.profileImage" :src="getMediaThumb(userInfo.profileImage, 'xs')">
              <img class="profile-img" v-else :src="$s3Url + 'placeholders/person-image.png'">
              <span class="name">{{userInfo.firstName}}</span>
              <font-awesome-icon icon="caret-down" />
            </a>
            <div class="profile-dropdown" v-if="profileDrop">
              <router-link @click="toggleProfileDrop()" :to="{name: 'AccountSettings'}">Account Settings</router-link>
              <div @click="profileDrop = false">
                <a @click="logoutHandler()">Logout</a>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import userMixin from '@/mixins/user'
  import mediaMixin from '@/mixins/media'

  export default {
    name: 'Header',
    mixins: [userMixin, mediaMixin],
    data() {
      return {
        translationsDrop: false,
        profileDrop: false,
        fundsDrop: false,
        marketingDrop: false,
        systemDrop: false,
        equitiesDrop: false,
        cryptoDrop: false,
        usersDrop: false,
        portfoliosDrop: false,
        knowledgeDrop: false
      }
    },
    methods: {
      closeTranslations() {
        this.translationsDrop = false;
      },
      toggleProfileDrop() {
        this.profileDrop = !this.profileDrop;
      },
      closeProfileDrop(e) {
        if (!this.$el.contains(e.target)) {
          this.profileDrop = false;
        }
      },
      logoutHandler() {
        this.logout()
      },
      closeFunds() {
        this.fundsDrop = false;
      },
      closeMarketing() {
        this.marketingDrop = false;
      },
      closeSystem() {
        this.systemDrop = false;
      },
      closeEquities() {
        this.equitiesDrop = false;
      },
      closeCrypto() {
        this.cryptoDrop = false;
      },
      closeUsers() {
        this.usersDrop = false;
      },
      closePortfolios() {
        this.portfoliosDrop = false;
      },
      closeKnowledge() {
        this.knowledgeDrop = false;
      }
    },
    computed: {
      isLogged() {
        return !!this.$store.state.auth.userToken;
      },
      userInfo() {
        return this.$store.state.auth.user;
      }
    },
    mounted () {
      document.addEventListener('click', this.closeProfileDrop)
    },
    beforeUnmount () {
      document.removeEventListener('click',this.closeProfileDrop)
    }
  }
</script>

<style scoped>
  .header {
    background: #FFF;
    border-bottom: 1px solid #E2E2EA;
    z-index: 20;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
  }

  .header-inner {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 18px;
    z-index: 11;
    position: sticky;
    top: 0;
    width: 100%;
    min-height: 74px;
  }

  .header.logged .header-inner {
    justify-content: flex-start;
  }

  .logo {
    padding: 12px 0;
  }

  .logo a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .logo .text {
    font-weight: 600;
    font-size: 18px;
    color: #171725;
    padding-left: 10px;
  }

  .navigation {
    display: flex;
    align-items: stretch;
  }

  .navigation .item a {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #171725;
    margin: 0 20px;
    padding: 25px 0;
  }

  .navigation .item a:not(.not-active).router-link-active {
    color: #3C6E71;
    font-weight: 600;
    position: relative;
  }

  .navigation .item a.router-link-active:not(.not-active):after {
    content: '';
    width: calc(100% + 30px);
    height: 3px;
    background: #3C6E71;
    display: block;
    position: absolute;
    left: -15px;
    bottom: -3px;
    border-radius: 3px 3px 0 0;
  }

  .navigation a:hover {
    text-decoration: underline;
  }

  .sub-sub-menu .side-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background: #FFF;
    box-shadow: 0px 4px 22px rgb(0 0 0 / 8%);
    border-radius: 10px;
    overflow: hidden;
  }

  .sub-sub-menu:hover .side-menu {
    display: block;
  }

  .sub-sub-menu .side-menu a {
    margin-top: 0 !important;
  }

  .langs-account-holder {
    display: flex;
    padding: 12px 0;
  }

  .header.logged .langs-account-holder {
    position: absolute;
    right: 0;
  }

  .login-btn {
    border-radius: 8px;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 18px;
    margin-right: 10px;
    cursor: pointer;
    outline: none !important;
  }

  .login-btn:hover {
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }

  .login-btn {
    background: #FAFAFB;
    color: #070707;
  }

  .profile-holder {
    margin-right: 10px;
    cursor: pointer;
  }

  .profile-holder a {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
  }

  .profile-holder a:hover {
    background-color: #f1f1f1;
  }

  .profile-img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }

  .profile-holder .name {
    color: #191927;
    font-weight: 600;
    font-size: 16px;
    margin: 0 15px 0 10px;
  }

  .profile-holder svg {
    color: #92929D;
  }

  .profile-dropdown {
    position: absolute;
    top: 100%;
    background: #FFF;
    width: 150px;
    right: 0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

  .profile-dropdown a {
    display: block;
    padding: 10px 15px;
    cursor: pointer;
    color: #171725;
    text-decoration: none;
    font-size: 14px;
  }

  .profile-dropdown a:hover {
    background-color: #f1f1f1;
  }

  .profile-dropdown a .green {
    font-size: 11px;
    color: #4BD89F;
  }

  .public-nav,
  .public-nav > div {
    display: flex;
    align-items: center;
  }

  .public-nav .main-item-link {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #171725;
    margin: 0 20px;
    padding: 25px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .public-nav .main-item-link:not(.not-active).router-link-active {
    color: #3c6e71;
    font-weight: 600;
    position: relative;
  }

  .public-nav .main-item-link.router-link-active:not(.not-active):after {
    content: "";
    width: calc(100% + 30px);
    height: 3px;
    background: #3c6e71;
    display: block;
    position: absolute;
    left: -15px;
    bottom: -2px;
    border-radius: 3px 3px 0 0;
  }

  .public-nav .main-item-link:hover {
    text-decoration: underline;
  }

  .public-nav .main-item-link svg {
    color: #92929d;
    font-size: 18px;
    margin-left: 5px;
    padding-bottom: 2px;
  }

  .public-nav .main-item-link svg.rotate {
    transform: rotate(180deg);
  }

  .item-with-submenu .dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
  }

  .item-with-submenu .dropdown h6 {
    margin: 18px 40px 8px 15px;
    color: #3c6e71;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }

  .item-with-submenu .dropdown .links {
    margin-bottom: 10px;
  }

  .links .links-group:not(:last-child) {
    border-bottom: 1px dashed rgba(68,68,79,.5);
    padding-bottom: 10px;
  }

  .item-with-submenu .dropdown a {
    display: block;
    padding: 10px 15px;
    color: #44444f;
    text-decoration: none;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
  }

  .item-with-submenu .dropdown a.faded {
    opacity: 0.5;
    cursor: default;
  }

  .item-with-submenu .dropdown h6 + .links a {
    padding: 10px 30px;
  }

  .item-with-submenu .dropdown a:first-child {
    margin-top: 10px;
  }

  .item-with-submenu .dropdown h6 + .links a:first-child {
    margin-top: 0;
  }

  .item-with-submenu .dropdown a:not(.faded):hover {
    background: #3c6e71;
    color: #fff;
  }

  @media (min-width: 1200px) {
    .navigation {
      position: absolute;
      left: 50%; 
      transform: translateX(-50%);
    }
  }

  @media (max-width: 1250px) {
    .navigation,
    .login-btn,
    .profile-holder {
      display: none;
    }
    
    .header {
      z-index: 12;
    }

    .header.logged {
      justify-content: space-between;
    }

    .header .langs-account-holder {
      top: 0;
      height: 70px;
      margin-right: 60px;
    }
  }

  @media (max-width: 1024px) {
    .header.fixed {
      position: fixed;
    }
  }

  @media (max-width: 350px) {
    .logo .text {
      font-size: 16px;
      padding-left: 5px;
    }
  }
</style>
