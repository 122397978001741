<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // * Get securities, paginated list as defined by data
      getSecurities(data, typeId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/securities/${typeId}`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Get securities list, simple list of securities as defined by typeId
      getSecurityList(typeId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/securities/${typeId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Historical data
      getSecurityChartData(securityId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/security/history/chart/${securityId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Update security
      updateSecurity(data, securityId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/security/${securityId}`, {
            method: 'PATCH',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      // * Delete historical data between 2 dates
      deletePricesBetween(startDate, endDate, securityId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/security/history/chart/delete-row/${startDate}/${endDate}/${securityId}`, {
            method: 'GET'
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },
      // * Multiplies historical data by factor between 2 dates
      multiplyPricesBetween(startDate, endDate, factor, securityId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/security/history/chart/multiply-row/${startDate}/${endDate}/${factor}/${securityId}`, {
            method: 'GET'
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },
        // * Get all active or candidate securities
        getAllActiveAndCandidateSecurityISINs(securityTypeId) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/securities/isins/${securityTypeId}`, {
                    method: 'GET'
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.message);
                    })
            })
        },

      getMissingIsinData(valor) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/security/missing-isin/${valor}`, {
            method: 'GET',
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

      // * Get a summary of the context by chatgpt
      getChatGptSummary(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/ai-content/chatgpt-summary`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

      getSecuritiesByTag(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/filtered-securities`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getUmuCompanyDescriptions(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/securities/umu/descriptions`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

      getFinancialResults(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/securities/financial/results`, {
            method: 'POST',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

      updateFinancialResults(data, resultsId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/update/financial/results/${resultsId}`, {
            method: 'PATCH',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
        })
      },

    }
  }
</script>
