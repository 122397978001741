<template>
  <!-- Fund Umbrella Funds Count Page -->
  <div class="fund-umbrella-funds-count-page">
    <h1>Fund Umbrella Funds Count <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="[]"
      @update-table="getFundUmbrellasHandler" 
    />

  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'FundUmbrellaFundsCount',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'UmbrellaName',
            sortable: true
          },
          {
            title: 'Funds Count',
            name: 'fundCount',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'AddedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'UpdatedOn',
            sortable: true
          }
        ],
        pages: 0,
        updateResults: true,
        total: null
      }
    },
    methods: {
      // * Get fund umbrellas from API
      getFundUmbrellasHandler(data) {
        this.getFundUmbrellasPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let umbrellasFormatted = [];
            // * Loop through groups and add only the needed properties
            for (const umbrella of res.fundUmbrellas) {
              let umbrellaObj = {
                id: umbrella.id,
                name: umbrella.UmbrellaName,
                fundCount: umbrella.fundCount,
                addedOn: umbrella.AddedOn,
                updatedOn: umbrella.UpdatedOn,
              }
              // * Add formatted group to formatted groups array
              umbrellasFormatted.push(umbrellaObj);
            }
            // * Assign table rows to be the formatted groups array
            this.rows = umbrellasFormatted;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      }
    }
  }
</script>

<style scoped>


</style>