<template>
  <div class="daily-nationality">
    <apexchart v-if="showChart" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  export default {
    name: 'DailyNationality',
    props: {
      data: Object
    },
    data() {
      return {
        showChart: false,
        series: [
          {
            data: []
          }
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          title: {
            text: 'Nationality Demographics',
            align: 'center',
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#171725'
            },
          },
          colors:['#3C6E71', '#C1A16F', '#61F1F9', '#003E52', '#000', '#6f94af'],
          legend: {
            position: 'bottom',
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          xaxis: {
            categories: []
          }
        }
      }
    },
    mounted() {
      let mainData;
      if (this.data.totals) {
        mainData = this.data.totals.totalVisitorsDemographics.nationality;
      } else { 
        if (this.data.series == 0) {
          mainData = this.data.data.visitorsGuestDemographics.nationality;
        } else if (this.data.series == 1) {
          mainData = this.data.data.visitorsPublicFollowerDemographics.nationality;
        } else if (this.data.series == 2) {
          mainData = this.data.data.visitorsExclusiveFollowerDemographics.nationality;
        }
      }

      for (const country in mainData) {
        this.chartOptions.xaxis.categories.push(country.split(',')[0]);
        this.series[0].data.push(mainData[country]);
      }

      this.showChart = true;
    }
  }
</script>

<style scoped>

</style>