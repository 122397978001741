<template>
  <div class="custom-select" :class="{open: dropdown}" @click="dropdown = !dropdown" v-click-away="closeDropdown">
    <span>Select Image</span>
    <div class="select-dropdown" v-if="dropdown">
      <a
        v-for="(image, index) in images"
        :key="index"
        @click="emitImage(image)"
      >
        <img :src="image">
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImagesDropdown',
    props: {
      images: Array,
      optionIndex: Number
    },
    data() {
      return {
        dropdown: false
      }
    },
    methods: {
      closeDropdown() {
        this.dropdown = false;
      },
      emitImage(image) {
        if (this.optionIndex != null) {
          this.$emit('select-image', {option: this.optionIndex, image})
        } else {
          this.$emit('select-image', image)
        }
      }
    }
  }
</script>

<style scoped>
  .select-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .select-dropdown a {
    flex: 0 0 25%;
    padding: 2px;
  }

  .select-dropdown a img {
    max-width: 100%;
    max-height: 200px;
  }
</style>
