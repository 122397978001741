<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // * Get portfolio orders paginated
      getOrders(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/orders`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      deleteOrder(orderId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/order/${orderId}`, {
            method: 'delete'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      getPortfolios(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/portfolios`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      updatePortfolio(portfolioId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/portfolio/${portfolioId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      getNonDeleted(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/portfolios/non-deleted`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      getPortfolioTransactions(portfolioId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/portfolio/${portfolioId}/transactions`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      getPortfolioChart(portfolioId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/portfolio/chart/${portfolioId}`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      getPortfolioPerformanceIbo(portfolioId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/portfolio/${portfolioId}/performanceIbo`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
      getTransactionsBySecurity(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/transactions-by-security`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getPortfoliosByTag(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/filtered-portfolios`, {
            method: 'post',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

    }
  }
</script>
