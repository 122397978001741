<template>
  <div>
    <h2>Analytics By Day</h2>
    <div class="two-cols">
      <div class="col">
        <UserPerformanceChart
          :user="user"
          :type="userType"
          :startDate="startDate"
          :endDate="endDate"
          @data-received="dataReceivedHandler"
          @marker-selected="showDailyDemographic"
        />
      </div>
      <div class="col">
        <div class="two-cols">
          <div class="col">
            <DailyGenders
              v-if="dailyDemographic"
              :data="dailyDemographic"
            />
          </div>
          <div class="col">
            <DailyAges
              v-if="dailyDemographic"
              :data="dailyDemographic"
            />
          </div>
        </div>
        <div class="two-cols">
          <div class="col">
            <DailyNationality
              v-if="dailyDemographic"
              :data="dailyDemographic"
            />
          </div>
          <div class="col">
            <DailyDegree
              v-if="dailyDemographic"
              :data="dailyDemographic"
            />
          </div>
        </div>
      </div>
    </div>
    <h2>Analytics Total</h2>
    <div class="four-cols">
      <div class="col">
        <DailyAges
          v-if="dataTotals"
          :data="dataTotals"
        />
      </div>
      <div class="col">
        <DailyGenders
          v-if="dataTotals"
          :data="dataTotals"
        />
      </div>
      <div class="col">
        <DailyDegree
          v-if="dataTotals"
          :data="dataTotals"
        />
      </div>
      <div class="col">
        <DailyNationality
          v-if="dataTotals"
          :data="dataTotals"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import UserPerformanceChart from '../charts/users/UserPerformanceChart'
  import DailyGenders from '../charts/users/DailyGenders'
  import DailyAges from '../charts/users/DailyAges'
  import DailyNationality from '../charts/users/DailyNationality'
  import DailyDegree from '../charts/users/DailyDegree'
  
  export default {
    name: 'InspectUserMarketing',
    components: {
      UserPerformanceChart,
      DailyGenders,
      DailyAges,
      DailyNationality,
      DailyDegree
    },
    props: {
      user: Object,
      userType: String,
      startDate: String,
      endDate: String
    },
    data() {
      return {
        dailyDemographic: null,
        totals: null
      }
    },
    methods: {
      showDailyDemographic(data) {
        this.dailyDemographic = null;
        setTimeout(() => {
          this.dailyDemographic = data;
        }, 1000);
      },
      dataReceivedHandler(data) {
        this.totals = null;
        setTimeout(() => {
          this.totals = data.totals;
        }, 1000);
      }
    },
    computed: {
      dataTotals() {
        if (this.totals) {
          return {
            totals: this.totals
          }
        } else {
          return null;
        }
      }
    }
  }
</script>

<style scoped>
  .two-cols,
  .four-cols {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
  }

  .two-cols .col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .four-cols .col {
    flex: 0 0 25%;
    max-width: 25%;
  }
</style>
