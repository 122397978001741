<template>
  <popup
      title="Inspect Umu Benchmark"
      @close="$emit('close')"
  >

    <template v-slot:content>

      <span class="info-text">
        ID: {{umuBenchmarkId}}
      </span>

      <div class="field">
        <label for="umuBenchmark">Umu Benchmark:</label>
        <input type="text" id="umuBenchmark" v-model="umuBenchmark" placeholder="Umu benchmark...">
      </div>

      <div class="field">
        <label for="toBeMerged">Merge Umu Benchmark INTO <strong>{{umuBenchmarkPlaceholder}}</strong>:</label>
        <input type="text" id="toBeMerged" v-model="toBeMerged" placeholder="ID of the one to be merged...">
      </div>

      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

      <div v-if="requiresConfirmation" :class="{'warning-message': true, 'orange': confirm === 'CONFIRM'}">
        This action is irreversible! Make sure the ID you want to merge is the right one.
      </div>

      <div class="field" v-if="requiresConfirmation">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder="Type 'CONFIRM'..." v-model.trim="confirm">
      </div>

      <!-- save button -->
      <button class="transition save-btn" @click="updateUmuBenchmarkHandler()" :disabled="updateLoading || (requiresConfirmation && confirm !=='CONFIRM')">
        Save
      </button>
    </template>
  </popup>
</template>

<script>
import Popup from "@/components/shared/Popup.vue";
import users from "@/mixins/users.vue";
import system from "@/mixins/system.vue";

export default {
  name: 'InspectUmuBenchmark',
  mixins: [system, users],
  props: {
    item: Object
  },
  components: {
    Popup,
  },
  data() {
    return {
      umuBenchmarkId: this.item.id,
      umuBenchmark: this.item.umu_benchmark,
      umuBenchmarkPlaceholder: this.item.umu_benchmark,
      toBeMerged: null,
      updateLoading: false,
      errorMessage: null,
      requiresConfirmation: false,
      confirm: null,
    }
  },

  methods: {
    updateUmuBenchmarkHandler(){
      this.updateLoading = true;
      let dataToSend = {
        umuBenchmark: this.umuBenchmark !== this.item.umu_benchmark ? this.umuBenchmark : null,
        toBeMerged: this.toBeMerged,
      }
      this.updateUmuBenchmark(this.umuBenchmarkId, dataToSend)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            this.errorMessage = err;
            setTimeout(() => {
              this.errorMessage = null;
            }, 5000);
          })
    },



  },

  watch: {
    toBeMerged(newVal) {
      if (newVal) {
        this.requiresConfirmation = true;
      } else {
        this.requiresConfirmation = false;
        this.confirm = null;
      }
    }
  },

  computed: {

  },

  mounted() {

  }
}
</script>

<style scoped>

.warning-message {
  background-color: #ffcdd2;
  color: #900c3f;
  border: 1px solid #e5c0c4;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.warning-message.orange {
  background-color: #ffe6cc;
  color: #843534;
  border-color: #f0b4aa;
}

</style>