<template>
  <!-- Inspect Equity market Cap Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Equity Candidate"
    @close="$emit('close')"
  >
    <template v-slot:content>

      <span class="equity-id">
         {{equity.companyName}}
      </span>

      <span class="equity-id">
        Equity ID: {{equity.id}}
      </span>

      <span class="equity-id">
        Valor: {{equity.valor}}
      </span>

      <div class="field">
        <label for="admin-comment">Admin Comment:</label>
        <input type="text" id="admin-comment" v-model="equity.adminComment" placeholder="Admin Comment..."/>
      </div>

      <div class="field">
        <label for="isin">ISIN:</label>
        <input type="text" id="isin" v-model="equity.isin" placeholder="ISIN..."/>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="equity.isActive">
          <span class="checkmark"></span>
          Is Active
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="equity.candidate">
          <span class="checkmark"></span>
          Candidate
        </label>
      </div>

      <div class="field" v-if="isActive != equity.isActive || candidate != equity.candidate">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder='Type "CONFIRM" to be able to change the equity status' v-model="confirm">
      </div>

      <ChartBox
        v-if="graphData"
        :graph-data="graphData"
        :item="equity"
        type="equity"
        :currency="equity.currencyCode"
      />
      
      <button class="transition save-btn" @click="updateEquityHandler()" :disabled="updateLoading
            || (equity.isActive == false && equity.candidate == false && equity.adminComment == '')
            || ((isActive != equity.isActive || candidate != equity.candidate) && confirm !== 'CONFIRM' )
            || (equity.isActive == true && equity.candidate == true)">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

      <span class="error-message" v-if="error">
        {{error}}
      </span>
    </template>
  </popup>
</template>

<script>
  import equitiesMixin from '@/mixins/equities'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  import ChartBox from '@/components/shared/charts/ChartBox'
  
  export default {
    name: 'InspectCandidateEquity',
    mixins: [equitiesMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup,
      ChartBox
    },
    data() {
      return {
        graphData: null,
        isActive: this.item.isActive,
        candidate: this.item.candidate,
        updateLoading: false,
        error: null,
        confirm: null,
        equity: this.item
      }
    },
    methods: {
      // * Get equity chart data
      getEquityChartDataHandler() {
        this.getEquityChartData(this.equity.id)
          .then(res => {
            this.graphData = res;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      updateEquityHandler() {
        this.updateLoading = true;

        const dataToSend = {
          isActive: this.equity.isActive,
          candidate: this.equity.candidate,
          adminComment: this.equity.adminComment,
          isin: this.equity.isin
        }

        this.updateEquityCandidate(this.equity.id, dataToSend)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code == 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
      }
    },
    mounted() {
      this.getEquityChartDataHandler();
    }
  }
</script>

<style scoped>
  .equity-id {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
</style>
