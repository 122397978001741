<template>
    <popup
        :title="`Edit (${selectedLanguage.toUpperCase()}) Page: ` + objectToEdit.title"
        @close="$emit('close')"
    >
        <template v-slot:content>
            <div class="lang-switch">
                <button @click="selectedLanguage='en'" class="green-btn lang-btn" :class="{ 'selected': selectedLanguage === 'en' }">EN</button>
                <button @click="selectedLanguage='de'" class="green-btn lang-btn" :class="{ 'selected': selectedLanguage === 'de' }">DE</button>
            </div>

            <div class="selected-lang-element">                
                <!-- Title -->
                <div class="field">
                    <label for="edit-title">Title:</label>
                    <input type="text" id="edit-title" v-model="objectToEdit.title" />
                </div>

                <!-- Content -->
                <TextEditor
                    :body="objectToEdit.content"
                    @set-content="setContent"
                />

                <!-- Type -->
                <div class="field">
                    <label for="edit-type">Type:</label>
                    <input type="number" id="edit-type" v-model="objectToEdit.type" />
                </div>

                <!-- Type 1 (Career Page) -->
                <template v-if="objectToEdit.type === 1">
                    <!-- Career Type -->
                    <div class="field">
                        <label for="edit-career-type">Career Type:</label>
                        <input type="text" id="edit-career-type" v-model="objectToEdit.additionalData.careerType" />
                    </div>

                    <!-- Career Position -->
                    <div class="field">
                        <label for="edit-career-position">Career Position:</label>
                        <input type="text" id="edit-career-position" v-model="objectToEdit.additionalData.careerPosition" />
                    </div>

                    <!-- Career Location -->
                    <div class="field">
                        <label for="edit-career-location">Career Location:</label>
                        <input type="text" id="edit-career-location" v-model="objectToEdit.additionalData.careerLocation" />
                    </div>

                    <!-- Career ApplicationLink -->
                    <div class="field">
                        <label for="edit-career-application-link">Career Application Link:</label>
                        <input type="text" id="edit-career-application-link" v-model="objectToEdit.additionalData.applicationLink" />
                    </div>

                    <!-- Career Date -->
                    <div class="field">
                        <label for="edit-career-date">Career Date:</label>
                        <input type="date" id="edit-career-date" v-model="objectToEdit.additionalData.careerDate" />
                    </div>
                </template>

                <!-- Connected Page -->
                <div class="field">
                    <label for="edit-connected-page">Connected Page ID:</label>
                    <input type="number" id="edit-connected-page" v-model="objectToEdit.connectedPageId" />
                </div>
            </div>

            <div class="button-holder">
                <button @click="updateHandler" class="green-btn">
                    <template v-if="!updateLoading">Save</template>
                    <template v-else><font-awesome-icon icon="spinner" spin /></template>
                </button>
            </div>
        </template>
    </popup>
</template>

<script>
    import TextEditor from '@/components/shared/TextEditor';
    import Popup from "@/components/shared/Popup.vue";
    import axios from 'axios';

    export default {
        name: 'EditPagePopup',
        
        emits: ['close', 'updated'],
        
        props: {
            item: Object, // The item to edit, passed as a prop
        },

        components: {
            Popup,
            TextEditor,
        },

        data() {
            return {
                selectedLanguage: this.item.language || 'en', // Default to item's language or 'en'
                objectToEdit: this.constructItem(), // Clone the item for editing
                updateLoading: false, // Track the loading state
            };
        },

        methods: {
            setContent(body) {
                this.objectToEdit.content = body;
            },

            constructItem() {
                const additionalData = this.item.additionalData ?? {};

                // Career Page specific fields
                if (this.item.type === 1) {
                    additionalData.careerType = additionalData.careerType ?? null;
                    additionalData.careerPosition = additionalData.careerPosition ?? null;
                    additionalData.careerLocation = additionalData.careerLocation ?? null;
                    additionalData.applicationLink = additionalData.applicationLink ?? null;
                    additionalData.careerDate = additionalData.careerDate ?? null;
                }

                return {
                    ...this.item,
                    additionalData,
                };
            },
            
            // Handle updating the page
            updateHandler() {
                this.updateLoading = true;

                axios.put(`${this.$store.state.global.apiUrl}/backoffice/page/${this.objectToEdit.urlName}`, {
                    title: this.objectToEdit.title,
                    content: this.objectToEdit.content,
                    additionalData: this.objectToEdit.additionalData,
                    language: this.selectedLanguage,
                    type: this.objectToEdit.type,
                    connectedPageId: this.objectToEdit.connectedPageId,
                })
                .then(() => {
                    this.updateLoading = false;
                    this.$emit('updated'); // Emit the updated event
                })
                .catch(err => {
                    this.updateLoading = false;
                    console.error("Error updating page:", err);
                });
            },
        },
    };
</script>
  
<style scoped>
    .lang-switch {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
    }

    .lang-switch button {
        min-width: 60px;
    }

    .lang-switch button:not(.selected) {
        border-color: #295456;
        background-color: #fff;
        color: #295456;
    }

    .button-holder {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .button-holder button {
       min-width: 120px;
    }

    .field {
    margin-bottom: 20px;
    }

    .field label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
    }

    .field input,
    .field select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    }

    .save-btn {
    background: #3c6e71;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    }

    .save-btn:hover {
    background: #295456;
    }

    .save-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    }

    .field select {
    max-width: 100%; /* Ensure that dropdowns don't overflow the container */
    width: 100%; /* Full width within its container */
    box-sizing: border-box;
    }

    #article, #partner, #security, #tagId {
        max-width: 300px; /* Limit the width of the Article dropdown */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; /* Ensures text is truncated if too long */
    }

    :deep(#dp-input-expiresOn),
    :deep(#dp-input-masterclassDate) {
        padding-left: 35px;
    }

    #masterclassTitle {
        max-width: 500px; /* Adjust max width as needed */
        width: 100%; /* Full width within its container */
        box-sizing: border-box;
    }

    .language-section {
        border: 1px solid #ddd;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: #f9f9f9; /* Light background for visual separation */
    }

    .language-section h3 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #3c6e71;
    }

    .field textarea {
        width: 100%; /* Full width within its container */
        max-width: 100%; /* Ensure it doesn’t overflow */
        min-height: 60px; /* Minimum height for easier text editing */
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        resize: vertical; /* Allow resizing only vertically */
        font-size: 14px;
    }

    .delete-section {
        margin-top: 30px;
        padding: 20px;
        border-top: 1px solid #ccc;
    }

    .delete-section h3 {
        color: #d9534f;
    }

    .delete-section p {
        margin-bottom: 10px;
        color: #666;
    }

    .delete-section input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 100%;
        max-width: 300px;
    }

    .delete-btn {
        margin-top: 10px;
        background: #d9534f;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
    }

    .delete-btn:disabled {
        background: #f7a9a9;
        cursor: not-allowed;
    }

    .delete-toggle {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        cursor: pointer;
    }

    .delete-toggle input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        cursor: pointer;
        accent-color: #d9534f; /* Ensures the checkbox color matches the delete theme */
    }

    .delete-toggle input[type="checkbox"]:checked {
        appearance: checkbox; /* Explicitly show the check state */
    }

    .delete-confirmation {
        margin-top: 15px;
        padding: 10px;
        border-top: 1px solid #ccc;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s ease;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0;
    }

</style>