<template>

    <div class="user-achievements">
        <h1>User Achievements <small v-if="total">({{total}})</small></h1>

        <!-- Table with results -->
        <DataTableHolder
                :columns="columns"
                :rows="rows"
                :totalPages="pages"
                :updateResults="updateResults"
                :actions="[]"
                @update-table="getUserAchievementsHandler"
        />
    </div>

</template>

<script>
    import userMixin from '@/mixins/user'
    import usersMixin from '@/mixins/users'
    import achievementsMixin from '@/mixins/achievements'

    import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

    export default {
        name: 'UsersAchievements',
        mixins: [userMixin, usersMixin, achievementsMixin],
        components: {
            DataTableHolder
        }, // components
        data() {
            return {
                rows: null,
                columns: [
                    {
                        title: 'ID',
                        name: 'id',
                        sortable: true
                    },
                    {
                        title: 'Email',
                        name: 'email',
                        sortable: true
                    },
                    {
                        title: 'First Name',
                        name: 'firstName',
                        sortable: true
                    },
                    {
                        title: 'Last Name',
                        name: 'lastName',
                        sortable: true
                    },
                    {
                        title: 'Achivements',
                        name: 'achievementsCount',
                        sortable: true
                    }
                ],
                updateResults: true,
                total: null,
                pages: 0,
                fullData: null,
                achievementsAll: []
            }
        }, // data()

        methods: {

            getAchievementsAllHandler(){
                this.getAchievements()
                    .then(res => {
                        this.achievementsAll = res.items;
                    })
                    .catch(err => {
                        if (err == 401) {
                            this.logout();
                        }
                    })
            },

            getUserAchievementsHandler(data){

                data.filter = [{name: 'hasAchievements', term: true}];
                this.getUsersPaginated(data)
                    .then(res => {

                        let usersFormatted = [];

                        for (const user of res.userRows) {
                            let singleUserFormatted = {
                                id: user.id,
                                email: user.email,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                achievementsCount: this.getAchievementsFormatted(user)
                            };

                            usersFormatted.push(singleUserFormatted);
                        }

                        this.rows = usersFormatted;
                        this.fullData = res.userRows;
                        this.total = res.resultsCount;
                        this.pages = res.pagesCount;
                    })
                    .catch(err => {
                        if (err == 401) {
                            this.logout();
                        }
                    })

            }, // getUserAchievementsHandler

            getAchievementById(achievementId) {
                let result = this.achievementsAll.filter(function(item){
                    return item.id == achievementId;
                });

                return result[0];
            }, // function getAchievementById

            getAchievementsFormatted(user) {
                let result = '<span class="achievements-wrapper">';

                if (user.badgesData && user.badgesData.achieved) {
                    //let indexUserAchievement = 0;
                    for (const userAchievement of user.badgesData.achieved) {
                        let fullUserAchievement = this.getAchievementById(userAchievement.id);
                        //console.log(fullUserAchievement.translation.en.name);

                        if (fullUserAchievement) {
                            //result = result + (indexUserAchievement != 0 ? ', ' : '') + fullUserAchievement.translation.en.name;
                            //result = result + (indexUserAchievement != 0 ? ', ' : '') + '<img style="display: inline-block; width: 30px;" src="' + fullUserAchievement.image + '" title="' + fullUserAchievement.name + '" />';
                            result = result + '<img style="display: inline-block; margin-right: 5px; width: 30px;" src="' + fullUserAchievement.image + '" title="' + fullUserAchievement.name + '" />';
                            //result = result + (indexUserAchievement != 0 ? ', ' : '') + userAchievement.id;
                        }
                        //indexUserAchievement++;
                    }
                }

                result = result + '</span>';
                return result;
            } // function getAchievementsFormatted
        },

        created() {
            this.getAchievementsAllHandler();
        }

    } // export default

</script>


<style>
    .user-achievements span.achievements-wrapper {
        width: auto !important;
    }

    .user-achievements .statuses span:hover {
        border: none !important;
    }
</style>