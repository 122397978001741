<script>
  import axios from 'axios'
  

  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      addMedia(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/media`, {
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      // * Get media image thumbnail
      getMediaThumb(link, size) {
        let thumb;
        if (size) {
          thumb = link.replace('/media/', `/media/thumbs/${size}/`);
          if (thumb.includes('.webp')) {
            thumb = thumb.replace('.webp', '.jpeg');
          } else if (thumb.includes('.jfif')) {
            thumb = thumb.replace('.jfif', '.jpeg');
          }
          return thumb;
        } else {
          return link;
        }
      }
    }
  }
</script>
