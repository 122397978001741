<template>
  <div class="umu-benchmarks-page">
    <h1>Umu Benchmarks <small v-if="total">({{total}})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['edit']"
        @update-table="getUmuBenchmarksHandler"
        @edit="editHandler"
    />

    <InspectUmuBenchmark
        v-if="editItem"
        :item="editItem"
        @close="editItem = null"
        @updated="updatedHandler"
    />

  </div>
</template>

<script>
import userMixin from '@/mixins/user.vue'
import DataTableHolder from '@/components/shared/datatable/DataTableHolder.vue'
import systemMixin from "@/mixins/system.vue";
import InspectUmuBenchmark from "@/components/system/popups/InspectUmuBenchmark.vue";
export default {
  name: 'UmuBenchmarks',
  mixins: [userMixin, systemMixin],
  components: {
    InspectUmuBenchmark,
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'umuBenchmarkId',
          sortable: true
        },
        {
          title: 'Umu Benchmark',
          name: 'umuBenchmark',
          sortable: true
        },


      ],
      editItem: null,
      pages: 0,
      updateResults: true,
      total: null,
      fullData: null,
    }
  },
  methods: {
    getUmuBenchmarksHandler(data) {

      this.getUmuBenchmarksPaginated(data)
          .then(res => {
            this.pages = res.pagesCount;
            let resultsFormatted = [];

            for (const results of res.results) {
              let resultsObj = {
                umuBenchmarkId: results.id,
                umuBenchmark: results.umu_benchmark,
              }
              resultsFormatted.push(resultsObj);
            }
            this.rows = resultsFormatted;
            this.fullData = res.results;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },

    editHandler(item) {
      this.editItem = this.fullData.find(results => {
        return results.id === item.umuBenchmarkId;
      });
    },
    updatedHandler() {
      this.editItem = null;
      this.updateResults = !this.updateResults;

    },

  },

  computed: {
  },

}
</script>

<style scoped>


</style>