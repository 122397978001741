<template>
  <div>
    <apexchart v-if="showChart" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  import marketingMixin from '@/mixins/marketing'
  import datesMixin from '@/mixins/dates'

  export default {
    name: 'UsersVerifiedPieChart',
    mixins: [marketingMixin, datesMixin],
    data() {
      return {
        showChart: false,
        series: [],
        chartOptions: {
          chart: {
            type: 'donut',
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          labels: ['Verified Users', 'Unverified Users'],
          colors:['#3C6E71', '#003E52'],
          legend: {
            position: 'bottom',
            fontFamily: 'Poppins',
            fontWeight: 500
          }
        }
      }
    },
    methods: {
      getUsersMarketingHandler() {
        const dataToSend = {};

        let yesterday = this.getDateBeforeDays(1);
        dataToSend.toDate = this.formatDateDashes(yesterday);

        let sevenDaysAgo = this.getDateBeforeDays(7);
        dataToSend.fromDate = this.formatDateDashes(sevenDaysAgo);

        this.getUsersMarketing(dataToSend)
          .then(res => {
            this.series.push(res.totalVerifiedUsers);
            this.series.push(res.totalUnverifiedUsers);

            this.showChart = true;
          })
      }
    },
    mounted() {
      this.getUsersMarketingHandler();
    }
  }
</script>