<template>
  <!-- Fund Umbrella Managers Page -->
  <div class="investment-fund-managers-page">
    <h1>Investment Fund Managers <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="[]"
      @update-table="getInvestmentFundsHandler" 
    />

  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'InvestmentFundManagersMds',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'fundName',
            sortable: true
          },
          {
            title: 'Group ID',
            name: 'fundGroupId',
            sortable: true
          },
          {
            title: 'Group Name',
            name: 'fundGroupName',
            sortable: true
          },
          {
            title: 'Advisor ID',
            name: 'advisorId',
            sortable: true
          },
          {
            title: 'Advisor Name',
            name: 'advisorName',
            sortable: true
          },
          {
            title: 'Asset Manager ID',
            name: 'assetManagerId',
            sortable: true
          },
          {
            title: 'Asset Manager',
            name: 'assetManagerName',
            sortable: true
          },
          {
            title: 'AM Preferred Name',
            name: 'assetManagerPreferredName',
            sortable: true
          },
          {
            title: 'URL Name',
            name: 'urlName',
            sortable: true
          },
          {
            title: 'Share Class Count',
            name: 'shareClassCount',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        pages: 0,
        updateResults: true,
        total: null
      }
    },
    methods: {
      // * Get fund umbrellas from API
      getInvestmentFundsHandler(data) {
        this.getInvestmentFundsPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let fundsFormatted = [];
            // * Loop through groups and add only the needed properties
            for (const investmentFund of res.investmentFunds) {
              let investmentFundObj = {
                id: investmentFund.id,
                name: investmentFund.fundName,
                groupId: investmentFund.fundGroupId,
                groupName: investmentFund.fundGroupName,
                advisorId: investmentFund.advisorId,
                advisorName: investmentFund.advisorName,
                assetManagerId: investmentFund.assetManagerId,
                assetManager: investmentFund.assetManagerName,
                assetManagerpreferredName: investmentFund.assetManagerPreferredName,
                urlName: investmentFund.urlName,
                shareClassCount: investmentFund.shareClassCount,
                addedOn: investmentFund.addedOn,
                updatedOn: investmentFund.updatedOn,
              }
              // * Add formatted group to formatted groups array
              fundsFormatted.push(investmentFundObj);
            }
            // * Assign table rows to be the formatted groups array
            this.rows = fundsFormatted;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      }
    }
  }
</script>

<style scoped>


</style>