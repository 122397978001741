<template>
  <!-- Edit Fund Characteristics Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Fund Characteristics"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Name <small>(not editable)</small>:</label>
        <input type="text" id="name" v-model="fund.fundName" placeholder="Name" disabled>
      </div>

      <div class="field umbrella-description" v-if="fund.investmentObjective">
        <label>Description</label>
        <p>
          {{fund.investmentObjective}}
        </p>
      </div>

      <div class="field selected-benchmarks" v-if="fund.SelectedBenchmarks && fund.SelectedBenchmarks.length > 0">
        <label>Selected Benchmarks:</label>
        <ul>
          <template v-for="(benchmark, index) in fund.SelectedBenchmarks">
            <li v-if="benchmark.benchmark" :key="index">
              {{benchmark.benchmark}}
            </li>
          </template>
        </ul>
      </div>

      <div class="field autocomplete characteristics-dropdown">
        <label>Fund Category:</label>
        <div class="select-container">
          <select v-model="selectedFundCategory" @change="selectFundCategory">
            <option disabled value="">Please select one</option>
            <option v-for="fundCategory in fundCategories" :key="fundCategory.id" :value="fundCategory">{{ fundCategory.name }}</option>
          </select>
        </div>
      </div>

      <div class="field autocomplete characteristics-dropdown">
        <label>Sector:</label>
        <div class="select-container">
          <select v-model="selectedSector" @change="selectSector">
            <option disabled value="">Please select one</option>
            <option :value="{id: -1, name: '-'}">-</option>
            <option v-for="sector in getFilteredSectors" :key="sector.id" :value="sector">{{ sector.name }}</option>
          </select>
        </div>
      </div>

      <div class="field autocomplete characteristics-dropdown">
        <label>Style:</label>
        <div class="select-container">
          <select v-model="selectedStyle" @change="selectStyle">
            <option disabled value="">Please select one</option>
            <option :value="{id: -1, name: '-'}">-</option>
            <option v-for="style in getFilteredStyles" :key="style.id" :value="style">{{ style.name }}</option>
          </select>
        </div>
      </div>

      <div class="field autocomplete characteristics-dropdown">
        <label>Geography:</label>
        <div class="select-container">
          <select v-model="selectedGeography" @change="selectGeography">
            <option disabled value="">Please select one</option>
            <option :value="{id: -1, name: '-'}">-</option>
<!--            We use geography.geography to access the name since we use the geographies array from the child component and not a filtered array from here -->
            <option v-for="geography in geographies" :key="geography.id" :value="geography">{{ geography.geography }}</option>
          </select>
        </div>
      </div>

      <!-- Benchmark -->
      <div class="field">
        <label for="name"> Benchmark </label>
        <input type="text" id="benchmark" v-model="fund.benchmark">
      </div>

      <!-- Holdings -->
      <div class="field">
        <label for="name"> Top 10 Holdings </label>
        <ul>
          <div v-if="top10Holdings.length > 0">
            <li v-for="(holding, index) in top10Holdings" :key="index">
              {{ holding }}
            </li>
          </div>
          <div class="field" v-else>
            No top holdings
          </div>
        </ul>
      </div>  

      <!-- Objective -->
      <div class="field">
        <label for="name"> Objective </label>
        <textarea type="text" id="objective" v-model="fund.objective.en.objective"></textarea>
      </div>

      <!-- Search Score -->
      <div class="field">
        <label for="name"> Search Score </label>
        <input type="number" id="search-score" min="1" max="10" placeholder="min: 1 | max: 10" v-model="fund.searchScore">
      </div>

      <button class="transition save-btn" @click="updateFundHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundCharacteristics',
    mixins: [fundsMixin, userMixin],
    components: {
      Popup
    },
    props: {
      item: Object,
      // We use filtered sectors and styles, geographies are not filtered
      sectors: Array,
      styles: Array,
      geographies: Array,
      fundCategories: Array,
    },
    data() {
      return {
        selectedFundCategory: {
          id: null,
          name: null,
        },
        selectedSector: {
          id: null,
          name: null,
        },
        selectedStyle: {
          id: null,
          name: null,
        },
        selectedGeography: {
          id: null,
          name: null,
        },
        updateLoading: false,
        fund: {...this.item},
        searchTerm: '',
        filteredItems: [],
        top10Holdings: []
      }
    },
    methods: {
      // * Triggers on save button click and updates umbrella
      updateFundHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {};

        // * If user has selected Fund Category from the list add it to the request data, else send 0 "Other"
        if (this.selectedFundCategory) {
          dataToSend.fundCategoryId = this.selectedFundCategory.id;
        } else {
          dataToSend.fundCategoryId = 0;
        }

        // * If user has selected sector from the list add it to the request data else send null
        if (this.selectedSector) {
          dataToSend.sectorId = this.selectedSector.id;
        } else {
          dataToSend.sectorId = null;
        }

        // * If user has selected style from the list add it to the request data else send null
        if (this.selectedStyle) {
          dataToSend.styleId = this.selectedStyle.id;
        } else {
          dataToSend.styleId = null;
        }

        // * If user has selected geography from the list add it to the request data else send null
        if (this.selectedGeography) {
          dataToSend.geographyId = this.selectedGeography.id;
        } else {
          dataToSend.geographyId = null;
        }

        if (this.fund.searchScore) {
          dataToSend.searchScore = this.fund.searchScore;
        }

        this.editFundCharacteristics(this.fund.id, dataToSend)
            .then(() => {
              this.$emit('updated');
              this.updateLoading = false;
            })
            .catch(err => {
              if (err == 401) {
                this.logout();
              }
              this.updateLoading = false;
            })
      },

      getHoldings() {
        this.getTop10Holdings(this.fund.id)
            .then(res => {
              res.top10Holdings.forEach(holding => {
                this.top10Holdings.push(holding.holdingSecurityName);
              })
            })
            .catch(err => {
              if (err == 401) {
                this.logout();
              }
            })
      },

      selectFundCategory() {
        this.fund.fundCategoryId = this.selectedFundCategory.id;
        this.fund.fundCategoryName = this.selectedFundCategory.name;
      },
      selectSector() {
        this.fund.sectorId = this.selectedSector.id;
        this.fund.sectorName = this.selectedSector.name;
      },
      selectStyle() {
        this.fund.styleId = this.selectedStyle.id;
        this.fund.styleName = this.selectedStyle.name;
      },
      selectGeography() {
        this.fund.geographyId = this.selectedGeography.id;
        this.fund.geographyName = this.selectedGeography.name;
      },

      submitFundCategory(event) {
        this.selectedFundCategory = JSON.parse(event.target.value);
      },
      submitSector(event) {
        this.selectedSector = JSON.parse(event.target.value);
      },
      submitStyle(event) {
        this.selectedStyle = JSON.parse(event.target.value);
      },
      submitGeography(event) {
        this.selectedGeography = JSON.parse(event.target.value);
      },
    },

    computed: {

      getFilteredSectors() {
        let filteredSectors = [];
        switch (this.selectedFundCategory.id) {
          case 0:
            for (const sector of this.sectors){
              if (sector.other) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
          case 1:
            for (const sector of this.sectors){
              if (sector.equity) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
          case 2:
            for (const sector of this.sectors){
              if (sector.bond) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
          case 3:
            for (const sector of this.sectors){
              if (sector.multiAsset) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
          case 4:
            for (const sector of this.sectors){
              if (sector.moneyMarket) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
          case 5:
            for (const sector of this.sectors){
              if (sector.commodity) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
          case 6:
            for (const sector of this.sectors){
              if (sector.crypto) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
          case 7:
            for (const sector of this.sectors){
              if (sector.property) {
                filteredSectors.push({id: sector.id, name: sector.sector});
              }
            }
            return filteredSectors;
        }
        return null;
      },

      getFilteredStyles() {
        let filteredStyles = [];
        switch (this.selectedFundCategory.id) {
          case 0:
            for (const style of this.styles){
              if (style.other) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
          case 1:
            for (const style of this.styles){
              if (style.equity) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
          case 2:
            for (const style of this.styles){
              if (style.bond) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
          case 3:
            for (const style of this.styles){
              if (style.multiAsset) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
          case 4:
            for (const style of this.styles){
              if (style.moneyMarket) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
          case 5:
            for (const style of this.styles){
              if (style.commodity) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
          case 6:
            for (const style of this.styles){
              if (style.crypto) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
          case 7:
            for (const style of this.styles){
              if (style.property) {
                filteredStyles.push({id: style.id, name: style.style});
              }
            }
            return filteredStyles;
        }
        return null;
      },
    },

    mounted() {
      this.getHoldings();
      this.selectedFundCategory = {
        id: this.fund.fundCategoryId,
        name: this.fund.fundCategoryName
      }

      if (this.fund.sectorId) {
        this.selectedSector = {
          id: this.fund.sectorId,
          name: this.fund.sectorName
        }
      } else {
        this.selectedSector = {
          id: -1,
          name: "-"
        }
      }

      if (this.fund.styleId) {
        this.selectedStyle = {
          id: this.fund.styleId,
          name: this.fund.styleName
        }
      } else {
        this.selectedStyle = {
          id: -1,
          name: "-"
        }
      }

      if (this.fund.geographyId) {
        this.selectedGeography = this.geographies.find(geography => geography.id === this.fund.geographyId);
      } else {
        this.selectedGeography = {
          id: -1,
          name: "-"
        }
      }
    }
  }
</script>

<style scoped>

  .umbrella-description,
  .selected-benchmarks li {
    font-size: 14px;
  }

  .characteristics-dropdown {
    display: flex;
    align-items: center;
  }

  .characteristics-dropdown label {
    font-size: 18px;
    margin-right: 10px;
  }

  .characteristics-dropdown select {
    width: 210px;
    border: none;
    border-radius: 20px;
    padding: 9px 15px;
    font-size: 18px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 0.75rem center/8px 10px;
    background-color: white;
  }

  .characteristics-dropdown .filter-btn {
    background: #3c6e71;
    color: #FFF;
    border: none;
    font-weight: 600;
    height: 45px;
    width: 100px;
    border-radius: 20px;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .characteristics-dropdown .filter-btn:hover {
    background: #FFF;
    color: #3c6e71;
  }

  .characteristics-dropdown {
    display: flex;
    align-items: center;
  }

  .characteristics-dropdown label {
    width: 150px; /* Adjust the width of the labels as needed */
  }

  .characteristics-dropdown .select-container {
    margin-left: 10px; /* Adjust the left margin as needed */
  }

</style>
