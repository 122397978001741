<template>
  <!-- Edit FAQ Category popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit FAQ Category"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label for="name">Category Name EN:</label>
        <input type="text" id="name" v-model="nameEn" placeholder="Category Name EN...">
      </div>

      <div class="field">
        <label for="nameDe">Category Name DE:</label>
        <input type="text" id="nameDe" v-model="nameDe" placeholder="Category Name DE...">
      </div>

      <button class="transition save-btn" @click="editFaqCategoryHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFAQCategory',
    mixins: [systemMixin, userMixin],
    components: {
      Popup
    },
    props: {
      item: Object
    },
    data() {
      return {
        nameEn: this.item.languages.en.name,
        nameDe: this.item.languages.de.name,
        updateLoading: false
      }
    },
    methods: {
      editFaqCategoryHandler() {
        this.updateLoading = true;
        const dataToSend = {
          languages: {
            en: {
              name: this.nameEn
            },
            de: {
              name: this.nameDe
            }
          }
        }

        this.editFaqCategory(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated')
            this.updateLoading = false;
          })
          .catch(err => {
            this.updateLoading = false;
            if (err == 401) {
              this.logout();
            }
          })
      }
    }
  }
</script>

<style scoped>
  
</style>
