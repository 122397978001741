<template>
  <div>
    <apexchart v-if="showChart" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  export default {
    name: 'QuizKnowledgePieChart',
    props: {
      knowledge: Object
    },
    data() {
      return {
        showChart: true,
        series: [
          this.knowledge.beginner,
          this.knowledge.advanced
        ],
        chartOptions: {
          chart: {
            type: 'pie',
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          labels: ['Beginner', 'Advanced'],
          colors:['#C1A16F', '#61F1F9'],
          legend: {
            position: 'bottom',
            fontFamily: 'Poppins',
            fontWeight: 500
          }
        }
      }
    }
  }
</script>