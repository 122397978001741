<template>
  <div v-if="editSession" class="knowledge-session">
    <!-- <pre>{{ editSession }}</pre> -->
    <div class="content">
      <h1 class="page-title">Edit Lecture</h1>
      <!-- Language switcher -->
      <div class="language-switcher">
        <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">EN</button>
        <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">DE</button>
      </div>

      <!-- Session title -->
      <div class="field">
        <label for="session-title">Lecture Name</label>
        <input type="text" id="session-title" placeholder="Lecture name" v-model="editSession.title[currentLanguage].title">
      </div>

      <!-- Session Description -->
      <div class="field">        
        <div class="field-header">
          <label>Lecture Description</label>
          <button class="add-file-btn transition" @click="addFilePopup = true">Add Media</button>
        </div>

        <!-- RTE (Rich text editor) field (using external library - https://tiptap.dev/) -->
        <TextEditor
          :body="editSession && editSession.description && editSession.description[currentLanguage] ? editSession.description[currentLanguage].description : ''"
          :addedImage="addedImage"
          @set-content="setDescription"
        />
      </div>

      <!-- Video data -->
      <!-- Displayed only if type is video type(1) -->
      <div v-if="editSession.type == '1'">
        <div class="field">
          <label for="video-id">Video ID</label>
          <input type="text" id="video-id" v-model="editSession.videoData[currentLanguage].videoId">
        </div>

        <div class="field">
          <label for="video-thumbnail">Video Thumbnail</label>
          <input type="text" id="video-thumbnail" v-model="editSession.videoData[currentLanguage].thumbnail">
        </div>

        <div class="field">
          <label for="video-previewId">Video Preview Id</label>
          <input type="text" id="video-previewId" v-model="editSession.videoData[currentLanguage].previewId">
        </div>

        <div class="field">
          <label for="video-duration">Video Duration</label>
          <input type="number" id="video-duration" v-model="editSession.videoData[currentLanguage].duration">
        </div>
      </div>

      <!-- Document data -->
      <div class="field">
        <label class="document-data-label" for="document-data">Document data</label>
        <button class="add-document-btn" @click="addDocumentField()">+</button>
        <button class="add-document-btn" @click="attachDocument = true"><font-awesome-icon :icon="['fas', 'paperclip']" /></button>
        <div class="document-fields-container">
          <div v-for="(document, index) in editSession.documentData[currentLanguage]" :key="index" class="document-field">
            <input type="text" v-model="document.url" placeholder="Document URL">
            <input type="text" v-model="document.title" placeholder="Document Title">
          </div>
        </div>
      </div>
    </div>

    <div class="right-sidebar">
      <!-- Status -->
      <div class="field status-type-field">
        <label>Status:</label>
        <select v-model="editSession.status">
          <option value="0" :selected="editSession.status === '0'">Draft</option>
          <option value="1" :selected="editSession.status === '1'">Active</option>
          <option value="-1" :selected="editSession.status === '-1'">Hidden</option>
        </select>
      </div>

      <!-- Content type -->
      <div class="field content-type-field">
        <label>Type:</label>
        <select v-model="editSession.type">
          <option value="0" :selected="editSession.type === '0'">Text</option>
          <option value="1" :selected="editSession.type === '1'">Video</option>
          <option value="2" :selected="editSession.type === '2'">Quiz</option>
        </select>
      </div>

      <!-- Quiz data -->
      <!-- Displayed only if type is quiz type(1) -->
      <div v-if="editSession.type == '2'">
        <div class="field quiz-field">
          <label>Select Quiz:</label>
          <select @change="updateQuizId($event.target.value)">
            <option value="" selected disabled hidden>Select Quiz</option>
            <option v-for="quiz in quizzes" :key="quiz.id" :value="quiz.id" :selected="quiz.id === editSession.quizId">
              <span v-html="quiz.quizName.en.name"></span>
            </option>
          </select>
        </div>
      </div>

      <div class="field task-type-field">
        <label>Task Type:</label>
        <select @change="updateTaskTypeId($event.target.value)">
          <option class="default" value="null" selected>Select Task Type</option>
          <option v-for="taskType in taskTypes" :key="taskType.id" :value="taskType.id" :selected="taskType.id === editSession.taskType">
            {{ taskType.title }}
          </option>
        </select>
      </div>

      <div class="field active-from-field">
        <label for="session-active-from">Active From:</label>
        <Datepicker v-model="editSession.activeFrom" format="yyyy-MM-dd" :enable-time-picker="false" />
      </div>

      <div class="field">
        <label class="input-with-tooltip" for="bonus-points-end-date">Availability:
          <span class="tooltip">
            This session will be available for the selected plans
          </span>
        </label>
        <div class="availability-options">
          <div class="option">
            <label class="custom-check green">
              <label for="explore">Explore</label>
              <input type="checkbox" id="explore" v-model="editSession.explore">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="option">
            <label class="custom-check green">
              <label for="learn">Learn</label>
              <input type="checkbox" id="learn" v-model="editSession.learn">
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="option">
            <label class="custom-check green">
              <label for="grow">Grow</label>
              <input type="checkbox" id="grow" v-model="editSession.grow">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="field points-field">
        <label for="session-points">Points:</label>
        <input type="number" id="session-points" v-model="editSession.pointsData.points">
      </div>

      <div class="field bonus-points-end-date">
        <label class="input-with-tooltip" for="bonus-points-end-date">Bonus End Date:
          <span class="tooltip">
            The date when the bonus points will no longer be available
          </span>
        </label>
        <Datepicker v-model="editSession.pointsData.bonusPointsEndDate" format="yyyy-MM-dd" :enable-time-picker="false" />
      </div>

      <div class="field points-field">
        <label class="input-with-tooltip" for="max-bonus-points-percentage">Max Bonus %:
          <span class="tooltip">
            Maximum percentage of bonus points that can be earned
          </span>
        </label>
        <input type="number" id="max-bonus-points-percentage" v-model="editSession.pointsData.maxBonusPointsPercentage"/>
      </div>

      <div class="centered">
        <button class="save-btn transition" @click="editSessionHandler()" :disabled="isUpdating">
           {{ isUpdating ? 'Updating...' : 'Update' }}
        </button>
        <button class="back-btn transition" @click="goBack">
          <font-awesome-icon class="back-arrow" icon="arrow-left" />
          Go Back
        </button>
      </div>
      <p v-if="updateSuccess" class="success-message">Lecture updated successfully</p>
      <p v-if="!updateSuccess && errorMsg" class="error-message">{{errorMsg}}</p>
    </div>

    <!-- Popups -->
    <AddFile
      v-if="addFilePopup"
      :sessionId="+sessionId"
      @image-added="imageAddedHandler"
      @close="addFilePopup = false"
    />

    <AttachDocument
      v-if="attachDocument"
      :sessionId="+sessionId"
      @close="attachDocument = false"
      @document-added="documentAdded"
    />
  </div>
</template>

<script>
import knowledgeMixin from "@/mixins/knowledge";
import quizMixin from '@/mixins/quiz';
import TextEditor from '@/components/shared/TextEditor'
import dayjs from 'dayjs';
import AddFile from './popups/AddFile'
import AttachDocument from './popups/AttachDocument'

export default {
  name: "KnowledgeSessionEdit",
  mixins: [knowledgeMixin, quizMixin],
  components: { 
    TextEditor,
    AddFile,
    AttachDocument,
  },
  data() {
    return {
      addedImage: null,
      addFilePopup: false,
      editSession: {
        title: {
          en: {
            title: "",
          },
          de: {
            title: "",
          },
        },
        description: {
          en: {
            description: "",
          },
          de: {
            description: "",
          },
        },
        videoData: {
          en: {
            videoId: "",
            thumbnail: "",
            previewId: '',
            duration: null,
          },
          de: {
            videoId: "",
            thumbnail: "",
            previewId: '',
            duration: null,
          }
        },
        documentData: {
          en: [],
          de: []
        },
        quizId: null,
        status: 1,
        type: 0,
        taskType: null,
        activeFrom: null,
        explore: null,
        learn: null,
        grow: null,
        pointsData: {
          points: null,
          bonusPointsEndDate: null,
          maxBonusPointsPercentage: null
        }
      },
      courseId: this.$route.params.course,
      moduleId: this.$route.params.module,
      sectionId: this.$route.params.section,
      sessionId: this.$route.params.session,
      currentLanguage: 'en',
      languages: ['en', 'de'],
      quizzes: null,
      taskTypes: null,
      updateSuccess: false,
      isUpdating: false,
      errorMsg: '',
      attachDocument: false,
    };
  },
  methods: {
    imageAddedHandler(imageUrl) {
      this.addFilePopup = false;
      this.addedImage = imageUrl;
    },
    getSessionHandler() {
      this.getSession(this.courseId, this.moduleId, this.sectionId, this.sessionId).then((res) => {
        if(res.session) {
          const data = res.session;
          for (let key in data) {
            let dataValue = data[key];

            if(dataValue == null) {
              continue;
            }

            if (typeof dataValue === 'object' && (dataValue == null || Object.keys(dataValue).length == 0)) {
              continue;
            }

            if (Array.isArray(dataValue) && dataValue.length == 0) {
              continue;
            }

            if (typeof dataValue === 'string' && dataValue.trim().length == 0) {
              continue;
            }

            if (key == 'taskType') {
              this.editSession[key] = dataValue.id;
              continue;
            }
   
            this.editSession[key] = dataValue;
          }
        }
      });
    },
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    editSessionHandler() {
      this.isUpdating = true;
      for (const language in this.editSession.documentData) {
        this.editSession.documentData[language] = this.editSession.documentData[language].filter(
          (document) => document.url.trim() !== '' && document.title.trim() !== ''
        );
      }

      if (this.editSession.activeFrom) {
        const date = dayjs(this.editSession.activeFrom);
        this.editSession.activeFrom = date.format('YYYY-MM-DD');
      }

      if (!this.validateSessionInputs()) {
        this.isUpdating = false;
        this.updateSuccess = false;
        setTimeout(() => {
          this.errorMsg = '';
        }, 3000);
        return;
      }

      this.updateSession(
          this.courseId, 
          this.moduleId, 
          this.sectionId, 
          this.sessionId, 
          this.editSession
        )
        .then(() => {
          this.isUpdating = false;
          this.updateSuccess = true;
          this.$emit('updated');

          setTimeout(() => {
            this.updateSuccess = false;
          }, 5000);
        })
        .catch(() => {
          this.isUpdating = false;
        });
    },
    validateSessionInputs() {
      const activeFrom = !!this.editSession.activeFrom;
      const points = !!this.editSession.pointsData.points;
      const bonusPointsEndDateExists = !!this.editSession.pointsData.bonusPointsEndDate;
      const maxBonusPointsExists = !!this.editSession.pointsData.maxBonusPointsPercentage;
      if ((bonusPointsEndDateExists && !maxBonusPointsExists) || (!bonusPointsEndDateExists && maxBonusPointsExists)) {
        this.errorMsg = "Both Bonus End Date and Max Bonus Percentage are required together.";
        return false;
      }

      if ((bonusPointsEndDateExists && !activeFrom)) {
        this.errorMsg = "You cannot set Bonus End Date without Active From";
        return false;
      }

      if (!points && (bonusPointsEndDateExists || maxBonusPointsExists)) {
        this.errorMsg = "Points must be set to add Bonus End Date or Max Bonus Percentage.";
        return false;
      }

      if (this.editSession.activeFrom && this.editSession.pointsData.bonusPointsEndDate) {
        const activeFromDate = dayjs(this.editSession.activeFrom);
        const bonusPointsEndDate = dayjs(this.editSession.pointsData.bonusPointsEndDate);
        if (bonusPointsEndDate.isBefore(activeFromDate)) {
          this.errorMsg = "Bonus Points End Date should be after the Active From date";
          return false;
        }

        this.editSession.pointsData.bonusPointsEndDate = bonusPointsEndDate.format('YYYY-MM-DD');
      }

      if (this.editSession.pointsData.maxBonusPointsPercentage && this.editSession.pointsData.maxBonusPointsPercentage < 0) {
        this.errorMsg = "Bonus Points cannot be a negative number";
        return false;
      }

      if ((this.editSession.pointsData.points !== null && this.editSession.pointsData.points != '') && this.editSession.pointsData.points < 1) {
        this.errorMsg = "Session points should be a positive number";
        return false;
      }

      return true;
    },
    getAllQuizzesHandler() {
      this.getQuizzes().then(res => {
        this.quizzes = res.quizzes;
      })
    },
    getQuizName(quizId) {
        let selectedQuiz = this.quizzes.find(quiz => {
          return quiz.id == quizId;
        });

        return selectedQuiz.quizName.en.name;
    },
    updateQuizId(selectedValue) {
      this.editSession.quizId = selectedValue;
    },
    updateTaskTypeId(selectedValue) {
      this.editSession.taskType = selectedValue === "null" ? null : selectedValue;
    },
    addDocumentField() {
      if(!this.editSession.documentData[this.currentLanguage]) {
        this.editSession.documentData[this.currentLanguage] = [];
      }

      this.editSession.documentData[this.currentLanguage].push({ url: '', title: '' });
    },
    // * Set content from RTE
    setDescription(body) {
      if(!this.editSession.description[this.currentLanguage]) {
        this.editSession.description[this.currentLanguage] = [];
      }

      this.editSession.description[this.currentLanguage].description = body;
    },
    fetchTaskTypes() {
      this.getTaskTypes()
        .then((taskTypes) => {
          if(taskTypes) {
            this.taskTypes = taskTypes;
          }
        })
        .catch((error) => {
          console.error("Error fetching task types:", error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    documentAdded(documentData) {
      this.attachDocument = false;
      console.log(documentData);
      this.editSession.documentData[this.currentLanguage].push(documentData);
    }
  },
  mounted() {
    this.getSessionHandler();
    this.getAllQuizzesHandler();
    this.fetchTaskTypes();
  },
};
</script>

<style scoped>
  .availability-options {
    display: flex;
    width: 100%;
    padding: 2% 0px 1% 4%;
    flex-direction: column;
    align-items: flex-start !important;
    border: 1px solid #a7a7a7;
    border-radius: 8px;
  }

  .availability-options .option {
    margin-bottom: 0px;
  }

  .availability-options .option .custom-check {
    margin-bottom: 0px;
  }

  label {
    color: #44444f;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    flex: 0 0 100px;
    text-align: left;
    margin-right: 7px;
  }
  
  input {
    border: 1px solid #e2e2ea;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-size: 20px;
    outline: none;
  }

  .knowledge-session button.active {
    background-color: #3c6e71;
    color: white;
  }

  .document-field {
    width: 100%;
  }

  .document-field input {
    margin-bottom: 0;
    width: 49%;
  }

  .document-field input:first-child {
    margin-right: 2%;
  }

  .add-document-btn {
    background-color: #3c6e71;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    padding: 6px 14px;
    margin-left: 15px;
  }

  .document-data-label {
    margin-bottom: 25px;
  }

  .document-data-label, .content-type-field > label, .quiz-field > label, .status-type-field > label, .active-from-field > label, .points-field > label, .task-type-field > label {
    flex: 0 0 102px;
    display: inline-block;
  }

  .quiz-field select, .task-type-field select{
    max-width: 215px;
  }

  .right-sidebar select {
    padding: 3px 12px;
    color: #696974;
    font-weight: 500;
    border: 1px solid #e2e2ea;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
  }

  .knowledge-session {
    display: flex;
  }

  .content {
    flex-grow: 1;
    margin-left: 5%;
    width: 60%;
    max-width: calc(100% - 512px);
    padding: 45px 60px 45px 35px;
  }

  .content .field {
    margin-bottom: 25px;
  }

  .right-sidebar {
    padding: 18px;
    flex-basis: 20%;
    background-color: white;
  }

  .right-sidebar div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .save-btn {
    background: #3c6e71;
    color: #fff;
    border-radius: 8px;
    outline: none;
    border: 2px solid #3c6e71;
    cursor: pointer;
    padding: 5px 15px;
    font-size: 16px;
  }

  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .page-title {
    display: inline-block;
  }

  .language-switcher {
    float: right;
  }

  .language-switcher button {
    border: none;
    padding: 9px 15px;
    font-weight: 600;
  }

  .field.points-field input {
    background: white;
    max-height: 40px;
  }

  .input-with-tooltip {
    position: relative;
  }

  .tooltip {
    display: none;
    position: absolute;
    background: #f9f9f9;
    color: #333;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    bottom: 120%;
    width: 200px;
    z-index: 3;
  }

  .input-with-tooltip:hover .tooltip {
    display: block;
  }

  .bold {
    font-weight: bold;
  }

  .task-type-field .default {
    color: #999;
  }

  .back-btn, .save-btn {
    margin-bottom: 10px;
  }

  .back-btn {
    background: #6c757d;
    color: #fff;
    border-radius: 8px;
    outline: none;
    border: 2px solid #6c757d;
    cursor: pointer;
    padding: 5px 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .back-btn .back-arrow {
    margin-right: 5px;
  }

  .field-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .field-header label {
    text-wrap: nowrap;
  }

  .add-file-btn {
    color: var(--dark-green);
    background: #FFFFFF;
    border: 1px solid var(--dark-green);
    border-radius: 6px;
    padding: 5px 22px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    outline: none;
  }

  .add-file-btn:hover {
    color: #FFF;
    background: var(--dark-green);
  }

</style>
