<template>
  <!-- Mushroom Loader -->
  <div class="loader">
    <img :src="$s3Url + 'preloader.gif'">
  </div>
</template>

<script>
  export default {
    name: 'Loader'
  }
</script>

<style scoped>
  .loader {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 80px;
    color: #3C6E71;
    margin: 60px 0;
  }
</style>