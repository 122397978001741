<template>
  <!-- Equity Market Data Page -->
  <div class="active-equities-new-page">
    <h1>Active Equities <small v-if="total">({{total}})</small></h1>
    <button class="export-btn" @click="exportFile()">ISIN Export</button>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getEquitiesHandler"
      @edit="editHandler" 
    />

    <!-- Inspect Popup -->
    <InspectActiveEquityNew
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import securitiesMixin from '@/mixins/securities'
  import userMixin from '@/mixins/user'
  import exportFromJSON from "export-from-json";

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectActiveEquityNew from "@/components/equities/popups/InspectActiveEquityNew.vue";

  export default {
    name: 'ActiveEquitiesNew',
    mixins: [securitiesMixin, userMixin],
    components: {
      InspectActiveEquityNew,
      DataTableHolder,
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Valor',
            name: 'valor',
            sortable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Currency',
            name: 'currencyCode',
            sortable: true
          },
          {
            title: 'Market Cap USD (Bn)',
            name: 'marketCapUsd',
            sortable: true
          },
          {
            title: 'Exchange ID',
            name: 'exchangeId',
            sortable: true
          },
          {
            title: 'Exchange Name',
            name: 'exchangeName',
            sortable: true
          },
          {
            title: 'Threshold',
            name: 'threshold',
            sortable: true
          },
          {
            title: 'Factset OK',
            name: 'factsetOk',
            sortable: true
          },
          {
            title: 'Sentifi OK',
            name: 'sentifiOk',
            sortable: true
          },
          {
            title: 'URL Name',
            name: 'urlName',
            sortable: true
          },
          {
            title: 'Active Comment',
            name: 'activeComment',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get equities from API
      getEquitiesHandler(data) {
        let foundIsActiveFilter = data.filter.filter(function(item){
          return item.name == 'isActive';
        });
        if (foundIsActiveFilter.length == 0) {
          data.filter.push({
            name: 'isActive',
            term: true
          });
        }

        this.getSecurities(data, 1)   // TYPE_EQUITY = 1;
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let equitiesFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const equity of res.securities) {
              //let marketCapRounded = Math.round(equity.market_cap_data.market_cap_usd);
              let marketCapRounded;
              if (equity.market_cap_data != null) {
                marketCapRounded = equity.market_cap_data.market_cap_usd / 1000000000;
                marketCapRounded = marketCapRounded.toFixed(2);
              }

              let equityObj = {
                id: equity.id,
                name: equity.name,
                valor: equity.valor,
                isin: equity.isin,
                currencyCode: equity.code,
                //marketCapUsd: marketCapRounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                marketCapUsd: marketCapRounded,
                exchangeId: equity.feed_data != null ? equity.feed_data.exchange_id : null,
                exchangeName: equity.exchange_name,
                threshold: equity.feed_data != null ? equity.feed_data.threshold : null,
                factsetOk: equity.feed_data != null ? equity.feed_data.factset_ok : null,
                sentifiOk: equity.sentiment_data != null ? equity.sentiment_data.sentifi_ok : null,
                urlName: equity.url_name,
                activeComment: equity.active_comment,
                addedOn: equity.added_on
              };
              // * Add formatted equity to formatted equities array
              equitiesFormatted.push(equityObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = equitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(equity => {
          return equity.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      // * Generate an Excel which can be FTPed as is to FactSet
      exportFile() {
        this.getAllActiveAndCandidateSecurityISINs(1)
            .then(response => {
              const csvData = response.items.map(item => ({
                isin: item.isin
              }));

              const currentDate = new Date();
              const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, '');
              const fileName = `UMU_UNIVERSE_${formattedDate}`;

              exportFromJSON({
                data: csvData,
                fileName: fileName,
                exportType: 'csv'
              });
            })
            .catch(err => {
              if (err === 401) {
                this.logout();
              }
            })
      }
    }
  }
</script>

<style scoped>
.export-btn {
  background: #3c6e71;
  color: #FFF;
  border: none;
  font-weight: 600;
  height: 40px;
  width: 120px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  display: block;
  margin: 0 0 25px auto;
}

.export-btn:hover {
  background: #FFF;
  color: #3c6e71;
}
</style>