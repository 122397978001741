<template>
  <!-- Equity Stock Splits Page -->
  <div class="equity-page">
    <h1>Stock Splits <small v-if="total">({{total}})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['view']"
        @update-table="getEquitiesHandler"
        @view="viewHandler"
    />

    <!-- Inspect Popup -->
    <InspectStockSplits
      v-if="viewItem"
      :item="viewItem"
      @close="viewItem = null"
    />
  </div>
</template>

<script>
import securitiesMixin from '@/mixins/securities'
import userMixin from '@/mixins/user'

import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
import InspectStockSplits from "@/components/equities/popups/InspectStockSplits.vue";

export default {
  name: 'StockSplits',
  mixins: [securitiesMixin, userMixin],
  components: {
    DataTableHolder,
    InspectStockSplits
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable: true
        },
        {
          title: 'Name',
          name: 'name',
          sortable: true
        },
        {
          title: 'Valor',
          name: 'valor',
          sortable: true
        },
        {
          title: 'ISIN',
          name: 'isin',
          sortable: true
        },
        {
          title: 'Currency',
          name: 'currencyCode',
          sortable: true
        },
        {
          title: 'Upcoming Split Ratio',
          name: 'upcomingSplitRatio',
          sortable: true
        },
        {
          title: 'Upcoming Split Date',
          name: 'upcomingSplitDate',
          sortable: true
        },
        {
          title: 'Confirmed Split Ratio',
          name: 'confirmedSplitRatio',
          sortable: true
        },
        {
          title: 'Confirmed Split Date',
          name: 'confirmedSplitDate',
          sortable: true
        },
        {
          title: 'Active Comment',
          name: 'activeComment',
          sortable: true
        }
      ],
      viewItem: null,
      pages: 0,
      updateResults: true,
      fullData: null,
      total: null
    }
  },
  methods: {
    // * Get equities from API
    getEquitiesHandler(data) {
      let foundStockSplitsFilter = data.filter.filter(function(item){
        return item.name == 'stockSplits';
      });

      if (foundStockSplitsFilter.length == 0) {
        data.filter.push({
          name: 'stockSplits',
          term: true
        });

        data.sortBy = {
          column: 'upcomingSplitDate',
          order: 'DESC'
        };
      }

      this.getSecurities(data, 1)
        .then(res => {
          // * After receiving response update data
          this.pages = res.pagesCount;
          // * Choose which fields to be visible in the datatable
          let equitiesFormatted = [];

          // * Loop through funds and add only the needed properties
          for (const equity of res.securities) {
            let equityObj = {
              id: equity.id,
              name: equity.name,
              valor: equity.valor,
              isin: equity.isin,
              currencyCode: equity.code,
              upcomingSplitRatio: equity.equity_data.stock_splits.upcoming.split,
              upcomingSplitDate: equity.equity_data.stock_splits.upcoming.date,
              confirmedSplitRatio: equity.equity_data.stock_splits.confirmed ? equity.equity_data.stock_splits.confirmed.split : null,
              confirmedSplitDate: equity.equity_data.stock_splits.confirmed ? equity.equity_data.stock_splits.confirmed.date : null,
              activeComment: equity.active_comment
            };
            
            // * Add formatted equity to formatted equities array
            equitiesFormatted.push(equityObj);
          }
          // * Assign table rows to be the formatted equities array
          this.rows = equitiesFormatted;
          this.fullData = res.securities;
          this.total = res.resultsCount;
        })
        .catch(err => {
          if (err === 401) {
            this.logout();
          }
        })
    },
    // * Triggered when user click the watch icon on a row
    viewHandler(item) {
      this.viewItem = this.fullData.find(equity => {
        return equity.id === item.id;
      });
    }
  }
}
</script>

<style scoped>

</style>