<template>
  <popup
    title="New broker"
    @close="$emit('close')"
  >
    <template v-slot:content>

      <div class="field">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="name" placeholder="Name">
      </div>

      <div class="field">
        <label for="preferredName">Preferred Name:</label>
        <input type="text" id="preferredName" v-model="preferredName" placeholder="Preferred Name">
        <p v-if="!urlName" class="success-message">URL will be based on this name.</p>
      </div>

      <div class="field">
        <label for="urlName">URL:</label>
        <input type="text" id="urlName" v-model="urlName" placeholder="URL">
        <p v-if="!urlName" class="error-message">URL must not be empty!</p>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPaid">
          <span class="checkmark"></span>
          Is Paid
        </label>
      </div>

      <button class="transition save-btn" @click="createBrokerHandler()" :disabled="!urlName || updateLoading">
        <template v-if="!updateLoading">
          Add
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

    </template>
  </popup>
</template>

<script>
  import organizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewBroker',
    mixins: [organizationsMixin, userMixin],
    components: {
      Popup
    },
    data() {
      return {
        name: null,
        urlName: null,
        preferredName: null,
        type: 4,
        isPaid: false,
        teamStatus: 0,
        isAssetManager: false,
        updateLoading: false
      }
    },
    methods: {
      createBrokerHandler() {
        this.updateLoading = true;
        const dataToSend = {
          name: this.name,
          urlName: this.urlName,
          preferredName: this.preferredName,
          type: this.type,
          isPaid: this.isPaid,
          teamStatus: this.teamStatus,
          isAssetManager: this.isAssetManager
        }

        this.createOrganization(dataToSend)
            .then(() => {
              this.$emit('created');
              this.updateLoading = false;
            })
            .catch(err => {
              if (err === 401) {
                this.logout();
              }
              this.updateLoading = false;
            })
      },
      slugify(text) {
        const charMap = {
          // German characters
          'ä': 'ae', 'ö': 'oe', 'ü': 'ue', 'ß': 'ss',
          // French characters
          'à': 'a', 'â': 'a', 'ç': 'c', 'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e',
          'î': 'i', 'ï': 'i', 'ô': 'o', 'ù': 'u', 'û': 'u', 'ÿ': 'y',
          // Spanish characters
          'á': 'a', 'í': 'i', 'ñ': 'n', 'ó': 'o', 'ú': 'u',
          // Portuguese characters
          'ã': 'a', 'õ': 'o',
          // Italian character
          'ò': 'o',
          // Nordic characters
          'å': 'a', 'ø': 'o',
          // Slavic characters
          'č': 'c', 'ć': 'c', 'đ': 'd', 'š': 's', 'ž': 'z', 'ł': 'l',
          // Romanian characters
          'ă': 'a', 'ș': 's', 'ț': 't',
          // Turkish characters
          'ğ': 'g', 'ı': 'i', 'ş': 's',
        };
        return text.toString().toLowerCase()
            .split('').map(char => charMap[char] || char).join('')
            .replace(/\s+/g, '-')     // Replace spaces with -
            .replace(/[^\w-]+/g, '') // Remove all non-word chars
            .replace(/--+/g, '-')   // Replace multiple - with single -
            .replace(/^-+/, '')       // Trim - from start of text
            .replace(/-+$/, '');      // Trim - from end of text
      },
    },
    watch: {
      // Watch for changes in name and update urlName accordingly
      preferredName(newValue) {
        this.urlName = this.slugify(newValue);
      }
    },
  }
</script>

<style scoped>

</style>