<template>
  <div class="module-item">
    <div class="module-header" @click="toggleOpenModule()">
      <div>
        <font-awesome-icon v-if="isModuleOpened" icon="minus" />
        <font-awesome-icon v-else icon="plus" />
        <h3 class="module-header">{{moduleItem.title.en.title}}</h3>
        <div class="new-item">
          <button class="transition" @click.stop="$emit('new-section', moduleItem.id)">
            <img class="transition" :src="$s3Url + 'svgs/icons/plus.svg'">
            <span>New Chapter</span>
          </button>
        </div>
      </div>
      <div class="actions">
        <font-awesome-icon icon="edit" @click.stop="$emit('edit-module', moduleItem.id)" />
        <a @click.stop="deleteModuleHandler()">
          <font-awesome-icon icon="trash" />
        </a>
      </div>
    </div>
    <div class="module-content" v-if="isModuleOpened">
      <draggable v-model="moduleItemData.sections" group="sections" item-key="id">
        <template #item="{ element }">
          <SectionItem
            :section="element"
            :moduleId="moduleItem.id"
            :courseId="courseId"
            @edit-section="editSectionHandler"
            @new-session="handleNewSession"
            @delete-section="$emit('delete-section', $event)"
            @delete-session="$emit('delete-session', $event)"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Draggable from "vuedraggable";
  import SectionItem from './SectionItem'

  export default {
    name: 'ModuleItem',
    mixins: [knowledgeMixin],
    components: {
      Draggable,
      SectionItem
    },
    props: {
      moduleItem: Object,
      courseId: Number
    },
    data() {
      return {
        moduleOpen: true,
        moduleItemData: this.moduleItem
      }
    },
    computed: {
      isModuleOpened() {
        const openedModules = this.$store.getters['knowledge/getOpenedModules'];
        return openedModules.includes(this.moduleItem.id);
      },
    },
    methods: {
      toggleOpenModule() {
        this.moduleOpen = !this.isModuleOpened;
        const isOpen = this.moduleOpen;
        const moduleId = this.moduleItem.id;
        this.$store.commit('knowledge/updateOpenedModules', { moduleId, isOpen });
      },
      editSectionHandler(sectionData) {
        const data = {
          ...sectionData,
          courseId: this.courseId
        }

        this.$emit('edit-section', data)
      },
      handleNewSession(data) {
        this.$emit('new-session', data)
      },
      deleteModuleHandler() {
        const deleteData = {
          'courseId': this.courseId, 
          'moduleId': this.moduleItem.id
        };

        this.$emit("delete-module", deleteData);
      },
    },
    watch: {
      moduleItemData: {
        handler(newModule) {
          const dataToSend = {
            items: newModule.sections,
            parentId: newModule.id
          }

          this.reorderItems('sections', dataToSend)
            .then()
            .catch(err => {
              console.log(err);
            })
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  .module-item {
    margin-left: 50px;
  }

  .module-header,
  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid rgb(146, 146, 146);
  }

  .section {
    margin-left: 50px;
  }

  .module-header div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .module-header h3,
  .section h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }

  .module-header > svg,
  .section div svg {
    font-size: 20px;
    cursor: pointer;
  }

  .new-item button {
    display: flex;
    align-items: center;
    background: #FFF;
    color: #3c6e71;
    border: 1px solid #3c6e71;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }

  .new-item button:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .new-item button img {
    margin-right: 5px;
    filter: invert(41%) sepia(8%) saturate(2190%) hue-rotate(135deg) brightness(89%) contrast(82%);
  }

  .new-item button:hover img {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(105%) contrast(104%);
  }

  .section div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .module-header {
    color: #28a745;
  }
</style>
