<template>
  <!-- Add new geography popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit geography"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Geography:</label>
        <input type="text" id="name" v-model="item.geography" placeholder="Geography name...">
      </div>

      <div class="field autocomplete characteristics-dropdown">
        <label>UMU Geography:</label>
        <div class="select-container">
          <select v-model="selectedUmuGeography">
            <option disabled value="">Please select one</option>
            <option :value="{id: -1, name: '-'}">-</option>
            <option v-for="umuGeography in umuGeographies" :key="umuGeography.id" :value="umuGeography">{{ umuGeography.name }}</option>
          </select>
        </div>
      </div>

      <button class="transition save-btn" @click="editGeographyHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundUmbrellaGeography',
    mixins: [fundsMixin, userMixin],
    components: {
      Popup
    },
    props: {
      geography: Object
    },
    data() {
      return {
        item: {...this.geography},
        updateLoading: false,
        umuGeographies: [],
        selectedUmuGeography: {
          id: null,
          name: null,
        },

      }
    },
    methods: {
      // * Triggers on save button click and creates geography
      editGeographyHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          geography: this.item.geography,
          umuGeographyId: this.selectedUmuGeography.id,
        }

        this.editUmbrellaGeography(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },

      fetchUmuGeographies(){
        this.getUmuGeographies()
            .then(res => {
              this.umuGeographies = res.umuGeographies.map(item => ({
                id: item.id,
                name: item.translationData?.en?.name || null
              }));
            })
            .catch(error => {
              console.error("Error fetching umuGeographies:", error);
            })
      },


    },

    created() {
      this.fetchUmuGeographies();
      this.selectedUmuGeography.id = this.item.umuGeographyId;
      this.selectedUmuGeography.name = this.item.umuGeography;
    },
  }
</script>

<style scoped>
.umbrella-description,
.selected-benchmarks li {
  font-size: 14px;
}

.characteristics-dropdown {
  display: flex;
  align-items: center;
}

.characteristics-dropdown label {
  font-size: 18px;
  margin-right: 10px;
}

.characteristics-dropdown select {
  width: 210px;
  border: none;
  border-radius: 20px;
  padding: 9px 15px;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 0.75rem center/8px 10px;
  background-color: white;
}

.characteristics-dropdown .filter-btn {
  background: #3c6e71;
  color: #FFF;
  border: none;
  font-weight: 600;
  height: 45px;
  width: 100px;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.characteristics-dropdown .filter-btn:hover {
  background: #FFF;
  color: #3c6e71;
}

.characteristics-dropdown {
  display: flex;
  align-items: center;
}

.characteristics-dropdown label {
  width: 150px; /* Adjust the width of the labels as needed */
}

.characteristics-dropdown .select-container {
  margin-left: 10px; /* Adjust the left margin as needed */
}

</style>
