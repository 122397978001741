<template>
  <!-- Exchanges Page -->
  <div class="exchanges">
    <h1>Exchanges <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :actions="['edit']"
      :updateResults="updateResults"
      @update-table="getExchangesHandler"
      @edit="editHandler" 
    />

    <!-- Edit Popup -->
    <EditExchange
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import equitiesMixin from '@/mixins/equities'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditExchange from './popups/EditExchange'

  export default {
    name: 'Exchanges',
    mixins: [equitiesMixin, userMixin],
    components: {
      DataTableHolder,
      EditExchange
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Code',
            name: 'code',
            sortable: true
          },
          {
            title: 'Enabled',
            name: 'enabled',
            sortable: true
          },
          {
            title: 'Location',
            name: 'location',
            sortable: true
          },
          {
            title: 'Timezone',
            name: 'timezone',
            sortable: true
          },
          {
            title: 'Six Reference',
            name: 'sixReference',
            sortable: true
          },
          {
            title: 'Start Time',
            name: 'startTime',
            sortable: true
          },
          {
            title: 'End Time',
            name: 'endTime',
            sortable: true
          },
          {
            title: 'Threshold',
            name: 'threshold',
            sortable: true
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        total: null,
        fullData: null
      }
    },
    methods: {
      // * Get exchanges from API
      getExchangesHandler(data) {
        this.getExchanges(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let exchangesFormatted = [];
            // * Loop through exchanges and add only the needed properties
            for (const exchange of res.stockExchanges) {
              let exchangeObj = {
                id: exchange.id,
                name: exchange.name,
                code: exchange.code,
                enabled: exchange.enabled,
                location: exchange.location,
                timezone: exchange.timezone,
                sixReference: exchange.sixReference,
                startTime: exchange.startTime,
                endTime: exchange.endTime,
                threshold: exchange.threshold,
              }
              // * Add formatted exchange to formatted exchanges array
              exchangesFormatted.push(exchangeObj);
            }
            // * Assign table rows to be the formatted exchanges array
            this.rows = exchangesFormatted;
            this.fullData = res.stockExchanges;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(exchange => {
          return exchange.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>

</style>