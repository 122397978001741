<template>
  <!-- Glossary Page -->
  <div class="glossary-page">
    <h1>Glossary <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :actions="['edit', 'new', 'delete']"
      :updateResults="updateResults"
      :langs="true"
      @update-table="getGlossariesHandler"
      @add-new="newGlossaryPopup = true"
      @edit="editGlossaryHandler"
      @delete="deleteHandler"
    />

    <NewGlossary
      v-if="newGlossaryPopup"
      :glossaryCategories="glossaryCategories"
      @close="newGlossaryPopup = false"
      @added="addedHandler"
    />

    <EditGlossary
      v-if="editItem"
      :item="editItem"
      :glossaryCategories="glossaryCategories"
      @close="editItem = null"
      @updated="updatedHandler"
    />

    <DeleteGlossary
      v-if="deleteItem"
      :item="deleteItem"
      @deleted="deletedHandler"
      @close="deleteItem = null"
    />
  </div>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import NewGlossary from './popups/NewGlossary'
  import EditGlossary from './popups/EditGlossary'
  import DeleteGlossary from './popups/DeleteGlossary'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'Glossary',
    mixins: [systemMixin, userMixin],
    components: {
      NewGlossary,
      EditGlossary,
      DeleteGlossary,
      DataTableHolder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Category ID',
            name: 'categoryId',
            sortable: true
          },
          {
            title: 'Display',
            name: 'display',
            sortable: false
          },
          {
            title: 'Glossary Entry',
            name: 'glossaryEntry',
            sortable: false
          },
          {
            title: 'Glossary Entry Answer',
            name: 'glossaryEntryAnswer',
            sortable: false
          }
        ],
        newGlossaryPopup: false,
        editItem: null,
        deleteItem: null,
        updateResults: false,
        glossaryCategories: null,
        total: null
      }
    },
    methods: {
      // * Get glossaries from API
      getGlossariesHandler(data) {
        if (data.sortBy.column == 'categoryId') {
          if (data.sortBy.order == 'ASC') {
            this.rows = this.rows.sort((a, b) => {
              return a.categoryId - b.categoryId;
            });
          } else {
            this.rows = this.rows.sort((a, b) => {
              return b.categoryId - a.categoryId;
            });
          }
        } else {
          this.getGlossaries(data)
            .then(res => {
              this.rows = res;
              this.total = res.length;
            })
            .catch(err => {
              if (err == 401) {
                this.logout();
              }
            })
        }
      },
      // * Open edit popup with the item to edit
      editGlossaryHandler(item) {
        this.editItem = item
      },
      // * Triggered when Glossary is added
      addedHandler() {
        // * Closes popup
        this.newGlossaryPopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when Glossary is updated
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      deleteHandler(glossary) {
        this.deleteItem = glossary;
      },
      deletedHandler() {
        this.deleteItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      // * Get all Glossary categories
      this.getGlossaryCategories()
        .then(res => {
          this.glossaryCategories = res;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>


</style>