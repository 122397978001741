<template>
  <!-- Fund Umbrella Styles Page -->
  <div class="fund-umbrella-styles-page">
    <h1>Fund Umbrella Styles</h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :updateResults="updateResults"
      :actions="['edit', 'new']"
      @update-table="getFundUmbrellaStylesHandler"
      @add-new="addNewHandler"
      @edit="editHandler"
    />

    <NewFundUmbrellaStyle
      v-if="addStylePopup"
      @created="styleCreatedHandler"
      @close="addStylePopup = false"
    />

    <EditFundUmbrellaStyle
      v-if="editItem"
      :styleItem="editItem"
      @updated="styleUpdatedHandler"
      @close="editItem = null"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewFundUmbrellaStyle from './popups/NewFundUmbrellaStyle'
  import EditFundUmbrellaStyle from './popups/EditFundUmbrellaStyle'

  export default {
    name: 'FundUmbrellaStyles',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      NewFundUmbrellaStyle,
      EditFundUmbrellaStyle
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Style',
            name: 'style',
            sortable: false
          },
          {
            title: 'Other',
            name: 'other',
            sortable: false
          },
          {
            title: 'Equity',
            name: 'equity',
            sortable: false
          },
          {
            title: 'Bond',
            name: 'bond',
            sortable: false
          },
          {
            title: 'Multi-Asset',
            name: 'multiAsset',
            sortable: false
          },
          {
            title: 'Money-Market',
            name: 'moneyMarket',
            sortable: false
          },
          {
            title: 'Commodity',
            name: 'commodity',
            sortable: false
          },
          {
            title: 'Crypto',
            name: 'crypto',
            sortable: false
          },
          {
            title: 'Property',
            name: 'property',
            sortable: false
          },
        ],
        editItem: null,
        updateResults: true,
        addStylePopup: false
      }
    },
    methods: {
      // * Get fund umbrella sectors from API
      getFundUmbrellaStylesHandler(data) {
        this.getAllFundUmbrellaStyles(data)
          .then(res => {
            // * After receiving response update data
            this.rows = res.styles;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Add new sector handler. Triggered when user click the "+ New" button
      addNewHandler() {
        this.addStylePopup = true;
      },
      styleCreatedHandler() {
        this.addStylePopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      styleUpdatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      
    }
  }
</script>

<style scoped>

</style>