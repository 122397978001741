<script>
import axios from 'axios';

export default {
  methods: {
    getDomiciles() {
      return new Promise((resolve, reject) => {
        axios.get(`${this.$store.state.global.apiUrl}/domiciles`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data.code);
          });
      });
    },

    getDomicilesWithSuitabilities() {
      return new Promise((resolve, reject) => {
        axios.get(`${this.$store.state.global.apiUrl}/suitability-profiles`)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data.code);
            });
      });
    },
  }
};
</script>
