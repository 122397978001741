<template>
  <div class="user-notifications">
    <div v-if="isDataLoading" class="loading-overlay">
      <div class="loading-indicator">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <div class="filters-container" ref="filtersContainer">
      <div class="dropdowns-wrapper">
        <div class="dropdown-container" v-for="(dropdown, index) in filters.dropdowns" :key="index">
          <div @mouseover="activeFilterIndex = dropdown.tableName" @mouseout="activeFilterIndex = -1">
            <multiselect
              v-model="dropdown.selectedOptions"
              :options="dropdown.dropdownOptions"
              :label="dropdown.label"
              :placeholder="dropdown.placeholder"
              :multiple="dropdown.multiselect"
              track-by="id"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Added On Filter:</label>
        <div class="date-filter" @mouseover="activeFilterIndex = 'addedOn'" @mouseout="activeFilterIndex = -1">
          <Datepicker v-model="addedOnFilter" range multi-calendars :enable-time-picker="true" format="yyyy-MM-dd HH:mm:ss"/>
        </div>
      </div>
    </div>

    <h1>Users Notifications <small v-if="total != null">({{total}})</small></h1>
    <DataTableHolder
      v-if="notifications"
      :columns="columns"
      :rows="notifications"
      :totalPages="pages"
      :actions="[]"
      @update-table="getUserNotificationsHandler"
    />
  </div>
</template>

<script>
import userMixin from "@/mixins/user";
import usersMixin from "@/mixins/users";
import notificationsMixin from "@/mixins/notification";
import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
import Multiselect from "vue-multiselect";

export default {
  name: "UsersNotifications",
  mixins: [userMixin, usersMixin, notificationsMixin],
  components: {
    DataTableHolder,
    Multiselect
  },
  data() {
    return {
      notifications: [],
      columns: [
        {
          title: "User",
          name: "user",
          sortable: false,
        },
        {
          title: "UUID",
          name: "uuid",
          sortable: false,
        },
        {
          title: "Type",
          name: "notificationType",
          sortable: false,
        },
        {
          title: "Status",
          name: "status",
          sortable: false,
        },
        {
          title: "Added On",
          name: "addedOn",
          sortable: false,
        },
        {
          title: "Notification Type",
          name: "notificationTypeName",
          sortable: false,
        },
        {
          title: "Is Push",
          name: "isPushNotification",
          sortable: false,
        },
      ],
      isDataLoading: false,
      pages: 0,
      total: null,
      filters: {
        dropdowns: {
          notificationType: {
            selectedOptions: [],
            dropdownOptions: [],
            label: 'name', // need to point to dropdownOptions
            placeholder: "Select Notification Type",
            multiselect: false,
            tableName: 'notificationTypeName'
          },
          status: {
            selectedOptions: [],
            dropdownOptions: [
              { id: 1, name: "Read" },
              { id: 0, name: "Pending" },
            ],
            label: 'name', // need to point to dropdownOptions
            placeholder: "Select Status",
            multiselect: false,
            tableName: 'status'
          },
        },
      },
      addedOnFilter: null,
      activeFilterIndex: -1,
      payLoadData: {},
      filtersToSend: null,
    };
  },
  methods: {
    filterChangeHandler() {
      const fromDate = this.addedOnFilter ? this.addedOnFilter[0]?.toISOString().split('.')[0] : null;
      const toDate = this.addedOnFilter ? this.addedOnFilter[1]?.toISOString().split('.')[0] : null;

      const filters = {
        notificationType: this.filters.dropdowns.notificationType.selectedOptions?.name ?? null,
        status: this.filters.dropdowns.status.selectedOptions?.id ?? null,
        addedOnRange: {
          from: fromDate,
          to: toDate
        }
      };
      
      this.filtersToSend = filters;
      this.getUserNotificationsHandler();
    },
    getUserNotificationsHandler(data) {
      this.isDataLoading = true;
      if (data) {
        this.payLoadData = data;
      }

      this.payLoadData = {... this.payLoadData, filter: this.filtersToSend};
      this.getUsersNotifications(this.payLoadData)
        .then((res) => {
          this.notifications = [];
          this.pages = res.pagesCount;
          this.total = res.resultsCount;
          const notifications = res.notifications;
          for (const notification of notifications) {
            let row = {
              user: notification.user,
              uuid: notification.uuid,
              notificationType: this.determineNotificationType(notification),
              status: notification.status == 1 ? 'Read' : 'Pending',
              addedOn: this.$filters.formatDateTime(notification.addedOn, 'custom'),
              notificationTypeName: notification.notificationTypeName,
              isPushNotification: notification.isPushNotification,
            };
            this.notifications.push(row);
          }
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    fetchNotificationsTypes() {
      this.getNotificationsTypes().then((res) => {
        const notificationTypes = res.notificationTypes;
        this.filters.dropdowns.notificationType.dropdownOptions = notificationTypes;
      })
      .finally(() => {
        this.isDataLoading = false;
      });
    },
    determineNotificationType(type) {
      if (type.isEmail) return "Email";
      if (type.isWebHeader) return "Web Header";
      if (type.isWebMenu) return "Web Menu";
      return "Unknown";
    },
  },
  created() {
    this.fetchNotificationsTypes();
  },
  updated() {
    // Find the target element with class "table-actions" (Coming from DataTableHolder -> DataTable)
    const tableActionsElement = document.querySelector('.datatable-container .table-actions');
    const filtersContainer = this.$refs.filtersContainer;

    // Move the filters container after the tableActionsElement
    if (tableActionsElement && filtersContainer && !this.filtersDisplayed) {
      tableActionsElement.parentNode.insertBefore(filtersContainer, tableActionsElement.nextSibling);
      this.filtersDisplayed = true;
    }
  },
  watch: {
    activeFilterIndex(newIndex) {
      this.columns.find((column) => {
        if(newIndex !== -1) {
          let columnName = column.name.toLowerCase();
          let hoveredFilter = newIndex.toLowerCase();
          if(columnName == hoveredFilter) {
            column['customClass'] = 'highlighted';
          } else {
            column['customClass'] = '';
          }
        } else {
          column['customClass'] = '';
        }
      });
    },
    'filters.dropdowns.notificationType.selectedOptions': 'filterChangeHandler',
    'filters.dropdowns.status.selectedOptions': 'filterChangeHandler',
    addedOnFilter: 'filterChangeHandler',
  },
};
</script>

<style>
.filters-container {
  width: 30%;
  background-color: white;
  padding: 1.5% 1%;
  margin: 0px 0px 1.5% 0px;
}

.checkboxes-wrapper {
  border-bottom: 1px solid gray;
}

.checkboxes-wrapper, .dropdowns-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.dropdowns-wrapper {
  justify-content: space-between;
}

.checkbox-container, .dropdown-container {
  margin-right: 20px;
  margin-bottom: 10px;
}

.dropdown-container .multiselect {
  max-width: 100%;
  min-width: 300px;
  cursor: pointer;
}

.date-filter {
  max-width: 400px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
</style>
