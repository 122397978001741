<template>
  <div  class="equity-risk-scores-page">
    <h1>Active Risk Scores <small v-if="total">({{total}})</small></h1>

    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :total-pages="pages"
        :actions="[]"
        @update-table="getEquitiesHandler"
    />
  </div>
</template>

<script>
import securitiesMixin from '@/mixins/securities'
import userMixin from '@/mixins/user'

import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";

export default {
  name: 'ActiveEquitiesRiskScores',
  mixins: [securitiesMixin, userMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable : true
        },
        {
          title: 'Name',
          name: 'name',
          sortable: true
        },
        {
          title: 'Valor',
          name: 'valor',
          sortable: true
        },
        {
          title: 'ISIN',
          name: 'isin',
          sortable: true
        },
        {
          title: 'Vol 1Y',
          name: 'volatility_1y',
          sortable: true
        },
        {
          title: 'SIX Vol 1Y',
          name: 'six_250_day_vol',
          sortable: true
        },
        {
          title: 'Diff 1Y',
          name: 'vol_diff_1y',
          sortable: true
        },
        {
          title: 'Vol 3Y',
          name: 'volatility_3y',
          sortable: true
        },
        {
          title: 'SIX Vol 3Y',
          name: 'six_3_year_vol',
          sortable: true
        },
        {
          title: 'Diff 3Y',
          name: 'vol_diff_3y',
          sortable: true
        },
        {
          title: 'Vol 5Y',
          name: 'volatility_5y',
          sortable: true
        },
        {
          title: 'SIX Vol 5Y',
          name: 'six_5_year_vol',
          sortable: true
        },
        {
          title: 'Diff 5Y',
          name: 'vol_diff_5y',
          sortable: true
        },
        {
          title: 'Risk Score',
          name: 'risk_score',
          sortable: true
        },
      ],
      pages: 0,
      fullData: null,
      total: null
    };
  },
  methods: {
    getEquitiesHandler(data) {
      let foundIsActiveFilter = data.filter.filter(function(item){
        return item.name == 'isActive';
      });
      if (foundIsActiveFilter.length == 0) {
        data.filter.push({
          name: 'isActive',
          term: true
        });
      }

      this.getSecurities(data, 1)
          .then(res => {

            this.pages = res.pagesCount;
            let equitiesFormatted = [];
            for (const equity of res.securities) {

              let Equity = {
                id: equity.id,
                name: equity.name,
                valor: equity.valor,
                isin: equity.isin,

                volatility_1y: this.formatDecimals(equity.volatility_data.volatility_1y),
                six_250_day_vol: this.formatDecimals(equity.volatility_data.six_250_day_vol),
                vol_diff_1y: this.formatDecimals(equity.vol_diff_1y),

                volatility_3y: this.formatDecimals(equity.volatility_data.volatility_3y),
                six_3_year_vol: this.formatDecimals(equity.volatility_data.six_3_year_vol),
                vol_diff_3y: this.formatDecimals(equity.vol_diff_3y),

                volatility_5y: this.formatDecimals(equity.volatility_data.volatility_5y),
                six_5_year_vol: this.formatDecimals(equity.volatility_data.six_5_year_vol),
                vol_diff_5y: this.formatDecimals(equity.vol_diff_5y),

                risk_score: equity.risk_score,
              };
              equitiesFormatted.push(Equity);
            }
            this.rows = equitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
    },
    formatDecimals(value){
      return Math.round(value * 100) / 100;
    }
  }
}

</script>

<style scoped>

</style>