<template>
  <!-- Fund Advisors Page -->
  <div class="fund-umbrella-fund-categories-page">
    <h1>Umbrella Fund Categories <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getFundUmbrellaFundCategoriesHandler"
      @edit="editHandler" 
    />

<!-- Edit Popup -->
    <EditFundUmbrellaFundCategory
      v-if="editItem"
      :item="editItem"
      :fundCategories="fundCategories"
      @close="editItem = null"
      @updated="updatedHandler"
    />
    
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundUmbrellaFundCategory from './popups/EditFundUmbrellaFundCategory'

  export default {
    name: 'FundUmbrellaFundCategories',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundUmbrellaFundCategory
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Umbrella Name',
            name: 'UmbrellaName',
            sortable: true
          },
          {
            title: 'EFC Category (from Feed)',
            name: 'mainEFCCategory',
            sortable: true
          },
          {
            title: 'Fund Category',
            name: 'fundCategory',
            sortable: true
          },
          {
            title: 'Fund Category Name',
            name: 'fundCategoryName',
            sortable: false
          },
          {
            title: 'Added On',
            name: 'AddedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'UpdatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        fundCategories: null,
        updateResults: true,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get fund advisors from API
      getFundUmbrellaFundCategoriesHandler(data) {
        this.getFundUmbrellasPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let umbrellasFormatted = [];
            // * Loop through groups and add only the needed properties
            for (const umbrella of res.fundUmbrellas) {
              let umbrellaObj = {
                id: umbrella.id,
                UmbrellaName: umbrella.UmbrellaName,
                mainEFCCategory: umbrella.mainEFCCategory,
                fundCategory: umbrella.fundCategory,
                fundCategoryName: umbrella.fundCategoryName,
                AddedOn: umbrella.AddedOn,
                UpdatedOn: umbrella.UpdatedOn
              }
              // * Add formatted group to formatted groups array
              umbrellasFormatted.push(umbrellaObj);
            }
            // * Assign table rows to be the formatted groups array
            this.rows = umbrellasFormatted;
            this.fullData = res.fundUmbrellas;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(umbrella => {
          return umbrella.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      // * On component (page) load put the Fund Categories in a list for the autocomplete field in the edit popup
      this.fundCategories = [
          {
              fundCategory: "0",
              fundCategoryName: "Other"
          },
          {
              fundCategory: "1",
              fundCategoryName: "Equity"
          },
          {
              fundCategory: "2",
              fundCategoryName: "Bond"
          },
          {
              fundCategory: "3",
              fundCategoryName: "Multi-Asset"
          },
          {
              fundCategory: "4",
              fundCategoryName: "Money Market"
          },
          {
              fundCategory: "5",
              fundCategoryName: "Commodity"
          }
      ];
    }
  }
</script>

<style scoped>


</style>