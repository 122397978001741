<script>
import axios from "axios";

if (localStorage.getItem("x-auth-token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("x-auth-token");
}

export default {
  methods: {
    getNotificationsTypes() {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/notifications/types`, {
          method: "GET",
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data.code);
          });
      });
    },
    getUsersNotifications(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/notifications`, {
          method: "POST",
          data: data
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data.code);
          });
      });
    },
    updateNotificationType(notificationTypeId, data) {
      return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/notification-type/${notificationTypeId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
    },
    sendNotification(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/send-notification`, {
          method: "POST",
          data: data,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    }
  },
};
</script>
