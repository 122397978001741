<script>
  import axios from 'axios'
  

  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      returnMonthName(number) {
        switch (number) {
          case 0:
            return 'Jan';
          case 1:
            return 'Feb';
          case 2:
            return 'Mar';
          case 3:
            return 'Apr';
          case 4:
            return 'May';
          case 5:
            return 'Jun';
          case 6:
            return 'Jul';
          case 7:
            return 'Aug';
          case 8:
            return 'Sep';
          case 9:
            return 'Oct';
          case 10:
            return 'Nov';
          case 11:
            return 'Dec';
        }
      },
      formatDateDashes(date) {
        const dateObj = new Date(date);
        return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
      },
      formatDateMonth(date) {
        return `${date.getDate()} ${this.returnMonthName(date.getMonth())}`;
      },
      getDateBeforeDays(daysBefore) {
        let now = new Date();

        return new Date(now.getTime() - (daysBefore * 24 * 60 * 60 * 1000))
      }
    }
  }
</script>
