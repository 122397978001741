<template>
  <popup
    title="Add new instrument right (new)"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>User:</label>
        <vue3-simple-typeahead
          placeholder="Type user email..."
          :items="users"
          :minInputLength="1"
          :itemProjection="(item) => item.email"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
      </div>

      <div class="field">
        <label>Instrument Type:</label>
        <div class="custom-select" :class="{open: instrumentTypeSelect}" @click="instrumentTypeSelect = !instrumentTypeSelect" v-click-away="instrumentTypeAway">
          <span v-if="selectedInstrumentType">{{selectedInstrumentType}}</span>
          <span v-else>Select Instrument Type</span>
          <div class="select-dropdown" v-if="instrumentTypeSelect">
            <a v-for="(type, index) in instrumentTypes" :key="index" @click="selectInstrumentType(type)">
              {{type}}
            </a>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="instrument-id">Instrument ID:</label>
        <input type="text" id="instrument-id" v-model="instrumentId" placeholder="Instrument ID">
      </div>

      <div class="field">
        <label>Role Type:</label>
        <div class="custom-select" :class="{open: roleTypeSelect}" @click="roleTypeSelect = !roleTypeSelect" v-click-away="roleTypeAway">
          <span v-if="selectedRoleType">{{selectedRoleType.name}}</span>
          <span v-else>Select Role Type</span>
          <div class="select-dropdown" v-if="roleTypeSelect">
            <!--            Add the following filter in <a>(here)</a> before @click= if you want a specific role displayed for a specific type: -->
            <!--                v-if="-->
            <!--                selectedInstrumentType == 'Wealth Manager' ||-->
            <!--                selectedInstrumentType == 'Asset Manager' ||-->
            <!--                selectedInstrumentType == 'Author'  ||-->
            <!--                selectedInstrumentType == 'Community Group'-->
            <!--              "-->
            <a
              @click="selectRoleType('Admin', 1)"
            >
              Admin
            </a>
            <a
              @click="selectRoleType('Editor', 2)"
            >
              Editor
            </a>
            <a
              @click="selectRoleType('Portfolio Manager', 3)"
            >
              Portfolio Manager
            </a>
            <a
              @click="selectRoleType('Employee', 4)"
            >
              Employee
            </a>
            <a
              @click="selectRoleType('Client', 5)"
            >
              Client
            </a>
            <a
              @click="selectRoleType('Member', 6)"
            >
              Member
            </a>
            <a
                @click="selectRoleType('Portfolio Follower', 7)"
            >
              Portfolio Follower
            </a>
            <a
              @click="selectRoleType('Prospect', 9)"
            >
              Prospect
            </a>
          </div>
        </div>
      </div>
      
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isVisible">
          <span class="checkmark"></span>
          Is Visible
        </label>
      </div>

      <button class="transition save-btn" @click="createRightHandler()" :disabled="updateLoading || !selectedUser || !selectedInstrumentType || !instrumentId || !selectedRoleType">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewInstrumentRight',
    mixins: [usersMixin, userMixin],
    components: {
      Popup
    },
    props: {
      users: Array
    },
    data() {
      return {
        instrumentTypeSelect: false,
        selectedInstrumentType: null,
        instrumentTypes: [
          'Investment Fund',
          'Equity',
          'Community Group',
          'Wealth Manager',
          'Asset Manager',
          'Portfolio',
          'Broker',
          'Contest',
          'Author',
          'Collection',
        ],
        instrumentId: null,
        roleTypeSelect: false,
        selectedRoleType: null,
        isVisible: false,
        updateLoading: false,
        selectedUser: null
      }
    },
    methods: {
      createRightHandler() {
        this.updateLoading = true;
        const dataToSend = {
          isVisible: this.isVisible,
          role: this.selectedRoleType.id
        }

        if (this.selectedInstrumentType == 'Investment Fund') {
          dataToSend.investmentFundId = this.instrumentId;
        } else if (this.selectedInstrumentType == 'Equity') {
          dataToSend.securityId = this.instrumentId;
        } else if (this.selectedInstrumentType == 'Asset Manager') {
          dataToSend.organizationId = this.instrumentId;
          dataToSend.organizationTypeIds = [0,1]; // TYPE_FUND_GROUP = 0; TYPE_FUND_ADVISOR = 1;
        } else if (this.selectedInstrumentType == 'Wealth Manager') {
          dataToSend.organizationId = this.instrumentId;
          dataToSend.organizationTypeIds = [2]; // TYPE_WEALTH_MANAGER = 2;
        } else if (this.selectedInstrumentType == 'Community Group') {
          dataToSend.organizationId = this.instrumentId;
          dataToSend.organizationTypeIds = [3]; // TYPE_COMMUNITY_GROUP = 3;
        } else if (this.selectedInstrumentType == 'Broker') {
          dataToSend.organizationId = this.instrumentId;
          dataToSend.organizationTypeIds = [4]; // TYPE_BROKER = 4;
        } else if (this.selectedInstrumentType == 'Contest') {
          dataToSend.organizationId = this.instrumentId;
          dataToSend.organizationTypeIds = [5]; // TYPE_CONTEST = 5;
        } else if (this.selectedInstrumentType == 'Portfolio') {
          dataToSend.portfolioId = this.instrumentId;
        } else if (this.selectedInstrumentType == 'Author') {
          dataToSend.authorId = this.instrumentId;
        } else if (this.selectedInstrumentType == 'Collection') {
          dataToSend.organizationId = this.instrumentId;
          dataToSend.organizationTypeIds = [6]; // TYPE_COLLECTION = 6;
        }


        this.addInstrumentRight(this.selectedUser.id, dataToSend)
          .then(() => {
            this.updateLoading = false;
            this.$emit('created');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err == 401) {
              this.logout();
            }
          })
      },
      instrumentTypeAway() {
        this.instrumentTypeSelect = false;
      },
      roleTypeAway() {
        this.roleTypeSelect = false;
      },
      selectInstrumentType(type) {
        this.selectedInstrumentType = type;
      },
      selectRoleType(name, id) {
        this.selectedRoleType = {
          name,
          id
        }
      },
      // * Triggered when user selects user from the autocomplete. Updates the selected user
      submit(result) {
        this.selectedUser = result;
      },
      // * Nulls user if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedUser = null;
        }
      }
    }
  }
</script>

<style scoped>
  
</style>
