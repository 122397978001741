<template>
  <div v-if="user">
    <div class="left-part">
      <h1 class="account-settings-header">Account Settings</h1>
    </div>
    <div class="account-settings">
      <div class="field">
        <label class="custom-check green two-factor-auth">
          <input type="checkbox" v-model="twoFactorEnabled" />
          <span class="checkmark"></span>
          Enable Two-Factor Authentication
        </label>
      </div>
      <div class="field save-btn">
        <button
          :disabled="saveLoading"
          class="green-btn save-btn transition"
          @click="updateAccountSettingsHandler"
        >
          <template v-if="!saveLoading"> Save </template>
          <font-awesome-icon v-else icon="spinner" spin />
        </button>
      </div>
      <div class="success-message" v-if="successMessage">
        <font-awesome-icon icon="check" />
        <span>Settings saved successfully</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import userMixin from "@/mixins/user";
import usersMixin from "@/mixins/users";

export default {
  name: "AccountSettings",
  mixins: [userMixin, usersMixin],
  data() {
    return {
      user: null,
      twoFactorEnabled: false,
      saveLoading: false,
      successMessage: false,
    };
  },
  methods: {
    updateAccountSettingsHandler() {
      this.saveLoading = true;

      const dataToSend = {
        enable2FA: this.twoFactorEnabled,
      };

      this.editUser(this.user.id, dataToSend)
        .then((res) => {
          this.saveLoading = false;
          if (res && res.code == 200) {
            this.successMessage = true;
            setTimeout(() => {
              this.successMessage = false;
            }, 5000);

            this.getUserDataHandler();
          }
        })
        .catch((err) => {
          this.saveLoading = false;
          if (err == 401) {
            this.logout();
          }
        });
    },
    getUserDataHandler() {
      // Fetch the user's two-factor authentication status and update the checkbox
      this.getUserData()
        .then((res) => {
          this.user = res.user;
          this.twoFactorEnabled = res.user.is2FAEnabled;
        })
        .catch(() => {
          this.logout();
        });
    },
  },
  created() {
    this.getUserDataHandler();
  },
};
</script>
  
<style scoped>
.left-part {
  display: flex;
  align-items: center;
}

.account-settings-header {
  color: #171725;
  font-size: 40px;
  margin: 0;
  margin-right: 75px;
}

.account-settings {
  margin: 10px 0px;
}

.save-btn button {
  height: 50px;
  width: 170px;
  flex: 0 0 170px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1%;
}

.two-factor-auth {
  font-weight: 600;
}

.success-message {
  max-width: 350px;
}
</style>
  