<template>
  <div class="commands-chart-view">
    <h1>Commands Chart View</h1>
    <div class="filters">
      <span>Number of days:</span>
      <a v-for="n in 7" @click="filterDays(n)" :key="n">
        {{n}}
      </a>
    </div>
    <br>
    <br>
    <template v-if="myChartStart && myChartEnd">
      <g-gantt-chart
        :chart-start="myChartStart"
        :chart-end="myChartEnd"
        bar-start="myBeginDate"
        bar-end="myEndDate"
        :row-label-width="`15%`"
        font="Poppins"
      >
        <g-gantt-row
          v-for="row in rows"
          :key="row.label"
          :label="row.label"
          :bars="row.bars"
          bar-start="myStart"
          bar-end="myEnd"
        >
          <!-- <template #bar-label="{bar}">
            <span class="bar-label">{{bar.label}}</span>
          </template> -->
        </g-gantt-row>
      </g-gantt-chart>
    </template>
  </div>
</template>

<script>
  import systemMixin from '@/mixins/system'

  export default {
    name: 'CommandsChartView',
    mixins: [systemMixin],
    data(){
      return {
        myChartStart: null,
        myChartEnd: null,
        rows: null
      }
    },
    methods: {
      filterDays(days) {
        this.myChartStart = null;
        this.myChartEnd =  null;
        this.rows = null;

        this.getCommandsChartView(days && days)
          .then(res => {
            this.rows = res.chartData.rows;

            let counter = 1;
            for (const row of this.rows) {
              for (const bar of row.bars) {
                bar.ganttBarConfig.id = counter;
                bar.ganttBarConfig.label = bar.label;
                bar.myBeginDate = bar.myStart.slice(0, bar.myStart.length - 3);
                bar.myEndDate = bar.myEnd.slice(0, bar.myEnd.length - 3);
                bar.ganttBarConfig.style = {
                  background: bar.ganttBarConfig.background,
                  borderRadius: bar.ganttBarConfig.borderRadius
                }
                delete bar.myStart;
                delete bar.myEnd;
                delete bar.label;
                delete bar.ganttBarConfig.background;
                delete bar.ganttBarConfig.borderRadius;
                counter++;
              }
            }

            this.myChartStart = res.chartData.myChartStart.slice(0, res.chartData.myChartStart.length - 3);
            this.myChartEnd = res.chartData.myChartEnd.slice(0, res.chartData.myChartEnd.length - 3);
          }) 
      }
    },
    mounted() {
      this.filterDays();
    }
  }
</script>

<style scoped>
  .filters {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .filters span {
    font-size: 20px;
  }

  .filters a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .filters a:hover {
    background: #3c6e71;
    color: #FFF;
  }
</style>

<style>
  .g-gantt-tooltip::before {
    display: none !important;
  }
  /* .g-timeaxis-day {
    font-size: 14px !important;
  }

  .g-gantt-row {
    height: auto !important;
    min-height: 40px !important;
  }

  .g-gantt-row-label {
    justify-content: flex-start !important;
    padding: 10px !important;
    border-top: 1px solid rgb(207, 207, 207);
    border-bottom: 1px solid rgb(207, 207, 207);
    word-break: break-all;
  }

  #g-gantt-chart,
  .g-gantt-bar {
    overflow: visible !important;
  }

  .g-gantt-bar {
    min-height: calc(100% - 4px) !important;
  }

  .bar-label {
    display: none;
    position: absolute;
    bottom: 120%;
    color: #000;
    left: 0px;
    background: #FFF;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    z-index: 20;
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
  }

  .g-gantt-bar:hover .bar-label {
    display: block;
  } */
</style>
