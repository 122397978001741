<template>
  <div class="quiz-summary">
    <div v-if="isDataLoading" class="loading-overlay">
      <div class="loading-indicator">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <div v-if="quizzes && quizzes.length > 0" class="quiz-names-holder">
      <h1>Quizzes</h1>
      <div v-for="(quiz, index) in quizzes" :key="index">
        <button class="quiz-names" :class="{ selected: selectedQuiz.id === quiz.id }" @click="selectQuiz(quiz)">
          <span v-html="quiz.quizName['en']['name']"></span>
        </button>
      </div>
    </div>
    
    <template v-if="selectedQuiz?.id">
      <div class="top-section">
        <div class="submissions-count">
          <h1 v-if="quizSubmissionsCount?.totalCount != null">Total Submissions: <small>({{ quizSubmissionsCount.totalCount }})</small></h1>
          <h3 v-if="quizSubmissionsCount?.completedCount != null">Completed Submissions: <small>({{ quizSubmissionsCount.completedCount }})</small></h3>
          <h3 v-if="quizSubmissionsCount?.nonCompletedCount != null">Non-Completed Submissions: <small>({{ quizSubmissionsCount.nonCompletedCount }})</small></h3>
        </div>
  
        <div class="filters-holder">
          <div class="field">
            <label class="filter-label" for="user-filter">Participants:</label>
            <select class="filter-select" v-model="filters.usersFilter" @change="filterChangeHandler">
              <option value="null">All</option>
              <option value="true">Users</option>
              <option value="false">Guests</option>
            </select>
          </div>
          <div v-if="filters.usersFilter === true || filters.usersFilter == 'true'" class="field">
            <label class="filter-label" for="age-group-filter">Age Group:</label>
            <select class="filter-select" v-model="filters.ageGroupFilter" @change="filterChangeHandler">
              <option value="null">All</option>
              <option value="{&quot;minAge&quot;: 0, &quot;maxAge&quot;: 17}">Under 18</option>
              <option value="{&quot;minAge&quot;: 18, &quot;maxAge&quot;: 30}">18 to 30</option>
              <option value="{&quot;minAge&quot;: 31, &quot;maxAge&quot;: 50}">31 to 50</option>
              <option value="{&quot;minAge&quot;: 51, &quot;maxAge&quot;: null}">Over 50</option>
            </select>
          </div>
        </div>
      </div>

      <h1 v-if="nationalityData || ageGroupData">Demographic Data</h1>
      <div class="flex demographic-data" v-if="nationalityData || ageGroupData">
          <div v-if="nationalityData && nationalityData.length > 0" class="nationality-chart-holder">
            <h3 class="demographic-label">Nationality</h3>
            <QuizSubmissionsByNationalityBar v-if="nationalityData" :demographicData="nationalityData" type="nationality" />
          </div>
          <div v-if="ageGroupData && ageGroupData.length > 0" class="pie-chart-holder">
            <h3 class="demographic-label">Age Group</h3>
            <QuizDemographicPie v-if="ageGroupData" :demographicData="ageGroupData" type="ageGroup" />
          </div>
      </div>

      <template v-if="selectedQuiz.id == 2">
        <h1>Main Summary</h1>
        <div class="flex">
          <div class="pie-chart-holder">
            <QuizKnowledgePieChart
              v-if="knowledge"
              :knowledge="knowledge"
            />
          </div>
          <div class="pie-chart-holder">
            <QuizTypesPieChart
              v-if="types"
              :types="types"
            />
          </div>
        </div>
      </template>
      <div class="non-completed-chart-holder" v-if="nonCompletedQuestions">
        <h1>Non Completed Submissions</h1>
        <QuizNonCompletedQuestionsBar
          :questions="nonCompletedQuestions"
        />
      </div>

      <template v-if="selectedQuiz.id == 2">
        <h1>Questions Summary</h1>
        <div v-if="knowledge" class="question-summary-holder">
          <div class="question-summary" v-for="(question, index) in questionNumbers" :key="index">
            <QuizQuestionPie
              v-if="shouldDisplayQuestion(index)"
              :question="question"
              :filters="filters"
              @loaded="loadNextQuestion(index)"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import quizMixin from '@/mixins/quiz'

  import QuizKnowledgePieChart from './QuizKnowledgePieChart'
  import QuizTypesPieChart from './QuizTypesPieChart'
  import QuizQuestionPie from './QuizQuestionPie'
  import QuizDemographicPie from './QuizDemographicPie'
  import QuizNonCompletedQuestionsBar from './QuizNonCompletedQuestionsBar'
  import QuizSubmissionsByNationalityBar from './QuizSubmissionsByNationalityBar'

  export default {
    name: 'QuizSummary',
    mixins: [quizMixin],
    components: {
      QuizKnowledgePieChart,
      QuizTypesPieChart,
      QuizQuestionPie,
      QuizDemographicPie,
      QuizNonCompletedQuestionsBar,
      QuizSubmissionsByNationalityBar
    },
    data() {
      return {
        quizzes: [],
        selectedQuiz: { id: null },
        quizSubmissionsCount: {},
        nationalityData: null,
        ageGroupData: null,
        knowledge: null,
        types: null,
        nonCompletedQuestions: null,
        isDataLoading: false,
        filters: {
          usersFilter: null,
          ageGroupFilter: null
        },
        questionNumbers: [6, 8, 10, 11, 12],
        loadedQuestionIndex: -1
      }
    },
    methods: {
      selectQuiz(quiz) {
        if (quiz.id === this.selectedQuiz.id) {
          return;
        }

        this.selectedQuiz = quiz;

        this.resetPageData();
        this.filterChangeHandler();
      },
      shouldDisplayQuestion(questionIndex) {
        return questionIndex <= this.loadedQuestionIndex + 1;
      },
      loadNextQuestion(index) {
        if (index > this.loadedQuestionIndex) {
          this.loadedQuestionIndex = index;
        }
      },
      filterChangeHandler() {
        if (this.filters.usersFilter !== true && this.filters.usersFilter != "true") {
          this.filters.ageGroupFilter = null;
        }

        this.isDataLoading = true;
        this.resetPageData();
        Promise.all([this.getQuizDemographicDataHandler(this.selectedQuiz.id), this.getQuizSummaryHandler(this.selectedQuiz.id)])
          .then(() => {
            this.isDataLoading = false;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            this.isDataLoading = false;
          });
      },
      resetPageData() {
        this.nationalityData = null;
        this.ageGroupData = null;
        this.nonCompletedQuestions = null;
        this.knowledge = null;
        this.types = null;
        this.loadedQuestionIndex = -1;
      },
      getQuizDemographicDataHandler(quizId) {
        if (this.filters.usersFilter !== true && this.filters.usersFilter != "true") {
          return Promise.resolve();
        }

        return this.getQuizDemographicData(quizId, {filter: this.filters})
          .then(res => {
            this.nationalityData = res.nationalityData;
            this.ageGroupData = res.ageGroupData;
          })
      },
      getQuizSummaryHandler(quizId) {
        this.isDataLoading = true;
        return this.getQuizSummary(quizId, {filter: this.filters})
          .then(res => {
            this.quizSubmissionsCount = res.quizSubmissionsCount;
            this.knowledge = res.knowledge;
            this.types = res.types;
            this.nonCompletedQuestions = res.nonCompletedQuestions;
            this.isDataLoading = false;
          })
      },
      getAllQuizzes() {
        this.isDataLoading = true;
        this.getQuizzes()
          .then((res) => {
            this.quizzes = res.quizzes;
            if (this.quizzes.length > 0) {
              const quizWithId2 = this.quizzes.find(quiz => quiz.id == 2);
              if (quizWithId2) {
                this.selectQuiz(quizWithId2);
              } else if (this.quizzes.length > 0) {
                this.selectQuiz(this.quizzes[0]);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching quizzes:", error);
          })
          .finally(() => {
            this.isDataLoading = false;
          });
      },
    },
    mounted() {
      this.getAllQuizzes();
    }
  }
</script>

<style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .quiz-names-holder {
    display: block;
    max-width: 60%;
  }

  .quiz-names-holder div {
    margin: 0px 10px 10px 0px;
    display: inline-block;
  }

  .quiz-names {
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    padding: 8px 16px;
    border: 2px solid transparent;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: #333;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }

  .quiz-names:hover {
    background-color: #ddd;
  }

  .quiz-names.selected {
    background: var(--dark-green);
    color: #fff;
  }

  .filters-holder {
    display: flex;
    flex-direction: column;
    gap: 11px;
    text-align: right;
    position: fixed;
    top: 15%;
    right: 5%;
    z-index: 3;
  }

  .filter-label {
    margin-right: 10px;
    font-weight: bold;
  }

  .filter-select {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .non-completed {
    width: 50%;
  }
  
  .flex {
    display: flex;
  }

  .pie-chart-holder {
    flex-basis: 33.33%;
  }

  .question-summary-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
  }

  .question-summary {
    flex-basis: 33%;
    margin-bottom: 2%;
  }

  .demographic-label {
    text-align: center;
  }

  .non-completed-chart-holder {
    flex-basis: 100%;
    margin: 3% 0px 0px 0px;
  }

  .top-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .nationality-chart-holder {
    flex-basis: 66%;
  }
</style>