<!-- <template>
  <div>
    <EditorContent :editor="editor" />
    <div v-if="editor">
      <button
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        class="m-1 inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
        :class="{ 'ring-indigo-900 ring-2': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
        v-text="'bold'"
      />
    </div>
  </div>
</template>

<script>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

export default {
  components: {
    EditorContent,
  },
  setup() {
    const editor = useEditor({
      // content: "<p>Here is the content!</p>",
      editorProps: {
        attributes: {
          class: 'w-full prose my-6 mx-auto focus:outline-none',
        },
      },
      extensions: [
        StarterKit,
      ],
    });

    return {
      editor,
    };
  },
}
</script> -->

<!-- <template>
  <div v-if="editor">
    <input
      type="color"
      @input="editor.chain().focus().setColor($event.target.value).run()"
      :value="editor.getAttributes('textStyle').color"
    >
    <button @click="editor.chain().focus().setColor('#000000').run()"/>
   
    <button @click="editor.chain().focus().unsetColor().run()">
      unsetColor
    </button>

    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorContent } from '@tiptap/vue-3'

export default {
  components: {
    EditorContent,
  },

  data() {
    return {
      editor: null,
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        TextStyle,
        Color,
      ],
      content: `
        <p><span style="color: #958DF1">Oh, for some reason that’s purple.</span></p>
      `,
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script> -->

<template>
  <div class="text-editor">
    <div class="toolbar">
      <button @click="wrapSelectionWithSpan(gradient1)" class="gradient" :style="{ background: gradient1 }"></button>
      <button @click="wrapSelectionWithSpan(gradient2)" class="gradient" :style="{ background: gradient2 }"></button>
      <button @click="wrapSelectionWithSpan(gradient3)" class="gradient" :style="{ background: gradient3 }"></button>
      <button @click="wrapSelectionWithSpan(gradient4)" class="gradient" :style="{ background: gradient4 }"></button>
      
      <button @click="undo" :disabled="undoStack.length === 0"><font-awesome-icon :icon="['fas', 'undo']" /></button>
      <button @click="redo" :disabled="redoStack.length === 0"><font-awesome-icon :icon="['fas', 'redo']" /></button>
    </div>
    <div class="editor" ref="editor" contenteditable @input="handleInput" v-html="editorContent"></div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  emits: ['input'],
  data() {
    return {
      undoStack: [],
      redoStack: [],
      gradient1: "linear-gradient(#FF4D4D, #FF9B9B)",
      gradient2: "linear-gradient(#FF4D4D, #C983E173)",
      gradient3: "linear-gradient(#FF4D4D, #5042F673)",
      gradient4: "linear-gradient(#5B4DFF, #5042F673)",
      editorContent: this.value
    };
  },
  created() {
    // console.log("test");
    // console.log(this.value);
    this.editorContent = this.value;
  },
  // watch: {
  //   value(newValue) {
  //     console.log("UUIIII");
  //     this.editorContent = newValue;
  //   }
  // },
  methods: {
    handleInput() {
      const editorContent = this.$refs.editor.innerHTML;
      this.undoStack.push(editorContent);
      this.redoStack = [];
      this.$emit('input', editorContent);
    },
    undo() {
      if (this.undoStack.length > 1) {
        const currentState = this.undoStack.pop();
        this.redoStack.push(currentState);
        this.$refs.editor.innerHTML = this.undoStack[this.undoStack.length - 1];
        // this.$emit('input', this.undoStack[this.undoStack.length - 1]); // Emit input event on undo
      }
    },
    redo() {
      if (this.redoStack.length > 0) {
        const nextState = this.redoStack.pop();
        this.undoStack.push(nextState);
        this.$refs.editor.innerHTML = nextState;
        // this.$emit('input', nextState); // Emit input event on redo
      }
    },
    wrapSelectionWithSpan(gradient) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const span = document.createElement("span");
        span.className = "gradient-text";
        span.style.backgroundImage = gradient;
        span.style.backgroundClip = "text";
        span.style.webkitBackgroundClip = "text";
        span.style.webkitTextFillColor = "transparent";
        span.style.color = "transparent";
        range.surroundContents(span);
        this.handleInput(); // Update undo/redo stacks
      }
    }
  }
};
</script>

<style>
.text-editor {
  width: 100%;
  height: auto;
  box-shadow: none;
  line-height: initial;
  background: #FAFAFB;
  border: 1px solid #F1F1F5;
  border-radius: 10px;
  padding: 12px 15px;
  margin: 0;
  outline: none;
  font-size: 16px;
  color: #171725;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.toolbar button {
  border-radius: 4px;
  border: none;
  outline: none;
  height: 26px;
  cursor: pointer;
  border: 1px solid #F1F1F5;
}

.editor {
  border: 1px solid #ddd;
  min-height: 100px;
  padding: 5px;
  outline: none;
}

.highlight {
  background-color: yellow;
}

.gradient {
  width: 26px;
}

.gradient-text {
  -webkit-background-clip: text;
  background-clip: text;
}

@supports (--css: variables) {
  .gradient-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
}
</style>
