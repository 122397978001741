<template>
  <!-- Edit Command Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Command"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <span class="command-name">
        {{item.command}}
      </span>

      <!-- Short Description field -->
      <div class="field">
        <label for="shortDesc">Command Short Description:</label>
        <input type="text" id="shortDesc" v-model="shortDesc" placeholder="Command Short Description...">
      </div>

      <!-- Long Description field -->
      <div class="field">
        <label for="longDesc">Command Long Description:</label>
        <textarea id="longDesc" v-model="longDesc" placeholder="Command Long Description..."></textarea>
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Micro Service:</label>
        <vue3-simple-typeahead
          placeholder="Type micro service..."
          :items="microServices"
          :minInputLength="1"
          :itemProjection="(item) => item.name"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="item.microService && !selectedMicroService">
          Are you sure you want to remove the micro service?
        </span>
      </div>

      <!-- Provider field -->
      <div class="field">
        <label for="provider">Provider:</label>
        <input type="text" id="provider" v-model="provider" placeholder="Provider...">
      </div>

      <button class="transition save-btn" @click="updateCommandHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditCommand',
    mixins: [systemMixin, userMixin],
    props: {
      item: Object,
      microServices: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        selectedMicroService: null,
        updateLoading: false,
        shortDesc: this.item.commandShortDesc,
        longDesc: this.item.commandLongDesc,
        provider: this.item.provider
      }
    },
    methods: {
      // * Triggers on save button click and updates command
      updateCommandHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          commandShortDesc: this.shortDesc,
          commandLongDesc: this.longDesc,
          provider: this.provider
        };
        
        // * If user has selected micro service from the list add it to the request data else send null
        if (this.selectedMicroService) {
          dataToSend.microService = this.selectedMicroService.id;
        } else {
          dataToSend.microService = null;
        }

        this.editCommand(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      // * Triggered when user selects group from the autocomplete. Updates the selected group
      submit(result) {
        this.selectedMicroService = result;
      },
      // * Nulls group if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedMicroService = null;
        }
      }
    },
    mounted() {
      // * If item has parent group set it on component load
      if (this.item.microService) {
        this.selectedMicroService = {
          id: this.item.microService,
          name: this.item.microServiceName
        }
      }
    }
  }
</script>

<style scoped>
  .command-name {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
  }
</style>
