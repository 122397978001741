<template>
  <popup
    title="Edit Lecture"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content" v-if="quizzes">
        <!-- Title -->
        <div class="two-cols">
          <div class="field">
            <label for="session-name-en">Lecture Name EN:</label>
            <input type="text" id="session-name-en" v-model="session.title.en.title" placeholder="Lecture Name EN">
          </div>
          <div class="field">
            <label for="session-name-de">Lecture Name DE:</label>
            <input type="text" id="session-name-de" v-model="session.title.de.title" placeholder="Lecture Name DE">
          </div>
        </div>
        <!-- Description -->
        <div class="two-cols">
          <div class="field">
            <label for="session-description-en">Lecture Description EN:</label>
            <textarea id="session-description-en" v-model="session.description.en.description" placeholder="Lecture Description EN"></textarea>
          </div>
          <div class="field">
            <label for="session-description-de">Lecture Description DE:</label>
            <textarea id="session-description-de" v-model="session.description.de.description" placeholder="Lecture Description DE"></textarea>
          </div>
        </div>
        <!-- Video Data -->
        <div class="field">
          <label for="video-url">Video URL:</label>
          <input type="url" id="video-url" v-model="session.videoData.url" placeholder="Video URL">
        </div>
        <div class="field">
          <label for="video-thumb">Video Thumbnail:</label>
          <input type="url" id="video-thumb" v-model="session.videoData.thumb" placeholder="Video Thumbnail">
        </div>
        <div class="field">
          <label>Video Style:</label>
          <div class="custom-select" :class="{open: videoStyleSelect}" @click="videoStyleSelect = !videoStyleSelect" v-click-away="videoStyleAway">
            <span v-if="session.videoData.style != null">{{session.videoData.style}}</span>
            <span v-else>Select Style</span>
            <div class="select-dropdown" v-if="videoStyleSelect">
              <a @click="session.videoData.style = 'vertical'">
                vertical
              </a>
              <a @click="session.videoData.style = 'horizontal'">
                horizontal
              </a>
            </div>
          </div>
        </div>
        <!-- Quiz -->
        <div class="field">
          <label>Select Quiz:</label>
          <div class="custom-select" :class="{open: quizSelect}" @click="quizSelect = !quizSelect" v-click-away="quizAway">
            <span v-if="session.quizId != null">{{getQuizName(session.quizId)}}</span>
            <span v-else>Select Quiz</span>
            <div class="select-dropdown" v-if="quizSelect">
              <a
                v-for="quiz in quizzes"
                :key="quiz.id"
                @click="session.quizId = quiz.id"
              >
                {{quiz.quizName.en.name}}
              </a>
            </div>
          </div>
        </div>

        <button class="save-btn transition" @click="editSessionHandler()">
          Update
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'
  import quizMixin from '@/mixins/quiz'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditSession',
    mixins: [knowledgeMixin, quizMixin],
    components: {
      Popup
    },
    props: {
      item: Object,
      editData: Object
    },
    data() {
      return {
        session: this.item,
        videoStyleSelect: false,
        quizzes: null,
        quizSelect: false
      }
    },
    methods: {
      editSessionHandler() {
        const dataToSend = {
          titleData: this.session.title,
          descriptionData: this.session.description,
          videoData: this.session.videoData,
          documentData: this.session.documentData,
          quizId: this.session.quizId,
          status: 1
        }

        this.updateSession(this.editData.courseId, this.editData.moduleId, this.editData.sectionId, this.editData.sessionId, dataToSend)
          .then(() => {
            this.$emit('updated');
          })
      },
      videoStyleAway() {
        this.videoStyleSelect = false;
      },
      getAllQuizzesHandler() {
        this.getQuizzes()
          .then(res => {
            this.quizzes = res.quizzes;
          })
      },
      quizAway() {
        this.quizSelect = false;
      },
      getQuizName(quizId) {
        let selectedQuiz = this.quizzes.find(quiz => {
          return quiz.id == quizId;
        });

        return selectedQuiz.quizName.en.name;
      }
    },
    mounted() {
      this.getAllQuizzesHandler();
    }
  }
</script>

<style scoped>

</style>
