<template>
    <popup
      @close="$emit('close')"
    >
      <template v-slot:content>
        <div class="all-media" v-show="!documentSection">
            <h4 class="title">Attach document</h4>
            <div class="add-section">
                <div class="section relative">
                    <div class="item relative">
                        Add document
                        <input type="file" @change="addImage($event)">
                    </div>
                        <div class="loader-holder" v-if="mediaLoading">
                        <font-awesome-icon icon="spinner" spin />
                    </div>
                </div>
            </div>
            <button class="save-btn transition" @click="$emit('close')">Done</button>
        </div>
        <div class="add-document-section" v-if="documentSection">
          <h4 class="title">Add document</h4>
  
          <div class="field">
            <label for="document-title">Document title:</label>
            <input type="text" id="document-title" v-model="documentTitle" placeholder="Edit document title">
          </div>
  
          <div class="actions">
            <button class="transition" @click="addDocument()" :disabled="!documentTitle">Add document</button>
            <a class="transition" @click="$emit('close')">Cancel</a>
          </div>
        </div>
      </template>
    </popup>
  </template>
  
  <script>
    import mediaMixin from '@/mixins/media'
  
    import Popup from '@/components/shared/Popup'
  
    export default {
      name: 'AttachDocument',
      mixins: [mediaMixin],
      components: {
        Popup
      },
      props: {
        sessionId: Number,
      },
      data() {
        return {
          image: null,
          imageExt: null,
          media: null,
          mediaLoading: false,
          documentTitle: null,
          documentSection: false,
        }
      },
      methods: {
        addImage(event) {
            this.mediaLoading = true;

            const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
            this.imageExt = file.name.split('.').pop();
            // const pattern = /image-*/;
            const reader = new FileReader();
            if (this.imageExt != 'pdf' && this.imageExt != 'xlsx' && this.imageExt != 'xls') {
                alert('Invalid format');
                this.mediaLoading = false;
                return;
            }

            if (this.imageExt == 'pdf' || this.imageExt == 'xlsx' || this.imageExt == 'xls') {
                this.documentTitle = file.name.split('.').shift();
            }

            // this.fileName = file.name;
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(file);
        },
        _handleReaderLoaded(e) {
            const reader = e.target;
            this.image = reader.result;
            this.documentSection = true;
        },
        addDocument() {
          const dataToSend = {
            fileContent: this.image,
            fileName: this.documentTitle,
            sessionId: this.sessionId,
            fileExtension: this.imageExt,
          };
  
          this.addMedia(dataToSend)
            .then(res => {
              this.image = null;
              this.documentTitle = null;
              const documentData = {
                url: res.media.pdf,
                title: res.media.title,
              }
              this.$emit('document-added', documentData);
            })
            .catch(err => {
                console.log(err);
            })
        },
      },
    }
  </script>
  
  <style scoped>
    .title {
      font-size: 30px;
      color: #171725;
      margin: 0;
      display: flex;
      align-items: center;
    }
  
    .title svg {
      font-size: 35px;
      margin-right: 20px;
    }
  
    .add-section {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
    }
  
    .section {
      flex: 0 0 100%;
      max-width: 100%;
      background: #EAEAEA;
      border-radius: 10px;
      height: 180px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 20px;
      overflow: hidden;
    }
  
    .section:nth-child(2n-1) {
      margin-right: 20px;
    }
  
    .section .delete-item,
    .section .set-featured,
    .section .set-to-fund {
      position: absolute;
      top: 5px;
      background: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;
    }
  
    .section .delete-item {
      right: 5px;
      font-size: 20px;
      line-height: 20px;
      color: #ef2d2d;
    }
  
    .section .delete-item:hover {
      color: #FFF;
      background: #ef2d2d;
    }
  
    .section .set-featured {
      left: 5px;
      font-size: 16px;
      line-height: 16px;
      color: var(--dark-green);
    }
  
    .section .set-featured:hover {
      color: #FFF;
      background: var(--dark-green);
    }
  
    .section .set-to-fund {
      left: 40px;
      font-size: 16px;
      line-height: 16px;
      color: var(--dark-green);
    }
  
    .section .set-to-fund:hover {
      color: #FFF;
      background: var(--dark-green);
    }
  
    .set-to-fund .tooltip {
      display: none;
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      font-size: 12px;
      padding: 3px 7px;
      border-radius: 20px;
      background: #FFF;
      color: var(--dark-green);
      white-space: nowrap;
    }
  
    .set-to-fund:hover .tooltip {
      display: block;
    }
  
    .section>img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  
    .image-item {
      cursor: pointer;
    }
  
    .section .loader-holder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .section .loader-holder svg {
      color: var(--dark-green);
      font-size: 60px;
    }
  
    .section .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #FFF;
      display: flex;
      width: 65px;
      height: 65px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
    }
  
    .section .play-button:hover {
      background: var(--dark-green);
    }
  
    .section .play-button svg {
      color: var(--dark-green);
      font-size: 30px;
      transform: translateX(3px);
    }
  
    .section .play-button:hover svg {
      color: #FFF;
    }
  
    .section .pdf-holder,
    .section .link-holder {
      background: var(--dark-green);
      width: 100%;
      height: 190px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-direction: column;
      text-decoration: none;
    }
  
    .section .pdf-holder:hover,
    .section .link-holder:hover {
      background: #FFF;
      box-shadow: 0 0 5px rgba(0,0,0,0.2);
    }
  
    .section .pdf-holder img {
      filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(175deg) brightness(110%) contrast(101%);
    }
  
    .section .pdf-holder:hover img {
      filter: invert(40%) sepia(9%) saturate(1746%) hue-rotate(135deg) brightness(92%) contrast(89%);
    }
  
    .section .link-holder svg {
      font-size: 70px;
      color: #FFF;
    }
  
    .section .link-holder:hover svg {
      color: var(--dark-green);
    }
  
    .section .pdf-holder span,
    .section .link-holder span {
      display: block;
      text-align: center;
      color: #FFF;
      font-size: 18px;
      margin-top: 10px;
    }
  
    .section .pdf-holder:hover span {
      color: var(--dark-green);
    }
  
    .link-holder img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    .link-holder .link-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  
    .link-holder .link-content.has-image {
      background: rgba(0,0,0,0.4);
    }
  
    .item {
      height: 90px;
      color: #92929D;
      font-size: 15px;
      padding: 0 7px;
      text-align: center;
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .item:nth-of-type(even) {
      border-left: 1px solid #C4C4C4;
    }
  
    .item:nth-of-type(3),
    .item:nth-of-type(4) {
      border-top: 1px solid #C4C4C4;
    }
  
    .item:hover {
      color: var(--dark-green);
    }
  
    .item input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      width: 100%;
    }
  
    .item input,
    .item input::-webkit-file-upload-button {
      cursor: pointer;
    }
  
    .custom-video-holder video {
      width: 100%;
      height: 100%;
    }
  
    .custom-video-holder .play-button-holder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  
    .save-btn {
      background: var(--dark-green);
      color: #FAFAFB;
      border-radius: 8px;
      border: 0;
      display: block;
      width: 100%;
      height: 50px;
      margin-top: 40px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      outline: none;
    }
  
    .save-btn:hover {
      background: #FAFAFB;
      color: var(--dark-green);
      box-shadow: 0 0 10px rgba(0,0,0,0.3);
    }
  
    .add-video-section .title,
    .add-link-section .title {
      cursor: pointer;
    }
  
    .add-video-section .title:hover,
    .add-link-section .title:hover {
      text-decoration: underline;
    }
  
    .add-video-section .site-logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0;
    }
  
    .site-logos svg {
      font-size: 60px;
      margin: 0 25px;
      color: var(--dark-green);
    }
  
    .site-logos svg[data-icon="youtube"] {
      color: #FF0217;
    }
  
    .site-logos svg[data-icon="vimeo"] {
      color: #04A1F0;
    }
  
    .site-logos svg:hover {
      color: var(--dark-green);
    }
  
    .field label {
      color: #44444F;
      font-size: 16px;
    }
  
    .field input {
      background: #FAFAFB;
      border: 1px solid #F1F1F5;
      border-radius: 15px;
      display: block;
      width: 100%;
      padding: 13px 17px;
      font-size: 16px;
      margin: 5px 0;
      outline: none;
    }
  
    .field input:focus {
      border-color: var(--dark-green);
    }
  
    .add-link-section .field {
      margin-top: 40px;
    }
  
    .link-image {
      max-width: 50%;
      background: #EAEAEA;
      border-radius: 10px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #171725;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      overflow: hidden;
    }
  
    .link-image:hover {
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
  
    .link-image svg {
      color: var(--dark-green);
      font-size: 40px;
    }
  
    .link-image .plus {
      margin-right: 5px;
    }
  
    .link-image img:not(.plus) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    .link-image input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
    }
  
    .link-image input,
    .link-image input::-webkit-file-upload-button {
      cursor: pointer;
    }
  
    .actions {
      margin-top: 60px;
    }
  
    .actions button {
      display: block;
      width: 100%;
      background: var(--dark-green);
      color: #FAFAFB;
      border-radius: 8px;
      border: 0;
      height: 50px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      outline: none;
    }
  
    .actions button:not(:disabled):hover {
      background: #FAFAFB;
      color: var(--dark-green);
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }
  
    .actions button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  
    .actions a {
      display: block;
      width: 100%;
      text-align: center;
      margin: 20px 0;
      color: #000;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
    }
  
    .actions a:hover {
      text-decoration: underline;
    }
  
    @media (max-width: 767px) {
      .title {
        font-size: 24px;
      }
  
      .section {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 10px;
        height: 200px;
      }
  
      .item {
        height: 100px;
      }
    }
  </style>
  