<template>
  <div class="scheduled-notifications">
    <h1>Scheduled Notifications <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit', 'new', 'clone']"
      @update-table="getNotifications"
      @add-new="addNewPopup = true"
      @edit="editHandler"
      @clone="cloneHandler"
    />

    <NewScheduledNotification
      v-if="addNewPopup"
      :notificationToEdit="editItem"
      @close="updatedHandler"
    />
  </div>
</template>

<script>
  import marketingMixin from '@/mixins/marketing'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewScheduledNotification from './popups/NewScheduledNotification'

  export default {
    name: 'ScheduledNotifications',
    mixins: [marketingMixin],
    components: {
      DataTableHolder,
      NewScheduledNotification
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Message',
            name: 'messageData',
            sortable: false
          },
          {
            title: 'Organization',
            name: 'organization',
            sortable: false
          },
          {
            title: 'Notes',
            name: 'notes',
            sortable: false
          },
          {
            title: 'Send Date',
            name: 'sendDate',
            sortable: false
          },
          {
            title: 'Expiration Date',
            name: 'expirationDate',
            sortable: false
          },
          {
            title: 'Is Push',
            name: 'isPush',
            sortable: false
          },
          {
            title: 'Status',
            name: 'status',
            sortable: false
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null,
        addNewPopup: false,
      }
    },
    methods: {
      getNotifications() {
        this.getScheduledNotifications()
          .then(res => {
            // * After receiving response update data
            let notificationsFormatted = [];
            // * Loop through portfolios and add only the needed properties
            for (const notification of res) {
              let messageText = '';
              if (notification.messageData.en.items) {
                for (let messageItem of notification.messageData.en.items) {
                  messageText = messageText + (messageItem.text || messageItem.title) + ' ';
                }
              }

              let notificationObj = {
                id: notification.id,
                messageData: messageText,
                organization: notification.organization,
                notes: notification.notes,
                sendDate: notification.sendDate,
                expirationDate: notification.expirationDate,
                isPush: notification.isPush,
                status: this.getStatusName(notification.status),
              }
              // * Add formatted equity to formatted userPortfolios array
              notificationsFormatted.push(notificationObj);
            }
            // * Assign table rows to be the formatted portfolios array
            this.rows = notificationsFormatted;
            this.fullData = res;
            this.total = res.length;
          })
      },
      getStatusName(statusId) {
        switch (statusId) {
          case -1:
            return 'Draft';
          case 0:
            return 'Active';
          case 1:
            return 'Started';
          case 2:
            return 'Completed';
        }
      },
      editHandler(item) {
        this.editItem = this.fullData.find(notification => {
          return notification.id == item.id;
        });
        this.addNewPopup = true;
      },
      updatedHandler() {
        this.addNewPopup = false;
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      cloneHandler(item) {
        this.cloneScheduledNotification(item)
          .then(() => {
            this.updateResults = !this.updateResults;
          })
      }
    }
  }
</script>

<style scoped>
  
</style>