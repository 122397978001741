<template>
  <div class="user-promo-codes">
    <h1>
      Users' Promo Codes <small v-if="total">({{ total }})</small>
    </h1>
    <button class="export-btn" @click="exportExcel()">Export to Excel</button>

    <!-- Master Filter -->
    <MasterFilter
      :filters="filters"
      :filtersDisplayed="filtersDisplayed"
      :activeFilterIndex="activeFilterIndex"
      @apply-filters="applyFilters"
      @reset-filters="resetFilters"
    />

    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :actions="[]"
      :updateResults="updateResults"
      @update-table="getUserPromoCodesHandler"
    />
  </div>
</template>

<script>
import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
import usersMixin from "@/mixins/users";
import userMixin from "@/mixins/user";
import { utils, writeFile } from "xlsx";
import MasterFilter from "@/components/shared/filters/MasterFilter.vue";

export default {
  name: "PromoCodes",
  mixins: [usersMixin, userMixin],
  components: {
    DataTableHolder,
    MasterFilter,
  },
  data() {
    return {
      rows: null,
      columns: [
        { title: "User ID", name: "userId", sortable: true },
        { title: "Promo Code", name: "code", sortable: true },
        { title: "Used On", name: "usedOn", sortable: true },
      ],
      pages: 0,
      total: null,
      fromDate: null,
      toDate: null,
      updateResults: false,
      filters: {
        dropdowns: {
          dateRange: {
            selectedOptions: [this.fromDate, this.toDate], // Initial date range values
            label: 'Date Range',
            placeholder: 'Select Date Range',
            type: 'dateRange', // Custom type for range picker in MasterFilter
          },
        }
      },
      filtersDisplayed: true,
      activeFilterIndex: -1,
      filtersToSend: {},
    };
  },
  created() {
    this.fromDate = this.startOfMonth();
    this.toDate = this.today();
    this.filters.dropdowns.dateRange.selectedOptions = [this.fromDate, this.toDate];
    this.applyFilters();
  },
  methods: {
    async getUserPromoCodesHandler(data) {
      this.setFilters(data);

      try {
        const result = await this.getUserPromoCodes(data);
        this.pages = result.pagesCount;
        this.rows = result.promoCodes.result.map((promo) => ({
          userId: promo.user_id,
          code: promo.code,
          usedOn: promo.used_on,
        }));
        this.total = result.resultsCount;
      } catch (err) {
        if (err === 401) {
          this.logout();
        }
      }
    },

    applyFilters() {
      const dateRange = this.filters.dropdowns.dateRange.selectedOptions;
      this.filtersToSend.fromDate = dateRange[0];
      this.filtersToSend.toDate = dateRange[1];
      this.updateResults = !this.updateResults;
    },

    
    resetFilters() {
      this.filters.dropdowns.dateRange.selectedOptions = [this.startOfMonth(), this.today()];
      this.applyFilters();
    },
    
    setFilters(data) {
      data.fromDate = this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[0]);
      data.toDate = this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[1]);
    },
    
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();
      
      return `${year}-${month}-${day}`;
    },
    
    async exportExcel() {
      let data = {
        fromDate : this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[0]),
        toDate : this.formatDate(this.filters.dropdowns.dateRange.selectedOptions[1])
      };

      let dataToExport = null;

      try {
        const res = await this.getUserPromoCodes(data);
        dataToExport = res.promoCodes.result;
      } catch (err) {
        if (err === 401) {
          this.logout();
          return;
        }
        console.error(err);
        return;
      }

      const excelData = dataToExport.map((row) => ({
        "User ID": row.user_id,
        "Promo Code": row.code,
        "Used On": row.used_on,
      }));

      const ws = utils.json_to_sheet(excelData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      const fileName = `User_Promo_Codes_${formattedDate}.xlsx`;

      writeFile(wb, fileName);
    },

    startOfMonth() {
      const date = new Date();
      date.setDate(1);
      return date.toISOString().split("T")[0];
    },

    today() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },
  },
};
</script>

<style scoped>
.export-btn {
  background: #3c6e71;
  color: #fff;
  border: none;
  font-weight: 600;
  height: 40px;
  width: 200px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: block;
  margin: 0 0 25px auto;
}

.export-btn:hover {
  background: #fff;
  color: #3c6e71;
}
</style>