<script>
import axios from 'axios'

// * If there is user token in the auth store add it to every request as Bearer token
if (localStorage.getItem('x-auth-token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
}

export default {
  methods: {
    getOldUrlNames(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/old-url-names`, {
          method: 'post',
          data: data
        })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
      })
    },
    deleteOldUrlName(urlId) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/old-url-names/${urlId}`, {
          method: 'delete'
        })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
      })
    },
    createOldUrlName(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/old-url-names/new`, {
          method: 'post',
          data: data
        })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
      })
    },

  }
}
</script>
