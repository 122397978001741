<template>
  <div class="quiz-list">
    <h1>Quizzes</h1>
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :actions="['view', 'new', 'edit', 'delete']"
      page="Quizzes"
      :updateResults="updateResults"
      @update-table="getAllQuizzes"
      @add-new="newQuizPopup = true"
      @edit="editHandler"
      @delete="deleteHandler"
      @view="openQuiz"
    />

    <NewQuiz
      v-if="newQuizPopup"
      @close="newQuizPopup = false"
      @update-quizzes="updateQuizzes"
    />

    <EditQuiz
      v-if="editQuizItem"
      :quizId="editQuizItem"
      @close="editQuizItem = null"
      @update-quizzes="updateQuizzes"
    />

    <DeleteQuiz
      v-if="deleteQuizItem"
      :quizId="deleteQuizItem"
      @close="deleteQuizItem = null"
      @update-quizzes="updateQuizzes"
    />
  </div>
</template>

<script>
  import quizMixin from '@/mixins/quiz'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewQuiz from './popups/NewQuiz'
  import EditQuiz from './popups/EditQuiz'
  import DeleteQuiz from './popups/DeleteQuiz'

  export default {
    name: 'QuizList',
    mixins: [quizMixin],
    components: {
      DataTableHolder,
      NewQuiz,
      EditQuiz,
      DeleteQuiz
    },
    data() {
      return {
        quizzes: null,
        newQuizPopup: false,
        editQuizItem: null,
        deleteQuizItem: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Name',
            name: 'name',
            sortable: false
          },
          {
            title: 'Status',
            name: 'status',
            sortable: false
          },
          {
            title: '# of Questions',
            name: 'numberQuestions',
            sortable: false
          },
          {
            title: 'Is Public',
            name: 'isPublic',
            sortable: false
          },
        ],
        rows: null,
        updateResults: false
      }
    },
    methods: {
      getAllQuizzes() {
        this.getQuizzes()
          .then(res => {
            this.quizzes = res.quizzes;
            this.rows = [];

            for (const quiz of res.quizzes) {
              let row = {
                id: quiz.id,
                name: quiz.quizName.en.name,
                status: quiz.status,
                numberQuestions: quiz.questions.length,
                isPublic: quiz.isPublic
              }

              this.rows.push(row);
            }
          })
      },
      updateQuizzes() {
        this.newQuizPopup = false;
        this.editQuizItem = null;
        this.deleteQuizItem = null;
        this.updateResults = !this.updateResults;
        this.getAllQuizzes();
      },
      openQuiz(quiz) {
        this.$router.push(`/quiz/questions/${quiz.id}`)
      },
      editHandler(quiz) {
        this.editQuizItem = quiz.id;
      },
      deleteHandler(quiz) {
        this.deleteQuizItem = quiz.id;
      }
    }
  }
</script>

<style scoped>
  .new-quiz {
    display: flex;
    align-items: center;
    background: #FFF;
    color: #3c6e71;
    border: 1px solid #3c6e71;
    font-weight: 600;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 40px;
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .new-quiz:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .new-quiz img {
    margin-right: 5px;
    filter: invert(41%) sepia(8%) saturate(2190%) hue-rotate(135deg) brightness(89%) contrast(82%);
  }

  .new-quiz:hover img {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(105%) contrast(104%);
  }

  .quizes {
    display: flex;
  }

  .quiz-item {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 25px;
  }

  .quiz-name {
    font-size: 25px;
    margin-top: 0;
  }

  .actions {
    display: flex;
    gap: 10px;
  }
</style>