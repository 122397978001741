<template>
  <!-- Edit Command Schedule Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Command Schedule"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <span class="command-name">
        {{item.command}}
      </span>

      <!-- Schedule field -->
      <div class="field">
        <label for="schedule">Command Schedule:</label>
        <input type="text" id="schedule" v-model="schedule" placeholder="Command Schedule...">
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="status">
          <span class="checkmark"></span>
          Status
        </label>
      </div>

      <!-- Arguments -->
      <div class="field">
        <label>Arguments:</label>
      </div>

      <template v-if="argumentsArr && argumentsArr.length > 0">
        <div class="field argument-fields" v-for="(argument, index) in argumentsArr" :key="index">
          <input type="text" v-model="argument.name" placeholder="Name...">
          <input type="text" v-model="argument.value" placeholder="Value...">
        </div>
      </template>

      <button class="add-argument transition" @click="addArgument">
        New argument
      </button>

      <button class="transition save-btn" @click="updateCommandScheduleHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditCommandSchedule',
    mixins: [systemMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        updateLoading: false,
        schedule: this.item.schedule,
        status: this.item.status,
        argumentsArr: this.item.arguments
      }
    },
    methods: {
      // * Triggers on save button click and updates command schedule
      updateCommandScheduleHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          schedule: this.schedule,
          status: this.status,
          arguments: this.argumentsArr
        };

        this.editCommandSchedule(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      addArgument() {
        this.argumentsArr.push({name: '', value: ''});
      }
    }
  }
</script>

<style scoped>
  .command-name {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
  }

  .argument-fields:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
    margin: 20px 0;
    padding-bottom: 15px;
  }

  .argument-fields {
    display: flex;
  }

  .argument-fields input {
    margin-bottom: 5px;
  }

  .argument-fields input:first-child {
    margin-right: 5px;
  }

  .argument-fields input:last-child {
    margin-left: 5px;
  }

  .add-argument {
    background: #3c6e71;
    color: #fff;
    border-radius: 8px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 15px;
    cursor: pointer;
    outline: none;
  }

  .add-argument:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    background: #fff;
    color: #3c6e71;
  }
</style>
