<template>
  <!-- Portfolio Performance Page -->
  <div class="portfolio-performance-page">
    <h1>Portfolio Performance <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      page="Portfolio Performance"
      @update-table="getNonDeletedHandler"
      @edit="editHandler"
    />

    <!-- Inspect Popup -->
    <InspectPortfolioPerformance
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
    />
  </div>
</template>

<script>
  import portfoliosMixin from '@/mixins/portfolios'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectPortfolioPerformance from './popups/InspectPortfolioPerformance'

  export default {
    name: 'PortfolioPerformance',
    mixins: [portfoliosMixin, userMixin],
    components: {
      DataTableHolder,
      InspectPortfolioPerformance
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Portfolio Name',
            name: 'portfolioName',
            sortable: true
          },
          {
            title: 'Total Performance',
            name: 'totalPerformance',
            sortable: true
          },
          {
            title: 'Weekly Performance',
            name: 'weeklyPerformance',
            sortable: true
          },
          {
            title: 'Daily Performance',
            name: 'dailyPerformance',
            sortable: true
          },
          {
            title: 'Cash Value',
            name: 'cashValue',
            sortable: true
          },
          {
            title: 'Free Cash Value',
            name: 'freeCashValue',
            sortable: true
          },
          {
            title: 'Securities Value',
            name: 'securitiesValue',
            sortable: true
          },
          {
            title: 'Securities Count',
            name: 'securitiesCount',
            sortable: true
          },
          {
            title: 'Inception Date',
            name: 'InceptionDate',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        total: null,
        deleteItem: null
      }
    },
    methods: {
      // * Get non-deleted portfolios from API
      getNonDeletedHandler(data) {
        this.getNonDeleted(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.rows = res.userPortfolios;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      editHandler(item) {
        this.editItem = item;
      }
    }
  }
</script>

<style scoped>

</style>