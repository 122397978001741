<template>
  <!-- Edit Fund Benchmark Mapping Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Fund Fund Benchmark Mapping"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Benchmark:</label>
        <vue3-simple-typeahead
          placeholder="Type benchmark..."
          :items="benchmarks"
          :minInputLength="1"
          :itemProjection="(item) => item.benchmark"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="item.benchmarkId && !selectedBenchmark">
          Are you sure you want to remove the benchmark?
        </span>
      </div>

      <button class="transition save-btn" @click="updateFundBenchmarkMappingHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundBenchmarkMapping',
    mixins: [fundsMixin, userMixin],
    props: {
      item: Object,
      benchmarks: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        selectedBenchmark: null,
        updateLoading: false
      }
    },
    methods: {
      // * Triggers on save button click and updates benchmark mapping
      updateFundBenchmarkMappingHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {}

        if (this.selectedBenchmark) {
          dataToSend.benchmarkId = this.selectedBenchmark.id
        } else {
          dataToSend.benchmarkId = null
        }

        this.updateFundBenchmarkMapping(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      // * Triggered when user selects benchmark from the autocomplete. Updates the selected benchmark
      submit(result) {
        this.selectedBenchmark = result;
      },
      // * Nulls benchmark if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedBenchmark = null;
        }
      }
    },
    mounted() {
      // * If item has parent benchmark set it on component load
      if (this.item.benchmarkId) {
        this.selectedBenchmark = {
          id: this.item.benchmarkId,
          benchmark: this.item.benchmark
        }
      }
    }
  }
</script>

<style scoped>
  
</style>
