<template>
  <Teleport to="body">
    <!-- HOC (High Order Component) for all popups on the site -->
    <div :class="['popup', customClass]" @mousedown.self="$emit('close')">
      <div class="popup-box white-box relative">
        <a class="close transition" @click="$emit('close')">
          <img :src="$s3Url + 'svgs/icons/popup-close.svg'">
        </a>
        <h4 class="title">{{title}}</h4>
        <slot name="content"></slot>
      </div>
    </div>
  </Teleport>
</template>

<script>
  export default {
    name: 'Popup',
    emits: ['close'],
    props: {
      title: String,
      customClass: String
    }
  }
</script>

<style scoped>
  .show-content-popup .popup-box {
    width: unset;
    min-width: 650px;
    max-width: 80%;
  }

  .new-session-popup .popup-box{
    width: 80%;
  }

  .title {
    font-size: 30px;
    margin: 0;
    color: #171725;
  }

  /* Two Columns */
  :deep(.two-cols),
  :deep(.three-cols),
  :deep(.four-cols) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  :deep(.two-cols .field) {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  :deep(.two-cols .field:first-child) {
    margin-right: 10px;
  }

  :deep(.two-cols .field:last-child) {
    margin-left: 10px;
  }

  /* Three Columns */
  :deep(.three-cols .field) {
    flex: 0 0 calc(33.33% - 10px);
    max-width: calc(33.33% - 10px);
  }

  :deep(.three-cols .field:first-child) {
    margin-right: 10px;
  }

  :deep(.three-cols .field:last-child) {
    margin-left: 10px;
  }

  /* Four Columns */
  :deep(.four-cols .field) {
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
  }

  :deep(.four-cols .field:first-child) {
    margin-right: 10px;
  }

  :deep(.four-cols .field:last-child) {
    margin-left: 10px;
  }

  :deep(.field) {
    margin: 15px 0;
  }

  :deep(.field label:not(.custom-check)) {
    color: #44444f;
    font-size: 16px;
    font-weight: 500;
  }

  :deep(.field textarea),
  :deep(.field input) {
    width: 100%;
    height: auto;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  :deep(.field input:disabled) {
    cursor: default;
  }

  :deep(.field textarea) {
    height: 150px;
    resize: none;
    scrollbar-color: #3C6E71 transparent;
    scrollbar-width: thin;
  }

  :deep(.field textarea::-webkit-scrollbar) {
    width: 5px;
  }

  :deep(.field textarea::-webkit-scrollbar-track) {
    background: transparent; 
  }
  
  :deep(.field textarea::-webkit-scrollbar-thumb) {
    background: #3C6E71;
    border-radius: 30px;
  }

  :deep(.field textarea::-webkit-scrollbar-thumb:hover) {
    background: #3C6E71; 
  }

  :deep(.field textarea:focus),
  :deep(.field input:focus),
  :deep(.field :deep(input:focus)) {
    border-color: #3C6E71;
  }
  
  :deep(.custom-select) {
    width: 100%;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0;
    outline: none;
    font-size: 16px;
    color: #171725;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  :deep(.custom-select.open) {
    border-color: #3c6e71;
  }
  
  :deep(.custom-select:after) {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    color: #B5B5BE;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    right: 20px;
  }

  :deep(.custom-select .select-dropdown) {
    background: #FAFAFB;
  }

  :deep(.save-btn) {
    background: #3C6E71;
    color: #FAFAFB;
    border-radius: 8px;
    border: 0;
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
  }

  :deep(.save-btn:not(:disabled):hover) {
    background: #FAFAFB;
    color: #3C6E71;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  :deep(.delete-btn) {
    background-color: #eb5757;
    color: #FFF;
    border: 0;
    outline: none;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  :deep(.delete-btn:not(:disabled):hover) {
    background-color: #FFF;
    color: #eb5757;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  :deep(.set-field-btn) {
    margin-left: 3%;
    background-color: #FFF;
    color: #3C6E71;
    border: solid thin #3C6E71;
    outline: none;
    width: 13%;
    height: 30px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  :deep(.set-field-btn:not(:disabled):hover) {
    background-color: #3C6E71;
    color: #FFF;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border: none;
  }

  :deep(button:disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 767px) {
    .title {
      font-size: 25px;
    }

    .field textarea {
      height: calc(100vh - 550px);
    }

    .field textarea,
    .field input {
      font-size: 14px;
    }
  }
</style>
