<template>
  <!-- Edit Fund Group Consolidation Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Fund Group Consolidation"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Name <small>(not editable)</small>:</label>
        <input type="text" id="name" v-model="fundGroup.name" placeholder="Name" disabled>
      </div>

        <!-- Brand Name field -->
      <div class="field">
        <label for="brandName">Brand Name <small>(not editable)</small>:</label>
        <input type="text" id="brandName" v-model="fundGroup.brandName" placeholder="Brand Name" disabled>
      </div>

      <!-- Preferred Name field -->
      <div class="field">
        <label for="preferredName">Preferred Name:</label>
        <input type="text" id="prefferedName" v-model="preferredName" placeholder="Preferred Name">
      </div>

      <span class="remove-warning" v-if="preferredName == ''">
          Preferred Name cannot be blank
      </span>

      <!-- Url Name field -->
      <div class="field">
        <label for="urlName">Url Name:</label>
        <input type="text" id="urlName" v-model="urlName" placeholder="Url Name">
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Consolidation Group:</label>
        <button v-if="fundGroup.consolidatedName" class="set-null-btn" @click="setConsolidatedGroupToNull" :disabled="updateLoading">
          Set Null
        </button>
        <vue3-simple-typeahead
          :placeholder="ConsolidationGroupPlaceholder"
          :items="fundGroups"
          :minInputLength="1"
          :itemProjection="(item) => item.name"
          @selectItem="submit"
          @onInput="keyUpEvent"
        >
          <template #list-item-text="{ item }">
            {{ item.name }}
            <span v-if="item.isAssetManager" class="green-check">&#10004;</span>
            <span v-else class="red-x">&#10008;</span>
            {{ item.fundsCount }}
          </template>

        </vue3-simple-typeahead>
        <span class="remove-warning" v-if="fundGroup.consolidatedId && !selectedFundGroup">
          Are you sure you want to remove the consolidation group?
        </span>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPaid">
          <span class="checkmark"></span>
          Is Paid
        </label>
      </div>

      <button class="transition save-btn" @click="updateFundGroupHandler()" :disabled="updateLoading
            || (preferredName == '')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

    </template>
  </popup>
</template>

<script>
  import organizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundGroupConsolidationMds',
    mixins: [organizationsMixin, userMixin],
    props: {
      item: Object,
      fundGroups: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        parentSelect: false,
        selectedFundGroup: null,
        updateLoading: false,
        isPaid: this.item.isPaid,
        fundGroup: this.item,
        preferredName: this.item.preferredName,
        urlName: this.item.urlName,
      }
    },
    methods: {
      // * Triggers on save button click and updates advisor
      updateFundGroupHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          name: this.fundGroup.name,
          preferredName: this.preferredName,
          urlName: this.urlName,
          isPaid: this.isPaid
        }

        // * If user has selected group from the list add it to the request data else send null
        if (this.selectedFundGroup) {
          dataToSend.consolidatedId = this.selectedFundGroup.id;
        } else {
          dataToSend.consolidatedId = null;
        }

        this.editOrganization(this.fundGroup.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      // * Triggered when user selects fund group from the autocomplete. Updates the selected group
      submit(result) {
        this.selectedFundGroup = result;
      },
      // * Nulls group if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedFundGroup = null;
        }
      },
      setConsolidatedGroupToNull() {
        this.selectedFundGroup = null;
      },
      slugify(text) {
        const charMap = {
          // German characters
          'ä': 'ae', 'ö': 'oe', 'ü': 'ue', 'ß': 'ss',
          // French characters
          'à': 'a', 'â': 'a', 'ç': 'c', 'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e',
          'î': 'i', 'ï': 'i', 'ô': 'o', 'ù': 'u', 'û': 'u', 'ÿ': 'y',
          // Spanish characters
          'á': 'a', 'í': 'i', 'ñ': 'n', 'ó': 'o', 'ú': 'u',
          // Portuguese characters
          'ã': 'a', 'õ': 'o',
          // Italian character
          'ò': 'o',
          // Nordic characters
          'å': 'a', 'ø': 'o',
          // Slavic characters
          'č': 'c', 'ć': 'c', 'đ': 'd', 'š': 's', 'ž': 'z', 'ł': 'l',
          // Romanian characters
          'ă': 'a', 'ș': 's', 'ț': 't',
          // Turkish characters
          'ğ': 'g', 'ı': 'i', 'ş': 's',
        };
        return text.toString().toLowerCase()
            .split('').map(char => charMap[char] || char).join('')
            .replace(/\s+/g, '-')     // Replace spaces with -
            .replace(/[^\w-]+/g, '') // Remove all non-word chars
            .replace(/--+/g, '-')   // Replace multiple - with single -
            .replace(/^-+/, '')       // Trim - from start of text
            .replace(/-+$/, '');      // Trim - from end of text
      },
    },
    computed: {
      ConsolidationGroupPlaceholder() {
        if (this.selectedFundGroup) {
          return this.fundGroup.consolidatedName;
        } else {
          return 'Type a fund group...';
        }
      },
    },
    mounted() {
      // * If fundGroup has consolidated group set it on component load
      if (this.fundGroup.consolidatedId) {
        this.selectedFundGroup = {
          id: this.fundGroup.consolidatedId,
          name: this.fundGroup.consolidatedName,
          urlName: this.fundGroup.urlName
        }
      }
    },
    watch: {
      // Watch for changes in name and update urlName accordingly
      preferredName(newValue) {
        this.urlName = this.slugify(newValue);
      }
    },
  }
</script>

<style scoped>

.green-check {
  color: green;
  margin-right: 5px;
}

.red-x {
  color: red;
  margin-right: 5px;
}

</style>
