<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
        // * Get achievement categories
        getAchievementCategories() {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/achievement-categories`, {
                    method: 'get'
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.code);
                    })
            })
        },
      // * Get achievements
      getAchievements() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/achievements`, {
            method: 'post'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      },
        // * Create achievement
        createAchievement(data) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/achievements/create`, {
                    method: 'post',
                    data: data
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.code);
                    })
            })
        },
        // * Patch achievement
        patchAchievement(achievementId, data) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/achievements/${achievementId}`, {
                    method: 'patch',
                    data: data
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.code);
                    })
            })
        },
        // * Delete achievement
        deleteAchievement(achievementId) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/achievements/${achievementId}`, {
                    method: 'delete'
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.code);
                    })
            })
        }
    }
  }
</script>
