<template>
  <popup
    title="Edit Chapter"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <div class="two-cols">
          <div class="field">
            <label for="section-name-en">Chapter Name EN:</label>
            <input type="text" id="section-name-en" v-model="section.title.en.title" placeholder="Chapter Name EN">
          </div>
          <div class="field">
            <label for="section-name-de">Chapter Name DE:</label>
            <input type="text" id="section-name-de" v-model="section.title.de.title" placeholder="Chapter Name DE">
          </div>
        </div>
        <div class="two-cols">
          <div class="field">
            <label for="section-description-en">Chapter Description EN:</label>
            <textarea id="section-description-en" v-model="section.description.en.description" placeholder="Chapter Description EN"></textarea>
          </div>
          <div class="field">
            <label for="section-description-de">Chapter Description DE:</label>
            <textarea id="section-description-de" v-model="section.description.de.description" placeholder="Chapter Description DE"></textarea>
          </div>
        </div>

        <button class="save-btn transition" @click="editSectionHandler()">
          Update
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditSection',
    mixins: [knowledgeMixin],
    components: {
      Popup
    },
    props: {
      item: Object,
      sectionData: Object
    },
    data() {
      return {
        section: this.item
      }
    },
    methods: {
      editSectionHandler() {
        const dataToSend = {
          titleData: this.section.title,
          descriptionData: this.section.description,
          status: 1
        }
        this.editSection(this.sectionData.courseId, this.sectionData.moduleId, this.sectionData.sectionId, dataToSend)
          .then(() => {
            this.$emit('updated')
          })
      }
    }
  }
</script>

<style scoped>

</style>