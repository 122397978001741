import App from './App'
import { createApp } from 'vue'

const app = createApp(App);

// Vue router
  import routes from './router'
  import { createRouter, createWebHistory } from 'vue-router'
    
  const router = createRouter({
    history: createWebHistory(),
    routes
  });

  app.use(router);
  
// Vuex
  import store from './store.js'
  import { createStore } from 'vuex'
  
  const storeInstance = createStore(store);
  app.use(storeInstance);
  
// Vue 3 Datepicker
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  
  app.component('Datepicker', Datepicker);
// Vue 3 Typeahead
  import SimpleTypeahead from 'vue3-simple-typeahead';
  
  app.use(SimpleTypeahead);
  
// Url for images from S3 server
  app.config.globalProperties.$s3Url = 'https://cdn.umushroom.com/static/';
  //app.config.globalProperties.$s3Data = 'https://umushroom.s3.eu-central-1.amazonaws.com/data/';
  app.config.globalProperties.$s3Data = 'https://cdn.umushroom.com/data/';

// Vue Cookies
  import VueCookies from 'vue3-cookies'
  app.use(VueCookies);

// Vue click away
  import VueClickAway from "vue3-click-away";
  app.use(VueClickAway);

// Gantt charts
  import ganttastic from '@infectoone/vue-ganttastic'
  app.use(ganttastic);

// Font Awesome
  import { library, config } from '@fortawesome/fontawesome-svg-core'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  import { fas } from '@fortawesome/free-solid-svg-icons'
  import { far } from '@fortawesome/free-regular-svg-icons'
  import { fab } from '@fortawesome/free-brands-svg-icons'

  // This is important, we are going to let Nuxt.js worry about the CSS
  config.autoAddCss = true

  // You can add your icons directly in this plugin. See other examples for how you
  // can add other styles or just individual icons.
  library.add(fas)
  library.add(fab)
  library.add(far)

  // Register the component globally
  app.component('font-awesome-icon', FontAwesomeIcon)

// Country Flags
  import CountryFlag from 'vue-country-flag-next'
  app.component('country-flag', CountryFlag)

// Apex Charts
  import VueApexCharts from 'vue3-apexcharts'
  app.use(VueApexCharts)
// Global filters
  app.config.globalProperties.$filters = {
    formatDate(dateInput) {
      // * Parse the date
      let date = new Date(dateInput);
      let dateString = '';

      // * Get the day and add 0 if less than 10
      if (date.getDate() < 10) {
        dateString += '0' + date.getDate() + '.';
      } else {
        dateString += date.getDate() + '.';
      }
      
      // * Get the month and add 0 if less than 10

      // * Add to parsed month 1 because in JS months start from 0 :)
      let realMonth = +date.getMonth() + 1;
      if (realMonth < 10) {
        dateString += '0' + realMonth + '.';
      } else {
        dateString += realMonth + '.';
      }

      // * Get the year
      dateString += date.getFullYear();

      return dateString;
    },
    formatDateTime(dateInput, format) {
      // * Parse the date
      let date = new Date(dateInput);
      let dateString = '';
  
      if (format === 'custom') {
        // Format as requested: "2024-04-05 12:09:03"
        dateString += date.getFullYear() + '-';
        
        // * Get the month and add 0 if less than 10
        let month = (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        dateString += month + '-';
    
        // * Get the day and add 0 if less than 10
        let day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
        dateString += day + ' ';
    
        // * Get the hour and add 0 if less than 10
        let hour = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours();
        dateString += hour + ':';
    
        // * Get the minutes and add 0 if less than 10
        let minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes();
        dateString += minutes + ':';
    
        // * Get the seconds and add 0 if less than 10
        let seconds = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();
        dateString += seconds;
      } else {
        // Default format: "05.04.2024 | 3:09:03 PM"
        // * Get the day and add 0 if less than 10
        if (date.getDate() < 10) {
          dateString += '0' + date.getDate() + '.';
        } else {
          dateString += date.getDate() + '.';
        }
        
        let realMonth = +date.getMonth() + 1;
        if (realMonth < 10) {
          dateString += '0' + realMonth + '.';
        } else {
          dateString += realMonth + '.';
        }
    
        // * Get the year
        dateString += date.getFullYear();
    
        let time = new Date(date).toLocaleTimeString();
    
        dateString += ' | ';
        dateString += time;
      }
  
      return dateString;
    }
  }
// Global Filters end

app.mount('#app');
