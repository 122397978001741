<template>
  <popup
    title="Edit quiz"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content" v-if="quiz">
        <div class="two-cols">
          <div class="field">
            <label for="quiz-name-en">Quiz Name EN:</label>
            <TextEditor 
              :value="quiz.quizName.en.name" 
              @input="quiz.quizName.en.name = $event"
            />
          </div>
          <div class="field">
            <label for="quiz-name-de">Quiz Name DE:</label>
            <TextEditor 
              :value="quiz.quizName.de.name" 
              @input="quiz.quizName.de.name = $event"
            />
          </div>
        </div>

        <div class="two-cols">
          <div class="field">
            <label for="quiz-name-en">Quiz Start Message EN:</label>
            <TextEditor 
              :value="quiz.quizName.en.startMessage" 
              @input="quiz.quizName.en.startMessage = $event"
            />
          </div>
          <div class="field">
            <label for="quiz-name-de">Quiz Start Message DE:</label>
            <TextEditor 
              :value="quiz.quizName.de.startMessage" 
              @input="quiz.quizName.de.startMessage = $event"
            />
          </div>
        </div>

        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="quiz.isPublic">
            <span class="checkmark"></span>
            Is Public
          </label>
        </div>

        <button class="save-btn transition" @click="updateQuiz()" :disabled="buttonLoading">
          <font-awesome-icon v-if="buttonLoading" icon="spinner" spin />
          <template v-else>
            Update
          </template>
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import quizMixin from '@/mixins/quiz'

  import Popup from '@/components/shared/Popup'
  import TextEditor from '../elements/TextEditor.vue'

  export default {
    name: 'NewQuiz',
    mixins: [quizMixin],
    components: {
      Popup,
      TextEditor
    },
    props: {
      quizId: Number
    },
    data() {
      return {
        quiz: null,
        buttonLoading: false
      }
    },
    methods: {
      updateQuiz() {
        this.buttonLoading = true;
        this.editQuiz(this.quizId, this.quiz)
          .then(() => {
            this.$emit('update-quizzes')
            this.buttonLoading = false;
          })
      },
      getQuizHandler() {
        this.getQuiz(this.quizId)
          .then(res => {
            this.quiz = res.quiz;
          })
      }
    },
    mounted() {
      this.getQuizHandler()
    }
  }
</script>

<style scoped>

</style>