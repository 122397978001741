<template>
  <!-- Portfolio Orders Page -->
  <div class="equity-historical-data-page">
    <h1>Portfolio Orders <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit', 'delete']"
      page="Portfolio Orders"
      @update-table="getOrdersHandler"
      @edit="editHandler"
      @delete="deleteHandler"
    />

    <!-- Inspect Popup -->
    <InspectOrderDetails
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
    />

    <DeleteOrder
      v-if="deleteItem"
      :item="deleteItem"
      @close="deleteItem = null"
      @deleted="deletedHandler"
    />
  </div>
</template>

<script>
  import portfoliosMixin from '@/mixins/portfolios'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectOrderDetails from './popups/InspectOrderDetails'
  import DeleteOrder from './popups/DeleteOrder'

  export default {
    name: 'PortfolioOrders',
    mixins: [portfoliosMixin, userMixin],
    components: {
      DataTableHolder,
      InspectOrderDetails,
      DeleteOrder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'User ID',
            name: 'userId',
            sortable: true
          },
          {
            title: 'Portfolio Owner',
            name: 'portfolioOwner',
            sortable: true
          },
          {
            title: 'Owner Email',
            name: 'email',
            sortable: true
          },
          {
            title: 'Portfolio ID',
            name: 'portfolioId',
            sortable: true
          },
          {
            title: 'Portfolio Name',
            name: 'portfolioName',
            sortable: true
          },
          {
            title: 'Security ISIN',
            name: 'securityIsin',
            sortable: true
          },
          {
            title: 'Security Name',
            name: 'securityName',
            sortable: true
          },
          {
            title: 'Security Type',
            name: 'securityType',
            sortable: true
          },
          {
            title: 'Value',
            name: 'orderValue',
            sortable: true
          },
          {
            title: 'Units',
            name: 'orderUnits',
            sortable: true
          },
          {
            title: 'Adjustable Until',
            name: 'orderAdjustableUntil',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Active Comment',
            name: 'activeComment',
            sortable: true
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null,
        deleteItem: null
      }
    },
    methods: {
      // * Get orders from API
      getOrdersHandler(data) {
        this.getOrders(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let orders = [];

            // let typesArr = ['Balance change', 'Trade', 'Income', 'Order']
            // * Loop through orders and add only the needed properties
            for (const order of res.orders) {
              let orderObj = {
                id: order.id,
                userId: order.userId,
                portfolioOwner: order.userFullName || order.organizationName,
                email: order.email,
                portfolioId: order.portfolioId,
                portfolioName: order.portfolioName,
                securityIsin: order.securityIsin,
                securityName: order.securityName,
                securityType: order.securityTypeName,
                orderValue: order.orderValue,
                orderUnits: order.orderUnits,
                orderAdjustableUntil: order.orderAdjustableUntil,
                addedOn: order.addedOn,
                activeComment: order.activeComment,
              }
              // * Add formatted order to formatted orders array
              orders.push(orderObj);
            }
            // * Assign table rows to be the formatted orders array
            this.rows = orders;
            this.fullData = res.orders;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      editHandler(item) {
        this.editItem = this.fullData.find(order => {
          return order.id == item.id;
        })
      },
      deleteHandler(item) {
        this.deleteItem = item;
      },
      deletedHandler() {
        this.deleteItem = null;
        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>

</style>