<template>
  <popup
    title="Delete FAQ"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <img :src="$s3Url + 'svgs/icons/delete-x.svg'">
        <h4>Are you sure?</h4>
        <p>Are you sure you want to permenantly delete this FAQ <strong>"{{item.languages.en.faq}}"</strong>?</p>
        <div class="field">
          <label for="confirm">Delete Confirmation:</label>
          <input type="text" id="confirm" autocomplete="off" placeholder='Type "CONFIRM" to be able to delete the order' v-model="confirm">
        </div>
        <button class="delete-btn transition" :disabled="confirm != 'CONFIRM'" @click="deleteHandler()">
          Delete
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'DeleteFaq',
    mixins: [systemMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        confirm: null
      }
    },
    methods: {
      deleteHandler() {
        this.deleteFaq(this.item.id)
          .then(() => {
            this.$emit('deleted');
          })
      }
    }
  }
</script>

<style scoped>
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup-content img {
    margin-top: 100px;
  }

  .popup-content h4 {
    color: #171725;
    font-size: 32px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 130px;
  }

  .popup-content p {
    color: #696974;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
  }

  .field {
    width: 100%;
  }

  @media (max-width: 1550px) {
    .popup-content img {
      margin-top: 30px;
    }

    .popup-content h4 {
      margin-bottom: 30px;
    }
  }
</style>