<template>
  <popup
    title="Participant Answers"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <ol class="answers">
        <li
          v-for="(answer, index) in answers"
          :key="index"
        >
          <strong>{{answer.question}}</strong>
          <p
            v-for="(subAnswer, subIndex) in answer.answer"
            :key="subIndex"
          >{{subAnswer}}</p>
        </li>
      </ol>
    </template>
  </popup>
</template>

<script>
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'ParticipantAnswers',
    components: {
      Popup
    },
    props: {
      answers: Array
    }
  }
</script>

<style scoped>
  .answers {
    padding-left: 20px;
    margin-top: 40px;
    font-size: 18px;
  }

  .answers li {
    margin-bottom: 20px;
  }

  .answers p {
    margin: 0;
    margin-top: 5px;
  }
</style>