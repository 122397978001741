<template>
  <popup
    title="Add new Chapter"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <div class="two-cols">
          <div class="field">
            <label for="section-name-en">Chapter Name EN:</label>
            <input type="text" id="section-name-en" v-model="section.titleData.en.title" placeholder="Chapter Name EN">
          </div>
          <div class="field">
            <label for="section-name-de">Chapter Name DE:</label>
            <input type="text" id="section-name-de" v-model="section.titleData.de.title" placeholder="Chapter Name DE">
          </div>
        </div>
        <div class="two-cols">
          <div class="field">
            <label for="section-description-en">Chapter Description EN:</label>
            <textarea id="section-description-en" v-model="section.descriptionData.en.description" placeholder="Chapter Description EN"></textarea>
          </div>
          <div class="field">
            <label for="section-description-de">Chapter Description DE:</label>
            <textarea id="section-description-de" v-model="section.descriptionData.de.description" placeholder="Chapter Description DE"></textarea>
          </div>
        </div>

        <button class="save-btn transition" @click="createSectionHandler()">
          Create
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewSection',
    mixins: [knowledgeMixin],
    components: {
      Popup
    },
    props: {
      courseId: Number,
      moduleId: Number
    },
    data() {
      return {
        section: {
          titleData: {
            en: {
              title: null
            },
            de: {
              title: null
            }
          },
          descriptionData: {
            en: {
              description: null
            },
            de: {
              description: null
            }
          }
        }
      }
    },
    methods: {
      createSectionHandler() {
        const dataToSend = {
          ...this.section,
          status: 1
        }
        this.createSection(this.courseId, this.moduleId, dataToSend)
          .then(() => {
            this.$emit('created')
          })
      }
    }
  }
</script>

<style scoped>

</style>