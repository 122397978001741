<template>
  <!-- Edit Glossary popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Glossary"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label for="glossaryEn">Glossary EN:</label>
        <input type="text" id="glossaryEn" v-model="glossaryEn" placeholder="Glossary EN...">
      </div>
      <div class="field">
        <label for="glossaryDe">Glossary DE:</label>
        <input type="text" id="glossaryDe" v-model="glossaryDe" placeholder="Glossary DE...">
      </div>

      <div class="field">
        <label for="answerEn">Answer EN:</label>
        <textarea v-model="answerEn" id="answerEn" placeholder="Answer EN..."></textarea>
      </div>
      <div class="field">
        <label for="answerDe">Answer DE:</label>
        <textarea v-model="answerDe" id="answerDe" placeholder="Answer DE..."></textarea>
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete" v-if="category">
        <label>Glossary category:</label>
        <vue3-simple-typeahead
          placeholder="Type glossary category..."
          :items="glossaryCategories"
          :minInputLength="1"
          :itemProjection="(item) => item.languages.en.name"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="display">
          <span class="checkmark"></span>
          Display
        </label>
      </div>

      <button class="transition save-btn" @click="updateGlossaryHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditGlossary',
    mixins: [systemMixin, userMixin],
    components: {
      Popup
    },
    props: {
      glossaryCategories: Array,
      item: Object
    },
    data() {
      return {
        glossaryEn: this.item.languages.en.glossaryEntry,
        glossaryDe: this.item.languages.de.glossaryEntry,
        answerEn: this.item.languages.en.glossaryEntryAnswer,
        answerDe: this.item.languages.de.glossaryEntryAnswer,
        category: null,
        display: this.item.display,
        updateLoading: false
      }
    },
    methods: {
      // * Update Glossary item
      updateGlossaryHandler() {
        this.updateLoading = true;
        const dataToSend = {
          categoryId: this.category ? this.category.id : null,
          display: this.display,
          languages: {
            en: {
              glossaryEntry: this.glossaryEn,
              glossaryEntryAnswer: this.answerEn
            },
            de: {
              glossaryEntry: this.glossaryDe,
              glossaryEntryAnswer: this.answerDe
            }
          }
        }

        this.updateGlossary(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated')
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user selects category from the autocomplete. Updates the category
      submit(result) {
        this.category = result;
      },
      // * Nulls category if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.category = this.glossaryCategories.find(category => {
            return category.id == this.item.categoryId;
          });
        }
      }
    },
    mounted() {
      // * If item has category set it on component load
      if (this.item.categoryId) {
        this.category = this.glossaryCategories.find(category => {
          return category.id == this.item.categoryId;
        })
      }
    }
  }
</script>

<style scoped>
  
</style>
