<template>
  <!-- Add new style popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit style"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Style:</label>
        <input type="text" id="name" v-model="item.style" placeholder="Style name...">
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.other">
          <span class="checkmark"></span>
          Other
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.equity">
          <span class="checkmark"></span>
          Equity
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.bond">
          <span class="checkmark"></span>
          Bond
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.multiAsset">
          <span class="checkmark"></span>
          Multi-Asset
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.moneyMarket">
          <span class="checkmark"></span>
          Money-Market
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.commodity">
          <span class="checkmark"></span>
          Commodity
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.crypto">
          <span class="checkmark"></span>
          Crypto
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.property">
          <span class="checkmark"></span>
          Property
        </label>
      </div>

      <button class="transition save-btn" @click="editStyleHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundUmbrellaStyle',
    mixins: [fundsMixin, userMixin],
    components: {
      Popup
    },
    props: {
      styleItem: Object
    },
    data() {
      return {
        updateLoading: false,
        item: {...this.styleItem}
      }
    },
    methods: {
      // * Triggers on save button click and creates style
      editStyleHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          style: this.item.style,
          other: this.item.other,
          equity: this.item.equity,
          bond: this.item.bond,
          multiAsset: this.item.multiAsset,
          moneyMarket: this.item.moneyMarket,
          commodity: this.item.commodity,
          crypto: this.item.crypto,
          property: this.item.property
        }

        this.editUmbrellaStyle(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      }
    }
  }
</script>

<style scoped>
  
</style>
