<template>
  <footer>
    <span class="copyright">© Copyright 2020 - {{currentYear}}. All Rights Reserved.</span>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {
        // * Get current year
        currentYear: new Date().getFullYear()
      }
    }
  }
</script>

<style scoped>
  footer {
    text-align: center;
    background: #3C6E71;
    padding: 10px 0;
    margin-top: 100px;
  }

  .copyright {
    font-size: 16px;
    color: #FFF;
  }
</style>