<template>
  <!-- Edit Umbrella Fund Category Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Umbrella Fund Category"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Umbrella <small>(not editable)</small>:</label>
        <input type="text" id="UmbrellaName" v-model="fundUmbrella.UmbrellaName" placeholder="UmbrellaName" disabled>
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Fund Category:</label>
        <vue3-simple-typeahead
          placeholder="Type fund category..."
          :items="fundCategories"
          :minInputLength="1"
          :itemProjection="(item) => item.fundCategoryName"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="(fundUmbrella.fundCategory || fundUmbrella.fundCategory == 0) && !selectedFundCategory">
          Cannot remove Fund Category, will be set to Other
        </span>
      </div>

      <a v-if="fundUmbrella.randomFund" :href="'https://umushroom.com/fund/' + fundUmbrella.randomFund.fundUrlName" target="_blank">Go to fund from this umbrella</a>

      <button class="transition save-btn" @click="updateFundUmbrellaFundCategoryHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundUmbrellaFundCategory',
    mixins: [fundsMixin, userMixin],
    props: {
      item: Object,
      fundCategories: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        selectedFundCategory: null,
        updateLoading: false,
        fundUmbrella: this.item
      }
    },
    methods: {
      // * Triggers on save button click and updates umbrella
      updateFundUmbrellaFundCategoryHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          fundCategory: this.fundUmbrella.FundCategory
        }
        
        // * If user has selected Fund Category from the list add it to the request data, else send 0 "Other"
        if (this.selectedFundCategory) {
          dataToSend.fundCategory = this.selectedFundCategory.fundCategory;
        } else {
          dataToSend.fundCategory = 0;
        }

        this.editFundUmbrella(this.fundUmbrella.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      // * Triggered when user selects Fund Category from the autocomplete. Updates the selected Fund Category
      submit(result) {
        this.selectedFundCategory = result;
      },
      // * Set Category to 0, Other, if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedFundCategory = 0;
        }
      }
    },
    mounted() {
      // * If fundUmbrella has Fund Category set it on component load, otherwise set it to 0, Other, if any nulls are found
      if (this.fundUmbrella.fundCategory) {
        this.selectedFundCategory = {
          fundCategory: this.fundUmbrella.fundCategory,
          fundCategoryName: this.fundUmbrella.fundCategoryName
        }
      }
      else {
        this.selectedFundCategory = {
          fundCategory: "0",
          fundCategoryName: "Other"
        }
      }
    }
  }
</script>

<style scoped>
  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
</style>
