<template>
  <!-- Equity Candidates Page -->
  <div class="equity-candidates-page">
    <h1>Equity Candidates <small v-if="total">({{total}})</small></h1>
    <button class="export-btn" @click="exportFile()">ISIN Export</button>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getEquitiesHandler"
      @edit="editHandler" 
    />

    <!-- Inspect Popup -->
    <InspectCandidateEquity
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import equitiesMixin from '@/mixins/equities'
  import userMixin from '@/mixins/user'
  import exportFromJSON from 'export-from-json'
  
  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectCandidateEquity from "@/components/equities/popups/InspectCandidateEquity.vue";

  export default {
    name: 'CandidateEquities',
    mixins: [equitiesMixin, userMixin],
    components: {
      InspectCandidateEquity,
      DataTableHolder,
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'companyName',
            sortable: true
          },
          {
            title: 'Valor',
            name: 'valor',
            sortable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Currency',
            name: 'currencyCode',
            sortable: true
          },
          {
            title: 'Market Cap USD',
            name: 'marketCapUsd',
            sortable: true
          },
          {
            title: 'Sentifi OK',
            name: 'sentifyOk',
            sortable: true
          },
          {
            title: 'Factset Company ID',
            name: 'factsetCompanyId',
            sortable: true
          },
          {
            title: 'Factset Entity ID',
            name: 'factsetEntityId',
            sortable: true
          },
          {
            
            title: 'Admin Comment',
            name: 'adminComment',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null
      };
    },
    methods: {
      // * Get equities from API
      getEquitiesHandler(data) {
        this.getEquityCandidates(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let equitiesFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const equity of res.equities) {
              let marketCapRounded = Math.round(equity.marketCapUsd);

              let equityObj = {
                id: equity.id,
                name: equity.companyName,
                valor: equity.valor,
                isin: equity.isin,
                currencyCode: equity.currencyCode,
                marketCapUsd: marketCapRounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                sentifyOk: equity.sentifyOk,
                factsetCompanyId: equity.factsetCompanyId,
                factsetEntityId: equity.factsetEntityId,
                adminComment: equity.adminComment,
                addedOn: equity.addedOn
              }
              // * Add formatted equity to formatted equities array
              equitiesFormatted.push(equityObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = equitiesFormatted;
            this.fullData = res.equities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(equity => {
          return equity.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      // * Format date as per FactSet FTP file naming requirement
      formatDate(dateInput) {
        // * Parse the date
        let date = new Date(dateInput);
        let dateString = '';

        // * Get the year
        dateString += date.getFullYear();

        // * Get the month and add 0 if less than 10

        // * Add to parsed month 1 because in JS months start from 0 :)
        let realMonth = +date.getMonth() + 1;
        if (realMonth < 10) {
          dateString += '0' + realMonth;
        } else {
          dateString += realMonth;
        }
        
        // * Get the day and add 0 if less than 10
        if (date.getDate() < 10) {
          dateString += '0' + date.getDate();
        } else {
          dateString += date.getDate();
        }

        return dateString;
      },
      // * Generate an Excel which can be FTPed as is to FactSet
      exportFile() {
        this.getAllActiveAndCandidateEquityISINs()
        .then(res => {
          const data = res.equities;
          const fileName = "UMU_UNIVERSE_" + this.formatDate(new Date());
          const exportType = exportFromJSON.types.csv;
          if (data) exportFromJSON({ data, fileName, exportType });
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
      }
    }
  }
</script>

<style scoped>
  .export-btn {
    background: #3c6e71;
    color: #FFF;
    border: none;
    font-weight: 600;
    height: 40px;
    width: 120px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    display: block;
    margin: 0 0 25px auto;
  }

  .export-btn:hover {
    background: #FFF;
    color: #3c6e71;
  }

</style>