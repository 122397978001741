<template>
  <!-- Add new old url name popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
      title="Add new old url name"
      @close="$emit('close')"
  >
    <template v-slot:content>

      <!-- Old Url Name -->
      <div class="field">
        <label for="old-url-name">Old Url Name:</label>
        <input type="text" id="old-url-name" v-model="oldUrlName" placeholder="Old url name...">
      </div>

      <!-- Object ID -->
      <div class="field">
        <label for="object-id">Object ID:</label>
        <input type="text" id="object-id" v-model="objectId" placeholder="Object ID...">
      </div>

      <!-- Object Type -->
      <div class="field">
        <label for="object-type">Object Type:</label>
        <input type="text" id="object-type" v-model="objectType" placeholder="Object type...">
      </div>

      <!-- Add button -->
      <button class="transition save-btn" @click="addOldUrlNameHandler()" :disabled="addLoading">
        <template v-if="!addLoading">
          Add Old Url Name
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

    </template>
  </popup>
</template>

<script>
import oldUrlNamesMixin from '@/mixins/oldUrlNames.vue';
import userMixin from '@/mixins/user'

import Popup from '@/components/shared/Popup'

export default {
  name: 'NewFundUmbrellaSector',
  mixins: [oldUrlNamesMixin, userMixin],
  components: {
    Popup
  },
  data() {
    return {
      oldUrlName: '',
      objectId: null,
      objectType: null,
      addLoading: false
    }
  },
  methods: {
    addOldUrlNameHandler() {
      this.addLoading = true;
      const dataToSend = {
        oldUrlName: this.oldUrlName,
        objectId: this.objectId,
        objectType: this.objectType,
      }

      this.createOldUrlName(dataToSend)
          .then(() => {
            this.addLoading = false;
            this.$emit('created');
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
            this.addLoading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
