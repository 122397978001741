<template>
  <popup
    title="Edit instrument right"
    @close="$emit('close')"
  >
    <template v-slot:content>
      
      <div class="field" v-if="selectedUser">
        <label for="user">User:</label>
        <input type="text" id="user" v-model="selectedUser.email" disabled>
      </div>

      <div class="field">
        <label>Role Type:</label>
        <div class="custom-select" :class="{open: roleTypeSelect}" @click="roleTypeSelect = !roleTypeSelect" v-click-away="roleTypeAway">
          <span v-if="selectedRoleType">{{selectedRoleType.name}}</span>
          <span v-else>Select Role Type</span>
          <div class="select-dropdown" v-if="roleTypeSelect">

            <a
                @click="selectRoleType('Admin', 1)"
            >
              Admin
            </a>
            <a
                @click="selectRoleType('Editor', 2)"
            >
              Editor
            </a>
            <a
                @click="selectRoleType('Portfolio Manager', 3)"
            >
              Portfolio Manager
            </a>
            <a
                @click="selectRoleType('Employee', 4)"
            >
              Employee
            </a>
            <a
                @click="selectRoleType('Client', 5)"
            >
              Client
            </a>
            <a
                @click="selectRoleType('Member', 6)"
            >
              Member
            </a>
            <a
                @click="selectRoleType('Portfolio Follower', 7)"
            >
              Portfolio Follower
            </a>
            <a
                @click="selectRoleType('Prospect', 9)"
            >
              Prospect
            </a>
          </div>
        </div>
      </div>
      
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isVisible">
          <span class="checkmark"></span>
          Is Visible
        </label>
      </div>
      
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="menuItem">
          <span class="checkmark"></span>
          Menu Item
        </label>
      </div>

      <button class="transition save-btn" @click="editRightHandler()" :disabled="updateLoading || !selectedInstrumentType || !instrumentId || !selectedRoleType">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditInstrumentRight',
    mixins: [usersMixin, userMixin],
    components: {
      Popup
    },
    props: {
      right: Object,
      users: Array
    },
    data() {
      return {
        instrumentTypeSelect: false,
        selectedInstrumentType: null,
        instrumentTypes: [
          'Fund Umbrella',
          'Equity',
          'Community Group',
          'Wealth Manager',
          'Fund Group',
          'Fund Advisor',
          'Portfolio',
          'Broker',
          'Contest',
          'Author',
          'Collection',
        ],
        instrumentId: this.right.instrumentId,
        roleTypeSelect: false,
        selectedRoleType: null,
        isVisible: this.right.isVisible,
        menuItem: this.right.menuItem,
        updateLoading: false,
        selectedUser: null,
        allTypes: [],
        canBeAdmin: [],
        canBeEditor: [],
        canBePortfolioManager: [],
        canBeEmployee: [],
        canBeClient: [],
        canBeMember: [],
        canBePortfolioFollower: [],
        canBeProspect: [],
      }
    },
    methods: {
      editRightHandler() {
        this.updateLoading = true;
        const dataToSend = {
          isVisible: this.isVisible,
          menuItem: this.menuItem,
          role: this.selectedRoleType.id
        }

        this.editInstrumentRight(this.right.id, dataToSend)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err == 401) {
              this.logout();
            }
          })
      },
      instrumentTypeAway() {
        this.instrumentTypeSelect = false;
      },
      roleTypeAway() {
        this.roleTypeSelect = false;
      },
      selectInstrumentType(type) {
        this.selectedInstrumentType = type;
      },
      selectRoleType(name, id) {
        this.selectedRoleType = {
          name,
          id
        }
      }
    },
    mounted() {

      // Filter what selection for what type
      this.allTypes = ['Investment Fund', 'Equity', 'Community Group', 'Wealth Manager', 'Asset Manager', 'Portfolio', 'Broker', 'Contest', 'Author', 'Collection'];
      // Just remove the corresponding type from all types if you don't want it to appear in a dropdown for a given type:
      this.canBeAdmin = this.allTypes;
      this.canBeEditor = this.allTypes;
      this.canBePortfolioManager = this.allTypes.filter(item => !['Asset Manager', 'Community Group'].includes(item));
      this.canBeEmployee = this.allTypes.filter(item => !['Asset Manager', 'Community Group'].includes(item));
      this.canBeClient = this.allTypes.filter(item => !['Asset Manager', 'Community Group'].includes(item));
      this.canBeMember = this.allTypes.filter(item => !['Asset Manager', 'Community Group'].includes(item));
      this.canBePortfolioFollower = this.allTypes.filter(item => !['Asset Manager', 'Community Group'].includes(item));
      this.canBeProspect = this.allTypes;
      // to make the drop downs more meaningful, add this line above each @click in the def on menu items above
      // v-if="canBeAdmin.includes(selectedInstrumentType)"
            
      // * Select initial instrument type
        switch (this.right.instrumentType) {
          case 'InvestmentFund':
            this.selectedInstrumentType = 'Investment Fund'
            break;
          case 'Equity':
            this.selectedInstrumentType = 'Equity'
            break;
          case 'CommunityGroup':
            this.selectedInstrumentType = 'Community Group'
            break;
          case 'WealthManager':
            this.selectedInstrumentType = 'Wealth Manager'
            break;
          case 'AssetManager':
            this.selectedInstrumentType = 'Asset Manager'
            break;
          case 'Portfolio':
            this.selectedInstrumentType = 'Portfolio'
            break;
          case 'Broker':
            this.selectedInstrumentType = 'Broker'
            break;
          case 'Contest':
            this.selectedInstrumentType = 'Contest'
            break;
          case 'Author':
            this.selectedInstrumentType = 'Author'
            break;
          case 'Collection':
            this.selectedInstrumentType = 'Collection'
            break;
        }
      // * Select initial instrument type END

      // * Select initial role type
        if (this.right.rights == 1) {
          this.selectedRoleType = {
            name: 'Admin',
            id: 1
          }
        } else if (this.right.rights == 2) {
          this.selectedRoleType = {
            name: 'Editor',
            id: 2
          }
        } else if (this.right.rights == 3) {
          this.selectedRoleType = {
            name: 'Portfolio Manager',
            id: 3
          }
        } else if (this.right.rights == 4) {
          this.selectedRoleType = {
            name: 'Employee',
            id: 4
          }
        } else if (this.right.rights == 5) {
          this.selectedRoleType = {
            name: 'Client',
            id: 5
          }
        } else if (this.right.rights == 6) {
          this.selectedRoleType = {
            name: 'Member',
            id: 6
          }
        } else if (this.right.rights == 7) {
          this.selectedRoleType = {
            name: 'Portfolio Follower',
            id: 7
          }
        } else if (this.right.rights == 9) {
          this.selectedRoleType = {
            name: 'Prospect',
            id: 9
          }
        }
      // * Select initial role type END

      this.selectedUser = this.users.find(user => {
        return user.id == this.right.userId;
      })
    }
  }
</script>

<style scoped>
  
</style>
