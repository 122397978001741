<template>
  <div class="knowledge-section">
    <h1 v-if="section">{{section.title.en.title}}</h1>
    <div class="new-item">
      <button class="transition" @click="newSessionPopup = true">
        <img class="transition" :src="$s3Url + 'svgs/icons/plus.svg'">
        <span>New Lecture</span>
      </button>
    </div>

    <div class="sessions">
      <SessionItem
        v-for="session in sessions"
        :key="session.id"
        :courseId="+courseId"
        :moduleId="+moduleId"
        :sectionId="+sectionId"
        :session="session"
        @delete-session="deleteSessionHandler"
      />
    </div>

    <NewSession
      v-if="newSessionPopup"
      :courseId="+courseId"
      :moduleId="+moduleId"
      :sectionId="+sectionId"
      @close="newSessionPopup = false"
      @created="newSessionPopup = false; getSectionHandler()"
    />

    <EditSession
      v-if="editItem"
      :item="editItem"
      :editData="editData"
      @close="editItem = null; editData = null;"
      @updated="editItem = null; editData = null; getSectionHandler()"
    />

    <DeleteSession
      v-if="deleteData"
      :deleteData="deleteData"
      @close="deleteData = null"
      @deleted="deleteData = null; getSectionHandler()"
    />
  </div>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import SessionItem from './SessionItem'
  import NewSession from './popups/NewSession'
  import EditSession from './popups/EditSession'
  import DeleteSession from './popups/DeleteSession'

  export default {
    name: 'KnowledgeSection',
    mixins: [knowledgeMixin],
    components: {
      SessionItem,
      NewSession,
      EditSession,
      DeleteSession
    },
    data() {
      return {
        courseId: this.$route.params.course,
        moduleId: this.$route.params.module,
        sectionId: this.$route.params.section,
        newSessionPopup: false,
        section: null,
        sessions: null,
        editItem: null,
        editData: null,
        deleteData: null
      }
    },
    methods: {
      getSectionHandler() {
        this.getSection(this.courseId, this.moduleId, this.sectionId)
          .then(res => {
            this.section = res.section;
            this.sessions = res.sessions;
          })
      },
      deleteSessionHandler(sessionId) {
        this.deleteData = {
          courseId: this.courseId,
          moduleId: this.moduleId,
          sectionId: this.sectionId,
          sessionId
        }
      }
    },
    mounted() {
      this.getSectionHandler();
    }
  }
</script>

<style scoped>
  .new-item button {
    display: flex;
    align-items: center;
    background: #FFF;
    color: #3c6e71;
    border: 1px solid #3c6e71;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }

  .new-item button:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .new-item button img {
    margin-right: 5px;
    filter: invert(41%) sepia(8%) saturate(2190%) hue-rotate(135deg) brightness(89%) contrast(82%);
  }

  .new-item button:hover img {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(105%) contrast(104%);
  }

  .sessions {
    margin-top: 50px;
  }
</style>