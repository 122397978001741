<template>
    <!-- Edit Advisor Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Financial Partner"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Name :</label>
        <input type="text" id="name" v-model="manager.name" placeholder="Name" >
      </div>

      <!-- Preferred Name field -->
      <div class="field">
        <label for="preferredName">Preferred Name:</label>
        <input type="text" id="preferredName" v-model="manager.preferredName" placeholder="Preferred Name">
      </div>

      <span class="remove-warning" v-if="manager.preferredName === ''">
        Preferred Name cannot be blank
      </span>

      <!-- Url Name field -->
      <div class="field">
        <label for="urlName">URL:</label>
        <input type="text" id="urlName" v-model="manager.urlName" placeholder="URL">
      </div>


      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPaid">
          <span class="checkmark"></span>
          Is Paid
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isVisible">
          <span class="checkmark"></span>
          Is Visible
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isDeleted">
          <span class="checkmark"></span>
          Is Deleted (will only mask WM-related data on main app)
        </label>
      </div>

      <button class="transition save-btn" @click="updateManagerHandler()" :disabled="updateLoading
              || (manager.preferredName === '')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

    </template>
  </popup>
</template>

<script>
  import organizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditWealthManagerNew',
    mixins: [organizationsMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        updateLoading: false,
        manager: {...this.item},
        isPaid: null,
        isVisible: null,
        isDeleted: null,
      }
    },
    methods: {
      // * Triggers on save button click and updates advisor
      updateManagerHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          name: this.manager.name,
          preferredName: this.manager.preferredName,
          urlName: this.manager.urlName,
          isPaid: this.isPaid,
          isVisible: this.isVisible,
          isDeleted: this.isDeleted,
        }

        this.editOrganization(this.manager.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      }
    },

    mounted() {
      this.isPaid = this.manager.isPaid;
      this.isVisible = this.manager.isVisible;
      this.isDeleted = this.manager.isDeleted;
    },

    watch: {
      isDeleted(newVal) {
        if (newVal) {
          this.isVisible = false;
        }
      },

      isVisible(newVal) {
        if (newVal) {
          this.isDeleted = false;
        }
      },
    },
  }
</script>

<style scoped>

</style>