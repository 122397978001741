<template>
  <!-- Asset Managers Urls Page -->
  <div class="asset-managers-page">
    <h1>Asset Managers<small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getAssetManagersUrlsHandler"
      @edit="editHandler"
    />

    <EditAssetManagerUrl
            v-if="editItem"
            :item="editItem"
            @close="editItem = null"
            @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import organizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditAssetManagerUrl from './popups/EditAssetManagerUrl'

  export default {
    name: 'AssetManagers',
    mixins: [organizationsMixin, userMixin],
    components: {
      DataTableHolder,
      EditAssetManagerUrl
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Preferred Name',
            name: 'preferredName',
            sortable: true
          },
          {
            title: 'Group',
            name: 'isFundGroup',
            sortable: false
          },
          {
            title: 'Advisor',
            name: 'isFundAdvisor',
            sortable: false
          },
          {
            title: 'Is AM',
            name: 'isAssetManager',
            sortable: true
          },
          {
            title: 'Is Paid',
            name: 'isPaid',
            sortable: true
          },
          {
            title: 'Is Visible',
            name: 'isVisible',
            sortable: true
          },
          {
            title: 'URL Name',
            name: 'urlName',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'createdOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        total: null,
        fullData: null
      }
    },
    methods: {
      // * Get fund umbrellas from API
      getAssetManagersUrlsHandler(data) {
        data.overwriteOrganizationType = [0, 1];
        this.getOrganizationsPaginated(data, 1)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.total = res.resultsCount;
            // * Choose which fields to be visible in the datatable
            let organizations = [];
            // * Loop through organizations and add only the needed properties
            for (const organization of res.organizations) {
              let organizationObj = {
                id: organization.id,
                name: organization.name,
                preferredName: organization.preferredName,
                isFundGroup: (organization.type == 0),
                isFundAdvisor: (organization.type == 1),
                isAssetManager: organization.isAssetManager,
                isPaid: organization.isPaid,
                isVisible: organization.isVisible,
                urlName: organization.urlName,
                createdOn: organization.createdOn,
                updatedOn: organization.updatedOn
              }
              // * Add formatted organization to formatted organizations array
              organizations.push(organizationObj);
            }
            // * Assign table rows to be the formatted orders array
            this.rows = organizations;
            this.fullData = res.organizations;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },

      editHandler(item) {
        this.editItem = this.fullData.find(row => {
          return row.id == item.id;
        });
      },
      updatedHandler() {
        this.editItem = null;

        this.updateResults = !this.updateResults;
      }
    },
        mounted() {
      // * On component (page) load get the fund groups for the autocomplete field in the edit popup
      this.getAllOrganizations(0)
        .then(res => {
          this.fundGroups = res.organizations;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>


</style>