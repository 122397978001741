<script>
  import axios from 'axios'

  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // * Quiz
        getQuizResults() {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz-results`, {
              method: 'GET'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        getQuizzes() {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quizzes`, {
              method: 'GET'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        getQuiz(quizId) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/${quizId}`, {
              method: 'GET'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        newQuiz(data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz`, {
              method: 'POST',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        editQuiz(quizId, data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/${quizId}`, {
              method: 'PATCH',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        deleteQuiz(quizId) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/${quizId}`, {
              method: 'DELETE'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
      // * Questions
        getQuestion(questionId) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/question/${questionId}`, {
              method: 'GET'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        newQuestion(data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/question`, {
              method: 'POST',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        editQuestion(questionId, data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/question/${questionId}`, {
              method: 'PATCH',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        deleteQuestion(questionId) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/question/${questionId}`, {
              method: 'DELETE'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
      // * Quiz Images
        getImages() {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/media`, {
              method: 'GET'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
      // * Results
        getQuizParticipants(id, data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/all-submissions/${id}/participants`, {
              method: 'POST',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        getParticipantAnswers(submissionId) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/submission/${submissionId}/answers`, {
              method: 'GET'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        getQuizSummary(id, data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/all-submissions/${id}/summary`, {
              method: 'POST',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        getQuizDemographicData(id, data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/all-submissions/${id}/demographics`, {
              method: 'POST',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        getQuizSummaryQuestion(question, data) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/question-submissions/${question}/results`, {
              method: 'POST',
              data
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        },
        deleteQuizSubmission(submissionId) {
          return new Promise((resolve, reject) => {
            axios(`${this.$store.state.global.apiUrl}/quiz-project/quiz/submission/${submissionId}`, {
              method: 'DELETE'
            })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.code);
              })
          })
        }
    }
  }
</script>
