<template>
  <popup
      title="New contest group"
      @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="name" ref="onlyInput" placeholder="Name">
      </div>

      <div class="field">
        <label for="urlName">URL:</label>
        <input type="text" id="urlName" v-model="urlName" placeholder="URL">
        <p v-if="!urlName" class="error-message">URL must not be empty!</p>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isVisible">
          <span class="checkmark"></span>
          Is Visible
        </label>
      </div>

      <div class="field autocomplete">
        <label>Admin user:</label>
        <vue3-simple-typeahead
            placeholder="Type user email..."
            :items="users"
            :minInputLength="1"
            :itemProjection="(item) => item.email"
            @selectItem="submit"
            @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
      </div>

      <button class="transition save-btn" @click="createGroupHandler()" :disabled="!urlName || updateLoading">
        <template v-if="!updateLoading">
          Add
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </template>
  </popup>
</template>

<script>
import organizationsMixin from "@/mixins/organizations.vue";
import usersMixin from "@/mixins/users.vue";
import userMixin from '@/mixins/user'

import Popup from '@/components/shared/Popup'

export default {
  name: 'NewContest',
  mixins: [organizationsMixin, userMixin, usersMixin],
  components: {
    Popup
  },
  data() {
    return {
      name: null,
      urlName: null,
      isPaid: false,
      teamStatus: 0,
      type: 5,
      isAssetManager: false,
      isVisible: false,
      founderId: null,
      manager1Message: null,
      manager2Message: null,
      updateLoading: false,
      users : [],
      selectedUser : null,
      errorMessage: null,
    }
  },
  methods: {
    createGroupHandler() {
      this.updateLoading = true;
      this.errorMessage = null;
      const dataToSend = {
        name: this.name,
        urlName: this.urlName,
        preferredName: this.preferredName,
        type: this.type,
        isPaid: this.isPaid,
        teamStatus: this.teamStatus,
        isAssetManager: this.isAssetManager,
        isVisible: this.isVisible,
        userId: this.selectedUser.id,
        founderId: this.founderId,
        manager1Message: this.manager1Message,
        manager2Message: this.manager2Message
      }

      this.createOrganization(dataToSend)
          .then(() => {
            this.$emit('created');
            this.updateLoading = false;
          })
          .catch(err => {
            this.errorMessage = err;
            if (err === 401) {
              this.logout();
            }
            this.updateLoading = false;
          });
    },
    // * Triggered when user selects user from the autocomplete. Updates the selected user
    submit(result) {
      this.selectedUser = result;
    },
    // * Nulls user if user deletes the autocomplete field content
    keyUpEvent(value) {
      if (!value.input || value.input.length == 0) {
        this.selectedUser = null;
      }
    },
    slugify(text) {
      const charMap = {
        // German characters
        'ä': 'ae', 'ö': 'oe', 'ü': 'ue', 'ß': 'ss',
        // French characters
        'à': 'a', 'â': 'a', 'ç': 'c', 'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e',
        'î': 'i', 'ï': 'i', 'ô': 'o', 'ù': 'u', 'û': 'u', 'ÿ': 'y',
        // Spanish characters
        'á': 'a', 'í': 'i', 'ñ': 'n', 'ó': 'o', 'ú': 'u',
        // Portuguese characters
        'ã': 'a', 'õ': 'o',
        // Italian character
        'ò': 'o',
        // Nordic characters
        'å': 'a', 'ø': 'o',
        // Slavic characters
        'č': 'c', 'ć': 'c', 'đ': 'd', 'š': 's', 'ž': 'z', 'ł': 'l',
        // Romanian characters
        'ă': 'a', 'ș': 's', 'ț': 't',
        // Turkish characters
        'ğ': 'g', 'ı': 'i', 'ş': 's',
      };
      return text.toString().toLowerCase()
          .split('').map(char => charMap[char] || char).join('')
          .replace(/\s+/g, '-')     // Replace spaces with -
          .replace(/[^\w-]+/g, '') // Remove all non-word chars
          .replace(/--+/g, '-')   // Replace multiple - with single -
          .replace(/^-+/, '')       // Trim - from start of text
          .replace(/-+$/, '');      // Trim - from end of text
    },
  },

  mounted() {
    this.getUsers()
        .then(res => {
          this.users = res.users;
        })
        .catch(err => {
          if (err.code == 401) {
            this.logout();
          }
        });

  },

  watch: {
    // Watch for changes in name and update urlName accordingly
    name(newValue) {
      this.urlName = this.slugify(newValue);
    }
  },
}
</script>

<style scoped>

</style>