<template>
  <!-- Commands Schedule Page -->
  <div class="commands-schedule-page">
    <h1>Commands Schedule <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit', 'new']"
      @update-table="getCommandsScheduleHandler"
      @edit="editHandler"
      @add-new="addNewHandler"
    />

    <!-- New Popup -->
    <NewCommandSchedule
      v-if="newCommandSchedulePopup"
      :commands="commands"
      @close="newCommandSchedulePopup = false"
      @added="updatedHandler"
    />

    <!-- Edit Popup -->
    <EditCommandSchedule
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewCommandSchedule from './popups/NewCommandSchedule'
  import EditCommandSchedule from './popups/EditCommandSchedule'

  export default {
    name: 'CommandsSchedule',
    mixins: [systemMixin, userMixin],
    components: {
      DataTableHolder,
      NewCommandSchedule,
      EditCommandSchedule
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Command',
            name: 'command',
            sortable: true
          },
          {
            title: 'Command Short Description',
            name: 'commandShortDesc',
            sortable: true
          },
          {
            title: 'Micro Service',
            name: 'microService',
            sortable: true
          },
          {
            title: 'Schedule',
            name: 'schedule',
            sortable: true
          },
          {
            title: 'Status',
            name: 'status',
            sortable: true
          },
          {
            title: 'Last Run',
            name: 'lastRun',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        newCommandSchedulePopup: false,
        commands: null,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get commands from API
      getCommandsScheduleHandler(data) {
        this.getCommandsSchedulePaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let rowsToAssign = [];
            let schedules = res.schedules;
            schedules.forEach(schedule => {
              let obj = {
                id: schedule.id,
                command: schedule.command,
                commandShortDesc: schedule.commandShortDesc,
                microService: schedule.microService,
                schedule: schedule.schedule,
                status: schedule.status,
                lastRun: schedule.lastRun
              };

              rowsToAssign.push(obj);
            })
            this.rows = rowsToAssign;
            this.fullData = res.schedules;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      addNewHandler() {
        this.newCommandSchedulePopup = true;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(schedule => {
          return schedule.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.newCommandSchedulePopup = false;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      this.getCommands()
        .then(res => {
          this.commands = res.commands;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>


</style>