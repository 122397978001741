<template>
  <!-- Fund Group Consolidations Page -->
  <div class="fund-group-consolidations-page">
    <h1>Fund Group Consolidations <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getFundGroupsHandler"
      @edit="editHandler"
    />

    <!-- Edit Popup -->
    <EditFundGroupConsolidation
      v-if="editItem"
      :item="editItem"
      :fundGroups="fundGroups"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundGroupConsolidation from './popups/EditFundGroupConsolidation'

  export default {
    name: 'GroupConsolidations',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundGroupConsolidation
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Name Consolidated Id',
            name: 'nameConsolidatedId',
            sortable: true
          },
          {
            title: 'Name Consolidated',
            name: 'consolidatedName',
            sortable: true
          },
          {
            title: 'Is Paid',
            name: 'isPaid',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        fundGroups: null,
        updateResults: true,
        total: null
      }
    },
    methods: {
      // * Get fund groups from API
      getFundGroupsHandler(data) {
        this.getFundGroupsPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let groupsFormatted = [];
            // * Loop through groups and add only the needed properties
            for (const group of res.fundGroups) {
              let groupObj = {
                id: group.id,
                name: group.name,
                nameConsolidatedId: group.nameConsolidatedId,
                consolidatedName: group.consolidatedName,
                isPaid: group.isPaid,
                addedOn: group.addedOn,
                updatedOn: group.updatedOn,
              }
              // * Add formatted group to formatted groups array
              groupsFormatted.push(groupObj);
            }
            // * Assign table rows to be the formatted groups array
            this.rows = groupsFormatted;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      // * On component (page) load get the fund groups for the autocomplete field in the edit popup
      this.getAllFundGroups()
        .then(res => {
          this.fundGroups = res.fundGroups;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>

</style>