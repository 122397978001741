<template>
  <div>
    <apexchart v-if="showChart" width="100%" :options="chartOptions" :series="series" height="700"></apexchart>
  </div>
</template>

<script>
import quizMixin from "@/mixins/quiz";

export default {
  name: "QuizSubmissionsByNationalityBar",
  mixins: [quizMixin],
  props: {
    demographicData: Array,
  },
  data() {
    return {
      showChart: false,
      series: [{ data: [] }],
      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
          },
        },
        xaxis: {},
        yaxis: {},
        legend: {
          show: false,
        },
        tooltip: {
          y: {
            formatter: function ($val) {
              return $val;
            },
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    if (this.demographicData) {
      let maxTotalSubmissions = Math.max(...this.demographicData.map((data) => data.nationality_count));
      this.chartOptions.yaxis.max = maxTotalSubmissions;

      let otherCount = 0;
      for (const data of this.demographicData) {
        if (data.nationality_count < 5) {
          otherCount += data.nationality_count;
          continue;
        }
        this.series[0].data.push({
          x: data.nationality,
          y: data.nationality_count,
        });
      }
      
      if (otherCount > 0) {
        this.series[0].data.push({
          x: "Other",
          y: otherCount,
        });
      }

      this.showChart = true;
    }
  },
};
</script>

<style scoped>
</style>
