<template>

  <popup
    title="Edit Tag"
    @close="$emit('close')"
    >

    <template v-slot:content>

      <div class="field">
        <label for="preferredName">English Tag:</label>
        <input type="text" v-model="this.tag.enName">
      </div>

      <div class="field">
        <label for="preferredName">German Tag:</label>
        <input type="text" v-model="this.tag.deName">
      </div>

      <div class="field">
        <label for="preferredName">Tag URL:</label>
        <input type="text" v-model="this.tag.urlName">
      </div>

      <div class="field">
        <label for="toBeMerged">Merge Tag INTO <strong>{{tagNamePlaceholder}}</strong>:</label>
        <input type="text" id="toBeMerged" v-model="toBeMerged" placeholder="ID of the one to be merged...">
      </div>

      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

      <div v-if="requiresConfirmation" :class="{'warning-message': true, 'orange': confirm === 'CONFIRM'}">
        This action is irreversible! Make sure the ID you want to merge is the right one.
      </div>

      <div class="field" v-if="requiresConfirmation">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder="Type 'CONFIRM'..." v-model.trim="confirm">
      </div>

      <button class="transition save-btn" @click="updateTagHandler()" :disabled="updateLoading || (requiresConfirmation && confirm !=='CONFIRM')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

    </template>


  </popup>
</template>

<script>

import organizationsMixin from '@/mixins/organizations.vue';
import userMixin from '@/mixins/user.vue';
import Popup from "@/components/shared/Popup.vue";
import tagsMixin from "@/mixins/tags.vue";

export default {
  name: 'EditTag',
  mixins: [organizationsMixin, userMixin, tagsMixin],
  props: {
    item: Object
  },
  components: {
    Popup
  },

  data() {
    return {
      updateLoading: false,
      tag: {...this.item},
      tagNamePlaceholder: this.item.enName,
      toBeMerged: null,
      errorMessage: null,
      requiresConfirmation: false,
      confirm: null,
    }
  },

  methods: {
    updateTagHandler() {
      this.updateLoading = true;
      const dataToSend = {
        name: this.tag.enName,
        deName: this.tag.deName,
        urlName: this.tag.urlName,
        toBeMerged: this.toBeMerged,
      };
      this.editTag(dataToSend, this.tag.id)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },
  },

  watch: {
    toBeMerged(newVal) {
      if (newVal) {
        this.requiresConfirmation = true;
      } else {
        this.requiresConfirmation = false;
        this.confirm = null;
      }
    }
  },
}

</script>

<style scoped>

.warning-message {
  background-color: #ffcdd2;
  color: #900c3f;
  border: 1px solid #e5c0c4;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.warning-message.orange {
  background-color: #ffe6cc;
  color: #843534;
  border-color: #f0b4aa;
}

</style>