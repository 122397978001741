<template>
  <div class="equity-chart-holder">
    <div class="top-part">
      <h5 class="title">
        <span v-if="item.companyName">{{item.companyName}}</span>
        <span v-else>{{item.fundName}}</span>

        <template v-if="type == 'fund'"> (NAV in {{currency}})</template>
        <template v-else-if="type == 'equity'"> (Closing price in {{currency}})</template>
      </h5>
      <div class="filters relative" v-if="windowWidth > 991">
        <div class="filter-selector transition" :style="{left: filterSelector.left + 'px', width: filterSelector.width + 'px'}"></div>
        <a
          v-for="(period, index) in graphData.periods"
          :key="index"
          class="transition filter-item"
          :class="{active: period == graphData.default}"
          @click="selectFilter($event, period)"
        >{{period}}</a>
      </div>
      <div class="filters-drop relative" v-if="windowWidth <= 991">
        <div class="drop-value" :class="{open: filterDrop}" @click="filterDrop = !filterDrop">
          <span class="value">{{selectedFilter}}</span>
          <font-awesome-icon icon="caret-down" class="transition" :class="{rotate: filterDrop}" />
        </div>
        <div class="dropdown" v-if="filterDrop">
          <a
            v-for="(period, index) in graphData.periods"
            :key="index"
            @click="selectFilter($event, period)"
            class="transition"
          >{{period}}</a>
        </div>
      </div>
    </div>
    <div class="chart" v-if="currency">
      <line-chart :chartData="chartDataComp" :height="240" :chartOptions="options" />
    </div>
  </div>
</template>

<script>
  import LineChart from './LineChart'

  export default {
    name: 'ChartBox',
    components: {
      LineChart
    },
    props: {
      graphData: Object,
      item: Object,
      currency: String,
      type: String
    },
    data() {
      return {
        windowWidth: window.innerWidth,
        selectedFilter: this.graphData.default,
        filterDrop: false,
        filterSelector: {
          left: 243,
          width: 104
        },
        chartData: {
          labels: this.graphData.datasets[this.graphData.default].labels,
          datasets: [
            {
              data: this.graphData.datasets[this.graphData.default].datasets.data,
              backgroundColor: 'transparent',
              borderColor: '#3C6E71',
              borderWidth: window.innerWidth>767 ? 3 : 1,
              pointBackgroundColor: 'transparent',
              pointBorderColor: 'transparent',
              pointBorderWidth: 0,
              tension: 0.4
            }
          ]
        },
        options: {
          scales: {
            y: {
              ticks: {
                font: {
                  family: 'Poppins',
                  size: 14
                },
                color: '#92929D',
                padding: 20,
                autoSkip: true,
                maxTicksLimit: 6
              },
              grid: {
                display: false
              }
            },
            x: {
              ticks: {
                font: {
                  family: 'Poppins',
                  size: 14
                },
                color: '#92929D',
                padding: 20,
                autoSkip: true,
                maxTicksLimit: 10
              },
              grid: {
                display: window.innerWidth>767 ? true : false,
                drawBorder: false,
                color: '#F1F1F5',
                lineWidth: 2
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          plugins: {
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              titleColor: '#3C6E71',
              titleFont: {
                family: 'Poppins',
                size: 14
              },
              bodyColor: '#3C6E71',
              bodyFont: {
                family: 'Poppins',
                size: 14
              },
              backgroundColor: '#FFF',
              borderColor: '#3C6E71',
              borderWidth: 1,
              callbacks: {
                label: (tooltipItems) => {
                  return `${this.currency} ` + +tooltipItems.parsed.y.toFixed(2);
                }
              }
            }
          }
        },
        datasetProps: {
          backgroundColor: 'transparent',
          borderColor: '#3C6E71',
          borderWidth: window.innerWidth>767 ? 3 : 1,
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointBorderWidth: 0,
          lineTension: 0.4
        }
      }
    },
    methods: {
      selectFilter(event, filter) {
        const elements = Array.from(document.querySelectorAll('.filters a'));
        for (const element of elements) {
          element.classList.remove('active')
        }
        event.target.classList.add('active')
        this.filterSelector.width = event.target.offsetWidth;
        this.filterSelector.left = event.target.offsetLeft;

        this.selectedFilter = event.target.innerHTML;
        this.filterDrop = false;

        this.chartData = {
          labels: this.graphData.datasets[filter].labels,
          datasets: [
            {
              data: this.graphData.datasets[filter].datasets.data,
              ...this.datasetProps
            }
          ]
        }
      },
      setInitialSelectedFilter() {
        let element = document.querySelector('.filter-item.active');
        this.filterSelector.width = element.offsetWidth;
        this.filterSelector.left = element.offsetLeft;
      },
      onResize() {
        this.windowWidth = window.innerWidth;
      }
    },
    computed: {
      chartDataComp() {
        return this.chartData;
      }
    },
    mounted() {
      setTimeout(() => {
        this.setInitialSelectedFilter()
      }, 100);
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },
    beforeUnmount() { 
      window.removeEventListener('resize', this.onResize); 
    }
  }
</script>

<style scoped>
  .equity-chart-holder {
    margin-top: 20px;
  }

  .top-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .title {
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    max-width: 100%;
    margin: 0;
  }

  .title span {
    text-transform: uppercase;
  }

  .filters {
    margin-top: 20px;
    border: 1px solid #F1F1F5;
    border-radius: 8px;
  }

  .filter-selector {
    position: absolute;
    height: 100%;
    background-color: #3C6E71;
    border-radius: 8px;
  }

  .filters a {
    font-size: 14px;
    color: #696974;
    padding: 5px 20px;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  
  .filters a.active {
    color: #FFF;
  }

  @media (max-width: 1550px) {
    .title {
      font-size: 16px;
    }
  }

  @media (max-width: 991px) {
    .filters-drop {
      width: 110px;
    }
    
    .filters-drop .drop-value {
      background: #3C6E71;
      color: #FFF;
      padding: 5px 10px;
      border-radius: 8px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .filters-drop .drop-value.open {
      border-radius: 8px 8px 0 0;
    }

    .filters-drop .drop-value span {
      margin-right: 10px;
    }

    .filters-drop .drop-value svg.rotate {
      transform: rotate(180deg);
    }

    .filters-drop .dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: #3C6E71;
      color: #FFF;
      border-radius: 0 0 8px 8px;
    }

    .filters-drop .dropdown a {
      display: block;
      font-size: 14px;
      padding: 5px 10px;
    }
  }

  @media (max-width: 767px) {
    .title {
      font-size: 16px;
    }
  }

  @media (max-width: 340px) {
    .title {
      font-size: 14px;
    }

    .filters-drop .drop-value span {
      width: max-content;
    }

    .filters-drop .dropdown a {
      font-size: 12px;
    }
  }
</style>
