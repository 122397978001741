<template>
  <!-- Fund Umbrella Characteristics Page -->
  <div class="fund-umbrella-characteristics-page">
    <h1>Fund Umbrella Characteristics <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      :columnSearch="true"
      @update-table="getFundUmbrellaCharacteristicsHandler"
      @edit="editHandler"
    />

    <EditFundUmbrellaCharacteristics
      v-if="editItem"
      :item="editItem"
      :fundCategories="fundCategories"
      :sectors="sectors"
      :styles="styles"
      :geographies="geographies"
      @updated="umbrellaUpdatedHandler"
      @close="editItem = null"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundUmbrellaCharacteristics from './popups/EditFundUmbrellaCharacteristics'

  export default {
    name: 'FundUmbrellaCharacteristics',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundUmbrellaCharacteristics
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Umbrella',
            name: 'UmbrellaName',
            sortable: true
          },
          {
            title: 'HF Strategy',
            name: 'hasHedgeFundStrategy',
            sortable: true
          },
          {
            title: 'EFC Category (from feed)',
            name: 'mainEFCCategory',
            sortable: true
          },
          {
            title: 'Fund Category ID',
            name: 'fundCategory',
            sortable: true
          },
          {
            title: 'Fund Category',
            name: 'fundCategoryName',
            sortable: false
          },
          {
            title: 'EFC Classification',
            name: 'activeEFCClassification',
            sortable: true
          },
          // {
          //   title: 'Sector ID',
          //   name: 'sectorId',
          //   sortable: true
          // },
          {
            title: 'Sector',
            name: 'sector',
            sortable: true
          },
          // {
          //   title: 'Style ID',
          //   name: 'styleId',
          //   sortable: true
          // },
          {
            title: 'Style',
            name: 'style',
            sortable: true
          },
          // {
          //   title: 'Geography ID',
          //   name: 'geographyId',
          //   sortable: true
          // },
          {
            title: 'Geography',
            name: 'geography',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'AddedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'UpdatedOn',
            sortable: true
          }
        ],
        editItem: null,
        updateResults: true,
        addStylePopup: false,
        pages: 0,
        sectors: [],
        styles: [],
        geographies: [],
        fundCategories: null,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get fund umbrella characteristics from API
      getFundUmbrellaCharacteristicsHandler(data) {
        this.getAllFundUmbrellaCharacteristics(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let rowsToAssign = [];
            res.fundUmbrellas.forEach(umbrella => {
              let obj = {
                id: umbrella.id,
                UmbrellaName: umbrella.UmbrellaName,
                hasHedgeFundStrategy: umbrella.hasHedgeFundStrategy,
                mainEFCCategory: umbrella.mainEFCCategory,
                fundCategory: umbrella.fundCategory,
                fundCategoryName: umbrella.fundCategoryName,
                activeEFCClassification: umbrella.activeEFCClassification,
                // sectorId: umbrella.sectorId,
                sector: umbrella.sector,
                // styleId: umbrella.styleId,
                style: umbrella.style,
                // geographyId: umbrella.geographyId,
                geography: umbrella.geography,
                AddedOn: umbrella.AddedOn,
                UpdatedOn: umbrella.UpdatedOn
              };

              rowsToAssign.push(obj);
            })
            this.rows = rowsToAssign;
            this.fullData = res.fundUmbrellas;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Add new sector handler. Triggered when user click the "+ New" button
      addNewHandler() {
        this.addStylePopup = true;
      },
      styleCreatedHandler() {
        this.addStylePopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(umbrella => {
          return umbrella.id == item.id
        });
      },
      // * Triggered when user updates item from the edit popup
      umbrellaUpdatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      getAllCharsOfUmbrellas() {
        this.getAllFundUmbrellaSectors()
          .then(res => {
            this.sectors = res.sectors;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
        this.getAllFundUmbrellaStyles()
          .then(res => {
            this.styles = res.styles;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
        this.getAllFundUmbrellaGeographies()
          .then(res => {
            this.geographies = res.geographies;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      }
    },
    mounted() {
      this.getAllCharsOfUmbrellas();
      // * Put the Fund Categories in a list for the autocomplete field in the edit popup
      this.fundCategories = [
          {
              fundCategory: "0",
              fundCategoryName: "Other"
          },
          {
              fundCategory: "1",
              fundCategoryName: "Equity"
          },
          {
              fundCategory: "2",
              fundCategoryName: "Bond"
          },
          {
              fundCategory: "3",
              fundCategoryName: "Multi-Asset"
          },
          {
              fundCategory: "4",
              fundCategoryName: "Money Market"
          },
          {
              fundCategory: "5",
              fundCategoryName: "Commodity"
          }
      ];
    }
  }
</script>

<style scoped>

</style>
