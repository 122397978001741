<template>
  <div class="user-performance-chart">
    <div class="filters-holder" v-if="!this.startDate && !this.endDate">
      <div class="date-filter">
        <Datepicker v-model="dates" range multi-calendars :enable-time-picker="false" />
        <button class="green-btn filter-btn" @click="getUserChartInfoHandler(null)">Filter</button>
      </div>
      <!-- <div class="filters">
        <a :class="{active: activeFilter == 7}" @click="getUserChartInfoHandler(7)">7 Days</a>
        <a :class="{active: activeFilter == 30}" @click="getUserChartInfoHandler(30)">1 Month</a>
        <a :class="{active: activeFilter == 365}" @click="getUserChartInfoHandler(365)">1 Year</a>
      </div> -->
    </div>
    <apexchart
      v-if="showChart"
      width="100%"
      height="600px"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
  import marketingMixin from '@/mixins/marketing'
  import datesMixin from '@/mixins/dates'

  export default {
    name: 'UserPerformanceChart',
    mixins: [marketingMixin, datesMixin],
    props: {
      user: Object,
      type: String,
      startDate: String,
      endDate: String
    },
    data() {
      return {
        performanceData: null,
        showChart: false,
        dates: null,
        activeFilter: 7,
        series: [
          {
            name: 'Guest Visits',
            type: 'column',
            data: []
          },
          {
            name: 'PF Visits',
            type: 'column',
            data: []
          },
          {
            name: 'EF Visits',
            type: 'column',
            data: []
          },
          {
            name: 'Public Followers',
            type: 'line',
            data: []
          },
          {
            name: 'Exclusive Followers',
            type: 'line',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            events: {
              dataPointSelection: this.markerClickHandler
            }
          },
          title: {
            text: 'Visitors',
            style: {
              fontSize: '20px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color:  '#171725'
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5
            },
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              style: {
                fontFamily: 'Poppins',
                fontWeight: 500
              }
            }
          },
          yaxis: [
            {
              seriesName: 'Guest Visits',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#3C6E71'
              },
              labels: {
                style: {
                  colors: '#3C6E71',
                  fontFamily: 'Poppins',
                  fontWeight: 500
                }
              },
              title: {
                text: 'Visits per day',
                style: {
                  color: '#3C6E71',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: 14
                }
              }
            },
            {
              show: false,
              seriesName: 'Guest Visits',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#C1A16F'
              },
              labels: {
                style: {
                  colors: '#C1A16F',
                }
              },
              title: {
                text: "Verified Users",
                style: {
                  color: '#C1A16F',
                }
              }
            },
            {
              show: false,
              seriesName: 'Guest Visits',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#003E52'
              },
              labels: {
                style: {
                  colors: '#003E52',
                }
              },
              title: {
                text: "Premium Users",
                style: {
                  color: '#003E52',
                }
              }
            },
            {
              seriesName: 'Total Public Followers',
              opposite: true,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: '#000'
              },
              labels: {
                style: {
                  colors: '#000',
                  fontFamily: 'Poppins',
                  fontWeight: 500
                }
              },
              title: {
                text: 'Followers',
                style: {
                  color: '#000',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: 14
                }
              }
            },
            {
              show: false,
              seriesName: 'Total Public Followers',
              opposite: true,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: '#000'
              },
              labels: {
                style: {
                  colors: '#000',
                  fontFamily: 'Poppins',
                  fontWeight: 500
                }
              },
              title: {
                text: 'Public Followers',
                style: {
                  color: '#000',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: 14
                }
              }
            }
          ],
          colors:['#3C6E71', '#C1A16F', '#61F1F9', '#003E52', '#000', '#6f94af'],
        }
      }
    },
    methods: {
      getUserChartInfoHandler(daysBehind) {
        this.showChart = false;
        const dataToSend = {};

        if (this.startDate && this.endDate) {
          dataToSend.fromDate = this.formatDateDashes(this.startDate);
          dataToSend.toDate = this.formatDateDashes(this.endDate);
        } else if (daysBehind) {
          this.activeFilter = daysBehind;

          let yesterday = this.getDateBeforeDays(1);
          dataToSend.toDate = this.formatDateDashes(yesterday);

          let fromDate = this.getDateBeforeDays(daysBehind);
          dataToSend.fromDate = this.formatDateDashes(fromDate);
        } else {
          dataToSend.fromDate = this.formatDateDashes(this.dates[0]);
          dataToSend.toDate = this.formatDateDashes(this.dates[1]);
        }

        this.getUserChartInfo(dataToSend, this.type, this.user.id)
          .then(res => {
            this.performanceData = res.performanceData.performance;
            this.chartOptions.xaxis.categories = [];
            this.series[0].data = [];
            this.series[1].data = [];
            this.series[2].data = [];
            this.series[3].data = [];
            this.series[4].data = [];

            res.performanceData.performance.forEach(obj => {
              this.chartOptions.xaxis.categories.push(obj.date);
              this.series[0].data.push(obj.visitorsGuest);
              this.series[1].data.push(obj.visitorsPublicFollower);
              this.series[2].data.push(obj.visitorsExclusiveFollower);
              this.series[3].data.push(obj.totalPublicFollowers || 0);
              this.series[4].data.push(obj.totalExclusiveFollowers || 0);
            });

            this.$emit('data-received', res.performanceData)
            this.showChart = true;
          })
      },
      markerClickHandler(event, chartContext, config) {
        const dataToSend = {
          series: config.seriesIndex,
          data: this.performanceData[config.dataPointIndex]
        }
        
        this.$emit('marker-selected', dataToSend);
      }
    },
    mounted() {
      this.getUserChartInfoHandler(7);
    }
  }
</script>

<style scoped>
  .filters-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .date-filter {
    display: flex;
    align-items: center;
  }

  .filter-btn {
    height: 34px;
    width: 70px;
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
  }

  .filters {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  .filters a {
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
  }

  .filters a.active {
    color: #FFF;
    background: #3C6E71;
    border-radius: 8px;
  }
</style>
