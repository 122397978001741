<script>
import axios from "axios";

if (localStorage.getItem("x-auth-token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("x-auth-token");
}

export default {
  methods: {
    getFileNamesByType(type) {
      return new Promise((resolve, reject) => {
        axios(
          `${this.$store.state.global.apiUrl}/backoffice/translations/file-names/${type}`,
          {
            method: "GET",
          }
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data.code);
          });
      });
    },
    getTranslationsByFileAndType(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/translations`, {
          method: "POST",
          data: data,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    updateTranslation(id, data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/translation/${id}`, {
          method: "PATCH",
          data: data,
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error.response.data);
        });
      });
    },
    importTranslationFile(data) {
      let headers = {
        'Content-Type': 'multipart/form-data'
      };

      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/translations/import`, {
          method: "POST",
          data: data,
          headers: headers
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    exportTranslationFile(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/translations/export`, {
          method: "POST",
          data: data,
          responseType: "blob",
        })
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = data.fileName;
          if (contentDisposition) {
          // Extract filename from content-disposition header
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) { 
              fileName = matches[1].replace(/['"]/g, '');
            }
          }

          // Create blob and download link
          const blob = new Blob([response.data], {
            type: "application/yaml",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;

          // Trigger download
          document.body.appendChild(link);
          link.click();

          // Cleanup
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          resolve();
        })
        .catch((error) => {
          reject(error.response.data);
        });
      });
    },
  },
};
</script>
