<script>
  import axios from 'axios'  

  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // * Format float numbers (if < 10 with 2 decimal places, if > 10 && < 100 with 1 decimal place, if > 100 with no decimal places)
      formatPercentDecimals(percent) {
        let percentNumber;
        if (typeof percent == 'string') {
          percentNumber = +percent.replaceAll(',', '');
        } else {
          percentNumber = +percent;
        }

        let returnValue;
        if (percentNumber >= 0) {
          if (percentNumber < 10) {
            returnValue = percentNumber.toFixed(2);
          } else if (percentNumber < 100) {
            returnValue = percentNumber.toFixed(1);
          } else {
            returnValue = percentNumber.toFixed(0);
          }
        } else {
          if (percentNumber > -10) {
            returnValue = percentNumber.toFixed(2);
          } else if (percentNumber > -100) {
            returnValue = percentNumber.toFixed(1);
          } else {
            returnValue = percentNumber.toFixed(0);
          }
        }

        return returnValue.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
</script>
