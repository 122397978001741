<template>
  <popup
      title="Edit contest group"
      @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="group.name" placeholder="Name">
      </div>

      <div class="field">
        <label for="url-name">URL Name:</label>
        <input type="text" id="url-name" v-model="group.urlName" placeholder="URL Name">
      </div>

      <div class="field mb-40">
        <label class="custom-check green">
          <input type="checkbox" v-model="group.isVisible">
          <span class="checkmark"></span>
          Is Visible
        </label>
      </div>

      <div class="field mb-40">
        <label class="custom-check green">
          <input type="checkbox" v-model="group.isOpen">
          <span class="checkmark"></span>
          Is Open
        </label>
      </div>

      <button class="transition save-btn" @click="updateGroupHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

      <!--      <span class="error-message" v-if="error">-->
      <!--        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />-->
      <!--        {{error}}-->
      <!--      </span>-->
    </template>
  </popup>
</template>

<script>
import organizationsMixin from "@/mixins/organizations.vue";
import userMixin from '@/mixins/user'
import Popup from '@/components/shared/Popup'

export default {
  name: 'EditContest',
  mixins: [organizationsMixin, userMixin],
  props: {
    item: Object
  },
  components: {
    Popup
  },
  data() {
    return {
      updateLoading: false,
      group: this.item,
      // error: null
    }
  },
  methods: {
    updateGroupHandler() {
      this.updateLoading = true;
      const dataToSend = {
        name: this.group.name,
        urlName: this.group.urlName,
        isVisible: this.group.isVisible,
        isOpen: this.group.isOpen,
      }

      this.editOrganization(this.group.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>