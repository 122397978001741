<template>
  <popup title="Inspect Contest User" @close="$emit('close')">
    <template v-slot:content>
      <div v-for="(section, sectionId) in organizedResults" :key="sectionId" class="section">
        <h3 @click="toggleSection(sectionId)">
          {{ section.section_title_data.en.title }}
        </h3>
        <div v-if="section.expanded" class="sessions">
          <div v-for="session in section.sessions" :key="session.session_id" class="session">
            <h4 @click="toggleSession(sectionId, session.session_id)">
              {{ session.session_title_data.en.title }}
            </h4>
            <div v-if="session.expanded" class="session-details">
              <table>
                <tr>
                  <th>Status</th>
                  <th>Points</th>
                  <th>Started On</th>
                  <th>Completed On</th>
                  <th>Duration</th>
                </tr>
                <tr>
                  <td>{{ session.status !== null ? session.status : '-' }}</td>
                  <td>{{ session.points !== null ? session.points : '-' }}</td>
                  <td>{{ session.started_on !== null ? session.started_on : '-' }}</td>
                  <td>{{ session.completed_on !== null ? session.completed_on : '-' }}</td>
                  <td>{{ session.duration !== null ? session.duration : '-' }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </popup>
</template>



<script>
import usersMixin from '@/mixins/users'
import userMixin from '@/mixins/user'
import Popup from '@/components/shared/Popup'

export default {
  name: 'InspectContestUser',
  mixins: [usersMixin, userMixin],
  props: {
    item: Object
  },
  components: {
    Popup
  },
  data() {
    return {
      updateLoading: false,
      user: {...this.item},
      organizedResults: [],
    }
  },
  methods: {
    fetchUserPerformancePerSectionAndPerSession() {
      this.getUserPerformancePerSectionAndPerSession(this.user.userId)
          .then(res => {
            // console.log('Fetched data:', res.organizedResults);
            this.organizedResults = this.prepareData(res.organizedResults);
            // console.log('Processed data:', this.organizedResults);
          })
          .catch(err => {
            if (err.code === 401) {
              this.logout();
            }
          })
    },

    prepareData(data) {
      for (const sectionId in data) {
        data[sectionId].expanded = false;
        for (const session of data[sectionId].sessions) {
          session.expanded = false;
        }
      }
      return data;
    },

    toggleSection(sectionId) {
      this.organizedResults[sectionId].expanded = !this.organizedResults[sectionId].expanded;
    },

    toggleSession(sectionId, sessionId) {
      const session = this.organizedResults[sectionId].sessions.find(s => s.session_id === sessionId);
      if (session) {
        session.expanded = !session.expanded;
      }
    }
  },

  mounted() {
    this.fetchUserPerformancePerSectionAndPerSession();
  }
}
</script>


<style scoped>
/* Existing Styles */
.section {
  background-color: #f8f9fa;
  border: 1px solid #e3f2fd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px; /* Space between sections */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section h3 {
  cursor: pointer;
  color: #3c6e71;
  margin: 0 0 10px 0; /* Space above the section title */
}

.sessions .session {
  background-color: #ffffff;
  border: 1px solid #e3f2fd;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px; /* Space between sessions */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sessions .session h4 {
  cursor: pointer;
  color: #3c6e71;
  margin: 0; /* Adjust as needed */
}

.session-details table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: 10px;
}

.session-details th, .session-details td {
  border: 1px solid #e3f2fd;
  text-align: left;
  padding: 8px;
  background-color: #e3f2fd;
}

.session-details th {
  background-color: #f8f9fa;
  color: #5a6268;
  position: sticky;
  top: 0;
  z-index: 10;
}

.session-details tr:nth-child(even) td {
  background-color: #f1f8fe;
}

.session-details td {
  vertical-align: middle;
}

.session-details td:not(:last-child) {
  border-right: none;
}
</style>



