<template>
  <popup
    title="Edit Asset Manager"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label for="urlName">Url Name:</label>
        <input type="text" id="urlName" v-model="assetManager.urlName" placeholder="Url Name">
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPaid">
          <span class="checkmark"></span>
          Is Paid
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isVisible">
          <span class="checkmark"></span>
          Is Visible
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isDeleted">
          <span class="checkmark"></span>
          Is Deleted
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="hasSharedPortfolioPanels">
          <span class="checkmark"></span>
          Has shared portfolio panels
        </label>
      </div>

      <button class="transition save-btn" @click="updateAssetManagerHandler()" :disabled="updateLoading
            || (assetManager.preferredName == '')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

    </template>
  </popup>
</template>

<script>
  import organizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditOrganization',
    mixins: [organizationsMixin, userMixin],
    props: {
      item: Object,
      parentGroups: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        parentSelect: false,
        selectedGroup: null,
        updateLoading: false,
        isPaid: this.item.isPaid,
        isVisible: this.item.isVisible,
        isDeleted: this.item.isDeleted,
        hasSharedPortfolioPanels: this.item.hasSharedPortfolioPanels,
        assetManager: this.item
      }
    },
    methods: {
      // * Triggers on save button click and updates advisor
      updateAssetManagerHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          urlName: this.assetManager.urlName,
          isPaid: this.isPaid,
          isVisible: this.isVisible,
          isDeleted: this.isDeleted,
          hasSharedPortfolioPanels: this.hasSharedPortfolioPanels
        }

        this.editOrganization(this.assetManager.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
    },

    watch: {
      isDeleted(newVal) {
        if (newVal) {
          this.isVisible = false;
        }
      },

      isVisible(newVal) {
        if (newVal) {
          this.isDeleted = false;
        }
      },
    },

  }
</script>

<style scoped>
  
</style>
