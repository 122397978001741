<template>
  <!-- Commands Page -->
  <div class="commands-page">
    <h1>Commands <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit', 'new']"
      @update-table="getCommandsHandler"
      @edit="editHandler"
      @add-new="addNewHandler"
    />

    <!-- New Popup -->
    <NewCommand
      v-if="newCommandPopup"
      :microServices="microServices"
      @close="newCommandPopup = false"
      @added="updatedHandler"
    />

    <!-- Edit Popup -->
    <EditCommand
      v-if="editItem"
      :item="editItem"
      :microServices="microServices"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewCommand from './popups/NewCommand'
  import EditCommand from './popups/EditCommand'

  export default {
    name: 'Commands',
    mixins: [systemMixin, userMixin],
    components: {
      DataTableHolder,
      NewCommand,
      EditCommand
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Command',
            name: 'command',
            sortable: true
          },
          {
            title: 'Command Short Description',
            name: 'commandShortDesc',
            sortable: true
          },
          {
            title: 'Command Long Description',
            name: 'commandLongDesc',
            sortable: true
          },
          {
            title: 'Provider',
            name: 'provider',
            sortable: true
          },
          {
            title: 'Micro Service',
            name: 'microService',
            sortable: true
          },
          {
            title: 'Micro Service Name',
            name: 'microServiceName',
            sortable: false
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        microServices: null,
        newCommandPopup: false,
        total: null
      }
    },
    methods: {
      // * Get commands from API
      getCommandsHandler(data) {
        this.getCommandsPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * After receiving response update data
            let rowsToAssign = [];
            let commands = res.commands;
            commands.forEach(command => {
              let obj = {
                id: command.id,
                command: command.command,
                commandShortDesc: command.commandShortDesc,
                commandLongDesc: command.commandLongDesc,
                provider: command.provider,
                microService: command.microService,
                microServiceName: command.microServiceName
              };

              rowsToAssign.push(obj);
            })
            this.rows = rowsToAssign;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      addNewHandler() {
        this.newCommandPopup = true;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.newCommandPopup = false;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      // * Get all microservices for the autocomplete
      this.getMicroServices()
        .then(res => {
          this.microServices = res.microServices;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>


</style>