<template>
  <!-- Edit FAQ popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit FAQ"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label for="questionEn">Question EN:</label>
        <input type="text" id="questionEn" v-model="questionEn" placeholder="Question EN...">
      </div>
      <div class="field">
        <label for="questionDe">Question DE:</label>
        <input type="text" id="questionDe" v-model="questionDe" placeholder="Question DE...">
      </div>
      <div class="field">
        <label for="answerEn">Answer EN:</label>
        <textarea v-model="answerEn" placeholder="Answer EN..."></textarea>
      </div>
      <div class="field">
        <label for="answerDe">Answer DE:</label>
        <textarea v-model="answerDe" placeholder="Answer DE..."></textarea>
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete" v-if="chosenCategory">
        <label>FAQ category:</label>
        <vue3-simple-typeahead
          placeholder="Type FAQ category..."
          :items="faqCategories"
          :minInputLength="1"
          :itemProjection="(item) => item.languages.en.name"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="display">
          <span class="checkmark"></span>
          Display
        </label>
      </div>

      <button class="transition save-btn" @click="updateFaqHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFAQ',
    mixins: [systemMixin, userMixin],
    components: {
      Popup
    },
    props: {
      faqCategories: Array,
      item: Object
    },
    data() {
      return {
        questionEn: this.item.languages.en.faq,
        questionDe: this.item.languages.de.faq,
        answerEn: this.item.languages.en.faqAnswer,
        answerDe: this.item.languages.de.faqAnswer,
        display: this.item.display,
        updateLoading: false,
        chosenCategory: null
      }
    },
    methods: {
      // * Update faq item
      updateFaqHandler() {
        this.updateLoading = true;
        const dataToSend = {
          categoryId: this.chosenCategory.id,
          display: this.display,
          languages: {
            en: {
              faq: this.questionEn,
              faqAnswer: this.answerEn
            },
            de: {
              faq: this.questionDe,
              faqAnswer: this.answerDe
            }
          }
        }

        this.updateFaq(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated')
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user selects category from the autocomplete. Updates the category
      submit(result) {
        this.chosenCategory = result;
      },
      // * Nulls category if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.category = null;
        }
      }
    },
    mounted() {
      // * If item has category set it on component load
      if (this.item.categoryId) {
        this.chosenCategory = this.faqCategories.find(category => {
          return category.id == this.item.categoryId;
        })
      }
    }
  }
</script>

<style scoped>

</style>
