<template>
  <!-- Inspect Order Details Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Order Details"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <span class="order-id">ORDER ID: {{item.id}}</span>
      <a :href="`https://umushroom.com/en/${item.securityTypeName}/${item.urlName}`" target="_blank">Security Link</a>
      
      <div class="field" v-if="item.userFullName">
        <label for="userFullName">User Name:</label>
        <input type="text" id="userFullName" :value="item.userFullName" placeholder="User Name..." disabled>
      </div>

      <div class="field" v-if="item.userEmail">
        <label for="userEmail">User Email:</label>
        <input type="text" id="userEmail" :value="item.userEmail" placeholder="User Email..." disabled>
      </div>

      <div class="field" v-if="item.organizationName">
        <label for="organizationName">Organization Name:</label>
        <input type="text" id="organizationName" :value="item.organizationName" placeholder="Organization Name..." disabled>
      </div>

      <div class="field">
        <label for="portfolioName">Portfolio Name:</label>
        <input type="text" id="portfolioName" :value="item.portfolioName" placeholder="Portfolio Name..." disabled>
      </div>

      <div class="field">
        <label for="securityTypeName">Security Type:</label>
        <input type="text" id="securityTypeName" :value="item.securityTypeName" placeholder="Security Type..." disabled>
      </div>

      <div class="field">
        <label for="securityName">Security Name:</label>
        <input type="text" id="securityName" :value="item.securityName" placeholder="Security Name..." disabled>
      </div>

      <div class="field" v-if="item.lastPrice">
        <label for="lastPrice">Last Price:</label>
        <input type="text" id="lastPrice" :value="item.lastPrice" placeholder="Last Price..." disabled>
      </div>

      <div class="field" v-if="item.lastPriceDate">
        <label for="lastPriceDate">Last Price Date:</label>
        <input type="text" id="lastPriceDate" :value="$filters.formatDate(item.lastPriceDate.date)" placeholder="Last Price Date..." disabled>
      </div>
    </template>
  </popup>
</template>

<script>
  import Popup from '@/components/shared/Popup'
  
  export default {
    name: 'InspectOrderDetails',
    props: {
      item: Object
    },
    components: {
      Popup
    }
  }
</script>

<style scoped>
  .order-id {
    font-weight: 700;
    margin-top: 10px;
    display: block;
  }

  a {
    color: #3c6e71;
  }

  input:disabled {
    cursor: text !important;
  }
</style>
