<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
  // import NumberBox from './NumberBox'

  export default {
    name: 'Dashboard',
    components: {
      // NumberBox
    },
    data() {
      return {
        // circleOptions: {
        //   color: '#3C6E71',
        //   strokeWidth: 3,
        //   trailWidth: 2,
        //   text: {
        //     value: '75%'
        //   },
        //   easing: 'easeInOut'
        // }
      }
    },
    mounted: function () {
      // this.$refs.circle1.animate(0.75)
      // this.$refs.circle2.animate(0.75)
    }
  }
</script>

<style scoped>
  .numbers-boxes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .progresses {
    width: 25%;
    background: #FFF;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    margin: 0 10px;
    border-radius: 10px;
    padding: 20px;
  }

  .progresses h4 {
    text-align: center;
    margin: 0;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 25px;
  }

  .progress-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }

  .progress-row h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  .progress-circle {
    width: 60px;
  }

  .progress-circle :deep(.progressbar-text) {
    font-size: 20px;
  }
</style>
