<template>
  <div>
    <apexchart v-if="showChart" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  import marketingMixin from '@/mixins/marketing'
  import datesMixin from '@/mixins/dates'

  export default {
    name: 'UsersPremiumPieChart',
    mixins: [marketingMixin, datesMixin],
    data() {
      return {
        showChart: false,
        series: [],
        chartOptions: {
          chart: {
            type: 'donut',
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          labels: ['Basic Users', 'Premium Users'],
          colors:['#C1A16F', '#000', '#61F1F9'],
          legend: {
            position: 'bottom',
            fontFamily: 'Poppins',
            fontWeight: 500
          }
        }
      }
    },
    methods: {
      getUsersMarketingHandler() {
        const dataToSend = {};

        let yesterday = this.getDateBeforeDays(1);
        dataToSend.toDate = this.formatDateDashes(yesterday);

        let sevenDaysAgo = this.getDateBeforeDays(7);
        dataToSend.fromDate = this.formatDateDashes(sevenDaysAgo);

        this.getUsersMarketing(dataToSend)
          .then(res => {
            this.series.push(res.totalBasicUsers);
            if (res.totalSubscriptions) {
              this.chartOptions.labels.push('Subscribed Users');
              this.series.push(res.totalPremiumUsers - res.totalSubscriptions.active);
              this.series.push(res.totalSubscriptions.active);
            } else {
              this.series.push(res.totalPremiumUsers);
            }

            this.showChart = true;
          })
      }
    },
    mounted() {
      this.getUsersMarketingHandler();
    }
  }
</script>