<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      getCryptoCategories() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/crypto/categories`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        })
      }
    }
  }
</script>
