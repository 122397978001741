<template>
  <div class="users-marketing">
    <h1>Users Marketing Charts</h1>
    <div class="row">
      <div class="col">
        <UsersColumnChart />
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <UsersPremiumPieChart />
          </div>
          <div class="col">
            <UsersVerifiedPieChart />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UsersColumnChart from './charts/users/UsersColumnChart'
  import UsersPremiumPieChart from './charts/users/UsersPremiumPieChart'
  import UsersVerifiedPieChart from './charts/users/UsersVerifiedPieChart'

  export default {
    name: 'UsersMarketingCharts',
    components: {
      UsersColumnChart,
      UsersPremiumPieChart,
      UsersVerifiedPieChart
    }
  }
</script>

<style scoped>
  .row {
    display: flex;
  }

  .col {
    flex: 0 0 50%;
  }
</style>