<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {

    methods: {
      // Get Organizations, all fields, paginated
      getOrganizationsPaginated(data, typeId) {

          if (typeId != undefined) {
              return new Promise((resolve, reject) => {
                  axios(`${this.$store.state.global.apiUrl}/backoffice/organizations/${typeId}`, {
                      method: 'post',
                      data: data
                  })
                      .then(res => {
                          resolve(res.data);
                      })
                      .catch(err => {
                          reject(err.response.data.message);
                      })
              })
          } else {
              return new Promise((resolve, reject) => {
                  axios(`${this.$store.state.global.apiUrl}/backoffice/organizations`, {
                      method: 'post',
                      data: data
                  })
                      .then(res => {
                          resolve(res.data);
                      })
                      .catch(err => {
                          reject(err.response.data.message);
                      })
              })
          }


      },
      // ------------------------------------------------------------------------------
      // Get all Organizations, simple list, alphabetical order
      getAllOrganizations(typeId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/organizations/${typeId}`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // ------------------------------------------------------------------------------
      // Update organization
      editOrganization(organizationId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/organizations/${organizationId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        });
      },
      createOrganization(data, type) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/organization`, {
            method: 'post',
            data: data,
            type: type
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              const errorMessage = err.response && err.response.data && err.response.data.msg
                  ? err.response.data.msg
                  : "An unknown error occurred";
              reject(errorMessage);
            })
        })
      },

      getPanelsByOrganizationId(organizationId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/panels/${organizationId}`, {
            method: 'get'
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

    }
  }
</script>
