<template>
  <!-- Retired Equities Page -->
  <div class="retired_equity-page">
    <h1>Retired Equities <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getEquitiesHandler"
      @edit="editHandler" 
    />

    <!-- Inspect Popup -->
    <InspectRetiredEquity
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import equitiesMixin from '@/mixins/equities'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectRetiredEquity from './popups/InspectRetiredEquity'

  export default {
    name: 'RetiredEquities',
    mixins: [equitiesMixin, userMixin],
    components: {
      DataTableHolder,
      InspectRetiredEquity
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'companyName',
            sortable: true
          },
          {
            title: 'Valor',
            name: 'valor',
            sortable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Currency',
            name: 'currencyCode',
            sortable: true
          },
          {
            title: 'Sentify OK',
            name: 'sentifyOk',
            sortable: true
          },
          {
            title: 'Factset Company ID',
            name: 'factsetCompanyId',
            sortable: true
          },
          {
            title: 'Factset Entity ID',
            name: 'factsetEntityId',
            sortable: true
          },
          {
            title: 'Admin Comment',
            name: 'adminComment',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get equities from API
      getEquitiesHandler(data) {
        this.getRetiredEquities(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let equitiesFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const equity of res.equities) {
              let equityObj = {
                id: equity.id,
                name: equity.companyName,
                valor: equity.valor,
                isin: equity.isin,
                currencyCode: equity.currencyCode,
                sentifyOk: equity.sentifyOk,
                factsetCompanyId: equity.factsetCompanyId,
                factsetEntityId: equity.factsetEntityId,
                adminComment: equity.adminComment,
                addedOn: equity.addedOn
              }
              // * Add formatted equity to formatted equities array
              equitiesFormatted.push(equityObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = equitiesFormatted;
            this.fullData = res.equities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(equity => {
          return equity.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>

</style>