<template>
  <popup
    title="Pay subscription"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label>User Email:</label>
        <input v-model="userEmail" type="text" readonly>
      </div>

      <SelectDropdown
        v-model="paidPlan"
        :options="plans"
        label="Paid Plan"
        placeholder="Select Plan"
      />

      <div class="field-group">
        <div class="field">
          <label>Payment Amount (NET):</label>
          <input v-model="nextPaymentAmount" type="number">
        </div>
  
        <div class="field">
          <label>Payment Amount (VAT):</label>
          <input v-model="nextPaymentAmountVAT" type="number">
        </div>

        <div class="field">
          <label>VAT Rate:</label>
          <input v-model="vatRate" type="text">
        </div>
      </div>

      <div class="field-group">
        <SelectDropdown
          v-model="selectedCurrency"
          :options="currencies"
          label="Currency"
          placeholder="Select Currency"
        />

        <SelectDropdown
          v-model="selectedPeriod"
          :options="periods"
          label="Period"
          placeholder="Select Period"
        />
      </div>

      <div class="field">
        <label>Next Payment Date:</label>
        <Datepicker class="datepicker" v-model="nextPaymentDate" format="yyyy-MM-dd HH:mm" :enable-time-picker="true" />
      </div>

      <div class="field">
        <label>Last Payment Date:</label>
        <Datepicker class="datepicker" v-model="lastPaymentDate" format="yyyy-MM-dd HH:mm" :enable-time-picker="true" />
      </div>

      <div class="error-message" v-if="actualPlan < item.paidPlan">
        You can't downgrade a subscriber
      </div>

      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>

      <button
        class="transition save-btn"
        :disabled="actualPlan < item.paidPlan"
        @click="updateSubscriptionHandler()"
      >
        <span v-if="!buttonLoading">
          Submit
        </span>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import { format } from 'date-fns';

  import usersMixin from '@/mixins/users'
  import datesMixin from '@/mixins/dates'
  
  import SelectDropdown from '@/components/shared/SelectDropdown'
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'PaySubscription',
    emits: ['updated', 'close'],
    mixins: [ usersMixin, datesMixin ],
    components: {
      Popup,
      SelectDropdown
    },
    props: {
      item: Object
    },
    data() {
      const plans = [
        { id: 0, name: 'Explore' },
        { id: 1, name: 'Learn' },
        { id: 2, name: 'Grow' }
      ];

      const currencies = [
        { id: 'CHF', name: 'CHF' },
        { id: 'EUR', name: 'EUR' },
      ];

      const periods = [
        { id: 0, name: 'MONTHLY' },
        { id: 1, name: 'YEARLY' },
      ];

      return {
        plans: plans,
        paidPlan: plans.find(plan => plan.id === this.item?.paidPlan) || null,
        currencies: currencies,
        selectedCurrency: currencies.find(currency => currency.id === 'CHF') || null,
        periods: periods,
        selectedPeriod: periods.find(period => period.id === this.item?.billingPeriod) || null,
        userEmail: this.item.email,
        actualPlan: this.item.actualPlan,
        paidPlanSelect: false,
        nextPaymentAmount: 0,
        nextPaymentAmountVAT: 0,
        vatRate: this.item.vatRate,
        buttonLoading: false,
        nextPaymentDate: (() => {
          const inputDate = this.item.nextPaymentDate;
          const date = inputDate ? new Date(inputDate) : new Date();
          date.setHours(0, 0, 0, 0);

          return date;
        })(),
        lastPaymentDate: (() => {
          const inputDate = this.item.lastPaymentDate;
          const date = inputDate ? new Date(inputDate) : new Date();
          date.setHours(0, 0, 0, 0);

          return date;
        })(),
        errorMessage: null
      }
    },
    methods: {
      paidPlanAway() {
        this.paidPlanSelect = false;
      },
      updateSubscriptionHandler() {
        this.buttonLoading = true;

        const vatRate = parseFloat(this.vatRate);
        if (isNaN(vatRate)) {
          this.errorMessage = 'VAT Rate must be a number';
          this.buttonLoading = false;
          return;
        }

        const dataToSend = {
          paidPlan: this.paidPlan?.id ?? null,
          billingPeriod: this.selectedPeriod?.id ?? null,
          nextPaymentDate: this.nextPaymentDate ? format(this.nextPaymentDate, 'yyyy-MM-dd HH:mm:ss') : null,
          lastPaymentDate: this.lastPaymentDate ? format(this.lastPaymentDate, 'yyyy-MM-dd HH:mm:ss') : null,
          netAmount: this.nextPaymentAmount,
          vatAmount: this.nextPaymentAmountVAT,
          currency: this.selectedCurrency?.id ?? null,
          vatRate: vatRate,
        }        
        
        this.paySubscription(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.buttonLoading = false;
          })
          .catch((error) => {
            console.log(error);
            
            this.errorMessage = error.msg;
            this.buttonLoading = false;
          });
      }
    }
  }
</script>

<style scoped>
  .field-group {
    display: flex;
    gap: 20px;
  }

  .field :deep(.vue-daterange-picker) {
    width: 100%;
  }

  .field :deep(.vue-daterange-picker .form-control) {
    width: 100%;
    height: auto;
    margin: 0 !important;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  .period-select {
    padding: 3px 12px;
    color: #696974;
    font-weight: 500;
    border: 1px solid #e2e2ea;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    margin-left: 2%;
  }

  .period-text {
    color: rgb(185, 35, 35);
  }

  .trial-expired-wrapper .checkbox{
    display: inline-block;
    margin-left: 1%;
    margin-bottom: 15px;
  }
</style>

<style>
  .datepicker input {
    padding-left: 6% !important;
  }
</style>
