<template>
  <!-- Fund Umbrella Geographies Page -->
  <div class="fund-umbrella-geographies-page">
    <h1>Fund Umbrella Geographies</h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :updateResults="updateResults"
      :actions="['edit', 'new']"
      @update-table="getFundUmbrellaGeographiesHandler"
      @add-new="addNewHandler"
      @edit="editHandler"
    />

    <NewFundUmbrellaGeography
      v-if="addGeographyPopup"
      @created="geographyCreatedHandler"
      @close="addGeographyPopup = false"
    />

    <EditFundUmbrellaGeography
      v-if="editItem"
      :geography="editItem"
      @updated="geographyUpdatedHandler"
      @close="editItem = null"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewFundUmbrellaGeography from './popups/NewFundUmbrellaGeography'
  import EditFundUmbrellaGeography from './popups/EditFundUmbrellaGeography'

  export default {
    name: 'FundUmbrellaGeographies',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      NewFundUmbrellaGeography,
      EditFundUmbrellaGeography
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Geography',
            name: 'geography',
            sortable: false
          },
          {
            title: 'UMU Geography',
            name: 'umuGeography',
            sortable: false
          },
          {
            title: 'UMU Geography ID',
            name: 'umuGeographyId',
            sortable: false
          },
        ],
        editItem: null,
        updateResults: true,
        addGeographyPopup: false
      }
    },
    methods: {
      // * Get fund umbrella sectors from API
      getFundUmbrellaGeographiesHandler(data) {
        this.getAllFundUmbrellaGeographies(data)
          .then(res => {

            let geographiesFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const geography of res.geographies) {
              let Geography = {
                id: geography.id,
                geography: geography.geography,
                umuGeography: geography.translationData?.en?.name || null,
                umuGeographyId: geography.umuGeographyId || null,
              }
              // * Add formatted fund to formatted funds array
              geographiesFormatted.push(Geography);
            }
            // * After receiving response update data
            this.rows = geographiesFormatted;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Add new sector handler. Triggered when user click the "+ New" button
      addNewHandler() {
        this.addGeographyPopup = true;
      },
      geographyCreatedHandler() {
        this.addGeographyPopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      geographyUpdatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      
    }
  }
</script>

<style scoped>

</style>