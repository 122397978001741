import auth from '@/store/auth.js' 
import knowledge from '@/store/knowledge.js' 
import global from '@/store/global.js' 

export default {
  modules: {
    auth,
    knowledge,
    global
  }
};
