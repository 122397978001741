<template>
  <div  class="equity-candidate-yearly-returns-page">
    <h1>Candidate Returns <small v-if="total">({{total}})</small></h1>

    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :total-pages="pages"
        :actions="[]"
        @update-table="getEquitiesHandler"
    />
  </div>
</template>

<script>
import securitiesMixin from '@/mixins/securities'
import userMixin from '@/mixins/user'

import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";

export default {
  name: 'CandidateEquitiesReturns',
  mixins: [securitiesMixin, userMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable : true
        },
        {
          title: 'Name',
          name: 'name',
          sortable: true
        },
        {
          title: 'Valor',
          name: 'valor',
          sortable: true
        },
        {
          title: 'ISIN',
          name: 'isin',
          sortable: true
        },
        {
          title: '1YR',
          name: 'return_1y',
          sortable: true
        },
        {
          title: 'SIX 1YR',
          name: 'six_52_week_return',
          sortable: true
        },
        {
          title: 'Diff 1YR',
          name: 'return_diff_1y',
          sortable: true
        },
        {
          title: '3YR',
          name: 'return_3y',
          sortable: true
        },
        {
          title: 'SIX 3YR',
          name: 'six_3_year_return',
          sortable: true
        },
        {
          title: 'Diff 3YR',
          name: 'return_diff_3y',
          sortable: true
        },
        {
          title: '5YR',
          name: 'return_5y',
          sortable: true
        },
        {
          title: 'SIX 5YR',
          name: 'six_5_year_return',
          sortable: true
        },
        {
          title: 'Diff 5YR',
          name: 'return_diff_5y',
          sortable: true
        },
      ],
      pages: 0,
      fullData: null,
      total: null
    };
  },
  methods: {
    getEquitiesHandler(data) {

      let foundIsActiveFilter = data.filter.filter(function(item){
        return item.name == 'candidate';
      });
      if (foundIsActiveFilter.length == 0) {
        data.filter.push({
          name: 'candidate',
          term: true
        });
      }

      this.getSecurities(data, 1)
          .then(res => {

            this.pages = res.pagesCount;
            let equitiesFormatted = [];
            for (const equity of res.securities) {

              let Equity = {
                id: equity.id,
                name: equity.name,
                valor: equity.valor,
                isin: equity.isin,

                return_1y: equity.performance_data.return_1y,
                six_52_week_return: equity.performance_data.six_52_week_return,
                return_diff_1y: equity.return_diff_1y,

                return_3y: equity.performance_data.return_3y,
                six_3_year_return: equity.performance_data.six_3_year_return,
                return_diff_3y: equity.return_diff_3y,

                return_5y: equity.performance_data.return_5y,
                six_5_year_return: equity.performance_data.six_5_year_return,
                return_diff_5y: equity.return_diff_5y,
              };
              equitiesFormatted.push(Equity);
            }
            this.rows = equitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
    },
  }
}

</script>

<style scoped>

</style>