<template>
  <div>
    <div class="filters-holder" style="align-items: baseline;">
      <div class="filters">
        <a :class="{active: activeFilter == 7}" @click="getUsersMarketingHandler(7)">7 Days</a>
        <a :class="{active: activeFilter == 30}" @click="getUsersMarketingHandler(30)">1 Month</a>
        <a :class="{active: activeFilter == 365}" @click="getUsersMarketingHandler(365)">1 Year</a>
      </div>

      <div class="field" style="margin-left: 10px;">
        <label class="custom-check green">
          <input type="checkbox" v-model="excludeTestingUsers">
          <span class="checkmark"></span>
          Exclude Testing Users
        </label>
      </div>

    </div>
    <apexchart
      v-if="showChart"
      width="100%"
      :options="chartOptions"
      :series="series"
      ref="usersChart"
    ></apexchart>
  </div>
</template>

<script>
  import marketingMixin from '@/mixins/marketing'
  import datesMixin from '@/mixins/dates'

  export default {
    name: 'UsersColumnChart',
    mixins: [marketingMixin, datesMixin],
    data() {
      return {
        showChart: false,
        activeFilter: 7,
        excludeTestingUsers: false,
        chartOptions: {
          chart: {
            id: 'users',
            type: 'line',
            stacked: false,
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              style: {
                fontFamily: 'Poppins',
                fontWeight: 500
              }
            }
          },
          yaxis: [
            {
              seriesName: 'New Users',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#3C6E71'
              },
              labels: {
                style: {
                  colors: '#3C6E71',
                  fontFamily: 'Poppins',
                  fontWeight: 500
                }
              },
              title: {
                text: "Users Per Day",
                style: {
                  color: '#3C6E71',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: 14
                }
              }
            },
            {
              show: false,
              seriesName: 'New Users',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#C1A16F'
              },
              labels: {
                style: {
                  colors: '#C1A16F',
                }
              },
              title: {
                text: "Verified Users",
                style: {
                  color: '#C1A16F',
                }
              }
            },
            {
              show: false,
              seriesName: 'New Users',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#003E52'
              },
              labels: {
                style: {
                  colors: '#003E52',
                }
              },
              title: {
                text: "Premium Users",
                style: {
                  color: '#003E52',
                }
              }
            },
            {
              seriesName: 'Total Users',
              opposite: true,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: '#000'
              },
              labels: {
                style: {
                  colors: '#000',
                  fontFamily: 'Poppins',
                  fontWeight: 500
                }
              },
              title: {
                text: "Total Users",
                style: {
                  color: '#000',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: 14
                }
              }
            },
          ],
          colors:['#3C6E71', '#C1A16F', '#003E52', '#000'],
          legend: {
            fontFamily: 'Poppins',
            fontWeight: 500
          }
        },
        series: [
          {
            name: 'New Users',
            type: 'column',
            data: []
          },
          {
            name: 'Verified Users',
            type: 'column',
            data: []
          },
          {
            name: 'Premium Users',
            type: 'column',
            data: []
          },
          {
            name: 'Total Users',
            type: 'line',
            data: []
          }
        ]
      }
    },
    methods: {
      getUsersMarketingHandler(daysBehind) {
        this.showChart = false;
        this.activeFilter = daysBehind;
        const dataToSend = {};

        let yesterday = this.getDateBeforeDays(1);
        dataToSend.toDate = this.formatDateDashes(yesterday);

        let fromDate = this.getDateBeforeDays(daysBehind);
        dataToSend.fromDate = this.formatDateDashes(fromDate);

        dataToSend.excludeTestingUsers = this.excludeTestingUsers;

        this.getUsersMarketing(dataToSend)
          .then(res => {
            let minUsers;
            let maxUsers;
            this.series[0].data = [];
            this.series[1].data = [];
            this.series[2].data = [];
            this.series[3].data = [];
            this.chartOptions.xaxis.categories = [];

            res.performanceData.forEach((obj) => {
              this.chartOptions.xaxis.categories.push(obj.date);
              this.series[0].data.push(this.excludeTestingUsers ? obj.newUsersExcludeTesting : obj.newUsers);
              this.series[1].data.push(this.excludeTestingUsers ? obj.verifiedUsersExcludeTesting : obj.verifiedUsers);
              this.series[2].data.push(this.excludeTestingUsers ? obj.premiumUsersExcludeTesting : obj.premiumUsers);
              this.series[3].data.push(this.excludeTestingUsers ? obj.totalBasicUsersExcludeTesting + obj.totalPremiumUsersExcludeTesting : obj.totalBasicUsers + obj.totalPremiumUsers);

              if (this.excludeTestingUsers == true) {
                  if (!minUsers || obj.totalBasicUsersExcludeTesting + obj.totalPremiumUsersExcludeTesting < minUsers) {
                      minUsers = obj.totalBasicUsersExcludeTesting + obj.totalPremiumUsersExcludeTesting;
                  }
                  if (!maxUsers || obj.totalBasicUsersExcludeTesting + obj.totalPremiumUsersExcludeTesting > maxUsers) {
                      maxUsers = obj.totalBasicUsersExcludeTesting + obj.totalPremiumUsersExcludeTesting;
                  }
              } else {
                  if (!minUsers || obj.totalBasicUsers + obj.totalPremiumUsers < minUsers) {
                      minUsers = obj.totalBasicUsers + obj.totalPremiumUsers;
                  }
                  if (!maxUsers || obj.totalBasicUsers + obj.totalPremiumUsers > maxUsers) {
                      maxUsers = obj.totalBasicUsers + obj.totalPremiumUsers;
                  }
              }
            })

            minUsers = minUsers - 10;
            maxUsers = maxUsers + 10;

            this.chartOptions.yaxis[3].min = minUsers;
            this.chartOptions.yaxis[3].max = maxUsers;
            
            this.showChart = true;
          })
      }
    },
    watch: {
      excludeTestingUsers() {
        this.getUsersMarketingHandler(this.activeFilter);
      }
    },
    mounted() {
      this.getUsersMarketingHandler(7);
    }
  }
</script>

<style scoped>
  .filters-holder {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .filters {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  .filters a {
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
  }

  .filters a.active {
    color: #FFF;
    background: #3C6E71;
    border-radius: 8px;
  }
</style>