<template>
  <!-- Security Pub Descr Data Page -->
  <div class="security-public-descriptions-page">
    <h1>
      Funds Public Descriptions <small v-if="total">({{ total }})</small>
    </h1>

<!--    &lt;!&ndash; Master Filter &ndash;&gt;-->
<!--    <div class="master-filter" ref="filtersContainer" v-show="filtersDisplayed">-->
<!--      <div class="filters-title">Master Filter</div>-->
<!--      <div class="filters-container">-->
<!--        <div class="checkboxes-wrapper">-->
<!--          <div class="checkbox-container" v-for="(checkbox, index) in filters.checkboxes" :key="index">-->
<!--            <div @mouseover="activeFilterIndex = checkbox.tableName" @mouseout="activeFilterIndex = -1">-->
<!--              <input :id="index" type="checkbox" :indeterminate="checkbox.value === null" :checked="checkbox.value" @change="checkBoxChangeHandler(checkbox)"/>-->
<!--              <label :for="index">{{ checkbox.name }}</label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="dropdowns-wrapper">-->
<!--          <div class="dropdown-container" v-for="(dropdown, index) in filters.dropdowns" :key="index">-->
<!--            <div @mouseover="activeFilterIndex = dropdown.tableName" @mouseout="activeFilterIndex = -1">-->
<!--              <multiselect-->
<!--                  v-model="dropdown.selectedOptions"-->
<!--                  :options="dropdown.dropdownOptions"-->
<!--                  :label="dropdown.label"-->
<!--                  :placeholder="dropdown.placeholder"-->
<!--                  :multiple="dropdown.multiselect"-->
<!--                  track-by="id"-->
<!--              ></multiselect>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="filter-buttons">-->
<!--          <button class="apply-filter" @click="applyFilters">Apply Filters</button>-->
<!--          <button class="reset-filter" @click="resetFilters">Reset Filters</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['edit']"
        @update-table="getFundsHandler"
        @edit="editHandler"
    />

    <EditFundPublicDescription
        v-if="editItem"
        :item="editItem"
        @close="editItem = null"
        @updated="updatedHandler"
    />


    <!-- Inspect Popup -->

  </div>
</template>

<script>
import fundsMixin from '@/mixins/funds.vue'
import userMixin from '@/mixins/user.vue'
// import Multiselect from "vue-multiselect";

import DataTableHolder from '@/components/shared/datatable/DataTableHolder.vue'
import EditFundPublicDescription from "@/components/funds/popups/EditFundPublicDescription.vue";

export default {
  name: 'PublicDescriptions',
  mixins: [fundsMixin, userMixin],
  components: {
    DataTableHolder,
    EditFundPublicDescription,
    // Multiselect,

  },
  data() {
    return {
      // filters: {
      //   checkboxes: {
      //     hasGeneralObjective: {
      //       name: "General Objective",
      //       value: null,
      //       indeterminate: true,
      //       tableName: 'hasGeneralObjective'
      //     },
      //     hasFundExposure: {
      //       name: "Fund Exposure",
      //       value: null,
      //       indeterminate: true,
      //       tableName: 'hasFundExposure'
      //     },
      //     hasRisks: {
      //       name: "Risks",
      //       value: null,
      //       indeterminate: true,
      //       tableName: 'hasRisks'
      //     },
      //     hasSource: {
      //       name: "Source",
      //       value: null,
      //       indeterminate: true,
      //       tableName: 'hasSource'
      //     },
      //     hasUpdatedOn: {
      //       name: "Updated On",
      //       value: null,
      //       indeterminate: true,
      //       tableName: 'hasUpdatedOn'
      //     },
      //
      //   },
      //   dropdowns: {
      //     language: {
      //       selectedOptions: [],
      //       dropdownOptions: [
      //         { id: 1, name: "English" },
      //         { id: 2, name: "German" },
      //       ],
      //       label: 'name', // need to point to dropdownOptions
      //       placeholder: "Select Languages",
      //       multiselect: true,
      //       tableName: 'language'
      //     },
      //
      //   },
      // },
      // initialFilters: {},
      // isDataFetched: false,
      // activeFilterIndex: -1,
      // filtersToSend: {},
      // filtersDisplayed: false,

      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable: true
        },
        {
          title: 'Fund Name',
          name: 'fundName',
          sortable: true
        },
        {
          title: 'Fund Type',
          name: 'fundType',
          sortable: true
        },
        {
          title: 'General Objective',
          name: 'generalObjective',
          sortable: true
        },
        {
          title: 'Fund Exposure',
          name: 'fundExposure',
          sortable: true
        },
        {
          title: 'Risks',
          name: 'risks',
          sortable: true
        },
        {
          title: 'DE General Objective',
          name: 'deGeneralObjective',
          sortable: true
        },
        {
          title: 'DE Fund Exposure',
          name: 'deFundExposure',
          sortable: true
        },
        {
          title: 'DE Risks',
          name: 'deRisks',
          sortable: true
        },
        {
          title: 'Source SLD',
          name: 'sld',
          sortable: true
        },
        {
          title: 'Updated On',
          name: 'updatedOn',
          sortable: true
        },

      ],

      editItem: null,
      pages: 0,
      updateResults: true,
      total: null,
      fullData: null,
      isNotNullContent: null,
    }
  },
  methods: {
    // * Get Securities from API
    getFundsHandler(data) {

      const newData = {...data, filter: this.filtersToSend};

      this.getFundsPublicContentPaginated(newData)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let fundsFormatted = [];
            // * Loop through securities and add only the needed properties


            for (const fund of res.investmentFunds) {
              let fundsObj = {
                id: fund.id,
                fundName: fund.fund_name,
                fundType: fund.fund_type,
                generalObjective: fund.general_objective || null,
                fundExposure: fund.fund_exposure || null,
                risks: fund.risks || null,
                deGeneralObjective: fund.de_general_objective || null,
                deFundExposure: fund.de_fund_exposure || null,
                deRisks: fund.de_risks || null,
                sld: this.getSecondLevelDomain(fund.source),
                updatedOn: fund.updated_on || null,
              }
              // * Add formatted security to formatted securities array
              fundsFormatted.push(fundsObj);
            }
            // * Assign table rows to be the formatted securities array
            this.rows = fundsFormatted;
            this.fullData = res.investmentFunds;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },

    // * Triggered when user click the pen icon on a row
    editHandler(item) {
      this.editItem = this.fullData.find(fund => {
        return fund.id === item.id;
      });
    },
    // * Triggered when user updates item from the edit popup
    updatedHandler() {
      // * Null the editing item
      this.editItem = null;
      this.updateResults = !this.updateResults;
    },

    getSecondLevelDomain(url) {
      if (!url){
        return null;
      }
      // Simpler pattern to match the SLD
      let pattern = /https?:\/\/(?:www\.)?([^/]+)/;
      let matches = url.match(pattern);

      // Check if we have a match
      if (matches && matches[1]) {
        return matches[1];
      }

      return null; // Return null if no SLD is found
    },


//   checkBoxChangeHandler(checkbox) {
    //     // if indeterminate is enabled for this checkbox - apply different behaviour
    //     if (checkbox.indeterminate) {
    //       switch (checkbox.value) {
    //         case null:
    //           checkbox.value = true;
    //           break;
    //         case true:
    //           checkbox.value = false;
    //           break;
    //         case false:
    //           checkbox.value = null;
    //           break;
    //       }
    //     } else {
    //       switch (checkbox.value) {
    //         case true:
    //           checkbox.value = false;
    //           break;
    //         case false:
    //           checkbox.value = true;
    //           break;
    //       }
    //     }
    //   },
    //   applyFilters() {
    //     // prepare filters for checkboxes and dropdowns to be send to BE
    //     let checkboxes = this.filters.checkboxes;
    //     let dropdowns = this.filters.dropdowns;
    //     this.filtersToSend = {};
    //
    //     for (const key in checkboxes) {
    //       let checkBoxValue = checkboxes[key].value;
    //       if(checkBoxValue !== null) {
    //         this.filtersToSend[key] = checkBoxValue;
    //       }
    //     }
    //
    //     for (const key in dropdowns) {
    //       let dropdownSelectedOptions = dropdowns[key].selectedOptions;
    //       if(dropdownSelectedOptions && dropdownSelectedOptions.length > 0) {
    //         this.filtersToSend[key] = dropdownSelectedOptions;
    //       }
    //     }
    //
    //     // update table data
    //     this.updateResults = !this.updateResults;
    //   },
    //   resetFilters() {
    //     //clear filters before updating table data and clear filters visualization
    //     this.filters = JSON.parse(JSON.stringify(this.initialFilters));
    //     this.applyFilters();
    //   },
    // },
    //
    // watch: {
    //   isDataFetched: {
    //     immediate: true,
    //     handler(newVal) {
    //       if (newVal) {
    //         this.initialFilters = JSON.parse(JSON.stringify(this.filters));
    //         this.isDataFetched = false;
    //       }
    //     },
    //   },
    //   activeFilterIndex(newIndex) {
    //     this.columns.find((column) => {
    //       if(newIndex !== -1) {
    //         let columnName = column.name.toLowerCase();
    //         let hoveredFilter = newIndex.toLowerCase();
    //         if(columnName == hoveredFilter) {
    //           column['customClass'] = 'highlighted';
    //         } else {
    //           column['customClass'] = '';
    //         }
    //       } else {
    //         column['customClass'] = '';
    //       }
    //     });
    //   }
    // },
    //
    // updated() {
    //   // Find the target element with class "table-actions" (Coming from DataTableHolder -> DataTable)
    //   const tableActionsElement = document.querySelector('.datatable-container .table-actions');
    //   const filtersContainer = this.$refs.filtersContainer;
    //
    //   // Move the filters container after the tableActionsElement
    //   if (tableActionsElement && filtersContainer && !this.filtersDisplayed) {
    //     tableActionsElement.parentNode.insertBefore(filtersContainer, tableActionsElement.nextSibling);
    //     this.filtersDisplayed = true;
    //   }
    // }
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.master-filter {
  margin: 2% 0%;
}
.filters-title {
  font-size: 20px;
  font-weight: 400;
}

.filters-container {
  width: 40%;
  background-color: white;
  padding: 1.5% 1%;
  margin: 0px 0px 1.5% 0px;
}

.checkboxes-wrapper {
  border-bottom: 1px solid gray;
}

.checkboxes-wrapper, .dropdowns-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.dropdowns-wrapper {
  justify-content: space-between;
}

.checkbox-container, .dropdown-container {
  margin-right: 20px;
  margin-bottom: 10px;
}

.dropdown-container .multiselect {
  width: 300px;
  cursor: pointer;
}

.filter-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.filter-buttons button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.filter-buttons button.apply-filter {
  background-color: #3c6e71;
  color: #fff;
}

.filter-buttons button.reset-filter {
  background-color: #a31111;
  color: #fff;
}

/* Change default checkbox behaviour and visualization */
.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container input[type="checkbox"] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  vertical-align: -20%;
  height: 2ex;
  width: 2ex;
  background-color: white;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 0.5em;
}

.checkbox-container input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  width: 1.2ex;
  height: 0.4ex;
  background: rgba(0, 0, 0, 0);
  top: 0.9ex;
  left: 0.4ex;
  border: 3px solid rgb(2, 124, 2);
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.filters-container .checkbox-container
input[type="checkbox"]:not(:checked):not(:indeterminate)
+ label::after {
  display: block;
  content: "✕";
  position: absolute;
  top: 0.65em;
  left: 0.1em;
  font-size: 1.2em;
  color: #b60f0f;
  line-height: 0;
  font-weight: bold;
  transition: all 0.2s;
}
</style>