<template>
  <!-- Command Info popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Command Info"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <span class="command-name">
        {{item.command.commandName}}
      </span>
      <div v-if="item.latestCommandLog[0]">
        <div class="field" v-if="item.latestCommandLog[0].startedAt">
          <label for="startedAt">Started at <small>(not editable)</small>:</label>
          <input type="text" id="startedAt" :value="$filters.formatDateTime(item.latestCommandLog[0].startedAt.date)" placeholder="Started At" disabled>
        </div>
        <div class="field" v-if="item.latestCommandLog[0].endedAt">
          <label for="endedAt">Ended at <small>(not editable)</small>:</label>
          <input type="text" id="endedAt" :value="$filters.formatDateTime(item.latestCommandLog[0].endedAt.date)" placeholder="Ended At" disabled>
        </div>
        <div class="field" v-if="item.latestCommandLog[0].data">
          <label for="message">Data Log message:</label>
          <div class="data-message" v-for="(message, title) in item.latestCommandLog[0].data" :key="title">
            <p v-if="typeof message == 'string' || typeof message == 'number'">
              <span class="capitalize">{{title}}</span>: {{message}}
            </p>
            <template v-else-if="typeof message == 'object'">
              <p v-if="message.length == 0">
                <span class="capitalize">{{title}}</span>: n/a
              </p>
              <template v-else>
                <p>
                  <span class="capitalize">{{title}}:</span>
                  <ul>
                    <li v-for="(item, index) in message" :key="index">
                      <template v-if="typeof item == 'string' || typeof message == 'number'">
                        {{item}}
                      </template>
                      <template v-else>
                        <template v-if="item[0]">
                          <span class="capitalize">{{index}}:</span>
                          <div class="list-item-sub-row" v-for="(value, key) in item" :key="key">
                            <ul>
                              <li v-for="(value2, key2) in value" :key="key2">
                                <span class="capitalize">{{key2}}</span>: {{value2}}
                              </li>
                            </ul>
                          </div>
                        </template>
                        <template v-else>
                          <div class="list-item-row" v-for="(value, key) in item" :key="key">
                            <span class="capitalize">{{key}}</span>: {{value}}
                          </div>
                        </template>
                      </template>
                    </li>
                  </ul>
                </p>
              </template>
            </template>
          </div>
          <!-- <div class="data-message">
            <p>Errors:</p>
            <span v-if="item.latestCommandLog[0].data.errors.skippedRows">
              {{item.latestCommandLog[0].data.errors[0].errorMessage}}
              <br>
              Index: {{item.latestCommandLog[0].data.errors[0].index}}
            </span>
            <ul>
              <template v-if="item.latestCommandLog[0].data.errors.skippedRows">
                <li class="error" v-for="(error, index) in item.latestCommandLog[0].data.errors.skippedRows.slice(0, 500)" :key="index">{{error.isin}}: {{error.reason}}</li>
              </template>
              <template v-else>
                <li class="error" v-for="(error, index) in item.latestCommandLog[0].data.errors" :key="index">{{error.errorMessage}}</li>
              </template>
            </ul>
          </div>
          <div>
            <p>Duration: {{item.latestCommandLog[0].data.duration}}</p>
          </div> -->
        </div>
      </div>
      <div v-else>
        <p class="no-info">No info for this data log</p>
      </div>
    </template>
  </popup>
</template>

<script>
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'CommandInfoPopup',
    props: {
      item: Object
    },
    components: {
      Popup
    }
  }
</script>

<style scoped>
  .command-name {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
  }

  .data-message {
    border-bottom: 1px solid #333;
  }

  .no-info {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: #333;
    margin-top: 50px;
  }

  .list-item-row:not(:last-child) {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
  }

  .list-item-sub-row:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #333;
  }

  li {
    padding: 10px 0;
    border-bottom: 1px solid #333;
  }
</style>
