<template>
  <!-- Inspect Stock Splits Report Data Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Stock Splits Report"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="stock-splits-popup">
        <span class="equity-id">
          Equity ID: {{equity.id}}
        </span>

        <a :href="'https://umushroom.com/equity/' + equity.urlName" target="_blank">Go to the equity on the main app</a>

        <div class="field">
          <label for="isin">ISIN:</label>
          <input type="text" id="isin" v-model="equity.isin" placeholder="ISIN..." readonly>
        </div>

        <div class="field">
          <label for="valor">Valor:</label>
          <input type="text" id="valor" v-model="equity.valor" placeholder="Valor..." readonly>
        </div>

        <div class="field">
          <div v-if="equity.equity_data.stock_splits.split_report && equity.equity_data.stock_splits.split_report.length > 0">
            <h4>Split Report:</h4>
            <ul>
              <li class="report-text" v-for="(report, index) in equity.equity_data.stock_splits.split_report" :key="index">
                <div><span class="performed-title">Performed:</span> {{ report.performed }}</div>
                <div><span class="message-title">Message:</span> {{ report.message }}</div>
              </li>
            </ul>
          </div>
        </div>
      
        <span class="error-message" v-if="error">
          {{error}}
        </span>
      </div>
    </template>
  </popup>
</template>

<script>
  import userMixin from '@/mixins/user'
  import Popup from '@/components/shared/Popup'
  
  export default {
    name: 'InspectActiveEquity',
    mixins: [userMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        equity: this.item,
        error: null,
      }
    }
  }
</script>

<style scoped>
  .equity-id {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }

  .stock-splits-popup {
    max-height: 700px;
    overflow: auto;
  }
  
  .performed-title, .message-title{
    font-weight: bold;
  }

  .report-text {
    margin-bottom: 2%;
  }

  .report-text > div {
    margin-bottom: 2px;
  }
</style>
