<template>
  <!-- DataTable Pagination -->
  <div class="pagination">
    <!-- Show first and previous buttons if current page is different than 1 -->
    <template v-if="currentPage != 1">
      <a class="arrow" @click="goToPage('first')">
        <font-awesome-icon icon="angle-double-left" />
      </a>
      <a class="arrow" @click="goToPage('previous')">
        <font-awesome-icon icon="angle-left" />
      </a>
    </template>
    <!-- Show all pages in list if total pages count is less or equal to 5 -->
    <template v-if="pages <= 5">
      <a v-for="n of pages" :key="n" @click="goToPage(n)" :class="{active: n == currentPage}">
        {{n}}
      </a>
    </template>
    <!-- Show only the last pages if current page is 4 or less pages behind the last page -->
    <template v-else-if="pages - 3 <= currentPage">
      <template v-for="n of pages">
        <a :key="n" v-if="n >= pages - 4" :class="{active: n == currentPage}" @click="goToPage(n)">
          {{n}}
        </a>
      </template>
    </template>
    <!-- Showing 3 pages in the beginning than three dots (...) than last 3 pages  -->
    <template v-else>
      <a v-if="currentPage > 1" @click="goToPage(currentPage - 1)">
        {{currentPage - 1}}
      </a>
      <a class="active" @click="goToPage(currentPage)">
        {{currentPage}}
      </a>
      <a @click="goToPage(currentPage + 1)">
        {{currentPage + 1}}
      </a>
      <a class="dots">
        <font-awesome-icon icon="ellipsis-h" />
      </a>
      <a @click="goToPage(pages - 2)">
        {{pages - 2}}
      </a>
      <a @click="goToPage(pages - 1)">
        {{pages - 1}}
      </a>
      <a @click="goToPage(pages)">
        {{pages}}
      </a>
    </template>
    <!-- Showing next and last buttons if current page is different from the last page -->
    <template v-if="currentPage != pages">
      <a class="arrow" @click="goToPage('next')">
        <font-awesome-icon icon="angle-right" />
      </a>
      <a class="arrow" @click="goToPage('last')">
        <font-awesome-icon icon="angle-double-right" />
      </a>
    </template>

    <!-- Go to page input -->
    <div class="go-to-page">
      <label for="page">Go To Page:</label>
      <input type="number" id="page" v-model="goToPageNumber" min="1" :max="pages" @keyup="checkNumber($event)">
      <button class="go-btn transition" @click="goToPage(+goToPageNumber)">Go</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      currentPage: Number,
      pages: Number
    },
    data() {
      return {
        goToPageNumber: null
      }
    },
    methods: {
      // * Triggered when user selects page
      goToPage(page) {
        this.goToPageNumber = null;
        this.$emit('go-to-page', page);
      },
      // * Triggered when user types in go to page input
      checkNumber(event) {
        // * If user hits enter go to page
        if (event.keyCode == 13) {
          this.goToPage(+this.goToPageNumber);
        } else {
          // * Limit user input to min 1 and max the total amount of pages
          if (this.goToPageNumber !== '' && +this.goToPageNumber < 1) {
            this.goToPageNumber = 1;
          } else if (+this.goToPageNumber > this.pages) {
            this.goToPageNumber = this.pages;
          }
        }
      }
    }
  }
</script>

<style scoped>
  .pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .pagination a {
    font-size: 18px;
    min-width: 40px;
    padding: 0 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 0 2px;
    background: #FFF;
    color: #070707;
    font-weight: 500;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    cursor: pointer;
    user-select: none;
  }

  .pagination a:not(.dots):hover,
  .pagination a.active {
    color: #FFF;
    background: #3C6E71;
  }

  /* .pagination a.arrow {
    color: #FFF;
    background: #3C6E71;
    transition: all 0.15s ease-in-out;
  }

  .pagination a.arrow:hover {
    background: #FFF;
    color: #070707;
  } */

  .pagination a.dots {
    color: #a0a0a0;
    align-items: flex-end;
    padding-bottom: 5px;
    cursor: default;
  }

  .go-to-page {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .go-to-page input {
    width: 100px;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 18px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    outline: none;
    margin: 0 10px;
  }

  .go-to-page button.go-btn {
    background: #3c6e71;
    color: #FFF;
    border: none;
    font-weight: 600;
    height: 37px;
    width: 50px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .go-to-page button.go-btn:hover {
    background: #FFF;
    color: #3c6e71;
  }
</style>