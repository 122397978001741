<template>
  <div class="wm-marketing">
    <h1>Financial Partners Marketing</h1>

    <div class="field autocomplete" v-if="wealthManagers">
      <label>Select Financial Partners:</label>
      <vue3-simple-typeahead
        placeholder="Type Financial Partners..."
        :items="wealthManagers"
        :minInputLength="1"
        :itemProjection="(item) => item.name"
        @selectItem="selectWM"
        @onInput="keyUpEvent"
      >
      </vue3-simple-typeahead>
    </div>

    <InspectMarketing
      v-if="selectedWealthManager"
      :user="selectedWealthManager"
      userType="wealthManager"
    />
  </div>
</template>

<script>
  import marketingMixin from '@/mixins/marketing'
  import datesMixin from '@/mixins/dates'

  import InspectMarketing from './popups/InspectMarketing'

  export default {
    name: 'WealthManagersMarketing',
    mixins: [marketingMixin, datesMixin],
    components: {
      InspectMarketing
    },
    data() {
      return {
        wealthManagers: null,
        selectedWealthManager: null
      }
    },
    methods: {
      getUsersPerformanceHandler() {
        const dataToSend = {
          orderBy: 'views',
          orderType: 'desc'
        };

        this.getUsersPerformance(dataToSend, 'wealthManager')
          .then(res => {
            this.wealthManagers = res.performanceData;
          })
      },
      selectWM(wm) {
        this.selectedWealthManager = null;
        setTimeout(() => {
          this.selectedWealthManager = wm;
        }, 100);
      },
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedWealthManager = null;
        }
      }
    },
    mounted() {
      this.getUsersPerformanceHandler();
    }
  }
</script>

<style scoped>
  .field {
    margin: 15px 0;
    display: flex;
    align-items: center;
  }

  .field label:not(.custom-check) {
    color: #44444f;
    font-size: 16px;
    font-weight: 500;
  }

  .field input,
  .field :deep(input) {
    width: 400px;
    height: auto;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #44444f;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  .field input:focus,
  .field :deep(input:focus) {
    border-color: #3C6E71;
  }

  .field button {
    background: #3c6e71;
    color: #FFF;
    font-weight: 500;
    font-size: 18px;
    width: 100px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
  }

  .field button:hover {
    background: #FFF;
    color: #3c6e71;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
</style>
