<template>
  <div class="instrument-rights-page">
    <h1>Permissions <small v-if="total">({{total}})</small></h1>
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['new', 'edit', 'delete']"
      @update-table="getInstrumentRightsHandler"
      @edit="editHandler"
      @add-new="addRightPopup = true"
      @delete="deleteHandler"
    />

    <NewInstrumentRight
      v-if="addRightPopup"
      :users="users"
      @close="addRightPopup = false"
      @created="createdHandler"
    />

    <EditInstrumentRight
      v-if="editItem"
      :right="editItem"
      :users="users"
      @close="editItem = null"
      @updated="updatedHandler"
    />

    <DeleteRight
      v-if="deleteItem"
      :item="deleteItem"
      @close="deleteItem = null"
      @deleted="updatedHandler"
    />
  </div>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewInstrumentRight from './popups/NewInstrumentRight'
  import EditInstrumentRight from './popups/EditInstrumentRight'
  import DeleteRight from './popups/DeleteRight'

  export default {
    name: 'InstrumentRights',
    mixins: [usersMixin, userMixin],
    components: {
      DataTableHolder,
      NewInstrumentRight,
      EditInstrumentRight,
      DeleteRight
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'User',
            name: 'userFullName',
            sortable: true
          },
          {
            title: 'Instrument ID',
            name: 'instrumentId',
            sortable: true
          },
          {
            title: 'Type',
            name: 'instrumentType',
            sortable: true
          },
          {
            title: 'Instrument',
            name: 'instrumentName',
            sortable: true
          },
          {
            title: 'Permission ID',
            name: 'rights',
            sortable: true
          },
          {
            title: 'Permission',
            name: 'rightsName',
            sortable: false
          },
          {
            title: 'Is Visible',
            name: 'isVisible',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Menu Item',
            name: 'menuItem',
            sortable: true
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: false,
        total: null,
        addRightPopup: false,
        fullData: null,
        users: null,
        deleteItem: null
      }
    },
    methods: {
      // * Get users from API
      getInstrumentRightsHandler(data) {
        this.getInstrumentRights(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let rightsFormatted = [];
            // * Loop through permissions and add only the needed properties
            for (const permission of res.InstrumentPermissions) {
              let permissionObj = {
                id: permission.id,
                userFullName: permission.userFullName,
                instrumentId: permission.instrumentId,
                instrumentType: permission.instrumentType,
                instrumentName: permission.instrumentName,
                rights: permission.rights,
                rightsName: this.$options.filters.permissionName(permission.rights),
                isVisible: permission.isVisible,
                addedOn: permission.addedOn,
                menuItem: permission.menuItem,
              }
              // * Add formatted permission to formatted permissions array
              rightsFormatted.push(permissionObj);
            }
            // * Assign table rows to be the formatted permissions array
            this.rows = rightsFormatted;
            this.fullData = res.InstrumentPermissions;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when right is created
      createdHandler() {
        this.addRightPopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(permission => {
          return permission.id == item.id;
        })
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.deleteItem = null;

        this.updateResults = !this.updateResults;
      },
      deleteHandler(item) {
        this.deleteItem = item;
      }
    },
    filters: {
      permissionName(permissionId) {
        if (permissionId == 1) {
          return 'Admin';
        } else if (permissionId == 2) {
          return 'Editor';
        } else if (permissionId == 3) {
          return 'Portfolio Manager';
        } else if (permissionId == 4) {
          return 'Employee';
        } else if (permissionId == 5) {
          return 'Client';
        } else if (permissionId == 6) {
          return 'Member';
        } else if (permissionId == 7) {
          return 'Portfolio Follower';
        } else if (permissionId == 9) {
          return 'Prospect';
        } else {
          return 'Other';
        }
      }
    },
    mounted() {
      this.getUsers()
        .then(res => {
          this.users = res.users;
        })
        .catch(err => {
          if (err.code == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>

</style>