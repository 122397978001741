<template>
  <popup title="User Watch History" @close="$emit('close')">
    <template v-slot:content>
      <div v-if="history.length" class="history-table">
        <table>
          <thead>
            <tr>
              <!-- <th>Chapter ID</th> -->
              <th>Chapter Title</th>
              <!-- <th>Video ID</th> -->
              <th>Video Title</th>
              <th>Completed On</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in history" :key="item.video_id">
              <!-- <td>{{ item.section_id }}</td> -->
              <td>{{ item.section_title }}</td>
              <!-- <td>{{ item.video_id }}</td> -->
              <td>{{ item.video_title }}</td>
              <td>{{ item.completed_on }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="updateLoading" class="loading">Loading...</div>
      <div v-else class="empty-state">No watch history available.</div>
  </template>
  </popup>
</template>

<script>
import knowledgeMixin from "@/mixins/knowledge";
import Popup from "@/components/shared/Popup";

export default {
  name: "InspectUserWatchHistory",
  mixins: [knowledgeMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
      updateLoading: false,
      history: [],
    };
  },
  mounted() {
    this.fetchUserWatchHistory();
  },

  methods: {
    async fetchUserWatchHistory() {
      this.updateLoading = true;
      try {
        const res = await this.getUserWatchHistory(this.user.user_id);
        this.history = [...res];
        console.log("Fetched history:", this.history);
      } catch (err) {
        if (err.code === 401) {
          this.logout();
        } else {
          console.error("Error fetching watch history:", err);
        }
      } finally {
        this.updateLoading = false;
      }
    },
  },
};
</script>


<style scoped>
.history-table {
  overflow-x: auto;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 10px;
}

thead th {
  background-color: #f8f9fa;
  color: #3c6e71;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #e3f2fd;
}

tbody td {
  padding: 10px;
  border: 1px solid #e3f2fd;
}

.loading {
  text-align: center;
  color: #999;
  margin-top: 20px;
}

.empty-state {
  text-align: center;
  color: #999;
  font-style: italic;
  margin-top: 20px;
}
</style>