<template>
  <div class="NotificationSender">
    <div class="btn-holder">
      <button class="send-btn" @click="sendNotificationHandler()" :disabled="isSending" title="Save changes before sending test notification">
        Send Test Notification
      </button>
    </div>
    <p v-if="successMsg" class="success-message">{{successMsg}}</p>
    <p v-if="errorMsg" class="error-message">{{errorMsg}}</p>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notification";

export default {
  name: "NotificationSender",
  mixins: [notificationMixin],
  props: {
    notificationTypeId: Number,
    language: String,
    contentData: Object,
    selectedObjects: Object,
    users: Array,
  },
  data() {
    return {
      successMsg: '',
      errorMsg: '',
      isSending: false,
    };
  },
  methods: {
    sendNotificationHandler() {
      if (!this.validateSelectedObjects()) {
        setTimeout(() => {
          this.errorMsg = '';
        }, 4000);

        return;
      }

      this.isSending = true;
      const selectedObjects = Object.keys(this.selectedObjects).map(key => ({
        type: this.selectedObjects[key],
        name: this.getObjectValue(this.selectedObjects[key])
      }));

      const dataToSend = {
        notificationTypeId: this.notificationTypeId,
        language: this.language,
        selectedObjects: selectedObjects,
        users: this.users,
      };

      this.sendNotification(dataToSend)
        .then(() => {
          this.successMsg = "Notification has been successfully sent. Make sure to check the main app to view it! ";
        })
        .catch(() => {
          this.errorMsg = "Failed to Send Notification. Please try again later.";
        })
        .finally(() => {
          this.isSending = false;
          setTimeout(() => {
            this.successMsg = "";
            this.errorMsg = "";
          }, 4000);
        });
    },
    validateSelectedObjects() {
      const objectItems = this.contentData[this.language]?.items.filter(item => item.type === 'object');
      const objectCount = objectItems ? objectItems.length : 0;
      let isCountEqual = objectCount === Object.keys(this.selectedObjects).length;
      if (!isCountEqual) {
        this.errorMsg = 'Please select object types for all items before sending the notification';
        return false;
      }

      return true;
    },
    getObjectValue(objectType) {
      switch (objectType) {
        case 'user': return "heinsweerts@gmail.com";
        case 'wealthManager': return "Zürcher Kantonalbank";
        case 'communityGroup': return "Team UMushroom";
        case 'assetManager': return "UBS Asset Management";
        case 'contest': return "Switzerland for Financial Literacy 2024";
        case 'broker': return "Swissquote";
        case 'fundUmbrella': return "Vanguard Global Bd Idx";
        case 'equity': return "Apple Inc";
        case 'etf': return "abrdn Global Real Estate Active Thematics UCITS ETF USD Accumulating";
        case 'fund': return "BlackRock Institutional Cash Series Euro Liquidity Fund Premier Acc EUR";
        case 'portfolio': return "US Tech Behemoths";
        default: return "";
      }
    },
  },
};
</script>

<style scoped>
.btn-holder {
  text-align: center;
}

.send-btn {
  background-color: var(--dark-green);
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
  border: 0;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.send-btn:hover {
  background: #fafafb;
  color: #3c6e71;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
