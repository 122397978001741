<template>
  <!-- Commands Log Page -->
  <div class="commands-log-page">
    <h1>Commands Log <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :actions="['edit']"
      @update-table="getCommandsLogHandler"
      @edit="infoPopupItemHandler"
    />

    <CommandInfoPopup
      v-if="infoPopupItem"
      :item="infoPopupItem"
      @close="infoPopupItem = null"
    />
  </div>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import CommandInfoPopup from './popups/CommandInfoPopup'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'CommandsLog',
    mixins: [systemMixin, userMixin],
    components: {
      CommandInfoPopup,
      DataTableHolder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Command',
            name: 'command',
            sortable: true
          },
          {
            title: 'Latest Runs',
            name: 'latestRuns',
            sortable: false
          },
          {
            title: 'Next Run',
            name: 'nextRun',
            sortable: false
          },
          {
            title: 'Started At',
            name: 'startedAt',
            sortable: true
          },
          {
            title: 'Ended At',
            name: 'endedAt',
            sortable: true
          }
        ],
        infoPopupItem: null,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get commands log from API
      getCommandsLogHandler(data) {
        this.getCommandsPaginatedLog(data)
          .then(res => {
            // * After receiving response update data
            let rowsToAssign = [];
            let commands = res.commands;
            commands.forEach(command => {
              let latestRuns = '';
              command.latestCommandLogStatus.forEach(run => {
                let selectedClass;
                if (run.status == 1) {
                  selectedClass = 'gray';
                } else if (run.status == 2) {
                  selectedClass = 'red';
                } else if (run.status == 3) {
                  selectedClass = 'orange';
                } else {
                  selectedClass = 'green';
                }

                latestRuns += `
                  <span class="${selectedClass}">
                    <div class="tooltip">
                      <div>
                        <strong>Started At:</strong>
                        ${run.startedAt}
                      </div>
                      <div>
                        <strong>Ended At:</strong>
                        ${run.endedAt}
                      </div>
                      <div>
                        <strong>Duration:</strong>
                        ${run.days > 0 ? run.days : ''}${run.days > 0 ? 'd' : ''}
                        ${run.hours > 0 ? run.hours : ''}${run.hours > 0 ? 'h' : ''}
                        ${run.minutes > 0 ? run.minutes : ''}${run.minutes > 0 ? 'm' : ''}
                        ${run.seconds > 0 ? run.seconds : ''}${run.seconds > 0 ? 's' : ''}
                      </div>
                      <hr>
                      <strong>Command progress:</strong><br>
                      ${run.startRow} <progress value="${run.currentRow}" max="${run.totalRows}"></progress> ${run.totalRows}
                      <div>
                        <strong>Start Row:</strong>
                        ${run.startRow}
                      </div>
                      <div>
                        <strong>Current Row:</strong>
                        ${run.currentRow}
                      </div>
                      <div>
                        <strong>Total Rows:</strong>
                        ${run.totalRows}
                      </div>
                    </div>
                  </span>
                `;
              })

              let obj = {
                id:         command.command.id,
                command:    `${command.command.microService} | ${command.command.commandShortDescription}<br><b>${command.command.commandName}</b>`,
                latestRuns: latestRuns,
                nextRun:    `${command.command.nextRun}<br><i>${command.command.nextRunHumanTime}</i>`,
                startedAt:  command.latestCommandLog[0] && command.latestCommandLog[0].startedAt ? command.latestCommandLog[0].startedAt.date : null,
                endedAt:    command.latestCommandLog[0] && command.latestCommandLog[0].endedAt ? command.latestCommandLog[0].endedAt.date : null
              };

              rowsToAssign.push(obj);
            })
            this.rows = rowsToAssign;
            this.fullData = res.commands;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Open info popup
      infoPopupItemHandler(row) {
        let itemForInfo = this.fullData.find(command => {
          return command.command.id == row.id;
        })
        this.infoPopupItem = itemForInfo;
      }
    }
  }
</script>

<style scoped>

</style>