<script>
import axios from "axios";

if (localStorage.getItem("x-auth-token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("x-auth-token");
}

export default {
  methods: {
    getBanners() {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/banners`, {
          method: "GET",
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data.code);
          });
      });
    },
    getBannerExcelReport(objectType, objectId) {
      return new Promise((resolve, reject) => {
        axios(
          `${this.$store.state.global.apiUrl}/backoffice/banner/excel-report/${objectType}/${objectId}`,
          {
            method: "POST",
            responseType: 'blob'
          }
        )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data.message);
          });
      });
    },
  },
};
</script>
