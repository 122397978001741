<template>
  <div class="users-page">
    <h1>
      Users <small v-if="total">({{ total }})</small>
    </h1>
    <div class="master-filter" ref="filtersContainer" v-show="filtersDisplayed">
      <div class="filters-title">Master Filter</div>
      <div class="filters-container">
        <div class="checkboxes-wrapper">
          <div class="checkbox-container" v-for="(checkbox, index) in filters.checkboxes" :key="index">
            <div @mouseover="activeFilterIndex = checkbox.tableName" @mouseout="activeFilterIndex = -1">
              <input :id="index" type="checkbox" :indeterminate="checkbox.value === null" :checked="checkbox.value" @change="checkBoxChangeHandler(checkbox)"/>
              <label :for="index">{{ checkbox.name }}</label>
            </div>
          </div>
        </div>
        <div class="dropdowns-wrapper">
          <div class="dropdown-container" v-for="(dropdown, index) in filters.dropdowns" :key="index">
            <div @mouseover="activeFilterIndex = dropdown.tableName" @mouseout="activeFilterIndex = -1">
              <multiselect
                v-model="dropdown.selectedOptions"
                :options="dropdown.dropdownOptions"
                :label="dropdown.label"
                :placeholder="dropdown.placeholder"
                :multiple="dropdown.multiselect"
                track-by="id"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="filter-buttons">
          <button class="apply-filter" @click="applyFilters">Apply Filters</button>
          <button class="reset-filter" @click="resetFilters">Reset Filters</button>
        </div>
      </div>
    </div>

    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit', 'delete']"
      @update-table="getUsersHandler"
      @edit="editHandler"
      @delete="deleteHandler"
    />

    <EditUser
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />

    <DeleteUser
      v-if="deleteItem"
      :item="deleteItem"
      @close="deleteItem = null"
      @deleted="updatedHandler"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import usersMixin from "@/mixins/users";
import userMixin from "@/mixins/user";
import countryMixin from "@/mixins/country";
import quizMixin from '@/mixins/quiz';

import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
import EditUser from "./popups/EditUser";
import DeleteUser from "./popups/DeleteUser";

export default {
  name: "Users",
  mixins: [usersMixin, userMixin, countryMixin, quizMixin],
  components: {
    Multiselect,
    DataTableHolder,
    EditUser,
    DeleteUser,
  },
  data() {
    return {
      filters: {
        checkboxes: {
          isDeleted: {
            name: "Deleted",
            value: false,
            indeterminate: true,
            tableName: 'isDeleted'
          },
          emailVerified: {
            name: "Verified",
            value: null,
            indeterminate: true,
            tableName: 'emailVerified'
          },
          isTesting: {
            name: "Is Testing",
            value: null,
            indeterminate: true,
            tableName: 'isTesting'
          },
          hasPortfolios: {
            name: "Has Portfolios",
            value: null,
            indeterminate: true,
            tableName: 'portfoliosCount'
          },
        },
        dropdowns: {
          domicile: {
            selectedOptions: [],
            dropdownOptions: [],
            label: 'country', // need to point to dropdownOptions
            placeholder: "Select Domiciles",
            multiselect: true,
            tableName: 'domicile'
          },
          language: {
            selectedOptions: [],
            dropdownOptions: [
              { id: 1, name: "English" },
              { id: 2, name: "German" },
            ],
            label: 'name', // need to point to dropdownOptions
            placeholder: "Select Languages",
            multiselect: true,
            tableName: 'language'
          },
          membershipType: {
            selectedOptions: [],
            dropdownOptions: [
              { id: 0, name: "Explore" },
              { id: 1, name: "Learn" },
              { id: 2, name: "Grow" },
            ],
            label: 'name', // need to point to dropdownOptions
            placeholder: "Select Memberships",
            multiselect: true,
            tableName: 'membershipType'
          },
          quizResult: {
            selectedOptions: [],
            dropdownOptions: [],
            label: 'name', // need to point to dropdownOptions
            placeholder: "Select Quiz Results",
            multiselect: true,
            tableName: 'quizResult'
          },
        },
      },
      initialFilters: {},
      isDataFetched: false,
      activeFilterIndex: -1,
      filtersToSend: {},
      filtersDisplayed: false,
      rows: null,
      columns: [
        {
          title: "ID",
          name: "id",
          sortable: true,
        },
        {
          title: "First Name",
          name: "firstName",
          sortable: true,
        },
        {
          title: "Last Name",
          name: "lastName",
          sortable: true,
        },
        {
          title: "Email",
          name: "email",
          sortable: true,
        },
        {
          title: "Domicile",
          name: "domicile",
          sortable: true,
        },
        {
          title: "Rule Group",
          name: "ruleGroup",
          sortable: false,
        },
        {
          title: "High Net Worth",
          name: "highNetWorthIndividual",
          sortable: false,
        },
        {
          title: "Language",
          name: "language",
          sortable: true,
        },
        {
          title: "Membership",
          name: "membershipType",
          sortable: true,
        },
        {
          title: "Quiz Result",
          name: "quizResult",
          sortable: true,
        },
        {
          title: "Email Verified",
          name: "emailVerified",
          sortable: true,
        },
        {
          title: "Email Verified On",
          name: "emailVerifiedOn",
          sortable: true,
        },
        {
          title: "Panels Enabled",
          name: "panelsEnabled",
          sortable: true,
        },
        {
          title: "Log Disabled",
          name: "logDisabled",
          sortable: true,
        },
        {
          title: "Back Office Role",
          name: "backOfficeRole",
          sortable: true,
        },
        {
          title: "Portfolios",
          name: "portfoliosCount",
          sortable: true,
        },
        {
          title: "Is Testing",
          name: "isTesting",
          sortable: true,
        },
        {
          title: "Is Deleted",
          name: "isDeleted",
          sortable: true,
        },
      ],
      editItem: null,
      pages: 0,
      updateResults: false,
      total: null,
      deleteItem: null,
    };
  },
  methods: {
    // * Get users from API
    getUsersHandler(data) {
      const newData = { ...data, filter: this.filtersToSend};
      this.getUsersPaginated(newData)
        .then((res) => {
          // * After receiving response update data
          this.pages = res.pagesCount;
          let usersFormatted = [];
          // * Loop through users and add only the needed properties
          for (const user of res.userRows) {
            let userLanguage = user.language;
            if(userLanguage == 1) {
              userLanguage = 'English';
            } else if (userLanguage == 2) {
              userLanguage = 'German';
            }

            let userObj = {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              domicile: user.domicile,
              ruleGroup: user.ruleGroup,
              highNetWorthIndividual: user.highNetWorthIndividual,
              language: userLanguage,
              membershipTypeName: user.membershipTypeName,
              quizResult: user.quizResult,
              emailVerified: user.emailVerified,
              emailVerifiedOn: user.emailVerifiedOn,
              panelsEnabled: user.panelsEnabled,
              logDisabled: user.logDisabled,
              backOfficeRole: user.backOfficeRole,
              portfoliosCount: user.portfoliosCount,
              isTesting: user.isTesting,
              isDeleted: user.isDeleted,
            };
            // * Add formatted user to formatted users array
            usersFormatted.push(userObj);
          }
          // * Assign table rows to be the formatted users array
          this.rows = usersFormatted;
          this.total = res.resultsCount;
        })
        .catch((err) => {
          if (err == 401) {
            this.logout();
          }
        });
    },
    // * Triggered when user click the pen icon on a row
    editHandler(item) {
      this.editItem = item;
    },
    // * Triggered when user updates item from the edit popup
    updatedHandler() {
      // * Null the editing item
      this.editItem = null;
      this.deleteItem = null;

      this.updateResults = !this.updateResults;
    },
    deleteHandler(item) {
      this.deleteItem = item;
    },
    checkBoxChangeHandler(checkbox) {
      // if indeterminate is enabled for this checkbox - apply different behaviour
      if (checkbox.indeterminate) {
        switch (checkbox.value) {
          case null:
            checkbox.value = true;
            break;
          case true:
            checkbox.value = false;
            break;
          case false:
            checkbox.value = null;
            break;
        }
      } else {
        switch (checkbox.value) {
          case true:
            checkbox.value = false;
            break;
          case false:
            checkbox.value = true;
            break;
        }
      }
    },
    applyFilters() {
      // prepare filters for checkboxes and dropdowns to be send to BE
      let checkboxes = this.filters.checkboxes;
      let dropdowns = this.filters.dropdowns;
      this.filtersToSend = {};

      for (const key in checkboxes) {
        let checkBoxValue = checkboxes[key].value;
        if(checkBoxValue !== null) {
          this.filtersToSend[key] = checkBoxValue;
        }
      }

      for (const key in dropdowns) {
        let dropdownSelectedOptions = dropdowns[key].selectedOptions;
        if(dropdownSelectedOptions && dropdownSelectedOptions.length > 0) {
          this.filtersToSend[key] = dropdownSelectedOptions;
        }
      }

      // update table data
      this.updateResults = !this.updateResults;
    },
    resetFilters() {
      //clear filters before updating table data and clear filters visualization
      this.filters = JSON.parse(JSON.stringify(this.initialFilters));
      this.applyFilters();
    },
    fetchDomiciles() {
      this.getDomiciles()
        .then((domiciles) => {
          if(domiciles) {
            this.filters.dropdowns.domicile.dropdownOptions = domiciles;
            this.isDataFetched = true;
          }
        })
        .catch((error) => {
          console.error("Error fetching domiciles:", error);
        });
    },
    fetchQuizResults() {
      this.getQuizResults()
        .then((result) => {
          if(result && result.quizResults) {
            this.filters.dropdowns.quizResult.dropdownOptions = result.quizResults;
            this.isDataFetched = true;
          }
        })
        .catch((error) => {
          console.error("Error fetching quizes:", error);
        });
    }
  },
  created() {
    this.fetchDomiciles();
    this.fetchQuizResults();
    this.applyFilters();
  },
  watch: {
    isDataFetched: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initialFilters = JSON.parse(JSON.stringify(this.filters));
          this.isDataFetched = false;
        }
      },
    },
    activeFilterIndex(newIndex) {
      this.columns.find((column) => {
        if(newIndex !== -1) {
          let columnName = column.name.toLowerCase();
          let hoveredFilter = newIndex.toLowerCase();
          if(columnName == hoveredFilter) {
            column['customClass'] = 'highlighted';
          } else {
            column['customClass'] = '';
          }
        } else {
          column['customClass'] = '';
        }
      });
    }
  },
  updated() {
    // Find the target element with class "table-actions" (Coming from DataTableHolder -> DataTable)
    const tableActionsElement = document.querySelector('.datatable-container .table-actions');
    const filtersContainer = this.$refs.filtersContainer;

    // Move the filters container after the tableActionsElement
    if (tableActionsElement && filtersContainer && !this.filtersDisplayed) {
      tableActionsElement.parentNode.insertBefore(filtersContainer, tableActionsElement.nextSibling);
      this.filtersDisplayed = true;
    }
  }
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.master-filter {
  margin: 2% 0%;
}
.filters-title {
  font-size: 20px;
  font-weight: 400;
}

.filters-container {
  width: 40%;
  background-color: white;
  padding: 1.5% 1%;
  margin: 0px 0px 1.5% 0px;
}

.checkboxes-wrapper {
  border-bottom: 1px solid gray;
}

.checkboxes-wrapper, .dropdowns-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.dropdowns-wrapper {
  justify-content: space-between;
}

.checkbox-container, .dropdown-container {
  margin-right: 20px;
  margin-bottom: 10px;
}

.dropdown-container .multiselect {
  width: 300px;
  cursor: pointer;
}

.filter-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.filter-buttons button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.filter-buttons button.apply-filter {
  background-color: #3c6e71;
  color: #fff;
}

.filter-buttons button.reset-filter {
  background-color: #a31111;
  color: #fff;
}

/* Change default checkbox behaviour and visualization */
.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container input[type="checkbox"] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  vertical-align: -20%;
  height: 2ex;
  width: 2ex;
  background-color: white;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 0.5em;
}

.checkbox-container input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  width: 1.2ex;
  height: 0.4ex;
  background: rgba(0, 0, 0, 0);
  top: 0.9ex;
  left: 0.4ex;
  border: 3px solid rgb(2, 124, 2);
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.filters-container .checkbox-container
  input[type="checkbox"]:not(:checked):not(:indeterminate)
  + label::after {
  display: block;
  content: "✕";
  position: absolute;
  top: 0.65em;
  left: 0.1em;
  font-size: 1.2em;
  color: #b60f0f;
  line-height: 0;
  font-weight: bold;
  transition: all 0.2s;
}
</style>

