<template>
    <div class="pages-page">
        <!-- Total Pages -->
        <h1>Pages<small v-if="total"> ({{ total }})</small></h1>

        <!-- Results Table -->
        <DataTableHolder
            :columns="columns"
            :column-search="false"
            :rows="filteredRows"
            :totalPages="pages"
            :actions="['new', 'edit']"
            :itemsPerPage="500"
            @update-table="getPagesHandler"
            @edit="editHandler"
            @add-new="showAddPopup=true"
        />

        <!-- Add Popup -->
        <AddPagePopup
            v-if="showAddPopup"
            :item="addItem"
            @added="getPagesHandler(); showAddPopup=false;"
            @close="showAddPopup=false"
        />

        <!-- Edit Popup -->
        <EditPagePopup
            v-if="showEditPopup"
            :item="editItem"
            @updated="getPagesHandler(); showEditPopup=false;"
            @close="showEditPopup=false"
        />
    </div>
</template>
  
<script>
    import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";
    import AddPagePopup from "@/components/system/popups/AddPage.vue";
    import EditPagePopup from "@/components/system/popups/EditPage.vue";
    
    import systemMixin from "@/mixins/system.vue";
    import userMixin from '@/mixins/user'

    export default {
        name: "Pages",

        mixins: [ systemMixin, userMixin ],
        
        components: {
            DataTableHolder,
            EditPagePopup,
            AddPagePopup
        },
        
        data() {
            return {
                rows: [],
                columns: [
                    {
                        title: "ID",
                        name: "id",
                        sortable: false,
                    },
                    {
                        title: "Title",
                        name: "title",
                        sortable: false,
                    },
                    {
                        title: "Url Name",
                        name: "urlName",
                        sortable: false,
                    },
                    {
                        title: "Content",
                        name: "content",
                        sortable: false,
                    },
                    {
                        title: "Type",
                        name: "type",
                        sortable: false,
                    },
                    {
                        title: "Connected Page ID",
                        name: "connectedPageId",
                        sortable: false,
                    },
                    {
                        title: "Added On",
                        name: "addedOn",
                        sortable: false,
                    },
                    {
                        title: "Updated On",
                        name: "updatedOn",
                        sortable: false,
                    },
                ],
                pages: 0,
                showAddPopup: false,
                showEditPopup: false,
                editItem: null,
            };
        },

        computed: {
            filteredRows() {
                return this.rows
                    .map(row => ({
                        id: row.id,
                        title: row.title,
                        urlName: row.urlName,
                        content: this.extractText(row.content, 200),
                        type: row.type,
                        connectedPageId: row.connectedPageId,
                        addedOn: row.addedOn,
                        updatedOn: row.updatedOn,
                    }));
            },

            total() {
                return this.filteredRows ? this.filteredRows.length : 0;
            },
        },

        methods: {
            extractText(html, charLimit) {
                // Create a temporary element to parse HTML
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = html;

                // Extract text content
                const text = tempDiv.textContent || tempDiv.innerText || '';

                // Return the truncated text
                return text.length > charLimit ? text.substring(0, charLimit) + '...' : text;
            },

            editHandler(item) {
                this.editItem = this.rows.find((result) => {
                    return result.id === item.id;
                });

                this.showEditPopup = true;
            },

            getPagesHandler(data) {
                this.getAllPages(data)
                    .then((res) => {
                        this.rows = res.pages || [];
                    })
                    .catch((err) => {
                        if (err === 401) {
                            this.logout();
                        }
                    });
            },
        },

        mounted() {},
    };
</script>

<style scoped>
.pages-page {
    margin: 20px;
}

h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

small {
    font-size: 16px;
    color: #888;
}

.date-filter {
    display: flex;
    align-items: center;
}

.field {
    margin: 15px 0 30px;
    display: flex;
    align-items: center;
}

.field label:not(.custom-check) {
    color: #44444f;
    font-size: 16px;
    font-weight: 500;
    width: 100px;
}

.field input,
.field :deep(input) {
    width: 200px;
    height: auto;
    box-shadow: none;
    line-height: initial;
    background: #fafafb;
    border: 1px solid #44444f;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
}

.field input:focus,
.field :deep(input:focus) {
    border-color: #3c6e71;
}

.field button {
    background: #3c6e71;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    width: 100px;
    height: 51px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
}

.field button:hover {
    background: #fff;
    color: #3c6e71;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.toggle-container {
    margin-bottom: 20px;
    text-align: right;
}

.toggle-container button {
    background: #3c6e71;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
}

.toggle-container button.active {
    background: #295456;
}

.toggle-container button:hover {
    background: #295456;
}

:deep(.popup .title) {
    padding-right: 40px;
}
</style>