<template>
  <popup
      title="Inspect Umu Description"
      @close="$emit('close')"
  >

    <template v-slot:content>

      <span class="info-text">
        Name: {{name}}
      </span>

      <span class="info-text">
        ID: {{id}}
      </span>

      <span class="info-text">
        ISIN: {{isin}}
      </span>


      <!-- Manually modify description -->
      <span>
        <a :href="getSecurityUrl()"
           target="_blank"
        >
          Go to security on web app
        </a>

      </span>

      <div class="field">
        <label for="ai-content"> Umu Description (EN): </label>
        <span class="remove-warning" v-if="hasCustomDescription">
          This item has a set custom description. Ask permission before you change it!
        </span>
        <textarea type="text" id="ai-content" v-model="umuDescription"></textarea>
      </div>

      <div class="field">
        <label for="ai-content"> Umu Description (DE): </label>
        <textarea type="text" id="ai-content-de" v-model="umuDescriptionDe"></textarea>
      </div>

<!--       source url-->
      <div class="field">
        <label for="ai-content-source"> Url Source: </label>
        <a :href="source" target="_blank">Open Link</a>
        <textarea type="text" id="ai-content-source" v-model="source"></textarea>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isFromSource">
          <span class="checkmark"></span>
          Is from source
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="translate">
          <span class="checkmark"></span>
          Translate
        </label>
      </div>

      <!-- Paste context for chatGPT -->
      <div class="field">
        <label for="isin"><strong>Summarise with ChatGPT:</strong></label>
        <template v-if="!isUpdateLoading">
          <button class="set-field-btn"
                  @click="fetchChatGptSummary"
                  :disabled="isUpdateLoading"
                  v-if="chatContext">
            Summarise
          </button>
        </template>
        <template v-else>
          Loading...
        </template>
        <input type="text" id="isin" v-model="chatContext" placeholder="Context..." />
      </div>


      <!-- save button -->
      <button class="transition save-btn" @click="updateSecurityHandler()" :disabled="isUpdateLoading">
        Save
      </button>
    </template>
  </popup>
</template>

<script>
import Popup from "@/components/shared/Popup.vue";
import securities from "@/mixins/securities.vue";
import users from "@/mixins/users.vue";

export default {
  name: 'InspectUmuCompanyDescription',
  mixins: [securities, users],
  props: {
    item: Object
  },
  components: {
    Popup,
  },
  data() {
    return {
      id: this.item.id,
      name: this.item.name,
      isin: this.item.isin,
      url: this.item.url,
      umuDescription: this.item.umu_description,
      umuDescriptionDe: this.item.umu_description_de,
      source: this.item.source,
      isFromSource: this.item.is_from_source,
      translate: false,
      isUpdateLoading: false,
      isLoadingChatGptSummary: false,
      chatContext: null,
      hasCustomDescription: this.item.has_custom_description,
    }
  },

  methods: {
    updateSecurityHandler(){
      this.isUpdateLoading = true;
      let dataToSend = {
        umuDescription: this.umuDescription !== this.item.umu_description ? this.umuDescription : null,
        umuDescriptionDe: this.umuDescriptionDe !== this.item.umu_description_de ? this.umuDescriptionDe : null,
        source: this.source !== this.item.source ? this.source : null,
        isFromSource: this.isFromSource !== this.item.is_from_source ? this.isFromSource : null,
        translate: this.translate
      }
      this.updateSecurity(dataToSend, this.id)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code === 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
    },
    getSecurityUrl() {
      return "https://umushroom.com/en/equity/" + this.url;
    },

    fetchChatGptSummary(){
      this.isUpdateLoading = true;
      const prompt = "Write a short one paragraph description of " + this.name + "'s business based on the following source as well as your knowledge as a financial journalist. The source is a description from the company's about page itself. Special instructions: Write objectively and avoid repeating the exaggerations, repetitions, and marketing expressions from the source; Ignore irrelevant information from the source with respect to the company's business, such as website user specific data privacy statements, and ignore ESG statements about the company's efforts regarding decarbonization or social governance; If the source lacks general information about the company, use your knowledge to briefly introduce it; Write in English. Here is the about page source:\n";
      let dataToSend = {
        context: prompt + this.chatContext,
      }

      this.getChatGptSummary(dataToSend)
          .then(response => {
            this.umuDescription = response.answer;
            this.isUpdateLoading = false;
            // this.updateLoading = false;
            // this.updateSecurityHandler();
          })
          .catch(err => {
            this.isUpdateLoading = false;
            if (err.code === 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
    },


  },

  watch: {

  },

  computed: {

  },

  mounted() {

  }
}
</script>

<style scoped>
.info-text {
  display: block;
  color: #171725;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  text-transform: uppercase;
}

a {
  color: #3C6E71;
}

a:hover {
  text-decoration: none;
}

:deep(.set-field-btn) {
  margin-left: 3%;
  background-color: #FFF;
  color: #3C6E71;
  border: solid thin #3C6E71;
  outline: none;
  width: 30%;
  height: 30px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

:deep(.set-field-btn:not(:disabled):hover) {
  background-color: #3C6E71;
  color: #FFF;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border: none;
}

textarea#ai-content, textarea#ai-content-de {
  height: 300px;
}

textarea#ai-content-source {
  height: 80px;
}

</style>