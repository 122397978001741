<template>
  <!-- Main component -->
  <div id="app" :class="{'user-page-container': isUserComponent}">
    <!-- Header -->
    <Header v-if="!isUserComponent" />
    <!-- Route selected component is loaded here -->
    <div class="content-holder" :class="{'in-user-component': isUserComponent}">
      <router-view :key="$route.fullPath" />
    </div>
    <!-- Footer -->
    <Footer v-if="!isUserComponent" />

    <!-- Go to top arrow -->
    <div class="go-to-top transition" :class="{active: scrollPosition > 200}" @click="scrollToTop()">
      <font-awesome-icon icon="angle-up" />
    </div>
  </div>
</template>

<script>
  import userMixin from '@/mixins/user'

  import Header from '@/components/core/Header'
  import Footer from '@/components/core/Footer'

  export default {
    mixins: [userMixin],
    components: {
      Header,
      Footer
    },
    data() {
      return {
        scrollPosition: null
      }
    },
    methods: {
      updateScroll() {
        this.scrollPosition = window.scrollY
      },
      scrollToTop() {
        window.scrollTo({ 
          top: 0, 
          behavior: 'smooth' 
        });
      }
    },
    computed: {
      isUserComponent() {
        return this.$store.state.auth.isUserComponent;
      },
      userToken() {
        return this.$store.state.auth.userToken;
      }
    },
    mounted() {
      // * On app load if user is logged get his/her details
      if (this.userToken) {
        this.getUserInfo()
          .then(res => {
            this.$store.commit('setUserInfo', res.user);
          })
          .catch(err => {
            if (err == 401 || err == 400) {
              this.logout();
            }
          })
      }
      
      window.addEventListener('scroll', this.updateScroll);
    }
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

  :root {
    /*color vars */
    --text-black:#373737;
    --dark-green:#3C6E71;
    --light-green:#749799;
    --dark-gray:#4D4D4D;
    --bg-gray:#F3F3F3;
    --footer-text:#5C5C5C;
    --subtitle-dark:#120F1F;
    --pink:#E39693;
    --blue:#013DB3;
  }

  /* Common Styles */
  * {
    box-sizing: border-box;
  }

  *::-moz-selection { /* Code for Firefox */
    color: #FAFAFB;
    background: #3C6E71;
  }

  *::selection {
    color: #FAFAFB;
    background: #3C6E71;
  }

  body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #FAFAFB;
  }

  #app {
    min-height: 100vh;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }

  #app.user-page-container {
    background-color: #EBEBEB;
  }

  #app .content-holder {
    max-width: 95%;
    margin: 30px auto;
    min-height: calc(100vh - 250px);
  }

  #app .content-holder.in-user-component {
    margin: 0 auto;
  }

  body * {
    font-family: 'Poppins', 'Arial', sans-serif;
  }
  
  h1 small {
    font-weight: 500;
    font-size: 20px;
    vertical-align: middle;
  }

  input,
  textarea {
    appearance: none;
  }

  .green-btn {
    background: #3C6E71;
    color: #FAFAFB;
    border-radius: 8px;
    height: 40px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
  }

  .green-btn:not(:disabled):hover {
    background: #FAFAFB;
    color: #3C6E71;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .green-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .transition {
    transition: all 0.25s ease-in-out;
  }
  
  .relative {
    position: relative;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }

  .mb-30 {
    margin-bottom: 30px !important;
  }

  .mb-40 {
    margin-bottom: 40px !important;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .lowercase {
    text-transform: lowercase;
  }

  .pre-line {
    white-space: pre-line;
  }

  .white-box {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 25px;
  }
  
  .percent.green {
    color: #3DD598;
  }

  .percent.red {
    color: #FC5A5A;
  }

  @media (max-width: 1024px) {
    html, body {
      max-width: 100%;
      /* overflow-x: hidden; */
    }
  }

  /* Item Boxes */
  .items-box-holder {
    width: 90%;
    margin: 0 auto;
  }

  .items-box {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 25px 0;
    overflow: hidden;
  }

  .items-box .box-title {
    margin-top: 0;
    margin-bottom: 25px;
    padding-left: 30px;
    font-weight: 600;
    font-size: 16px;
    color: #171725;
    text-transform: uppercase;
  }

  .items-box .carousel-holder {
    padding-left: 30px;
    width: 90%;
  }

  .items-box .carousel-holder .owl-carousel {
    position: initial;
  }

  .items-box .carousel-holder .owl-carousel .owl-stage-outer {
    position: initial;
    overflow: visible !important;
  }

  .items-box .carousel-holder .owl-carousel .owl-item {
    transform: none !important;
  }

  .items-box .carousel-holder .owl-carousel .owl-item:empty {
    display: none;
  }

  .items-box .carousel-holder .owl-carousel .owl-nav .owl-prev,
  .items-box .carousel-holder .owl-carousel .owl-nav .owl-next {
    font-size: 0;
    width: 50px;
    height: 50px;
    color: #FFF;
    background: #3C6E71;
    border: 4px solid #FFF;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .items-box .carousel-holder .owl-carousel .owl-nav .owl-prev {
    left: -20px;
  }

  .items-box .carousel-holder .owl-carousel .owl-nav .owl-next {
    right: -20px;
  }

  .items-box .carousel-holder .owl-carousel .owl-nav .owl-prev:hover,
  .items-box .carousel-holder .owl-carousel .owl-nav .owl-next:hover {
    color: #3C6E71;
    background: #FFF;
    border-color: #3C6E71;
  }

  .items-box .carousel-holder .owl-carousel .owl-nav .owl-prev:after,
  .items-box .carousel-holder .owl-carousel .owl-nav .owl-next:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    font-size: 24px;
  }

  .items-box .carousel-holder .owl-carousel .owl-nav .owl-prev:after {
    content: '\f053';
    transform: translateX(-2px);
  }

  .items-box .carousel-holder .owl-carousel .owl-nav .owl-next:after {
    content: '\f054';
    transform: translateX(2px);
  }

  .list-items-box {
    background: #FFFFFF;
    border-radius: 20px;
    width: 90%;
    margin: 0 auto 30px auto;
    padding: 25px;
  }
  
  .list-items-box .title-area {
    display: flex;
    justify-content: space-between;
  }

  .list-items-box h4 {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 600;
    color: #171725;
  }

  .list-items-box .title-area .see-all-link {
    color: #3C6E71;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer
  }

  .list-items-box .title-area .see-all-link:hover {
    text-decoration: underline;
  }

  .list-items-box .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .items-flex-box {
    display: flex;
  }

  .items-flex-box .item {
    flex: 0 1 calc(25% - 30px);
    margin-right: 30px;
  }

  .actions a .action-tooltip {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -5px);
    background: #000000;
    color: #FFF;
    padding: 2px 7px;
    white-space: nowrap;
    border-radius: 25px;
    font-size: 13px;
    font-weight: 500;
    z-index: 10;
  }

  .actions a:hover .action-tooltip {
    display: block;
  }

  .big-sign-up {
    background-color: #3C6E71;
    color: #FFF;
    border: 0;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    font-size: 30px;
    font-weight: 600;
    width: 300px;
    height: 85px;
    display: block;
    margin: 100px auto;
    cursor: pointer;
    outline: none;
  }

  .big-sign-up:hover {
    background-color: #FFF;
    color: #3C6E71;
  }
  
  /* Custom Checks */
  .custom-check.yellow {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    color: #696974;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-check.yellow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-check.yellow .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 3px;
  }

  .custom-check.yellow input:checked ~ .checkmark {
    background-color: #FFC542;
  }

  .custom-check.yellow .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-check.yellow input:checked ~ .checkmark:after {
    display: block;
  }

  .custom-check.yellow .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .custom-check.green {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    color: #44444F;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-check.green input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-check.green .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  @media (min-width: 1025px) {
    .custom-check.yellow:hover input:not(:disabled) ~ .checkmark {
      background-color: #ccc;
    }
    
    .custom-check.green:hover input:not(:disabled) ~ .checkmark {
      background-color: #ccc;
    }
  }

  .custom-check.green input:checked ~ .checkmark {
    background-color: #3DD598 !important;
    border: 1px solid #3DD598 !important;
  }

  .custom-check.green .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-check.green input:checked ~ .checkmark:after {
    display: block;
  }

  .custom-check.green .checkmark:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .custom-check.radio .checkmark {
    border-radius: 50%;
  }

  .custom-check.radio .checkmark:after {
    border: 0;
    background: #FFF;
    transform: none;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    top: 4px;
    left: 4px;
  }

  .vc-highlight {
    background-color: #3C6E71 !important;
  }

  /* Loading Items */
  .loading-items {
    display: flex;
    align-items: center;
    padding-left: 30px;
    width: 90%;
  }

  .loading-item {
    flex: 0 0 calc(25% - 24px);
    margin-right: 32px;
    background: #ddd;
    height: 307px;
    border-radius: 10px;
    box-shadow: 0px 4px 22px rgb(0 0 0 / 8%);
    background: linear-gradient(to right, #e4e4e4, #fafafb, #e4e4e4);
    animation: loading-item 1s ease infinite;
    background-size: 200% 200%;
  }

  @keyframes loading-item {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

  @media (max-width: 767px) {
    .loading-item {
      flex: 0 0 100%;
    }

    .loading-item:not(:first-of-type) {
      display: none;
    }
  }

  /* Popups */
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 21;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 50px 0;
    overflow: auto;
    animation-name: popup-appear;
    animation-duration: 0.4s;
    scrollbar-color: #3C6E71 transparent;
    scrollbar-width: thin;
  }

  @keyframes popup-appear {
    from {background-color: transparent;}
    to {background-color: rgba(0,0,0,0.3);}
  }

  .popup::-webkit-scrollbar {
    width: 5px;
  }

  .popup::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  .popup::-webkit-scrollbar-thumb {
    background: #3C6E71;
    border-radius: 30px;
  }

  .popup::-webkit-scrollbar-thumb:hover {
    background: #3C6E71; 
  }

  .popup-box {
    width: 650px;
    animation-name: popup-box-appear;
    animation-duration: 1s;
  }

  @keyframes popup-box-appear {
    from {transform: translateY(-100vw);}
    to {transform: translateY(0);}
  }

  .popup-box .close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  .popup-box .close:hover {
    transform: scale(1.2);
  }

  /* Dynamic Label Inputs */
  .dynamic-label {
    position: relative;
    margin-bottom: 20px;
  }
  
  .dynamic-label.selection:after {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    color: #B5B5BE;
    position: absolute;
    top: 12px;
    font-size: 20px;
    right: 20px;
    z-index: -1;
  }

  .dynamic-label input {
    border: 1px solid #E2E2EA;
    border-radius: 10px;
    padding: 11px 20px;
    font-size: 16px;
    width: 100%;
    outline: none;
    background: transparent;
  }

  .dynamic-label input:focus {
    border-color: #3c6e71;
  }

  .dynamic-label label {
    font-size: 16px;
    color: #92929D;
    position: absolute;
    left: 20px;
    top: 12px;
    transition: all 0.15s ease-in-out;
    cursor: text;
  }

  .dynamic-label.dropdown label {
    cursor: pointer;
  }

  .dynamic-label input:focus + label,
  .dynamic-label input:not(:placeholder-shown) + label,
  .dynamic-label label.up {
    font-size: 12px;
    color: #3C6E71;
    background: #FFF;
    top: -8px;
    left: 10px;
    padding: 0 10px;
  }

  .b-day-label.up {
    z-index: 2;
  }

  .dynamic-label .custom-select {
    border: 1px solid #E2E2EA;
    border-radius: 10px;
    padding: 11px 20px;
    font-size: 16px;
    width: 100%;
    height: 49px;
    outline: none;
    background: transparent;
    position: relative;
    cursor: pointer;
  }

  .dynamic-label .custom-select:focus {
    border-color: #3c6e71;
  }

  .dynamic-label .custom-select.error {
    border-color: #ef2d2d;
  }

  .dynamic-label .custom-select.error + label {
    color: #ef2d2d;
  }

  .dynamic-label .custom-select button {
    font-size: 16px;
    padding: 0;
    border: 0;
    background: none;
  }

  .dynamic-label .custom-select button:focus {
    background: #3c6e71;
    color: #FFF;
    outline: none;
  }

  .dynamic-label .custom-select.open {
    border-color: #3c6e71;
  }

  .select-dropdown {
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    top: 80%;
    background: #FFF;
    border-radius: 0 0 8px 8px;
    border: 1px solid #3c6e71;
    border-top: 0;
    padding: 10px 0;
    cursor: pointer;
    z-index: 2;
    max-height: 300px;
    overflow: auto;
  }

  .select-dropdown::-webkit-scrollbar {
    width: 4px;
  }

  .select-dropdown::-webkit-scrollbar-track {
    background: transparent;
  }

  .select-dropdown::-webkit-scrollbar-thumb {
    background: #3c6e71;
    border-radius: 3px;
  }

  .select-dropdown::-webkit-scrollbar-thumb:hover {
    background: #3c6e71;
  }

  .select-dropdown a {
    color: #44444F;
    display: block;
    padding: 10px 20px;
    cursor: pointer;
  }

  .select-dropdown a:not(.disabled):hover,
  .select-dropdown a.active {
    background-color: #ddd;
  }

  /* Errors */
  .error-message,
  .success-message {
    display: block;
    text-align: center;
    margin: 10px 0;
    border-radius: 8px;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    animation-name: error;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }

  .error-message {
    color: #ef2d2d;
    background: #ffeded;
    border: 1px solid #ef2d2d;
  }

  .error-message a {
    color: #ef2d2d;
  }

  .success-message {
    color: #00db63;
    background: #d1ffe6;
    border: 1px solid #00db63;
  }

  @keyframes error {
    0%   {transform: scale(0);}
    100% {transform: scale(1);}
  }
  
  /* Plan Limitations */
  .plan-limitation-text {
    color: #92929D;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  }

  .plan-limitation-text a {
    color: #3C6E71;
    text-decoration: underline;
  }

  .plan-limitation-text a:hover {
    text-decoration: none;
  }

  .preloader {
    background: #fbfbfb;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: all 0.5s ease-in-out;
  }

  .preloader.hidden {
    opacity: 0;
  }

  .go-to-top {
    position: fixed;
    bottom: -50px;
    right: 20px;
    z-index: 3;
    width: 50px;
    height: 50px;
    background: #FFF;
    color: #3c6e71;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
  }

  .go-to-top.active {
    bottom: 20px;
  }

  .go-to-top:hover {
    background: #3c6e71;
    color: #FFF;
    animation-name: go-top;
    animation-duration: 1s;
  }

  /* Autocomplete */
  .field .simple-typeahead {
    position: relative;
  }

  .field .simple-typeahead-list {
    position: absolute;
    z-index: 5;
    background: #FAFAFB;
    border-radius: 0 0 10px 10px;
    border: 1px solid #3c6e71;
    border-top: 0;
    margin: 0 10px;
    padding: 0;
    max-height: 400px;
    overflow: auto;
    transform: translateY(-10px);
    padding-top: 10px;
    max-width: 100%;
  }

  .field .simple-typeahead-list::-webkit-scrollbar {
    width: 5px;
  }

  .field .simple-typeahead-list::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  .field .simple-typeahead-list::-webkit-scrollbar-thumb {
    background: #3C6E71;
    border-radius: 30px;
  }

  .field .simple-typeahead-list::-webkit-scrollbar-thumb:hover {
    background: #3C6E71; 
  }

  .field .simple-typeahead-list-item {
    list-style: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .field .simple-typeahead-list-item:first-of-type {
    border-top: 1px solid #ddd;
  }

  .field .simple-typeahead-list-item:hover,
  .field .simple-typeahead-list-item-active {
    background: #3c6e71;
    color: #FFF;
  }

  .remove-warning {
    color: #fc5a5a;
    font-weight: 500;
    margin-top: 5px;
    display: block;
  }

  @keyframes go-top {
    0%   {padding-bottom: 0;}
    25%  {padding-bottom: 20px;}
    50%  {padding-bottom: 0;}
    75%  {padding-bottom: 20px;}
    100% {padding-bottom: 0;}
  }

  @media (max-width: 1550px) {
    .white-box {
      padding: 20px;
    }

    .big-sign-up {
      font-size: 25px;
      width: 220px;
      height: 70px;
    }
    
    .items-box-holder {
      margin-bottom: 20px !important;
    }

    .list-items-box {
      padding: 20px 25px;
    }

    .items-box {
      padding: 20px 0;
    }

    .list-items-box h4 {
      margin-bottom: 15px;
      font-size: 14px;
    }

    .items-box .box-title {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    /* html, body {
      overflow-x: hidden;
    } */

    body {
      background-color: #FFF;
    }

    body.ov-hidden {
      overflow: hidden;
      height: 100vh;
    }

    #app.user-page-container {
      background-color: #FFF;
    }

    #app.mobile-menu-open {
      height: 100vh;
      overflow: hidden;
    }

    .items-box-holder {
      width: 100%;
      margin: 0 !important;
    }

    .items-box {
      background: transparent;
      padding: 0;
      border-radius: 0;
      padding-left: 5%;
      padding-bottom: 30px;
    }

    .items-box .box-title {
      padding: 0;
    }

    .items-box .carousel-holder {
      padding-left: 0;
      width: 80%;
    }

    .white-box {
      padding: 0;
      border-radius: 0;
      background: transparent;
    }

    .list-items-box {
      padding: 0;
    }

    .popup {
      background: #FFF !important;
      padding: 0;
    }

    .popup-box {
      padding: 20px;
      animation: unset !important;
    }

    .big-sign-up {
      font-size: 20px;
      width: 170px;
      height: 60px;
      margin: 40px auto;
    }
  }

  @media (max-width: 340px) {
    .custom-check.yellow {
      font-size: 12px;
    }
  }
</style>
