<template>
  <!-- Equity Market Data Page -->
  <div class="equity-market-data-page">
    <h1>Active Equities <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getEquitiesHandler"
      @edit="editHandler" 
    />

    <!-- Inspect Popup -->
    <InspectActiveEquity
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import equitiesMixin from '@/mixins/equities'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectActiveEquity from "@/components/equities/popups/InspectActiveEquity.vue";
  export default {
    name: 'ActiveEquities',
    mixins: [equitiesMixin, userMixin],
    components: {
      InspectActiveEquity,
      DataTableHolder,
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Name',
            name: 'companyName',
            sortable: true
          },
          {
            title: 'Currency',
            name: 'currency',
            sortable: true
          },
          {
            title: 'Price',
            name: 'price',
            sortable: true
          },
          {
            title: 'Price Type',
            name: 'priceType',
            sortable: true
          },
          {
            title: 'Price Date',
            name: 'tradingDate',
            sortable: true
          },
          {
            title: 'Daily Performance',
            name: 'netChangePerc',
            sortable: true
          },
          {
            title: 'Min Daily Return',
            name: 'minDailyReturn',
            sortable: true
          },
          {
            title: 'Max Daily Return',
            name: 'maxDailyReturn',
            sortable: true
          },
          {
            title: 'Max Date Interval',
            name: 'maxDateInterval',
            sortable: true
          },
          {
            title: 'Sentifi OK',
            name: 'sentifyOk',
            sortable: true
          },
          {
            title: 'Factset Company ID',
            name: 'factsetCompanyId',
            sortable: true
          },
          {
            title: 'Factset Entity ID',
            name: 'factsetEntityId',
            sortable: true
          },
          {
            title: 'Active Comment',
            name: 'activeComment',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get equities from API
      getEquitiesHandler(data) {
        this.getEquities(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let equitiesFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const equity of res.equities) {
              let equityObj = {
                id: equity.id,
                isin: equity.isin,
                name: equity.companyName,
                currency: equity.currencyCode,
                price: equity.price,
                priceType: equity.priceType,
                tradingDate: equity.tradingDate,
                netChangePerc: typeof equity.netChangePerc == 'number' ? equity.netChangePerc.toFixed(2) : null,
                minDailyReturn: typeof equity.minDailyReturn == 'number' ? (parseFloat(equity.minDailyReturn) * 100).toFixed(2) : null,
                maxDailyReturn: typeof equity.maxDailyReturn == 'number' ? (parseFloat(equity.maxDailyReturn) * 100).toFixed(2) : null,
                maxDateInterval: equity.maxDateInterval,
                sentifyOk: equity.sentifyOk,
                factsetCompanyId: equity.factsetCompanyId,
                factsetEntityId: equity.factsetEntityId,
                activeComment: equity.activeComment,
                addedOn: equity.addedOn,
              }
              // * Add formatted equity to formatted equities array
              equitiesFormatted.push(equityObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = equitiesFormatted;
            this.fullData = res.equities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(equity => {
          return equity.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>

</style>