<template>
  <!-- Inspect Equity market Data Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Equity Market Data"
    @close="$emit('close')"
  >
    <template v-slot:content>

      <span class="equity-id">
        Equity ID: {{equity.id}}
      </span>

      <a :href="'https://umushroom.com/equity/' + equity.urlName" target="_blank">Go to the equity on the main app</a>

      <div class="field">
        <label for="isin">ISIN:</label>
        <input type="text" id="isin" v-model="equity.isin" placeholder="ISIN...">
      </div>

      <div class="field">
        <label for="valor">Valor:</label>
        <input type="text" id="valor" v-model="equity.valor" placeholder="Valor...">
      </div>

      <div class="field">
        <label for="WKN">WKN:</label>
        <input type="text" id="WKN" v-model="equity.WKN" placeholder="WKN...">
      </div>

      <div class="field">
        <label for="sixReference">SIX Reference:</label>
        <input type="text" id="sixReference" v-model="equity.sixReference" placeholder="Exchange Id...">
      </div>
      
      <ChartBox
        v-if="graphData"
        :graph-data="graphData"
        :item="equity"
        type="equity"
        :currency="equity.currencyCode"
      />

      <!--      Multiply historical prices by factor between startDate and endDate-->
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPriceToBeMultiplied" :disabled="updateLoading">
          <span class="checkmark"></span>
          Multiply Prices by Factor Between:
        </label>
        <input type="text" id="startDateMultiply" v-model="startDateMultiply" placeholder="Start included (default)">
        <input type="text" id="endDateMultiply" v-model="endDateMultiply" placeholder="End included (default)">
        <span v-if="(startDateMultiply && !isDateFormat(startDateMultiply)) || (endDateMultiply && !isDateFormat(endDateMultiply))" class="remove-warning">
          Format : dd Mmm yyyy
        </span>
        <div class="field">
          <input type="text" id="factor" v-model="factor" :placeholder="defaultFactor + ' (default factor)'">
        </div>
        <span v-if="startDateMultiply && endDateMultiply && isEarlierThan(endDateMultiply, startDateMultiply)" class="remove-warning">
          End date is earlier than start date !
        </span>
      </div>


      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="equity.isActive">
          <span class="checkmark"></span>
          Is Active
        </label>
      </div>

      <div class="field" v-if="equity.isActive">
        <label for="active-comment">Active Comment:</label>
        <textarea id="active-comment" v-model="equity.activeComment" placeholder="Active Comment..."></textarea>
      </div>

      <div class="field" v-if="equity.isActive == false">
        <label for="admin-comment">Admin Comment:</label>
        <textarea id="admin-comment" v-model="equity.adminComment" placeholder="Admin Comment..."></textarea>
      </div>

      <div class="field" v-if="equity.isActive == false || isPriceToBeMultiplied">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder='Type "CONFIRM" to be able to change the equity status' v-model="confirm">
      </div>

      <button class="transition save-btn" @click="updateEquityHandler()" :disabled="updateLoading 
                || (equity.isActive == false && (equity.adminComment == null || equity.adminComment == '' || confirm != 'CONFIRM' ))
                || (equity.isActive == true && equity.adminComment != null && equity.adminComment != '')
                || (isPriceToBeMultiplied && confirm !== 'CONFIRM')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

      <span class="error-message" v-if="error">
        {{error}}
      </span>
    </template>
  </popup>
</template>

<script>
  import equitiesMixin from '@/mixins/equities'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  import ChartBox from '@/components/shared/charts/ChartBox'
  
  export default {
    name: 'InspectActiveEquity',
    mixins: [equitiesMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup,
      ChartBox
    },
    data() {
      return {
        graphData: null,
        updateLoading: false,
        error: null,
        initialIsActive: this.item.isActive,
        confirm: null,
        equity: this.item,

        isPriceToBeMultiplied: false,
        factor: null,
        defaultFactor: 10,
        startDateMultiply: null,
        endDateMultiply: null,

        defaultStartDate: '2000-01-01',
        defaultEndDate: '2999-01-01'
      }
    },
    methods: {
      // * Get equity chart data
      getEquityChartDataHandler() {
        this.getEquityChartData(this.equity.id)
          .then(res => {
            this.graphData = res;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      updateEquityHandler() {
        this.updateLoading = true;

        const dataToSend = {
          exchangeId: this.equity.sixReference,
          valor: this.equity.valor,
          isin: this.equity.isin,
          WKN: this.equity.WKN,
          isActive: this.equity.isActive,
          adminComment: this.equity.adminComment || '',
          activeComment: this.equity.activeComment || ''
        }

        if (!this.startDateMultiply) {
          this.startDateMultiply = this.defaultStartDate;
        } else {
          this.formatDateForBE(this.startDateMultiply);
        }
        if (!this.endDateMultiply) {
          this.endDateMultiply = this.defaultEndDate;
        } else {
          this.formatDateForBE(this.endDateMultiply);
        }
        if (!this.factor) {
          this.factor = this.defaultFactor;
        }

        Promise.all([

          this.isPriceToBeMultiplied ?
              this.multiplyPricesBetween(this.formatDateForBE(this.startDateMultiply), this.formatDateForBE(this.endDateMultiply), this.factor, this.equity.id)
                  .then(() => {
                    // this.$emit('updated')
                  })
                  .catch((err) => {
                    if (err.code == 401) {
                      this.logout();
                    }
                  })
              : Promise.resolve(),

          this.updateEquity(this.equity.id, dataToSend)
              .then(() => {
                this.updateLoading = false;
                this.$emit('updated');
              })
              .catch(err => {
                this.updateLoading = false;
                if (err.code == 401) {
                  this.logout();
                } else {
                  this.error = err.msg;
                  setTimeout(() => {
                    this.error = null;
                  }, 5000);
                }
              })
        ]).then(() => {
          // execute code after all promises complete
          this.updateLoading = false;
        })
      },

      formatDateForBE(inputDate) {
        // Parse the input date string using Date.parse() method
        let date = new Date(Date.parse(inputDate));

        // Extract the year, month and day components from the Date object
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is 0-indexed
        let day = date.getDate();

        // Convert month and day to zero-padded strings
        let monthStr = month.toString().padStart(2, '0');
        let dayStr = day.toString().padStart(2, '0');

        // Return the formatted date string in "yyyy-mm-dd" format
        return `${year}-${monthStr}-${dayStr}`;
      },

      isEarlierThan(d1, d2) {
        const date1 = new Date(this.formatDateForBE(d1));
        const date2 = new Date(this.formatDateForBE(d2));

        return date1 < date2;
      },

      isDateFormat(dateString) {
        const pattern1 = /^\d{2} (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;
        const pattern2 = /^\d{4}-\d{2}-\d{2}$/;
        return pattern1.test(dateString) || pattern2.test(dateString);
      }
    },
    mounted() {
      this.getEquityChartDataHandler();
    }
  }
</script>

<style scoped>
  .equity-id {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
</style>
