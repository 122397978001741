<template>
  <div>
    <apexchart v-if="showChart" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: "QuizDemographicPie",
  props: {
    type: String,
    demographicData: Array,
  },
  data() {
    return {
      showChart: false,
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: 30
        },
        labels: [],
        colors:['#3ed699', '#3c6e71', '#5a6671', '#ae8a51', '#89929a'],
        legend: {
          position: 'bottom',
          fontFamily: 'Poppins',
          fontWeight: 500
        }
      }
    }
  },
  mounted() {
    if (this.type == "nationality") {
      for (const item of this.demographicData) {
        this.series.push(item.nationality_count);
        this.chartOptions.labels.push(item.nationality);
      }
    } else if (this.type == "gender") {
      for (const item of this.demographicData) {
        this.series.push(item.gender_count);
        this.chartOptions.labels.push(item.gender);
      }
    } else if (this.type == "ageGroup") {
      for (const item of this.demographicData) {
        this.series.push(item.age_group_count);
        this.chartOptions.labels.push(item.age_group);
      }
    }
    this.showChart = true;
  },
};
</script>
