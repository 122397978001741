<template>
  <div class="plans-page">
    <h1>Plans</h1>

    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getPlansHandler"
      @edit="editHandler"
    />

    <EditPlan
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditPlan from './popups/EditPlan'

  export default {
    name: 'Plans',
    mixins: [usersMixin, userMixin],
    components: {
      DataTableHolder,
      EditPlan
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Name',
            name: 'name',
            sortable: false
          },
          {
            title: 'Price 1Y',
            name: 'price1y',
            sortable: false
          },
          {
            title: 'Price 1M',
            name: 'price1m',
            sortable: false
          },
          {
            title: 'Trial Days',
            name: 'trialDays',
            sortable: false
          },
          {
            title: 'Country',
            name: 'country',
            sortable: false
          },
          {
            title: 'Currency Code',
            name: 'currencyCode',
            sortable: false
          },
          {
            title: 'VAT Rate',
            name: 'vatRate',
            sortable: false
          },
          {
            title: 'Is Active',
            name: 'isActive',
            sortable: false
          },
          {
            title: 'Type',
            name: 'type',
            sortable: false
          },
          {
            title: 'Plan Code',
            name: 'planCode',
            sortable: false
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: false,
        total: null,
        fullData: null
      }
    },
    methods: {
      // * Get subscriptions from API
      getPlansHandler(data) {
        this.getPlans(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let plansFormatted = [];
            // * Loop through plans and add only the needed properties
            for (const row of res.planRows) {
              let planObj = this.constructPlanRow(row);
              // * Add formatted plan to formatted plans array
              plansFormatted.push(planObj);
            }
            // * Assign table rows to be the formatted subscriptions array
            this.rows = plansFormatted;
            this.fullData = res.planRows;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
      },
      constructPlanRow(plan) {
        // * Construct the subscription in order
        let planRow = {
          id: plan.id,
          name: plan.name,
          price1y: plan.charge1Y,
          price1m: plan.charge1M,
          trialDays: plan.trialDays,
          country: plan.country,
          currencyCode: plan.currencyCode,
          vatRate: plan.vatRate * 100,
          isActive: plan.isActive,
          type: plan.type,
          planCode: plan.planCode,
        };

        return planRow;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(row => {
          return row.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;

        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>

</style>
