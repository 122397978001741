<template>
  <!-- Portfolio Summary Page -->
  <div class="portfolio-summary">
    <h1>Portfolio Summary <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit', 'exportData']"
      @update-table="getPortfoliosHandler"
      @edit="editHandler"
      @exportData="exportDataHandler"
      
    />

    <!-- Inspect Popup -->
    <InspectPortfolio
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import portfoliosMixin from '@/mixins/portfolios'
  import userMixin from '@/mixins/user'
  import commonMixin from '@/mixins/common'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectPortfolio from './popups/InspectPortfolio'
  import exportFromJSON from 'export-from-json'
  
  export default {
    name: 'PortfolioSummary',
    mixins: [portfoliosMixin, userMixin, commonMixin],
    components: {
      DataTableHolder,
      InspectPortfolio
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Portfolio',
            name: 'name',
            sortable: true
          },
          {
            title: 'User',
            name: 'owner',
            sortable: true
          },
          {
            title: 'URL Name',
            name: 'urlName',
            sortable: true
          },
          {
            title: 'Owner URL Name',
            name: 'ownerUrlName',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'publishedOn',
            sortable: true
          },
          {
            title: 'Performance',
            name: 'overallPerformance',
            sortable: true
          },
          {
            title: 'Is Hot',
            name: 'isHot',
            sortable: true
          },
          {
            title: 'Is Recommended',
            name: 'isRecommended',
            sortable: true
          },
          {
            title: 'Instruments',
            name: 'numberOfInstruments',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        fullData: null,
        total: null
      }
    },
    methods: {
      // * Get portfolios from API
      getPortfoliosHandler(data) {
        this.getPortfolios(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let portfoliosFormatted = [];
            // * Loop through portfolios and add only the needed properties
            for (const portfolio of res.userPortfolios) {
              let portfolioObj = {
                id: portfolio.id,
                name: portfolio.name,
                owner: portfolio.owner,
                urlName: portfolio.urlName,
                ownerUrlName: portfolio.ownerUrlName,
                publishedOn: portfolio.publishedOn,
                overallPerformance: this.formatPercentDecimals(portfolio.overallPerformance),
                isHot: portfolio.isHot,
                isRecommended: portfolio.isRecommended,
                numberOfInstruments: portfolio.numberOfInstruments,
              }
              // * Add formatted equity to formatted userPortfolios array
              portfoliosFormatted.push(portfolioObj);
            }
            // * Assign table rows to be the formatted portfolios array
            this.rows = portfoliosFormatted;
            this.fullData = res.userPortfolios;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      editHandler(item) {
        this.editItem = this.fullData.find(portfolio => {
          return portfolio.id == item.id;
        })
      },
      // * Format date as per IBO file naming requirement
      formatDate(dateInput) {
        // * Parse the date
        let date = new Date(dateInput);
        let dateString = '';

        // * Get the year
        dateString += date.getFullYear();
        dateString += '_';

        // * Get the month and add 0 if less than 10

        // * Add to parsed month 1 because in JS months start from 0 :)
        let realMonth = +date.getMonth() + 1;
        if (realMonth < 10) {
          dateString += '0' + realMonth;
        } else {
          dateString += realMonth;
        }
        dateString += '_';
        
        // * Get the day and add 0 if less than 10
        if (date.getDate() < 10) {
          dateString += '0' + date.getDate();
        } else {
          dateString += date.getDate();
        }

        return dateString;
      },
      exportDataHandler(item) {  
        this.getPortfolioPerformanceIbo(item.id)
        .then(res => {
          const data = res.perfomanceEntries
          const fileName = 'IBO_data_' + this.formatDate(new Date()) + '-' + item.id
          const exportType = 'csv'
          if (data) exportFromJSON({ data, fileName, exportType });
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })

      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>

</style>