<script>
  import axios from 'axios'

  // * If there is user token in the auth store add it to every request as Bearer token
  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    data() {
      return {
        currentUser: localStorage.getItem('user')
      }
    },
    methods: {
      // * Set user data in store, cookies, request headers and localStorage
      setUser(res, remember) {
        return new Promise((resolve, reject) => {
          this.currentUser = res.data.token;

          // * Set user bearer token in cookie
          if (remember) {
            localStorage.setItem('x-auth-token', res.data.token);
          } else {
            localStorage.setItem('x-auth-token', res.data.token);
          }

          // * Add Bearer token in request headers
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.currentUser;

          // * Set user data in store
          this.$store.commit('setUser', res.data.token);

          this.getUserInfo()
            .then(res => {
              this.$store.commit('setUserInfo', res.user);
              resolve();
            })
            .catch(err => {
              reject(err);
            })
        })
      },
      // * Login function
      login(userData, remember) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/login`, {
            method: "POST",
            data: userData,
          })
            .then((res) => {
              // check if 2FA is enabled for the user
              if (res && res.data && res.data["2fa_required"] == true) {
                resolve(res.data);
                return;
              }

              // 2FA is not enabled and there is successfully logged in user
              if (res && res.data && res.data["token"]) {
                // * After response set user data (function above)
                this.setUser(res, remember)
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    reject(err);
                  });
              }
            })
            .catch((err) => {
              reject(err.response.data);
            });
        });
      },
      verify2FALogin(data) {
        return new Promise((resolve, reject) => {
          axios(`${process.env.VUE_APP_API_URL}/validate-2fa-login`, {
            method: "POST",
            data,
          })
            .then((res) => {
              // 2FA returned success and token
              if (res && res.data && res.data["token"]) {
                // * After response set user data (function above)
                this.setUser(res, false)
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    reject(err);
                  });
              }
            })
            .catch((err) => {
              reject(err.response.data);
            });
        });
      },
      // * Logout function
      logout() {
        if (!this.$route.path.includes('login')) {
          // * Remove the user data
          this.currentUser = null;
          localStorage.removeItem('user');
          localStorage.removeItem('x-auth-token');
          this.$store.commit('clearUser');
          this.$router.push('/login');
        }
      },
      // * Get user info data from API
      getUserInfo() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/profile`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        });
      },
      getUserData() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user`, {
            method: 'GET'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
        });
      }
    },
    computed: {
      isLogged() {
        return !!this.currentUser
      }
    }
  }
</script>
