<script>
import axios from 'axios'

// * If there is user token in the auth store add it to every request as Bearer token
if (localStorage.getItem('x-auth-token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
}

export default {
  methods: {
    getTagsPaginated(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/tags`, {
          method: 'post',
          data: data
        })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
      })
    },

    editTag(data, tagId) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/tags/${tagId}`, {
          method: 'patch',
          data: data
        })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
      })
    },

    deleteTag(tagId) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/delete/tag/${tagId}`, {
          method: 'delete'
        })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
      })
    },

    getTagsByInstrumentsPaginated(data) {
      return new Promise((resolve, reject) => {
        axios(`${this.$store.state.global.apiUrl}/backoffice/tags/by-instruments`, {
          method: 'post',
          data: data
        })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.code);
            })
      })
    },
  }
}
</script>