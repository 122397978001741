<template>
  <div class="field custom-select-dropdown">
    <label>{{ label }}:</label>
    <div
      class="custom-select"
      :class="{ 'open': open }"
      @click="toggleDropdown"
      v-click-away="() => open = false"
    >
      <span v-if="selectedOption !== null">{{ selectedOption.name }}</span>
      <span v-else>{{ placeholder }}</span>
      <div class="select-dropdown" v-if="open">
        <a
          v-for="(option, index) in options"
          :key="option.id || index"
          @click="selectOption(option)"
        >
          {{ option.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectDropdown',
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    label: {
      type: String,
      default: 'Select an option'
    },
    modelValue: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    selectedOption() {
      return this.modelValue;
    }
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
    },
    selectOption(option) {
      this.$emit('update:modelValue', option);
    }
  }
}
</script>

<style scoped>
.field {
  width: 100%;
}
</style>
