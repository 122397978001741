<template>
  <div class="system-achievements">
    <h1>Achievements <small v-if="total">({{total}})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
            :columns="columns"
            :rows="rows"
            :totalPages="pages"
            :updateResults="updateResults"
            :actions="['new', 'edit', 'delete']"
            @update-table="getAchievementsHandler"
            @add-new="addItemPopup = true"
            @edit="editHandler"
            @delete="deleteHandler"
    />

    <NewAchievement
            v-if="addItemPopup"
            :achievementCategoriesAll="achievementCategoriesAll"
            @close="addItemPopup = false"
            @created="createdHandler"
    />

    <EditAchievement
            v-if="editItemPopup"
            :achievementCategoriesAll="achievementCategoriesAll"
            :item="editItemPopup"
            @close="editItemPopup = false"
            @updated="updatedHandler"
    />

    <DeleteAchievement
            v-if="deleteItemPopup"
            :item="deleteItemPopup"
            @close="deleteItemPopup = false"
            @deleted="deletedHandler"
    />

  </div>
</template>

<script>
  import userMixin from '@/mixins/user'
  import achievementsMixin from '@/mixins/achievements'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewAchievement from "./popups/NewAchievement"
  import EditAchievement from "./popups/EditAchievement"
  import DeleteAchievement from "./popups/DeleteAchievement"

  export default {
    name: 'SystemAchievements',
    mixins: [userMixin, achievementsMixin],
    components: {
      DataTableHolder,
      NewAchievement,
      EditAchievement,
      DeleteAchievement
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id'
          },
          {
            title: 'Url Name',
            name: 'name'
          },
          {
            title: 'Name En',
            name: 'nameEn'
          },
          {
            title: 'Category',
            name: 'categoryTitle'
          },
          {
            title: 'UMCoins',
            name: 'umCoins'
          },
          {
            title: 'Icon',
            name: 'imageIcon'
          },
          {
            title: 'Users Achieved It',
            name: 'usersAcquiredThisAchievement'
          }
        ],
        addItemPopup: null,
        editItemPopup: null,
        deleteItemPopup: null,
        updateResults: true,
        total: null,
        fullData: null,
        achievementCategoriesAll: null
      }
    },
    methods: {

      getAchievementCategoriesAllHandler() {
        this.getAchievementCategories()
                .then(res => {
                  this.achievementCategoriesAll = res.items;
                })
                .catch(err => {
                  if (err == 401) {
                    this.logout();
                  }
                })
      },

      getAchievementsHandler() {
        this.getAchievements()
                .then(res => {
                  let achievementsFormatted = [];
                  for (const achievement of res.items) {
                    let achievementObj = {
                      id: achievement.id,
                      name: achievement.name,
                      nameEn: achievement.translation.en.name,
                      categoryTitle: achievement.categoryTitle,
                      umCoins: achievement.coins,
                      //s3ImageIcon: 'achievements/' + achievement.name + '.png',
                      imageIcon: achievement.image,
                      usersAcquiredThisAchievement: achievement.usersCountAchievedIt
                    };
                    achievementsFormatted.push(achievementObj);
                  }

                  this.rows = achievementsFormatted;
                  this.fullData = res.items;
                  this.total = res.itemsCount;
                })
                .catch(err => {
                  if (err == 401) {
                    this.logout();
                  }
                })
      },

      editHandler(item) {
        this.editItemPopup = this.fullData.find(itemFullData => {
          return itemFullData.id == item.id;
        });
      },

      deleteHandler(item) {
        this.deleteItemPopup = this.fullData.find(itemFullData => {
          return itemFullData.id == item.id;
        });
      },

      createdHandler() {
        this.addItemPopup = null;
        this.updateResults = !this.updateResults;
      },

      updatedHandler() {
        this.editItemPopup = null;
        this.updateResults = !this.updateResults;
      },

      deletedHandler() {
        this.deleteItemPopup = null;
        this.updateResults = !this.updateResults;
      },


    },

    mounted() {
      this.getAchievementCategoriesAllHandler();
    }

  }
</script>

