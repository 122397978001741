<template>
  <!-- Inspect Retired Equity Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Retired Equity (new)"
    @close="$emit('close')"
  >
    <template v-slot:content>

      <span class="equity-id">
         {{equity.name}}
      </span>

      <span class="equity-id">
        Equity ID: {{equity.id}}
      </span>

      <span class="equity-id">
        Valor: {{equity.valor}}
      </span>

      <div class="field" v-if="equity.is_active">
        <label for="admin-comment">Active Comment:</label>
        <input type="text" id="active-comment" v-model="equity.active_comment" placeholder="Active Comment..."/>
      </div>

      <div class="field" v-if="equity.is_active == false">
        <label for="admin-comment">Admin Comment:</label>
        <input type="text" id="admin-comment" v-model="equity.admin_comment" placeholder="Admin Comment..."/>
      </div>

      <div class="field">
        <label for="isin">ISIN:</label>
        <input type="text" id="isin" v-model="equity.isin" placeholder="ISIN..."/>
      </div>


      <ChartBox
      v-if="graphData"
      :graph-data="graphData"
      :item="equity"
      type="equity"
      :currency="equity.code"
      />

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="equity.is_active">
          <span class="checkmark"></span>
          Is Active
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="equity.candidate">
          <span class="checkmark"></span>
          Candidate
        </label>
      </div>

      <div class="field" v-if="isActive != equity.is_active || candidate != equity.candidate">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder='Type "CONFIRM" to be able to change the equity status' v-model="confirm">
      </div>

      <button class="transition save-btn" @click="updateEquityHandler()" :disabled="
        updateLoading ||
        (equity.is_active === true && confirm !== 'CONFIRM') ||
        (equity.candidate === true && confirm !== 'CONFIRM')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

      <span class="error-message" v-if="error">
        {{error}}
      </span>
    </template>
  </popup>
</template>

<script>
  import securitiesMixin from '@/mixins/securities'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  import ChartBox from '@/components/shared/charts/ChartBox'
  
  export default {
    name: 'InspectRetiredEquityNew',
    mixins: [securitiesMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup,
      ChartBox
    },
    data() {
      return {
        graphData: null,
        isActive: this.item.is_active,
        candidate: this.item.candidate,
        updateLoading: false,
        error: null,
        confirm: null,
        equity: this.item
      }
    },
    methods: {
      // * Get equity chart data
      getEquityChartDataHandler() {
        this.getSecurityChartData(this.equity.id)
          .then(res => {
            this.graphData = res;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      updateEquityHandler() {
        this.updateLoading = true;

        const dataToSend = {
          isActive: this.equity.is_active,
          candidate: this.equity.candidate,
          permanentDeactivation: this.equity.permanent_deactivation,
          isin: this.equity.isin
        }

        if (this.equity.is_active) {
          dataToSend.activeComment = this.equity.active_comment;
        } else {
          dataToSend.adminComment = this.equity.admin_comment;
        }

        this.updateSecurity(dataToSend, this.equity.id)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code == 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
      }
    },
    watch: {
      'equity.is_active': function(newVal) {
        if (newVal) {
          this.equity.permanent_deactivation = false;
          this.equity.candidate = false;
        }
      },
      'equity.candidate': function(newVal) {
        if (newVal) {
          this.equity.permanent_deactivation = false;
          this.equity.is_active = false;
        }
      },
      'equity.permanent_deactivation': function(newVal) {
        if (newVal) {
          this.equity.is_active = false;
          this.equity.candidate = false;
        }
      },
    },
    mounted() {
      this.getEquityChartDataHandler();
    }
  }
</script>

<style scoped>
  .equity-id {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
</style>
