<template>
  <!-- Add File Popup -->
  <!-- Call the Popup HOC and add the content in it -->
  <popup
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="media" v-if="!addVideoSection">
        <h4 class="title">Add File</h4>
        <div class="add-item-section">
          <div class="add-image relative">
            <span v-if="!addImageLoading">Add Photo</span>
            <font-awesome-icon v-else icon="spinner" spin />
            <input type="file" @change="addImageHandler($event)" accept="image/*">
          </div>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
  import mediaMixin from '@/mixins/media'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'AddFile',
    mixins: [mediaMixin],
    components: {
      Popup
    },
    props: {
      sessionId: Number
    },
    data() {
      return {
        addImageLoading: false,
        addImageToUpload: null
      }
    },
    methods: {
      addImageHandler(event) {
        this.addImageLoading = true;
        
        const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (!file.type.match(pattern)) {
          alert('Invalid format');
          this.addImageLoading = false;
          return;
        }

        // this.fileName = file.name;
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      },
      _handleReaderLoaded(e) {
        const reader = e.target;
        this.addImageToUpload = reader.result;

        const dataToSend = {
          sessionId: this.sessionId,
          image: this.addImageToUpload
        }

        this.addMedia(dataToSend)
          .then(res => {
            this.$emit('image-added', res.media.image);
          })
          .catch(() => {
            this.addImageLoading = false;
          })
      }
    }
  }
</script>

<style scoped>
  .add-item-section {
    background: #EAEAEA;
    border-radius: 10px;
    display: flex;
    align-items: stretch;
    height: 200px;
    padding: 10px 0;
    margin-top: 15px;
  }

  .add-item-section div {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #92929D;
    cursor: pointer;
  }

  /* .add-item-section div:first-child {
    border-right: 1px solid #C4C4C4;
  } */

  .add-item-section svg {
    color: var(--dark-green);
    font-size: 40px;
  }

  .add-image input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  .add-image input::-webkit-file-upload-button {
    cursor: pointer;
  }

  .add-video-section .title {
    cursor: pointer;
  }

  .add-video-section .title:hover {
    text-decoration: underline;
  }

  .add-video-section .site-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
  }

  .site-logos svg {
    font-size: 60px;
    margin: 0 25px;
    color: var(--dark-green);
  }

  .site-logos svg[data-icon="youtube"] {
    color: #FF0217;
  }

  .site-logos svg[data-icon="vimeo"] {
    color: #04A1F0;
  }

  .site-logos svg:hover {
    color: var(--dark-green);
  }

  .field label {
    color: #44444F;
    font-size: 16px;
  }

  .field input {
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 15px;
    display: block;
    width: 100%;
    padding: 13px 17px;
    font-size: 16px;
    margin: 5px 0;
    outline: none;
  }

  .field input:focus {
    border-color: var(--dark-green);
  }

  .actions {
    margin-top: 60px;
  }

  .actions button {
    display: block;
    width: 100%;
    background: var(--dark-green);
    color: #FAFAFB;
    border-radius: 8px;
    border: 0;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
  }

  .actions button:not(:disabled):hover {
    background: #FAFAFB;
    color: var(--dark-green);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

  .actions button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .actions a {
    display: block;
    width: 100%;
    text-align: center;
    margin: 20px 0;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .actions a:hover {
    text-decoration: underline;
  }
</style>