<template>
  <popup
    title="Edit plan"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field relative">
        <label for="price1y">Price 1Y:</label>
        <input type="number" min="0" id="price1y" v-model="price1y" placeholder="Price 1Y" @input="v$.price1y.$touch()" :class="{error: v$.price1y.$error}">
          <template v-if="v$.price1y.$error">
            <div v-if="!v$.price1y.minValue" class="input-error">Min Price 1Y is 0</div>
          </template>
      </div>

      <div class="field relative">
        <label for="price1y">Price 1M:</label>
        <input type="number" min="0" id="price1m" v-model="price1m" placeholder="Price 1M" @input="v$.price1m.$touch()" :class="{error: v$.price1m.$error}">
          <template v-if="v$.price1m.$error">
              <div v-if="!v$.price1m.minValue" class="input-error">Min Price 1M is 0</div>
          </template>
      </div>

      <div class="field relative">
        <label for="price1y">Trial days:</label>
        <input type="number" min="0" max="30" id="trialDays" v-model="trialDays" placeholder="Trial days" @input="v$.trialDays.$touch()" :class="{error: v$.trialDays.$error}">
          <template v-if="v$.trialDays.$error">
            <div v-if="!v$.trialDays.between" class="input-error">Trial days may be between {{v$.trialDays.$params.between.min}} and {{v$.trialDays.$params.between.max}}</div>
          </template>
      </div>

      <button
        class="transition save-btn"
        @click="updatePlanHandler()"
        :disabled="v$.$invalid || buttonLoading">
        <span v-if="!buttonLoading">
          Update
        </span>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import datesMixin from '@/mixins/dates'

  import { useVuelidate } from '@vuelidate/core'
  import { minValue, between } from '@vuelidate/validators'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditPlan',
    mixins: [usersMixin, datesMixin],
    components: {
      Popup
    },
    props: {
      item: Object
    },
    data() {
      return {
        price1y: this.item.charge1Y,
        price1m: this.item.charge1M,
        trialDays: this.item.trialDays,
        type: this.item.type,
        country: this.item.country,
        vatRate: this.item.vatRate,
        isActive: this.item.isActive,
        buttonLoading: false,
        v$: useVuelidate()
      }
    },
    methods: {
      updatePlanHandler() {
        this.buttonLoading = true;
        const dataToSend = {
          charge1Y: this.price1y,
          charge1M: this.price1m,
          trialDays: this.trialDays,
          type: this.type,
          country: this.country,
          vatRate: this.vatRate,
          isActive: this.isActive,
        };

        this.updatePlan(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.buttonLoading = false;
          })
      }
    },
    validations: {
      price1y: {
        minValue: minValue(0)
      },
      price1m: {
        minValue: minValue(0)
      },
      trialDays: {
        between: between(0,30)
      }
    }
  }
</script>

<style scoped>
  .field :deep(.vue-daterange-picker) {
    width: 100%;
  }

  .field :deep(.vue-daterange-picker .form-control) {
    width: 100%;
    height: auto;
    margin: 0 !important;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  .input-error {
    color: #ef2d2d;
    font-size: 14px;
    position: absolute;
    bottom: 0px;
    transform: translateY(100%);
  }

  input.error {
    border-color: #ef2d2d;
  }

  input.error::placeholder {
    color: #ef2d2d;
  }

</style>
