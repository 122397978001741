<template>
  <!-- Add new sector popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Add new sector"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Sector:</label>
        <input type="text" id="name" v-model="name" placeholder="Sector name...">
      </div>

      <button class="transition save-btn" @click="addSectorHandler()" :disabled="addLoading">
        <template v-if="!addLoading">
          Add sector
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewFundUmbrellaSector',
    mixins: [fundsMixin, userMixin],
    components: {
      Popup
    },
    data() {
      return {
        name: '',
        addLoading: false
      }
    },
    methods: {
      // * Triggers on save button click and creates sector
      addSectorHandler() {
        // * Start update loading (for the save button)
        this.addLoading = true;
        const dataToSend = {
          sector: this.name
        }

        this.createUmbrellaSector(dataToSend)
          .then(() => {
            this.$emit('created');
            this.addLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.addLoading = false;
          })
      }
    }
  }
</script>

<style scoped>
  
</style>
