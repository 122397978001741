<template>
  <popup
    title="Delete Chapter"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <img :src="$s3Url + 'svgs/icons/delete-x.svg'">
        <h4>Are you sure?</h4>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <p>Are you sure you want to permenantly delete this Chapter?</p>
        <button class="delete-btn transition" @click="deleteHandler()">
          Delete
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'DeleteSection',
    mixins: [knowledgeMixin],
    props: {
      deleteData: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        errorMessage: ''
      };
    },
    methods: {
      deleteHandler() {
        this.deleteSection(this.deleteData.courseId, this.deleteData.moduleId, this.deleteData.sectionId)
          .then(() => {
            this.$emit('deleted');
          })
          .catch((error) => {
            if (error) {
              this.errorMessage = error;
            } else {
              this.errorMessage = 'An error occurred during the deletion process.';
            }
          });
      }
    }
  }
</script>

<style scoped>
  .error-message {
    padding: 2%;
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup-content img {
    margin-top: 100px;
  }

  .popup-content h4 {
    color: #171725;
    font-size: 32px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 130px;
  }

  .popup-content p {
    color: #696974;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
  }

  .field {
    width: 100%;
  }

  @media (max-width: 1550px) {
    .popup-content img {
      margin-top: 30px;
    }

    .popup-content h4 {
      margin-bottom: 30px;
    }
  }
</style>