<template>
  <!-- Equity Market Data Page -->
  <div class="equity-market-data-page">
    <h1>Old Url Names <small v-if="total">({{total}})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['delete', 'new']"
        @update-table="getEquitiesHandler"
        @delete="deleteHandler"
        @add-new="addHandler"
    />

    <DeleteOldUrlName
        v-if="deleteItem"
        :item="deleteItem"
        @close="deleteItem = null"
        @deleted="deletedHandler"
    />

    <AddOldUrlName
        v-if="addItemPopup"
        @created="addedHandler"
        @close="addItemPopup = false"
    />

  </div>
</template>

<script>
import oldUrlNamesMixin from '@/mixins/oldUrlNames.vue'
import userMixin from '@/mixins/user'

import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
import DeleteOldUrlName from "@/components/system/popups/DeleteOldUrlName.vue";
import AddOldUrlName from "@/components/system/popups/AddOldUrlName.vue";

export default {
  name: 'OldUrlNames',
  mixins: [oldUrlNamesMixin, userMixin],
  components: {
    DataTableHolder,
    DeleteOldUrlName,
    AddOldUrlName,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable: true
        },
        {
          title: 'Old Url Name',
          name: 'oldUrlName',
          sortable: true
        },
        {
          title: 'Object ID',
          name: 'objectId',
          sortable: true
        },
        {
          title: 'Object Type',
          name: 'objectType',
          sortable: true
        },

      ],
      editItem: null,
      pages: 0,
      updateResults: true,
      fullData: null,
      total: null,
      deleteItem: null,
      addItemPopup: false,
    }
  },
  methods: {
    // * Get equities from API
    getEquitiesHandler(data) {

      this.getOldUrlNames(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let urlsFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const result of res.results) {

              let urlObj = {
                id: result.id,
                oldUrlName: result.url_name,
                objectId: result.object_id,
                objectType: result.object_type
              };
              // * Add formatted equity to formatted equities array
              urlsFormatted.push(urlObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = urlsFormatted;
            this.fullData = res.urls;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },
    deleteHandler(item) {
      this.deleteItem = item;
    },
    deletedHandler() {
      this.deleteItem = null;
      this.updateResults = !this.updateResults;
    },
    addHandler(){
      this.addItemPopup = true;
    },
    addedHandler() {
      this.updateResults = !this.updateResults;
      this.addItemPopup = false;
    },

  }
}
</script>

<style scoped>

</style>