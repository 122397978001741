export default {
  state() {
    return {
      isUserComponent: false,
      user: null,
      userToken: localStorage.getItem('x-auth-token') || null
    }
  },
  getters: {
    getUserToken(state) {
      return state.userToken;
    }
  },
  mutations: {
    setUserComponent(state, bool) {
      state.isUserComponent = bool;
    },
    setUser(state, token) {
      state.userToken = token;
    },
    setUserInfo(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
      state.userToken = null;
    }
  }
}
