<template>
  <!-- Inspect Crypto Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Crypto"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="custom-select categories" :class="{open: categorySelect}" @click="categorySelect = !categorySelect" v-click-away="categoryAway">
        <span v-if="selectedCategories && selectedCategories.length > 0">
          <span v-for="(category, index) in selectedCategories" :key="index">
            {{showCategory(category, index)}}
          </span>
        </span>
        <span v-else>Select Categories</span>
        <!-- Dropdown content -->
        <div class="select-dropdown" v-if="categorySelect">
          <a class="disabled">Coins</a>
          <div class="sub-categories">
            <div
              v-for="subCategory in categories.coinCategories"
              :key="subCategory.categoryId"
            >
              <a
                :class="{active: selectedCategories.includes(subCategory.categoryId)}"
                @click.stop="addRemoveCategory(subCategory.categoryId, null, subCategory.children)"
              >
                {{subCategory.categoryName}}
              </a>
              <div class="children" v-if="subCategory.children">
                <a
                  v-for="child in subCategory.children"
                  :key="child.categoryId"
                  :class="{active: selectedCategories.includes(child.categoryId)}"
                  @click.stop="addRemoveCategory(child.categoryId, subCategory.categoryId)"
                >
                  {{child.categoryName}}
                </a>
              </div>
            </div>
          </div>
          <a class="disabled">Tokens</a>
          <div class="sub-categories">
            <div
              v-for="subCategory in categories.tokenCategories"
              :key="subCategory.categoryId"
            >
              <a
                :class="{active: selectedCategories.includes(subCategory.categoryId)}"
                @click.stop="addRemoveCategory(subCategory.categoryId, null, subCategory.children)"
              >
                {{subCategory.categoryName}}
              </a>
              <div class="children" v-if="subCategory.children">
                <a
                  v-for="child in subCategory.children"
                  :key="child.categoryId"
                  :class="{active: selectedCategories.includes(child.categoryId)}"
                  @click.stop="addRemoveCategory(child.categoryId, subCategory.categoryId)">
                  {{child.categoryName}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tags">
        Tags:
        <span class="tag" v-for="(tag, index) in crypto.tags" :key="index">#{{tag.name}}</span>
      </div>

      <div class="field">
        <label>L1/L2:</label>
        <div class="custom-select" :class="{open: l1l2Select}" @click="l1l2Select = !l1l2Select" v-click-away="l1l2Away">
          <span v-if="l1l2">{{l1l2}}</span>
          <span v-else-if="l1l2 === null">-</span>
          <span v-else>Select...</span>
          <div class="select-dropdown" v-if="l1l2Select">
            <a @click="l1l2 = null">
              -
            </a>
            <a @click="l1l2 = 'L0'">
              L0
            </a>
            <a @click="l1l2 = 'L1'">
              L1
            </a>
            <a @click="l1l2 = 'L2'">
              L2
            </a>
          </div>
        </div>
      </div>

      <!-- Category Autocomplete Field -->
      <div class="field autocomplete" v-if="l1l2 == 'L2' && parentCryptos">
        <label>Parent Crypto:</label>
        <vue3-simple-typeahead
          placeholder="Type crypto..."
          :items="parentCryptos"
          :minInputLength="1"
          :itemProjection="(item) => item.name"
          @selectItem="submitParentCrypto"
          @onInput="keyUpEvent"
        >
        </vue3-simple-typeahead>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="crypto.is_active">
          <span class="checkmark"></span>
          Is Active
        </label>
      </div>

      <div class="field" v-if="crypto.is_active == false">
        <label for="admin-comment">Admin Comment:</label>
        <textarea id="admin-comment" v-model="crypto.admin_comment" placeholder="Admin Comment..."></textarea>
      </div>

      <div class="field" v-if="crypto.is_active == false">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder='Type "CONFIRM" to be able to change the equity status' v-model="confirm">
      </div>

      <button class="transition save-btn" @click="updateCryptoHandler()" :disabled="updateLoading 
                || (crypto.is_active == false && (crypto.admin_comment == null || crypto.admin_comment == '' || confirm != 'CONFIRM' ))
                || (crypto.is_active == true && crypto.admin_comment != null && crypto.admin_comment != '')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

      <span class="error-message" v-if="error">
        {{error}}
      </span>
    </template>
  </popup>
</template>

<script>
  import cryptoMixin from '@/mixins/crypto'
  import userMixin from '@/mixins/user'
  import securityMixin from '@/mixins/securities'

  import Popup from '@/components/shared/Popup'
  
  export default {
    name: 'InspectCrypto',
    mixins: [cryptoMixin, userMixin, securityMixin],
    props: {
      item: Object,
      categories: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        updateLoading: false,
        error: null,
        confirm: null,
        categorySelect: false,
        l1l2Select: false,
        l1l2: this.item.crypto_data.l1_l2,
        selectedCategories: this.item.classification_data.categories || [],
        parentCryptos: null,
        selectedParentCrypto: this.item.crypto_data.parent_crypto_id,
        crypto: this.item
      }
    },
    methods: {
      updateCryptoHandler() {
        this.updateLoading = true;

        const dataToSend = {
          isActive: this.crypto.is_active,
          adminComment: this.crypto.admin_comment || '',
          categories: this.selectedCategories,
          l1_l2: this.l1l2
        }

        if (this.selectedParentCrypto) {
          dataToSend.parent_crypto_id = this.selectedParentCrypto.id;
        }

        this.updateSecurity(dataToSend, this.crypto.id)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code == 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
      },
      categoryAway() {
        this.categorySelect = false;
      },
      l1l2Away() {
        this.l1l2Select = false;
      },
      addRemoveCategory(category, parentCategory, children) {
        if (this.selectedCategories.includes(category)) {
          let categoryIndex = this.selectedCategories.indexOf(category);
          this.selectedCategories.splice(categoryIndex, 1);
          if (children) {
            children.forEach(child => {
              if (this.selectedCategories.includes(child.categoryId)) {
                let childIndex = this.selectedCategories.indexOf(child.categoryId);
                this.selectedCategories.splice(childIndex, 1);
              }
            })
          }
        } else {
          if (parentCategory && !this.selectedCategories.includes(parentCategory)) {
            this.selectedCategories.push(parentCategory);
          }
          this.selectedCategories.push(category);
        }
      },
      showCategory(categoryId, index) {
        let foundCategory = this.categories.coinCategories.find(category => {
          return category.categoryId == categoryId;
        });

        if (!foundCategory) {
          foundCategory = this.categories.tokenCategories.find(category => {
            return category.categoryId == categoryId;
          });
        }

        if (foundCategory) {
          if (index > 0) {
            foundCategory = '| ' + foundCategory.categoryName;
          } else {
            foundCategory = foundCategory.categoryName;
          }
          return foundCategory;
        }
      },
      getCryptosHandler() {
        this.getSecurityList(4)
          .then(res => {
            this.parentCryptos = res.securities;
          })
      },
      // * Triggered when user selects Fund Category from the autocomplete. Updates the selected Fund Category
      submitParentCrypto(result) {
        this.selectedParentCrypto = result;
      },
      // * Set Category to 0, Other, if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedParentCrypto = null;
        }
      }
    },
    mounted() {
      this.getCryptosHandler();
    }
  }
</script>

<style scoped>
  .equity-id {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  .custom-select.categories {
    margin-top: 20px;
  }

  .custom-select.categories .select-dropdown > a {
    font-weight: 600;
    border-top: 1px solid #ddd;
    text-transform: uppercase;
  }

  .sub-categories a {
    padding-left: 50px;
  }

  .children a {
    padding-left: 90px;
  }

  .custom-select.categories a.disabled {
    cursor: default;
  }

  .custom-select.categories a.active {
    background: #3c6e71;
    color: #FFF;
  }

  .custom-select.categories a.active:hover {
    background: #568486;
  }

  .tags {
    margin: 10px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .tag {
    background: #f1f1f1;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
    white-space: nowrap;
  }
</style>
