<template>
  <popup customClass="show-content-popup" title="Content" @close="$emit('close')">
    <template v-slot:content>
      <div class="notification-preview">
        <div class="preview-container">
          <div v-if="showJson" class="show-json-data">
            <pre>{{ contentData }}</pre>
          </div>
          <div class="preview-content">
            <div class="top-buttons">
              <button class="show-json-btn" @click="showJson = !showJson">{{ !showJson ? 'Show JSON' : 'Hide JSON'}}</button>
              <div class="language-switcher">
                <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">EN</button>
                <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">DE</button>
              </div>  
            </div>

            <div class="second-buttons">
              <button class="edit-mode-btn" @click="toggleEditMode">{{!editModeActive ? 'Edit Mode' : 'Hide Edit Mode'}}</button>
            </div>

            <NotificationEditor
              v-if="editModeActive"
              :notificationTypeId="notificationTypeId"
              :data="notificationData"
              :contentData="contentData"
              :language="currentLanguage"
              @update-items="updateItems"
              @update-actions="updateActions"
            ></NotificationEditor>
            
            <div v-if="selectedObjects && Object.keys(this.selectedObjects).length > 0" class="reset">
              <button @click="resetPreview()">Reset Preview</button>
            </div>

            <!-- <pre>{{ notificationData }}</pre> -->
            <!-- <pre>{{ contentData }}</pre> -->
            <!-- <pre>{{ contentData[currentLanguage] }}</pre> -->
            <div v-if="contentData[currentLanguage]" :class="['content-data', contentData.status]">
              <span v-for="(item, index) in contentData[currentLanguage].items" :key="index">
                <!-- <pre>{{ item }}</pre> -->
                <!-- <pre>{{ item.type }}</pre> -->
                <template v-if="item.type == 'object'">
                  <!-- Dropdown for object type selection -->
                  <select v-if="!selectedObjects[index]" v-model="selectedObjects[index]">
                    <option value="" disabled>Select Object Type</option>
                    <option value="user">User</option>
                    <option value="wealthManager">Wealth Manager</option>
                    <option value="communityGroup">Community Group</option>
                    <option value="assetManager">Asset Manager</option>
                    <option value="contest">Contest</option>
                    <option value="broker">Broker</option>
                    <option value="fundUmbrella">Fund Umbrella</option>
                    <option value="equity">Equity</option>
                    <option value="etf">ETF</option>
                    <option value="fund">Fund</option>
                    <option value="portfolio">Portfolio</option>
                  </select>
                  <span v-else-if="selectedObjects[index]">
                    <a>{{ renderObjectText(selectedObjects[index]) }}</a>
                  </span>
                </template>

                <template v-else-if="item.type == 'link' || item.type == 'url'">
                  <a>{{ item.title }}</a>
                </template>

                <template v-else-if="item.type == 'text'">
                  {{ item.text }}
                  <!-- &nbsp;{{item.text}}&nbsp; -->
                </template>
              </span>

              <div v-if="contentData[currentLanguage].actions" class="actions">
                <button v-for="(action, index) in contentData[currentLanguage].actions" 
                  :key="index"
                  :class="{
                    'green': action.type == 'accept',
                    'white': action.type != 'accept'
                  }"
                >
                  {{ action.title }}
                </button>
              </div>
            </div>

            <NotificationSender
              :notificationTypeId="notificationTypeId"
              :language="currentLanguage"
              :contentData="contentData"
              :selectedObjects="selectedObjects"
            ></NotificationSender>

          </div>
        </div>
      </div>
    </template>
  </popup>
</template>
  
<script>
import NotificationEditor from '../NotificationEditor.vue';
import NotificationSender from '../NotificationSender.vue';
import Popup from "@/components/shared/Popup";

export default {
  name: "NotificationPreview",
  components: {
    NotificationEditor,
    NotificationSender,
    Popup
  },
  props: {
    notificationTypeId: Number,
    notificationData: Object,
  },
  data() {
    return {
      contentData: Object,
      currentLanguage: "en",
      showJson: false,
      editModeActive: false,
      selectedObjects: {},
    };
  },
  methods: {
    toggleEditMode() {
      this.editModeActive = !this.editModeActive;
      if (this.editModeActive) {
        // Reset contentData to the original notificationData
        this.contentData = JSON.parse(JSON.stringify(this.notificationData.contentData));
      }
    },
    updateItems(data) {
      let items = data.items;
      let language = data.language;
      this.contentData[language].items = items;
    },
    updateActions(data) {
      let actions = data.actions;
      let language = data.language;
      this.contentData[language].actions = actions;
    },
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    resetPreview() {
      this.selectedObjects = {};
    },
    renderObjectText(objectType) {
      switch (objectType) {
        case 'user': return " Hein Sweerts de Landas ";
        case 'wealthManager': return " Zürcher Kantonalbank ";
        case 'communityGroup': return " Team UMushroom ";
        case 'assetManager': return " UBS Asset Management ";
        case 'contest': return " Switzerland for Financial Literacy 2024 ";
        case 'broker': return " Swissquote ";
        case 'fundUmbrella': return " Vanguard Global Bd Idx ";
        case 'equity': return " Apple Inc ";
        case 'etf': return " abrdn Global Real Estate Active Thematics UCITS ETF USD Accumulating ";
        case 'fund': return " BlackRock Institutional Cash Series Euro Liquidity Fund Premier Acc EUR ";
        case 'portfolio': return " US Tech Behemoths ";
        default: return "";
      }
    },
  },
  mounted() {
    this.contentData = this.notificationData.contentData ?? [];
  },
};
</script>
  
<style scoped>
select {
  padding: 3px 12px;
  color: #696974;
  font-weight: 500;
  border: 1px solid #e2e2ea;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
}

button {
  cursor: pointer;
}

.preview-container {
  display: flex;
}

.show-json-data {
  flex: 1;
  border-right: 1px solid gray;
  overflow-x: scroll;
  margin-right: 2%;
}

.preview-content {
  flex: 2;
}

.top-buttons, .second-buttons {
  display: flex;
  justify-content: space-between;
  margin: 1% 0%;
}

.language-switcher button {
  border: none;
  padding: 9px 15px;
  font-weight: 600;
}

.language-switcher button.active {
  background-color: var(--dark-green);
  color: white;
}

.edit-mode-btn {
  border: none;
  padding: 9px 15px;
  font-weight: 600;
  background-color: #a55b1d;
  color: white;
}

.edit-mode-btn:hover {
  background-color: #E36C0A;
}

.show-json-btn {
  border: none;
  padding: 9px 15px;
  font-weight: 600;
  background-color: var(--blue);
  color: white;
}

.show-json-btn:hover {
  background-color: #3B7DD8;
}

.reset {
  text-align: center;
}

.reset button {
  margin-top: 1%;
  color: white;
  background-color: var(--dark-green);
  border: none;
  padding: 9px 15px;
  font-weight: 600;
}

.notification-preview {
  margin: 10px;
}

.notification-preview span {
  display: inline;
}

.content-data {
  margin: 3% auto;
  max-width: 650px;
}

.content-data span {
  font-weight: 500;
  color: #171725;
  line-height: 18px;
  margin: 0;
}

.content-data span a {
  text-decoration: underline;
  color: var(--dark-green);
  cursor: pointer;
}

.content-data span a:hover {
  text-decoration: none;
}

.content-data .actions {
  margin-top: 10px;
}

.content-data .actions button {
  background: var(--dark-green);
  color: #FFF;
  border: 1px solid var(--dark-green);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  width: 90px;
  height: 27px;
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
  outline: none;
}

.content-data .actions button.white {
  background: #FFF;
  color: var(--dark-green);
}

.content-data .actions button:hover {
  background: #FFF;
  color: var(--dark-green);
  border: 0;
  box-shadow: 0 0 10px rgba(0,0,0,.3);
}

.content-data .actions button.white:hover {
  background: var(--dark-green);
  color: #FFF;
}

.content-data.success {
  background: #3DD598;
}

.content-data.warning {
  background: #B9961A;
}

.content-data.error {
  background: #DE3A3A;
}

.content-data.success, .content-data.warning, .content-data.error {
  padding: 2% 1%;
  text-align: center;
}

.content-data.success span, .content-data.warning span, .content-data.error span {
  color: white;
}

.content-data.success span a, .content-data.warning span a, .content-data.error span a {
  color: white;
}
</style>
  