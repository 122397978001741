<template>
  <!-- Edit benchmark popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit benchmark"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Benchmark:</label>
        <input type="text" id="name" v-model="name" placeholder="Benchmark...">
      </div>

      <button class="transition save-btn" @click="editBenchmarkHandler()" :disabled="addLoading">
        <template v-if="!addLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundBenchmark',
    mixins: [fundsMixin, userMixin],
    components: {
      Popup
    },
    props: {
      benchmark: Object
    },
    data() {
      return {
        name: this.benchmark.benchmark,
        addLoading: false
      }
    },
    methods: {
      // * Triggers on save button click and creates Benchmark
      editBenchmarkHandler() {
        // * Start update loading (for the save button)
        this.addLoading = true;
        const dataToSend = {
          benchmark: this.name
        }

        this.updateBenchmark(this.benchmark.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.addLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.addLoading = false;
          })
      }
    }
  }
</script>

<style scoped>

</style>
