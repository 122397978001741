<template>
  <div class="tags">
    <h1>
      Tags <small v-if="total">({{total}})</small>
    </h1>

    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['edit', 'delete']"
        @update-table="getTagsHandler"
        @edit="editHandler"
        @delete="deleteHandler"
    />

    <EditTag
        v-if="editItem"
        :item="editItem"
        @close="editItem = null"
        @updated="updatedHandler"
    />

    <DeleteTag
        v-if="deleteItem"
        :item="deleteItem"
        @close="deleteItem = null"
        @deleted="updatedHandler"
    />

  </div>
</template>

<script>
import userMixin from '@/mixins/user.vue'
import tagsMixin from '@/mixins/tags.vue'
import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";
import EditTag from "@/components/system/popups/EditTag.vue";
import DeleteTag from "@/components/system/popups/DeleteTag.vue";

export default {
  name: 'Tags',
  mixins: [userMixin, tagsMixin],
  components: {
    EditTag,
    DataTableHolder,
    DeleteTag,
  },

  data() {
    return {
      total: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable: true
        },
        {
          title: 'English',
          name: 'enName',
          sortable: true
        },
        {
          title: 'German',
          name: 'deName',
          sortable: false
        },
        {
          title: 'Url Name',
          name: 'urlName',
          sortable: true
        },
        {
          title: 'Added On',
          name: 'addedOn',
          sortable: true
        },{
          title: 'Updated On',
          name: 'updatedOn',
          sortable: true
        },{
          title: 'Status',
          name: 'status',
          sortable: true
        },
      ],
      rows: null,
      pages: 0,
      updateResults : false,
      filterType: 'articleId',
      filterValue: '',
      isLoading: false,
      editItem: null,
      deleteItem: null,
    }
  },

  methods: {

    getTagsHandler(data) {

      this.isLoading = true;

      this.getTagsPaginated(data)
          .then(res => {
            this.pages = res.pagesCount;
            let tags = [];

            for (const tag of res.tags) {
              let Tag = {
                id: tag.id,
                enName: tag.name,
                deName: tag.nameData?.de?.name || null,
                urlName: tag.urlName,
                addedOn: tag.addedOn,
                updatedOn: tag.updatedOn,
                status: tag.status,
              }
              tags.push(Tag);
            }

            this.rows = tags;
            this.total = res.resultsCount;
            this.isLoading = false;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },

    editHandler(item) {
      this.editItem = item;
    },

    updatedHandler() {
      this.editItem = null;
      this.deleteItem = null;
      this.updateResults = !this.updateResults;
    },

    deleteHandler(item) {
      this.deleteItem = item;
    },
  },

}
</script>

<style scoped>


</style>