<template>
  <popup
          title="Edit achievement"
          @close="$emit('close')"
  >
    <template v-slot:content>

      <div class="field">
        <label for="name">Name (En):</label>
        <input type="text" id="nameEn" v-model="achievementNameEn" placeholder="Achievement name(en)...">
      </div>

      <div class="field">
        <label for="name">Name (De):</label>
        <input type="text" id="nameDe" v-model="achievementNameDe" placeholder="Achievement name(de)...">
      </div>

      <div class="field">
        <label for="name">Description (En):</label>
        <input type="text" id="descriptionEn" v-model="achievementDescriptionEn" placeholder="Description (en)...">
      </div>

      <div class="field">
        <label for="name">Description (De):</label>
        <input type="text" id="descriptionDe" v-model="achievementDescriptionDe" placeholder="Description (de)...">
      </div>

      <div class="field">
        <label for="name">Moto (En):</label>
        <input type="text" id="motoEn" v-model="achievementMotoEn" placeholder="Achievement moto(en)...">
      </div>

      <div class="field">
        <label for="name">Moto (De):</label>
        <input type="text" id="motoDe" v-model="achievementMotoDe" placeholder="Achievement moto(de)...">
      </div>

      <div class="field">
        <label>Category:</label>
        <div class="custom-select" :class="{open: achievementCategorySelect}" @click="achievementCategorySelect = !achievementCategorySelect" off_v-click-away="achievementCategorySelect = false">
          <span v-if="selectedAchievementCategory">{{selectedAchievementCategory.title}}</span>
          <span v-else>Select Category</span>
          <div class="select-dropdown" v-if="achievementCategorySelect">
            <a v-for="(category) in achievementCategoriesAll" :key="category.id" @click="selectAchievementCategory(category)">
              {{category.title}}
            </a>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="name">Coins:</label>
        <input type="text" id="coins" v-model="achievementCoins" placeholder="Coins...">
      </div>

      <div class="field">
        <label for="name">Url Name:</label>
        <input type="text" id="name" v-model="achievementName" placeholder="Achievement name(key)...">
      </div>

      <button class="transition save-btn" @click="editAchievementHandler()" :disabled="addLoading || !achievementName || !achievementNameEn || !selectedAchievementCategory || !achievementCoins">
        <template v-if="!addLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import userMixin from '@/mixins/user'
  import achievementsMixin from '@/mixins/achievements'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewAchievement',
    mixins: [userMixin, achievementsMixin],
    components: {
      Popup
    },
    props: {
      achievementCategoriesAll: Array,
      item: Object
    },
    data() {
      return {
        achievementCategorySelect: false,
        selectedAchievementCategory: null,
        achievementId: this.item.id,
        achievementName: this.item.name,
        achievementNameEn: this.item.translation.en.name,
        achievementNameDe: this.item.translation.de.name,
        achievementDescriptionEn: this.item.translation.en.description,
        achievementDescriptionDe: this.item.translation.de.description,
        achievementMotoEn: this.item.translation.en.moto,
        achievementMotoDe: this.item.translation.de.moto,
        achievementCoins: this.item.coins,
        addLoading: false,
      }
    },
    methods: {

      selectAchievementCategory(category) {
        this.selectedAchievementCategory = category;
      },  // editAchievementHandler

      editAchievementHandler() {
        this.addLoading = true;
        let editedAchievementId = this.item.id;
        const dataToSend = {
          name: this.achievementName,
          categoryId: this.selectedAchievementCategory.id,
          coins: this.achievementCoins,
          nameEn: this.achievementNameEn,
          nameDe: this.achievementNameDe,
          descriptionEn: this.achievementDescriptionEn,
          descriptionDe: this.achievementDescriptionDe,
          motoEn: this.achievementMotoEn,
          motoDe: this.achievementMotoDe
        };

        this.patchAchievement(editedAchievementId, dataToSend)
                .then(() => {
                  this.addLoading = false;
                  this.$emit('updated');
                })
                .catch(err => {
                  if (err == 401) {
                    this.logout();
                  }
                  this.addLoading = false;
                })
      } // editAchievementHandler

    }, // methods

    mounted() {
      this.selectedAchievementCategory = this.achievementCategoriesAll.find(category => {
        return category.id == this.item.category;
      });
    }

  }
</script>

