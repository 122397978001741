<template>
  <div class="daily-degree">
    <apexchart v-if="showChart" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  export default {
    name: 'DailyDegree',
    props: {
      data: Object
    },
    data() {
      return {
        showChart: false,
        series: [],
        chartOptions: {
          chart: {
            type: 'donut',
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          title: {
            text: 'Education Demographics',
            align: 'center',
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#171725'
            },
          },
          labels: ['High School', 'Bachelor', 'Master', 'PhD', 'Other'],
          colors:['#C1A16F', '#3C6E71', '#61F1F9', '#003E52', '#6f94af'],
          legend: {
            position: 'bottom',
            fontFamily: 'Poppins',
            fontWeight: 500
          }
        }
      }
    },
    mounted() {
      let mainData;
      if (this.data.totals) {
        mainData = this.data.totals.totalVisitorsDemographics.degree;
      } else {
        if (this.data.series == 0) {
          mainData = this.data.data.visitorsGuestDemographics.degree;
        } else if (this.data.series == 1) {
          mainData = this.data.data.visitorsPublicFollowerDemographics.degree;
        } else if (this.data.series == 2) {
          mainData = this.data.data.visitorsExclusiveFollowerDemographics.degree;
        }
      }

      this.series.push(mainData['high-school'] || 0);
      this.series.push(mainData.bachelor || 0);
      this.series.push(mainData.master || 0);
      this.series.push(mainData.phd || 0);
      this.series.push(mainData.other || 0);

      this.showChart = true;
    }
  }
</script>

<style scoped>

</style>