<template>
  <div class="wealth-managers-page">
    <h1>Financial Partners <small v-if="total">({{total}})</small></h1>

    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults" 
      :actions="['edit', 'new']"
      @update-table="getWealthManagersHandler"
      @edit="editHandler"
      @add-new="addWealthManagerPopup = true"
    />

    <!-- Add New Wealth Manager Popup -->
    <NewWealthManager
      v-if="addWealthManagerPopup"
      @close="addWealthManagerPopup = false"
      @created="createdHandler"
    />

    <!-- Edit Popup -->
    <EditWealthManager
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />

  </div>
</template>

<script>
  import organizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewWealthManager from './popups/NewWealthManagerNew.vue'
  import EditWealthManager from './popups/EditWealthManagerNew.vue'

  export default {
    name: 'WealthManagersNew',
    mixins: [organizationsMixin, userMixin],
    components: {
      DataTableHolder,
      NewWealthManager,
      EditWealthManager,
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Preferred Name',
            name: 'preferredName',
            sortable: true
          },
          {
            title: 'URL',
            name: 'urlName',
            sortable: true
          },
          {
            title: 'Is Paid',
            name: 'isPaid',
            sortable: true
          },
          {
            title: 'Is Visible',
            name: 'isVisible',
            sortable: true
          },
          {
            title: 'Is Deleted',
            name: 'isDeleted',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'createdOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        total: null,
        addWealthManagerPopup: false,
      }
    },
    methods: {
      // * Get wealth managers from API
      getWealthManagersHandler(data) {
        this.getOrganizationsPaginated(data, 2)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.total = res.resultsCount;
            // * Choose which fields to be visible in the datatable
            let organizations = [];
            // * Loop through organizations and add only the needed properties
            for (const organization of res.organizations) {
              let organizationObj = {
                id: organization.id,
                name: organization.name,
                preferredName: organization.preferredName,
                urlName: organization.urlName,
                isPaid: organization.isPaid,
                isVisible: organization.isVisible,
                isDeleted: organization.isDeleted,
                createdOn: organization.createdOn,
                updatedOn: organization.updatedOn
              }
              // * Add formatted organization to formatted organizations array
              organizations.push(organizationObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = organizations;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      createdHandler() {
        this.addWealthManagerPopup = false;
        this.updateResults = !this.updateResults;
      },
      editHandler(item) {
        this.editItem = {
          ...item
        };
      },
      updatedHandler() {
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    //     mounted() {
    //   // * On component (page) load get the fund groups for the autocomplete field in the edit popup
    //   this.getAllOrganizations(0)
    //     .then(res => {
    //       this.fundGroups = res.organizations;
    //     })
    //     .catch(err => {
    //       if (err == 401) {
    //         this.logout();
    //       }
    //     })
    // }
  }
</script>

<style scoped>

</style>


