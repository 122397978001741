<template>
  <div>
    <h3 v-if="questionTitle" v-html="questionTitle"></h3>
    <apexchart
      v-if="showChart"
      width="100%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import quizMixin from "@/mixins/quiz";

export default {
  name: "QuizQuestionPie",
  mixins: [quizMixin],
  props: {
    question: Number,
    filters: Object,
  },
  data() {
    return {
      showChart: false,
      series: [],
      chartOptions: {
        chart: {
          type: "pie",
          fontFamily: "Poppins",
          fontWeight: 500,
          fontSize: 30,
        },
        labels: [],
        colors: ["#3ed699", "#3c6e71", "#5a6671", "#ae8a51", "#89929a"],
        legend: {
          position: "bottom",
          fontFamily: "Poppins",
          fontWeight: 500,
        },
      },
      questionTitle: null,
    };
  },
  mounted() {
    this.getQuizSummaryQuestion(this.question, { filter: this.filters }).then(
      (res) => {
        this.questionTitle = res.question;
        for (const result of res.results) {
          this.series.push(result.count);
          if (result.answer) {
            this.chartOptions.labels.push(result.answer);
          } else {
            this.chartOptions.labels.push("None");
          }
        }
        this.showChart = true;
        this.$emit("loaded");
      }
    );
  },
};
</script>

<style scoped>
</style>
