<template>
  <!-- Security Market Data Page -->
  <div class="security-market-data-page">
    <h1 v-if="$route.name.includes('ActiveEquities')">Active Equities Market Data<small v-if="total">({{total}})</small></h1>
    <h1 v-else-if="$route.name.includes('CandidateEquities')">Candidate Equities Market Data<small v-if="total">({{total}})</small></h1>
    <h1 v-else-if="$route.name.includes('Etfs')">ETFs Market Data<small v-if="total">({{total}})</small></h1>
    <h1 v-else-if="$route.name.includes('Funds')">Funds Market Data<small v-if="total">({{total}})</small></h1>
    <h1 v-else-if="$route.name.includes('Cryptos')">Cryptos Market Data <small v-if="total">({{total}})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getSecuritiesHandler"
      @edit="editHandler" 
    />

    <!-- Inspect Popup -->
    <inspect-market-data-mds
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import securitiesMixin from '@/mixins/securities.vue'
  import userMixin from '@/mixins/user.vue'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder.vue'
  import InspectMarketDataMds from "@/components/shared/marketData/InspectMarketDataMds.vue";

  export default {
    name: 'MarketDataMds',
    mixins: [securitiesMixin, userMixin],
    components: {
      DataTableHolder,
      InspectMarketDataMds
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Exchange',
            name: 'exchange',
            sortable: true
          },
          {
            title: 'Valor',
            name: 'valor',
            sortable: true
          },
          {
            title: 'Last Price',
            name: 'price',
            sortable: true
          },
          {
            title: 'Last Price Date',
            name: 'priceDate',
            sortable: true
          },
          {
            title: 'Price Type',
            name: 'priceType',
            sortable: true
          },
          {
            title: 'Min Daily Return',
            name: 'minDailyReturn',
            sortable: true
          },
          {
            title: 'Max Daily Return',
            name: 'maxDailyReturn',
            sortable: true
          },
          {
            title: 'Count',
            name: 'count',
            sortable: true
          },
          {
            title: 'i_1',
            name: 'i1',
            sortable: true
          },
          {
            title: 'i_7',
            name: 'i7',
            sortable: true
          },
          {
            title: 'i_14',
            name: 'i14',
            sortable: true
          },
          {
            title: 'i_30',
            name: 'i30',
            sortable: true
          },
          {
            title: 'i_90',
            name: 'i90',
            sortable: true
          },
          {
            title: 'Max Interval',
            name: 'maxInterval',
            sortable: true
          },
          {
            title: 'NAV',
            name: 'nav',
            sortable: true
          },
          {
            title: 'Active Comment',
            name: 'activeComment',
            sortable: true
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        total: null,
        fullData: null,
        securityTypeId: null
      }
    },
    methods: {
      // * Get Securities from API
      getSecuritiesHandler(data) {
        this.filterSecurities(data);

        this.getSecurities(data, this.securityTypeId)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let securitiesFormatted = [];
            // * Loop through securities and add only the needed properties
            for (const security of res.securities) {
              let securityObj = {
                id: security.id,
                name: security.name,
                isin: security.isin,
                exchange: security.exchange_name,
                valor: security.valor,
                price: `${security.code} ${security.price_data.price.toFixed(2)}`,
                priceDate: security.price_data.price_date,
                priceType: security.price_data.price_type,
                minDailyReturn: (parseFloat(security.quality_control_data.min_daily_return) * 100).toFixed(2),
                maxDailyReturn: (parseFloat(security.quality_control_data.max_daily_return) * 100).toFixed(2),
                count: security.quality_control_data.count,
                i1: security.quality_control_data.interval_1,
                i7: security.quality_control_data.interval_7,
                i14: security.quality_control_data.interval_14,
                i30: security.quality_control_data.interval_30,
                i90: security.quality_control_data.interval_90,
                maxInterval: security.quality_control_data.max_interval,
                nav: security.quality_control_data.nav,
                activeComment: security.active_comment,
              }
              // * Add formatted security to formatted securities array
              securitiesFormatted.push(securityObj);
            }
            // * Assign table rows to be the formatted securities array
            this.rows = securitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
      },
      filterSecurities(data){

        if (this.$route.name.includes('Equities')) {
          this.securityTypeId = 1;
          if (this.$route.name.includes('Active')) {
            this.filterByActivity(data, 'isActive');
          }
          if (this.$route.name.includes('Candidate')) {
            this.filterByActivity(data, 'candidate');
          }
        }
        if (this.$route.name.includes('Funds')) {
          this.securityTypeId = 2;
          this.filterByActivity(data, 'isActive');
        }
        if (this.$route.name.includes('Etfs')) {
          this.securityTypeId = 3;
          this.filterByActivity(data, 'isActive');
        }
        if (this.$route.name.includes('Cryptos')) {
          this.securityTypeId = 4;
          this.filterByActivity(data, 'isActive');
        }
      },
      filterByActivity(data, filterName){
        let filteredData = data.filter.filter(function(item){
          return item.name === filterName;
        });
        if (filteredData.length === 0) {
          data.filter.push({
            name: filterName,
            term: true
          });
        }
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(security => {
          return security.id === item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    watch: {
      '$route': {
        immediate: true,
        async handler() {
          const data = {
            page: 1,
            itemsPerPage: 500,
            orderBy: 'id',
            order: 'asc',
            filter: []
          };
          this.rows = null;
          this.pages = 0;
          this.total = 0;
          this.fullData = null;
          await this.getSecuritiesHandler(data);
        }
      }
    }
  }
</script>

<style scoped>


</style>