<template>
  <popup title="Edit Translation" @close="$emit('close')">
    <template v-slot:content>
      <div class="language-switcher">
        <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">EN</button>
        <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">DE</button>
      </div>
      <div class="field">
        <label for="text">Text:</label>
        <textarea id="text" v-model="translation.textData[currentLanguage]" placeholder="Text"></textarea>
      </div>
      <div class="field">
        <label for="description">Description:</label>
        <textarea id="description" v-model="translation.description" placeholder="Description"></textarea>
      </div>
      <button class="transition save-btn" @click="updateTranslationHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading"> Save </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>
  
<script>
import Popup from "@/components/shared/Popup";
import translations from "../../../mixins/translations.vue";

export default {
  name: "EditTranslation",
  mixins: [translations],
  props: {
    item: Object,
  },
  components: {
    Popup,
  },
  data() {
    return {
      translation: { ...this.item },
      updateLoading: false,
      currentLanguage: 'en',
    };
  },
  methods: {
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    updateTranslationHandler() {
      this.updateLoading = true;
      const dataToSend = {
        textData: this.translation.textData,
        description: this.translation.description,
      };

      this.updateTranslation(this.translation.id, dataToSend)
        .then(() => {
          this.updateLoading = false;
          this.$emit('updated');
        })
        .catch(err => {
          console.error("Error updating translation:", err);
        }); 
    },
  },
};
</script>
  
<style scoped>
textarea {
  height: auto !important;
}

.field {
  margin-bottom: 20px;
}

.field label {
  font-size: 18px;
  margin-bottom: 5px;
  color: #3c6e71;
  font-weight: 500;
}

.field input[type="text"] {
  width: 100%;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  padding: 9px 15px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
}

.field input[type="text"]:focus {
  border-color: #3c6e71;
}

.save-btn {
  background: #3c6e71;
  color: #fafafb;
  border-radius: 8px;
  border: 0;
  display: block;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
}

.save-btn:hover {
  background: #295456;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.save-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.language-switcher {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
}

.language-switcher button {
  border: none;
  padding: 9px 15px;
  font-weight: 600;
  cursor: pointer;
}

.language-switcher button.active {
  background-color: #3c6e71;
  color: white;
}
</style>