<template>
  <!-- Edit Fund Umbrella Characteristics Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Fund Umbrella Characteristics"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Name <small>(not editable)</small>:</label>
        <input type="text" id="name" v-model="fundUmbrella.UmbrellaName" placeholder="Name" disabled>
      </div>

      <div class="field umbrella-description" v-if="fundUmbrella.investmentObjective">
        <label>Description</label>
        <p>
          {{fundUmbrella.investmentObjective}}
        </p>
      </div>

      <div class="field selected-benchmarks" v-if="fundUmbrella.SelectedBenchmarks && fundUmbrella.SelectedBenchmarks.length > 0">
        <label>Selected Benchmarks:</label>
        <ul>
          <template v-for="(benchmark, index) in fundUmbrella.SelectedBenchmarks">
            <li v-if="benchmark.benchmark" :key="index">
              {{benchmark.benchmark}}
            </li>
          </template>
        </ul>
      </div>

      <!-- Category Autocomplete Field -->
      <div class="field autocomplete">
        <label>Fund Category:</label>
        <vue3-simple-typeahead
          placeholder="Type fund category..."
          :items="fundCategories"
          :minInputLength="1"
          :itemProjection="(item) => item.fundCategoryName"
          @selectItem="submitFundCategory"
          @onInput="keyUpEventFundCategory"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="(fundUmbrella.fundCategory || fundUmbrella.fundCategory == 0) && !selectedFundCategory">
          Cannot remove Fund Category, will be set to Other
        </span>
      </div>

      <!-- Sector Autocomplete Field -->
      <div class="field autocomplete">
        <label>Sector:</label>
        <vue3-simple-typeahead
          placeholder="Type sector..."
          :items="sectors"
          :minInputLength="1"
          :itemProjection="(item) => item.sector"
          @selectItem="submitSector"
          @onInput="keyUpEventSector"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="fundUmbrella.sectorId && !selectedSector">
          Are you sure you want to remove the sector?
        </span>
      </div>

      <!-- Style Autocomplete Field -->
      <div class="field autocomplete">
        <label>Style:</label>
        <vue3-simple-typeahead
          placeholder="Type style..."
          :items="styles"
          :minInputLength="1"
          :itemProjection="(item) => item.style"
          @selectItem="submitStyle"
          @onInput="keyUpEventStyle"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="fundUmbrella.styleId && !selectedStyle">
          Are you sure you want to remove the style?
        </span>
      </div>

      <!-- Geography Autocomplete Field -->
      <div class="field autocomplete">
        <label>Geography:</label>
        <vue3-simple-typeahead
          placeholder="Type geography..."
          :items="geographies"
          :minInputLength="1"
          :itemProjection="(item) => item.geography"
          @selectItem="submitGeography"
          @onInput="keyUpEventGeography"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="fundUmbrella.geographyId && !selectedGeography">
          Are you sure you want to remove the geography?
        </span>
      </div>

      <button class="transition save-btn" @click="updateFundUmbrellaHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundUmbrellaCharacteristics',
    mixins: [fundsMixin, userMixin],
    props: {
      item: Object,
      sectors: Array,
      styles: Array,
      geographies: Array,
      fundCategories: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        selectedSector: null,
        selectedStyle: null,
        selectedGeography: null,
        selectedFundCategory: null,
        updateLoading: false,
        fundUmbrella: this.item
      }
    },
    methods: {
      // * Triggers on save button click and updates umbrella
      updateFundUmbrellaHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {};
        
        // * If user has selected Fund Category from the list add it to the request data, else send 0 "Other"
        if (this.selectedFundCategory) {
          dataToSend.fundCategory = this.selectedFundCategory.fundCategory;
        } else {
          dataToSend.fundCategory = 0;
        }

        // * If user has selected sector from the list add it to the request data else send null
        if (this.selectedSector) {
          dataToSend.sectorId = this.selectedSector.id;
        } else {
          dataToSend.sectorId = null;
        }

        // * If user has selected style from the list add it to the request data else send null
        if (this.selectedStyle) {
          dataToSend.styleId = this.selectedStyle.id;
        } else {
          dataToSend.styleId = null;
        }

        // * If user has selected geography from the list add it to the request data else send null
        if (this.selectedGeography) {
          dataToSend.geographyId = this.selectedGeography.id;
        } else {
          dataToSend.geographyId = null;
        }

        this.editFundUmbrella(this.fundUmbrella.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      // * Fund Categories AutoComplete Functions
        // * Triggered when user selects Fund Category from the autocomplete. Updates the selected Fund Category
        submitFundCategory(result) {
          this.selectedFundCategory = result;
        },
        // * Set Category to 0, Other, if user deletes the autocomplete field content
        keyUpEventFundCategory(value) {
          if (!value.input || value.input.length == 0) {
            this.selectedFundCategory = 0;
          }
        },
      // * Sectors AutoComplete Functions
        // * Triggered when user selects sector from the autocomplete. Updates the selected sector
        submitSector(result) {
          this.selectedSector = result;
        },
        // * Nulls sector if user deletes the autocomplete field content
        keyUpEventSector(value) {
          if (!value.input || value.input.length == 0) {
            this.selectedSector = null;
          }
        },
      // * Styles AutoComplete Functions
        // * Triggered when user selects style from the autocomplete. Updates the selected style
        submitStyle(result) {
          this.selectedStyle = result;
        },
        // * Nulls style if user deletes the autocomplete field content
        keyUpEventStyle(value) {
          if (!value.input || value.input.length == 0) {
            this.selectedStyle = null;
          }
        },
      // * Geographies AutoComplete Functions
        // * Triggered when user selects geography from the autocomplete. Updates the selected geography
        submitGeography(result) {
          this.selectedGeography = result;
        },
        // * Nulls geography if user deletes the autocomplete field content
        keyUpEventGeography(value) {
          if (!value.input || value.input.length == 0) {
            this.selectedGeography = null;
          }
        }
    },
    mounted() {
      
      if (this.fundUmbrella.fundCategory) {
        this.selectedFundCategory = {
          fundCategory: this.fundUmbrella.fundCategory,
          fundCategoryName: this.fundUmbrella.fundCategoryName
        }
      }
      else {
        this.selectedFundCategory = {
          fundCategory: "0",
          fundCategoryName: "Other"
        }
      }

      if (this.fundUmbrella.sectorId) {
        this.selectedSector = {
          id: this.fundUmbrella.sectorId,
          sector: this.fundUmbrella.sector
        }
      }

      if (this.fundUmbrella.styleId) {
        this.selectedStyle = {
          id: this.fundUmbrella.styleId,
          style: this.fundUmbrella.style
        }
      }

      if (this.fundUmbrella.geographyId) {
        this.selectedGeography = {
          id: this.fundUmbrella.geographyId,
          geography: this.fundUmbrella.geography
        }
      }
    }
  }
</script>

<style scoped>
  .umbrella-description,
  .selected-benchmarks li {
    font-size: 14px;
  }
</style>
