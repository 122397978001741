<template>
  <div></div>
</template>

<script>
import userMixin from "@/mixins/user";

export default {
  name: "VerifyLogin",
  mixins: [userMixin],
  data() {
    return {
      verificationCode: this.$route.params.token,
    };
  },
  methods: {
    verify2FALoginHanlder() {
      const data = {
        token: this.verificationCode,
      };
      this.verify2FALogin(data)
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push('/login');
        });
    },
  },
  created() {
    this.verify2FALoginHanlder();
  },
};
</script>

<style scoped>
</style>
