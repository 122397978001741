<template>
  <div>
    <div class="actions" v-if="editor">
      <!-- Font Style -->
      <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <font-awesome-icon icon="bold" />
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <font-awesome-icon icon="italic" />
      </button>
      <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
        <font-awesome-icon icon="underline" />
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <font-awesome-icon icon="strikethrough" />
      </button>
      <!-- Headings -->
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        <font-awesome-icon icon="heading" />
        <small>1</small>
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        <font-awesome-icon icon="heading" />
        <small>2</small>
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        <font-awesome-icon icon="heading" />
        <small>3</small>
      </button>
      <!-- Lists -->
      <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <font-awesome-icon icon="list-ul" />
      </button>
      <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <font-awesome-icon icon="list-ol" />
      </button>
      <!-- Text Align -->
      <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
        <font-awesome-icon icon="align-left" />
      </button>
      <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
        <font-awesome-icon icon="align-center" />
      </button>
      <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
        <font-awesome-icon icon="align-right" />
      </button>
      <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
        <font-awesome-icon icon="align-justify" />
      </button>
      <!-- Link -->
      <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
        <font-awesome-icon icon="link" />
      </button>
      <button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
        <font-awesome-icon icon="unlink" />
      </button>
      <!-- Other -->
      <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        <font-awesome-icon icon="quote-right" />
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        <font-awesome-icon icon="minus" />
      </button>
      <button @click="editor.chain().focus().toggleHighlight({ color: '#3C6E71' }).run()" :class="{ 'is-active': editor.isActive('highlight') }">
        <font-awesome-icon icon="highlighter" />
      </button>
    </div>
    <div class="editor">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'

export default {
  name: 'TextEditor',
  components: {
    EditorContent,
  },
  props: {
    addedImage: String,
    body: String
  },
  data() {
    return {
      editor: null,
    }
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  },
  watch: {
    body() {
      this.editor.commands.setContent(this.body);
    },
    addedImage() {
      this.editor.commands.setImage({ src: this.addedImage });

      this.$emit('set-content', this.editor.getHTML());
    },
    // Handles internal model changes.
    // innerValue (newVal) {
    //   this.$emit('input', newVal)
    // },
    // Handles external model changes.
    value() {
      let html = this.editor.getHTML();
      if (html !== this.value) {
        this.editor.setContent(this.value);
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      content: '',
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Color,
        Placeholder.configure({
          placeholder: 'Type in your article'
        }),
        Underline,
        Link,
        Highlight.configure({
          multicolor: true,
        }),
        Image
      ],
      parseOptions: {
        preserveWhitespace: 'full',
      },
      // onUpdate: ({ getHTML }) => {
      //   this.innerValue = getHTML()
      // }
    })

    this.editor.on('blur', ({ editor }) => {
      this.$emit('set-content', editor.getHTML())
    })

    this.editor.commands.setContent(this.body, true, { preserveWhitespace: true });
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style scoped>
  .editor {
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 10px;
    margin-top: 5px;
    padding: 0 20px;
  }

  .editor :deep(.ProseMirror) {
    outline: none !important;
    min-height: 300px;
    color: #4D4D4D;
  }

  .editor :deep(.ProseMirror mark) {
    color: #FFF;
  }

  .editor :deep(.ProseMirror img) {
    max-width: 100%;
    border-radius: 10px;
  }

  .editor :deep(.ProseMirror a) {
    color: #3C6E71;
  }

  .editor :deep(.ProseMirror li p) {
    margin: 0;
  }

  .editor :deep(blockquote) {
    color: #4D4D4D;
    margin: 10px 0;
    padding: 20px 0 20px 30px;
    position: relative;
    border-left: 3px solid #3C6E71;
  }

  .editor :deep(blockquote:before) {
    content: '\f10e';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: -10px;
    padding: 5px 0;
    transform: translateY(-50%);
    color: #3C6E71;
    background-color: #FFF;
  }

  .editor :deep(blockquote p) {
    margin: 0;
    color: #4D4D4D;
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
  }

  button {
    background: transparent;
    border: 0;
    font-size: 16px;
  }

  button:hover,
  button.is-active {
    background: #E2E2EA;
    border-radius: 5px;
    cursor: pointer;
  }

  .relative-button {
    position: relative;
    overflow: hidden;
  }

  .relative-button input[type="color"] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }

  button small {
    margin-left: 2px;
    font-size: 11px;
    line-height: 8px;
  }

  @media (max-width: 767px) {
    .actions {
      margin: 20px 0 10px 0;
    }
  }
</style>
