<template>
  <!-- Fund Market Data Page -->
  <div class="fund-market-data-page">
    <h1>Fund Market Data <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getFundsHandler"
      @edit="editHandler" 
    />

    <!-- Inspect Popup -->
    <InspectFundMarketData
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import InspectFundMarketData from './popups/InspectFundMarketData'

  export default {
    name: 'FundMarketData',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      InspectFundMarketData
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Citicode',
            name: 'citiCode',
            sortable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Name',
            name: 'fundName',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Static',
            name: 'static',
            sortable: true
          },
          {
            title: 'Dynamic',
            name: 'dynamic',
            sortable: true
          },
          {
            title: 'Lifecycle',
            name: 'fundLifecycle',
            sortable: true
          },
          {
            title: 'Price Date',
            name: 'priceDate',
            sortable: true
          },
          {
            title: 'Daily Performance',
            name: 'dailyPerformance',
            sortable: true
          },
          {
            title: 'Min Daily Return',
            name: 'minDailyReturn',
            sortable: true
          },
          {
            title: 'Max Daily Return',
            name: 'maxDailyReturn',
            sortable: true
          },
          {
            title: 'CCY Override',
            name: 'ccyOverride',
            sortable: true
          },
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        total: null,
        fullData: null
      }
    },
    methods: {
      // * Get funds from API
      getFundsHandler(data) {
        this.getFundsPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let fundsFormatted = [];
            // * Loop through funds and add only the needed properties
            for (const fund of res.funds) {
              let fundObj = {
                id: fund.id,
                citiCode: fund.citiCode,
                isin: fund.isin,
                fundName: fund.fundName,
                addedOn: fund.addedOn,
                static: fund.static,
                dynamic: fund.dynamic,
                fundLifecycle: fund.fundLifecycle,
                priceDate: fund.priceDate,
                dailyPerformance: typeof fund.dailyPerformance == 'number' ? parseFloat(fund.dailyPerformance).toFixed(2) : null,
                minDailyReturn: typeof fund.minDailyReturn == 'number' ? (parseFloat(fund.minDailyReturn) * 100).toFixed(2) : null,
                maxDailyReturn: typeof fund.maxDailyReturn == 'number' ? (parseFloat(fund.maxDailyReturn) * 100).toFixed(2) : null,
                ccyOverride: fund.ccyOverride,
              }
              // * Add formatted fund to formatted funds array
              fundsFormatted.push(fundObj);
            }
            // * Assign table rows to be the formatted funds array
            this.rows = fundsFormatted;
            this.fullData = res.funds;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(fund => {
          return fund.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>


</style>