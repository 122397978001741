<template>
  <popup
      title="Delete old url name"
      @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <img :src="$s3Url + 'svgs/icons/delete-x.svg'">
        <h4>Are you sure?</h4>
        <p>Are you sure you want to permenantly delete the following old url name: <strong>{{item.id}}</strong>?</p>

        <button class="delete-btn transition" @click="deleteHandler()">
          Delete
        </button>
      </div>
    </template>
  </popup>
</template>

<script>

import Popup from '@/components/shared/Popup'
import oldUrlNames from "@/mixins/oldUrlNames.vue";

export default {
  name: 'DeleteOldUrlName',
  mixins: [oldUrlNames],
  props: {
    item: Object
  },
  components: {
    Popup
  },
  data() {
    return {
      confirm: null
    }
  },
  methods: {
    deleteHandler() {
      this.deleteOldUrlName(this.item.id)
          .then(() => {
            this.$emit('deleted');
          })
    }
  }
}
</script>

<style scoped>
.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-content img {
  margin-top: 100px;
}

.popup-content h4 {
  color: #171725;
  font-size: 32px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 130px;
}

.popup-content p {
  color: #696974;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

.field {
  width: 100%;
}

@media (max-width: 1550px) {
  .popup-content img {
    margin-top: 30px;
  }

  .popup-content h4 {
    margin-bottom: 30px;
  }
}
</style>