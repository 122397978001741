<template>
  <div class="session-item">
    <div class="session-header" @click="toggleSessionOpen">
      <div class="session-header-inner">
        <div class="header-content">
          <font-awesome-icon class="toggle-comments-icon" v-if="sessionOpen" icon="minus" />
          <font-awesome-icon class="toggle-comments-icon" v-else icon="plus" />
          <img v-if="session.videoData && (session.videoData.en.thumbnail || session.videoData.de.thumbnail)" :src="`https://vod.umushroom.com/${session.videoData.en.videoId}/${session.videoData.en.thumbnail || session.videoData.de.thumbnail}`" @error="noThumb($event)">
          <img v-else src="@/assets/images/video-placeholder.jpg">
          <router-link class="session-link" :to="getSessionEditRoute()">
            <h5>{{session.title.en.title}}</h5>
          </router-link>

          <div v-if="loadingComments" class="loading-indicator">
            <font-awesome-icon icon="spinner" spin />
          </div>
        </div>

        <div class="active-plans">
          <span v-if="session.explore" class="explore">Explore</span>
          <span v-if="session.learn" class="learn">Learn</span>
          <span v-if="session.grow" class="grow">Grow</span>
        </div>

        <div class="session-type">
          {{ getSessionType(session.type) }}
        </div>

        <div class="session-status">
          {{ getSessionStatus(session.status) }}
        </div>

        <div v-if="session.activeFrom" class="active-from">
          Active From: 
          <span class="bold">
            {{ session.activeFrom }}
          </span>
        </div>
      </div>

      <div class="actions">
        <a v-if="session.videoData" @click.stop="previewVideoItem = session.videoData.url" target="_blank">
          <font-awesome-icon icon="video" />
        </a>
        <router-link :to="getSessionEditRoute()">
          <font-awesome-icon icon="edit" />
        </router-link>
        <a @click.stop="deleteSessionHandler">
          <font-awesome-icon icon="trash" />
        </a>
      </div>
    </div>

    <div class="session-content" v-if="sessionOpen">
      <CommentItem
        v-for="comment in comments" 
        :key="comment.id" 
        :comment="comment"
        @update-comments="getCommentsHandler"
      />
    </div>

    <PreviewVideo
      v-if="previewVideoItem"
      :sessionUrl="previewVideoItem"
      @close="previewVideoItem = null"
    />
  </div>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'
  import PreviewVideo from './popups/PreviewVideo'
  import CommentItem from './CommentItem';

  export default {
    name: 'SessionItem',
    mixins: [knowledgeMixin],
    components: {
      PreviewVideo,
      CommentItem
    },
    props: {
      courseId: Number,
      moduleId: Number,
      sectionId: Number,
      session: Object
    },
    data() {
      return {
        comments: null,
        previewVideoItem: null,
        sessionOpen: false,
        loadingComments: false
      }
    },
    methods: {
      deleteSessionHandler() {
        const deleteData = {
          'courseId': this.courseId, 
          'moduleId': this.moduleId, 
          'sectionId': this.sectionId, 
          'sessionId': this.session.id
        };

        this.$emit("delete-session", deleteData);
      },
      noThumb(event) {
        event.target.src = `${this.$s3Url}placeholders/video-placeholder.jpg`;
      },
      getSessionEditRoute() {
        return `/knowledge/${this.courseId}/${this.moduleId}/${this.sectionId}/edit/${this.session.id}`;
      },
      getCommentsHandler() {
        this.loadingComments = true;
        this.getSessionComments(this.session.id)
          .then(res => {
            this.comments = res.comments;
          })
          .finally(() => {
            this.loadingComments = false;
          });
      },
      toggleSessionOpen() {
        this.sessionOpen = !this.sessionOpen;
        this.loadComments();
      },
      loadComments() {
        if (!this.comments) {
          this.getCommentsHandler();
        }
      },
      getSessionType(type) {
        switch (type) {
          case 0:
            return 'Text';
          case 1:
            return 'Video';
          case 2:
            return 'Quiz';
          default:
            return '-';
        }
      },
      getSessionStatus(status) {
        switch (status) {
          case 0:
            return 'Draft';
          case 1:
            return 'Active';
          case 2:
            return 'Hidden';
          default:
            return '-';
        }
      }
    }
  }
</script>

<style scoped>
  .active-plans {
    display: flex;
    gap: 10px;
    font-weight: 800;
    margin-right: 1%;
    flex: 0 0 250px;
  }

  .session-type,
  .session-status {
    flex: 0 0 100px;
  }

  .active-from {
    flex: 0 0 150px;
  }

  .active-plans .explore {
    color: #3c6e71;
  }

  .active-plans .learn {
    color: #E39693;
  }

  .active-plans .grow {
    color: #013DB3;
  }

  .session-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    border-bottom: 1px solid rgb(221, 221, 221);
  }

  .session-header-inner {
    display: flex;
    align-items: center;
    flex: 0 0 90%;
  }

  .header-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    gap: 10px;
    cursor: move;
    flex: 0 0 50%;
  }
  
  .session-link {
    text-decoration: none;
    color: black;
  }

  .session-item img {
    width: 80px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }

  .session-item h5 {
    font-weight: 500;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .session-item p {
    font-size: 16px;
    margin: 0;
  }

  .actions {
    display: flex;
    gap: 20px;
  }

  .actions a {
    font-size: 20px;
    color: #3c6e71 !important;
  }

  .toggle-comments-icon {
    cursor: pointer;
  }
  
  .bold {
    font-weight: bold;
  }
</style>
