<template>
  <popup
    title="Upgrade subscription"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="field">
        <label>User Email:</label>
        <input v-model="userEmail" type="text" readonly>
      </div>

      <div class="field">
        <label>Actual Plan:</label>
        <div class="custom-select" :class="{open: actualPlanSelect}" @click="actualPlanSelect = !actualPlanSelect" v-click-away="actualPlanAway">
          <span v-if="actualPlan != null">{{plans[actualPlan]}}</span>
          <span v-else>Select Plan</span>
          <div class="select-dropdown" v-if="actualPlanSelect">
            <a
              v-for="(plan, index) in plans"
              :key="index"
              @click="actualPlan = index"
            >
              {{plan}}
            </a>
          </div>
        </div>
      </div>

      <div class="field">
        <label>Free Upgrade Expiration Date:</label>
        <div class="date-filter">
          <Datepicker class="datepicker" v-model="freeUpgradeExpirationDate" format="yyyy-MM-dd HH:mm" :enable-time-picker="true" />
        </div>
      </div>

      <div class="error-message" v-if="actualPlan < item.paidPlan">
        You can't downgrade a subscriber
      </div>

      <button
        class="transition save-btn"
        :disabled="actualPlan < item.paidPlan"
        @click="updateSubscriptionHandler()"
      >
        <span v-if="!buttonLoading">
          Upgrade
        </span>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import { format } from 'date-fns';
  
  import usersMixin from '@/mixins/users'
  import datesMixin from '@/mixins/dates'
  
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'UpgradeSubscription',
    emits: ['updated', 'close'],
    mixins: [ usersMixin, datesMixin ],
    components: {
      Popup
    },
    props: {
      item: Object
    },
    data() {
      return {
        plans: [
          'Explore',
          'Learn',
          'Grow'
        ],
        userEmail: this.item.email,
        actualPlan: this.item.actualPlan,
        actualPlanSelect: false,
        buttonLoading: false,
        freeUpgradeExpirationDate: (() => {
          const inputDate = this.item.freeUpgradeExpirationDate;
          const date = inputDate ? new Date(inputDate) : new Date();
          date.setHours(0, 0, 0, 0);

          return date;
        })()
      }
    },
    methods: {
      actualPlanAway() {
        this.actualPlanSelect = false;
      },
      updateSubscriptionHandler() {
        this.buttonLoading = true;
        const dataToSend = {
          actualPlan: this.actualPlan,
          freeUpgradeExpirationDate: this.freeUpgradeExpirationDate ? format(this.freeUpgradeExpirationDate, 'yyyy-MM-dd HH:mm:ss') : null,
        }

        this.upgradeSubscription(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.buttonLoading = false;
          })
      },
    }
  }
</script>

<style scoped>
  :deep(.date-filter) {
    max-width: unset;
  }

  .field :deep(.vue-daterange-picker) {
    width: 100%;
  }

  .field :deep(.vue-daterange-picker .form-control) {
    width: 100%;
    height: auto;
    margin: 0 !important;
    box-shadow: none;
    line-height: initial;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 10px;
    outline: none;
    font-size: 16px;
    color: #171725;
  }

  .period-select {
    padding: 3px 12px;
    color: #696974;
    font-weight: 500;
    border: 1px solid #e2e2ea;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    margin-left: 2%;
  }

  .period-text {
    color: rgb(185, 35, 35);
  }

  .trial-expired-wrapper .checkbox{
    display: inline-block;
    margin-left: 1%;
    margin-bottom: 15px;
  }
</style>

<style>
  .datepicker input {
    padding-left: 6% !important;
  }
</style>
