<template>
  <!-- Fund Advisors Page -->
  <div class="fund-advisors-mds-page">
    <h1>Fund Advisors <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getFundAdvisorsMdsHandler"
      @edit="editHandler" 
    />

    <!-- Edit Popup -->
    <EditFundAdvisorMds
      v-if="editItem"
      :item="editItem"
      :parentGroups="parentGroups"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import orgnanizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'
  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundAdvisorMds from './popups/EditFundAdvisorMds'

  export default {
    name: 'FundAdvisorsMds',
    mixins: [orgnanizationsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundAdvisorMds
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Preferred Name',
            name: 'preferredName',
            sortable: true
          },
          {
            title: 'URL Name',
            name: 'urlName',
            sortable: true
          },
          {
            title: 'Parent ID',
            name: 'parentGroupId',
            sortable: true
          },
          {
            title: 'Parent Name',
            name: 'parentGroupName',
            sortable: true
          },
          {
            title: 'Parent Preferred Name',
            name: 'parentGroupPreferredName',
            sortable: true
          },
          {
            title: 'Is AM',
            name: 'isAssetManager',
            sortable: true
          },
          {
            title: 'Funds',
            name: 'fundsCount',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'createdOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
          ,
          {
            title: 'Is Paid',
            name: 'isPaid',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        parentGroups: null,
        updateResults: true,
        total: null
      }
    },
    methods: {
      // * Get fund advisors from API
      getFundAdvisorsMdsHandler(data) {
        this.getOrganizationsPaginated(data, 1)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.total = res.resultsCount;
            // * Choose which fields to be visible in the datatable
            let organizations = [];
            // * Loop through organizations and add only the needed properties
            for (const organization of res.organizations) {
              let organizationObj = {
                id: organization.id,
                name: organization.name,
                preferredName: organization.preferredName,
                urlName: organization.urlName,
                parentGroupId: organization.parentGroupId,
                parentGroupName: organization.parentGroupName,
                parentGroupPreferredName: organization.parentGroupPreferredName,
                isAssetManager: organization.isAssetManager,
                fundsCount: organization.fundsCount,
                createdOn: organization.createdOn,
                updatedOn: organization.updatedOn,
                isPaid: organization.isPaid
              }
              // * Add formatted organization to formatted organizations array
              organizations.push(organizationObj);
            }
            // * Assign table rows to be the formatted orders array
            this.rows = organizations;
            this.fullData = res.organizations;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = {
          ...item
        };
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      // * On component (page) load get the fund groups for the autocomplete field in the edit popup
      this.getAllOrganizations(0)
        .then(res => {
          this.parentGroups = res.organizations;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>


</style>