<template>
  <div class="community-groups-page">
    <h1>Community Groups <small v-if="total">({{total}})</small></h1>

    <div class="filter-module">
      <p class="filter-module-title">Find CG through Users' Portfolios</p>
      <div class="filter-dropdown">
        <label for="filter-type">Filter By:</label>
        <select v-model="filterType" id="filter-type">
          <option value="userId">User ID</option>
          <option value="userEmail">User Email</option>
          <option value="firstName">User First Name</option>
          <option value="lastName">User Last Name</option>
        </select>

        <label for="filter-value"></label>
        <input type="text" id="filter-value" v-model="filterValue" :placeholder="placeholderText" @keyup.enter="getCommunityGroupsHandler({ filter: [] })">
        <button class="filter-btn transition" @click="getCommunityGroupsHandler({ filter: [] })">Filter</button>

        <span v-if="isLoading" class="loading-text">Loading...</span>
        <div v-if="isLoading" class="spinner"></div>
      </div>
    </div>



    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['edit', 'new']"
        @update-table="getCommunityGroupsHandler"
        @edit="editHandler"
        @add-new="addCommunityGroupPopup = true"
    />

    <EditCommunityGroup
        v-if="editItem"
        :item="editItem"
        @close="editItem = null"
        @updated="updatedHandler"
    />

    <NewCommunityGroup
        v-if="addCommunityGroupPopup"
        @close="addCommunityGroupPopup = false"
        @created="createdHandler"
    />

  </div>
</template>

<script>
import organizationsMixin from "@/mixins/organizations.vue";
import userMixin from '@/mixins/user'

import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
import EditCommunityGroup from './popups/EditCommunityGroup.vue'
import NewCommunityGroup from './popups/NewCommunityGroup.vue'

export default {
  name: 'CommunityGroups',
  mixins: [organizationsMixin, userMixin],
  components: {
    DataTableHolder,
    EditCommunityGroup,
    NewCommunityGroup
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable: true
        },
        {
          title: 'Name',
          name: 'name',
          sortable: true
        },
        {
          title: 'URL Name',
          name: 'urlName',
          sortable: true
        },
        {
          title: 'Founder ID',
          name: 'founderId',
          sortable: true
        },
        {
          title: 'Is Open',
          name: 'isOpen',
          sortable: true
        },
        {
          title: 'Is Visible',
          name: 'isVisible',
          sortable: true
        },
        {
          title: 'Is Deleted',
          name: 'isDeleted',
          sortable: true
        },
      ],
      editItem: null,
      pages: 0,
      updateResults: false,
      addCommunityGroupPopup: false,
      total: null,
      filterType: 'userId', // default value
      filterValue: '',
      isLoading: false
    }
  },

  computed: {
    placeholderText() {
      switch (this.filterType) {
        case 'userId':
          return 'Enter user ID';
        case 'userEmail':
          return 'Enter user email';
        case 'firstName':
          return 'Enter user first name';
        case 'lastName':
          return 'Enter user last name';
        default:
          return '';
      }
    },
  },
  methods: {
    // * Get community groups from API
    getCommunityGroupsHandler(data) {
      this.isLoading = true;
      this.addUserIdFilter(data);
      this.getOrganizationsPaginated(data, 3)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let organizations = [];
            // * Loop through organizations and add only the needed properties
            for (const organization of res.organizations) {
              let organizationObj = {
                id: organization.id,
                name: organization.name,
                urlName: organization.urlName,
                founderId: organization.founderId,
                isOpen: this.getIsOpenBoolean(organization.isOpen),
                isVisible: organization.isVisible,
                isDeleted: organization.isDeleted,
              }
              // * Add formatted organization to formatted organizations array
              organizations.push(organizationObj);
            }
            // * Assign table rows to be the formatted equities array
            this.rows = organizations;
            this.total = res.resultsCount;
            this.isLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
    },
    // * Triggered when user click the pen icon on a row
    editHandler(item) {
      this.editItem = {
        ...item
      };
    },
    // * Triggered when user updates item from the edit popup
    updatedHandler() {
      // * Null the editing item
      this.editItem = null;
      this.updateResults = !this.updateResults;
    },
    createdHandler() {
      this.addCommunityGroupPopup = false;
      this.updateResults = !this.updateResults;
    },
    getIsOpenBoolean(value){
      if (value === null){
        return true;
      } else {
        return value;
      }
    },
    addUserIdFilter(data) {
      const filterIndex = data.filter.findIndex(item => item.name === this.filterType);
      if (this.filterValue.trim() !== '') {
        if (filterIndex === -1) {
          data.filter.push({
            name: this.filterType,
            term: this.filterType === 'userId' ? Number(this.filterValue) : this.filterValue,
          });
        } else {
          data.filter[filterIndex].term = this.filterType === 'userId' ? Number(this.filterValue) : this.filterValue;
        }
      } else if (filterIndex !== -1) {
        data.filter.splice(filterIndex, 1);
      }
    },
  },

  watch: {
    userId: {
      handler() {
        this.getCommunityGroupsHandler({ filter: [] });
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

.filter-module {
  background-color: #f7f9fc; /* Light background color for distinction */
  padding: 15px 20px; /* Padding to give space inside the module */
  border-radius: 10px; /* Rounded corners for a smoother appearance */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  margin-bottom: 25px; /* Space between the filter module and the table */
}

.filter-module-title {
  font-size: 20px;
  color: #3c6e71;
  margin-bottom: 10px; /* A bit of spacing between the title and the filter */
  font-weight: 600; /* Making the text slightly bold for emphasis */
}

.filter-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.filter-dropdown label {
  font-size: 18px;
  margin-right: 15px;
  color: #3c6e71;
  font-weight: 500;
}

.filter-dropdown select {
  width: 190px;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  padding: 9px 15px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
  margin-right: 10px;
}

.filter-dropdown input[type="text"] {
  width: 340px;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  padding: 9px 15px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
  margin-right: 10px;
}

.filter-dropdown select:focus, .filter-dropdown input[type="text"]:focus {
  border-color: #3c6e71;
}

.filter-dropdown select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 1rem center/8px 10px, #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.filter-dropdown .filter-btn {
  background: #3c6e71;
  color: #FFF;
  border: none;
  font-weight: 600;
  height: 45px;
  width: 100px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.filter-dropdown .filter-btn:hover {
  background: #295456;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.filter-dropdown .filter-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.loading-text {
  margin-left: 10px;
  font-size: 16px;
  color: #3c6e71;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3c6e71;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


</style>
