<template>
    <popup title="Add Setting" @close="$emit('close')">
        <template v-slot:content>
            <!-- Context Selection -->
            <div class="field">
                <label for="context">Context:</label>
                <select id="context" v-model="selectedContext" @change="onContextChange">
                    <option value="home">Home</option>
                    <option value="masterclass">Masterclass</option>
                    <option value="banners">Banners</option>
                </select>
            </div>

            <!-- Type Selection -->
            <div class="field" v-if="selectedContext">
                <label for="type">Type:</label>
                <select id="type" v-model="selectedType">
                    <option
                        v-for="type in availableTypes"
                        :key="type.name"
                        :value="type.name"
                        :disabled="type.disabled"
                    >
                        {{ type.name }} <span v-if="type.disabled">(Already Exists)</span>
                    </option>
                </select>
            </div>

            <!-- Form Fields Based on Selected Type -->
            <div v-if="selectedType">
                
                <!-- Home context fields -->
                <div v-if="selectedType === 'home_slider'">
                    <!-- Article Dropdown -->
                    <div class="field">
                        <label for="article">Article:</label>
                        <select id="article" v-model="selectedArticleId">
                            <option v-for="article in articles" :key="article.id" :value="article.id">
                                {{ article.title }} ({{ article.organization }})
                            </option>
                        </select>
                    </div>
                    
                    <!-- Expires On Datepicker -->
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Featured Asset Manager -->
                <div v-else-if="selectedType === 'featured_asset_manager'">
                    <div class="field">
                        <label for="partner">Partner:</label>
                        <select id="partner" v-model="selectedPartnerId">
                            <option v-for="partner in partners" :key="partner.id" :value="partner.id">
                                {{ partner.name }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Masterclass Fields -->
                <div v-else-if="['masterclass_beginner', 'masterclass_advanced'].includes(selectedType)">
                    <div class="field">
                        <label for="masterclassTitle">Title:</label>
                        <input type="text" id="masterclassTitle" v-model="masterclassTitle" />
                    </div>
                    <div class="field">
                        <label for="masterclassDate">Date:</label>
                        <input type="date" id="masterclassDate" v-model="masterclassDate" />
                    </div>
                    <div class="field">
                        <label for="tagId">Tag:</label>
                        <select id="tagId" v-model="selectedTagId">
                            <option v-for="tag in tags" :key="tag.id" :value="tag.id">
                                {{ tag.name }}
                            </option>
                        </select>
                    </div>
                  
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Organization Banner -->
                <div v-else-if="selectedType === 'organization_banner'">
                    <!-- English Banner Fields -->
                    <div class="language-section">
                        <h3>English (EN)</h3>
                        <div class="field">
                            <label>EN - Text:</label>
                            <textarea v-model="enBannerText" placeholder="Banner text message"></textarea>
                        </div>

                        <div class="field">
                            <label>EN - Link:</label>
                            <textarea v-model="enBannerLink" placeholder="Link URL"></textarea>
                        </div>

                        <div class="field">
                            <label>EN - Link Text:</label>
                            <textarea v-model="enBannerLinkText" placeholder="Link display text"></textarea>
                        </div>
                    </div>

                    <!-- German Banner Fields -->
                    <div class="language-section">
                        <h3>German (DE)</h3>
                        <div class="field">
                            <label>DE - Text:</label>
                            <textarea v-model="deBannerText" placeholder="Banner text message DE"></textarea>
                        </div>

                        <div class="field">
                            <label>DE - Link:</label>
                            <textarea v-model="deBannerLink" placeholder="Link URL DE"></textarea>
                        </div>

                        <div class="field">
                            <label>DE - Link Text:</label>
                            <textarea v-model="deBannerLinkText" placeholder="Link display text DE"></textarea>
                        </div>
                    </div>
                    
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>

                <!-- Most Visited Equity Fields -->
                <div v-else-if="selectedType === 'most_visited_equity'">
                    <div class="field">
                        <label for="search">Search Equity (Name or ISIN):</label>
                        <input type="text" id="search" v-model="searchTerm" @keyup.enter="searchEquity" placeholder="Enter at least 3 characters" />
                        <div v-if="searchTerm.length > 0 && searchTerm.length < 3" class="warning-message">Please enter at least 3 characters.</div>
                    </div>
                    <div v-if="securities.length > 0" class="field">
                        <label for="security">Select Security:</label>
                        <select id="security" v-model="selectedSecurityId" @change="updateSelectedSecurity">
                            <option v-for="security in securities" :key="security.id" :value="security.id">
                                {{ security.name }} ({{ security.isin }})
                            </option>
                        </select>
                    </div>
                    <div v-if="loadingSecurities">Loading securities...</div>
                    
                    <div class="field">
                        <label for="expiresOn">Expires On:</label>
                        <input type="date" id="expiresOn" v-model="expiresOn" />
                    </div>
                </div>
            </div>

            <!-- Create Button -->
            <button class="transition save-btn" @click="createSettingHandler()" :disabled="createLoading">
                <template v-if="!createLoading">Create</template>
                <font-awesome-icon v-else icon="spinner" spin />
            </button>
        </template>
    </popup>
</template>

<script>
import Popup from "@/components/shared/Popup.vue";
import axios from "axios";

export default {
    name: "NewSetting",
    components: { Popup },
    props: {
        existingUniqueTypes: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedContext: null,
            selectedType: null,
            availableTypes: [],
            articles: [],
            partners: [],
            securities: [],
            tags: [],
            selectedArticleId: null,
            selectedPartnerId: null,
            selectedSecurityId: null,
            selectedTagId: null,
            searchTerm: '',
            loadingSecurities: false,
            expiresOn: null,
            createLoading: false,
            masterclassTitle: "",
            masterclassDate: null,
            enBannerText: "",
            enBannerLink: "",
            enBannerLinkText: "",
            deBannerText: "",
            deBannerLink: "",
            deBannerLinkText: "",
        };
    },
    methods: {
        onContextChange() {
            this.selectedType = null;

            if (this.selectedContext === "home") {
                this.availableTypes = [
                    { name: "featured_articles", disabled: this.existingUniqueTypes["featured_articles"] || false },
                    { name: "featured_asset_manager", disabled: this.existingUniqueTypes["featured_asset_manager"] || false },
                    { name: "home_slider", disabled: this.existingUniqueTypes["home_slider"] || false },
                    { name: "latest_movement", disabled: this.existingUniqueTypes["latest_movement"] || false },
                    { name: "most_visited_equity", disabled: this.existingUniqueTypes["most_visited_equity"] || false },
                    { name: "star_of_the_week", disabled: this.existingUniqueTypes["star_of_the_week"] || false },
                    { name: "upcoming_events", disabled: this.existingUniqueTypes["upcoming_events"] || false },
                ];
                this.fetchArticles();

            } else if (this.selectedContext === "masterclass") {
                this.availableTypes = [
                    { name: "masterclass_advanced", disabled: this.existingUniqueTypes["masterclass_advanced"] || false },
                    { name: "masterclass_beginner", disabled: this.existingUniqueTypes["masterclass_beginner"] || false },
                ];
                this.fetchTags();

            } else if (this.selectedContext === "banners") {
                this.availableTypes = [
                    { name: "organization_banner", disabled: this.existingUniqueTypes["organization_banner"] || false }
                ];
            }
        },
        fetchArticles() {
            axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/articles`).then(res => {
                this.articles = res.data.map(article => ({
                    id: article.article_id,
                    title: article.article_title,
                    organization: article.organization_name
                }));
            });
        },
        fetchPartners() {
            axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/partners`).then(res => {
                this.partners = res.data;
            });
        },
        fetchTags() {
            axios.get(`${this.$store.state.global.apiUrl}/backoffice/settings/tags`).then(res => {
                this.tags = res.data.tags.map(tag => ({
                    id: tag.tagId,
                    name: tag.name
                }));
            });
        },
        searchSecurities() {
            this.loadingSecurities = true;
            axios.post(`${this.$store.state.global.apiUrl}/backoffice/settings/search-securities`, {
                searchTerm: this.searchTerm 
            })
            .then(res => {
                this.securities = res.data;
                this.loadingSecurities = false;
            })
            .catch(err => {
                console.error("Error searching securities:", err);
                this.loadingSecurities = false;
            });
        },
        createSettingHandler() {
            this.createLoading = true;
            const dataToSend = {
                context: this.selectedContext,
                type: this.selectedType,
                isActive: true,
                expiresOn: this.expiresOn,
                articleId: this.selectedArticleId,
                partnerId: this.selectedPartnerId,
                securityId: this.selectedSecurityId,
                ...(this.selectedType === "organization_banner" && {
                    bannerOptions: {
                        en: { text: this.enBannerText, link: this.enBannerLink, linkText: this.enBannerLinkText },
                        de: { text: this.deBannerText, link: this.deBannerLink, linkText: this.deBannerLinkText }
                    }
                }),
                ...(this.selectedType.includes("masterclass") && {
                    masterclassOptions: {
                        title: this.masterclassTitle,
                        date: this.masterclassDate,
                        tagId: this.selectedTagId
                    }
                })
            };

            axios
                .post(`${this.$store.state.global.apiUrl}/backoffice/settings/new`, dataToSend)
                .then(() => {
                    this.createLoading = false;
                    this.$emit("created");
                })
                .catch(err => {
                    this.createLoading = false;
                    console.error("Error creating setting:", err);
                });
        }
    },
    watch: {
        selectedType(newType) {
            if (newType === "featured_asset_manager") {
                this.fetchPartners();
            }
            if (newType === "most_visited_equity") {
                this.securities = []; // Reset securities when type changes
                this.searchTerm = ''; // Clear search term to avoid accidental triggers
            }
        },
        searchTerm(newTerm) {
            if (newTerm.length >= 3) {
                this.searchSecurities();
            }
        }
    },
};
</script>

<style scoped>
.field {
    margin-bottom: 20px;
}

.field label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
}

.field input,
.field select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.save-btn {
    background: #3c6e71;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-btn:hover {
    background: #295456;
}

.save-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

option[disabled] {
    color: #888;
    background-color: #f2f2f2;
}

.field select {
    max-width: 100%; /* Ensure that dropdowns don't overflow the container */
    width: 100%; /* Full width within its container */
    box-sizing: border-box;
    }

    #article, #partner, #security, #tagId {
        max-width: 300px; /* Limit the width of the Article dropdown */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; /* Ensures text is truncated if too long */
    }

    #expiresOn, #masterclassDate {
        max-width: 150px; /* Limit the width of the Expires On date input */
        width: 100%; /* Full width within its container */
        box-sizing: border-box;
        text-align: center; /* Center align the date text */
    }

    #masterclassTitle {
        max-width: 500px; /* Adjust max width as needed */
        width: 100%; /* Full width within its container */
        box-sizing: border-box;
    }

    .language-section {
        border: 1px solid #ddd;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: #f9f9f9; /* Light background for visual separation */
    }

    .language-section h3 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #3c6e71;
    }

    .field textarea {
        width: 100%; /* Full width within its container */
        max-width: 100%; /* Ensure it doesn’t overflow */
        min-height: 60px; /* Minimum height for easier text editing */
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        resize: vertical; /* Allow resizing only vertically */
        font-size: 14px;
    }
</style>