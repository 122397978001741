<template>
  <!-- Fund Benchmarks Page -->
  <div class="fund-benchmarks-page">
    <h1>Fund Benchmarks <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit', 'new']"
      @update-table="getFundBenchmarksHandler"
      @edit="editHandler"
      @add-new="addBenchmarkPopup = true"
    />

    <!-- New Benchmark Popup -->
    <NewFundBenchmark
      v-if="addBenchmarkPopup"
      @close="addBenchmarkPopup = false"
      @created="createdHandler"
    />
    <!-- Edit Popup -->
    <EditFundBenchmark
      v-if="editItem"
      :benchmark="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import NewFundBenchmark from './popups/NewFundBenchmark'
  import EditFundBenchmark from './popups/EditFundBenchmark'

  export default {
    name: 'FundBenchmarks',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      NewFundBenchmark,
      EditFundBenchmark
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Benchmark',
            name: 'benchmark',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'createdOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        addBenchmarkPopup: false,
        total: null
      }
    },
    methods: {
      // * Get fund advisors from API
      getFundBenchmarksHandler(data) {
        this.getFundBenchmarksPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.rows = res.fundBenchmarks;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when benchmark is created
      createdHandler() {
        this.addBenchmarkPopup = false;
        this.updateResults = !this.updateResults;
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>

</style>