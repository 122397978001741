<template>
  <div class="umu-social-media-sentiments">
    <h1>
      Umu Social Media Sentiments<small v-if="total">({{ total }})</small>
    </h1>

    <!--    Calendar Filter-->
    <div class="field">
      <label>From :</label>
      <div class="date-filter">
        <Datepicker v-model="fromDate" format="dd.MM.yyyy" :enable-time-picker="false" />
      </div>
    </div>
    <div class="field">
      <label>To :</label>
      <div class="date-filter">
        <Datepicker v-model="toDate" format="dd.MM.yyyy" :enable-time-picker="false" />
      </div>
    </div>

    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :column-search="false"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="[]"
      @update-table="getUmuSocialMediaSentimentsHandler"
      @edit="editHandler"
    />
  </div>
</template>

<script>
import userMixin from "@/mixins/user.vue";
import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";
import systemMixin from "@/mixins/system.vue";
export default {
  name: "UmuSocialMediaSentiments",
  mixins: [userMixin, systemMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: "ID",
          name: "id",
          sortable: true,
        },
        {
          title: "Security ID",
          name: "securityId",
          sortable: true,
        },
        {
          title: "Name",
          name: "name",
          sortable: true,
        },
        {
          title: "Type",
          name: "type",
          sortable: true,
        },
        {
          title: "Value",
          name: "value",
          sortable: true,
        },
        {
          title: "Added On",
          name: "added_on",
          sortable: true,
        },
      ],
      editItem: null,
      pages: 0,
      updateResults: true,
      total: null,
      fullData: null,
      fromDate: null,
      toDate: null,
      filtersToSend: [],
    };
  },

  created() {
    this.fromDate = this.today();
    this.toDate = this.today();
  },
  watch: {
    fromDate() {
      this.updateResults = !this.updateResults;
    },
    toDate() {
      this.updateResults = !this.updateResults;
    },
    countryOfDomicile() {
      this.updateResults = !this.updateResults;
    },
    operation() {
      this.updateResults = !this.updateResults;
    },
  },

  methods: {
    getUmuSocialMediaSentimentsHandler(data) {

      this.setFilters(data);

      this.getUmuSocialMediaSentimentsPaginated(data)
        .then((res) => {
          this.pages = res.pagesCount;
          let resultsFormatted = [];

          for (const results of res.results) {
            let resultsObj = {
              id: results.id,
              securityId: results.security_id,
              name: results.name,
              type: results.type,
              value: results.value,
              added_on: results.added_on,
            };
            resultsFormatted.push(resultsObj);
          }
          this.rows = resultsFormatted;
          this.fullData = res.results;
          this.total = res.resultsCount;
        })
        .catch((err) => {
          if (err === 401) {
            this.logout();
          }
        });
    },

    setFilters(data) {
      
      let fromDateFilter = {
        'name': 'fromDate',
        'term': this.fromDate
      };

      let toDateFilter = {
        'name': 'toDate',
        'term': this.toDate
      };

      this.filtersToSend.push(fromDateFilter);
      this.filtersToSend.push(toDateFilter);

      data['filter'] = this.filtersToSend;
    },

    editHandler(item) {
      this.editItem = this.fullData.find((results) => {
        return results.results_id === item.resultsId;
      });
    },

    updatedHandler() {
      this.editItem = null;
      this.updateResults = !this.updateResults;
    },

    typeToString(type) {
      switch (type) {
        case 1:
          return "Equity";
        case 4:
          return "Crypto";
        default:
          return "Unknown";
      }
    },

    today() {
      return new Date().toISOString().split("T")[0];
    },
  },

  computed: {},
};
</script>

<style scoped>
.date-filter {
  display: flex;
  align-items: center;
}

.field {
  margin: 15px 0 30px;
  display: flex;
  align-items: center;
}

.field label:not(.custom-check) {
  color: #44444f;
  font-size: 16px;
  font-weight: 500;
  width: 100px;
}

.field input,
.field :deep(input) {
  width: 200px;
  height: auto;
  box-shadow: none;
  line-height: initial;
  background: #fafafb;
  border: 1px solid #44444f;
  border-radius: 10px;
  padding: 12px 15px;
  margin: 0 10px;
  outline: none;
  font-size: 16px;
  color: #171725;
}

.field input:focus,
.field :deep(input:focus) {
  border-color: #3c6e71;
}

.field button {
  background: #3c6e71;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  width: 100px;
  height: 51px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
}

.field button:hover {
  background: #fff;
  color: #3c6e71;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
</style>
