<template>
  <!-- Glossary Categories Page -->
  <div class="glossary-cats-page">
    <h1>Glossary Categories <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="0"
      :actions="['edit', 'new']"
      :updateResults="updateResults"
      :langs="true"
      @update-table="getGlossaryCategoriesHandler"
      @add-new="newFaqPopup = true"
      @edit="editFaqCategoryHandler"
    />

    <NewGlossaryCategory
      v-if="newFaqPopup"
      @close="newFaqPopup = false"
      @added="addedHandler"
    />

    <EditGlossaryCategory
      v-if="editItem"
      :item="editItem"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import NewGlossaryCategory from './popups/NewGlossaryCategory'
  import EditGlossaryCategory from './popups/EditGlossaryCategory'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'GlossaryCategories',
    mixins: [systemMixin, userMixin],
    components: {
      NewGlossaryCategory,
      EditGlossaryCategory,
      DataTableHolder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: false
          },
          {
            title: 'Category',
            name: 'category',
            sortable: false
          }
        ],
        newFaqPopup: false,
        editItem: null,
        updateResults: false,
        total: null
      }
    },
    methods: {
      // * Get faqs from API
      getGlossaryCategoriesHandler(data) {
        this.getGlossaryCategories(data)
          .then(res => {
            this.rows = res;
            this.total = res.length;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Add faq category for edit
      editFaqCategoryHandler(item) {
        this.editItem = item;
      },
      addedHandler() {
        this.newFaqPopup = false;
        this.updateResults = !this.updateResults;
      },
      updatedHandler() {
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    }
  }
</script>

<style scoped>


</style>