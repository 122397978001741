<template>
  <!-- Add new sector popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit sector"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Sector:</label>
        <input type="text" id="name" v-model="item.sector" placeholder="Sector name...">
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.other">
          <span class="checkmark"></span>
          Other
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.equity">
          <span class="checkmark"></span>
          Equity
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.bond">
          <span class="checkmark"></span>
          Bond
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.multiAsset">
          <span class="checkmark"></span>
          Multi-Asset
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.moneyMarket">
          <span class="checkmark"></span>
          Money-Market
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.commodity">
          <span class="checkmark"></span>
          Commodity
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.crypto">
          <span class="checkmark"></span>
          Crypto
        </label>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="item.property">
          <span class="checkmark"></span>
          Property
        </label>
      </div>

      <div class="field autocomplete characteristics-dropdown">
        <label>UMU Sector:</label>
        <div class="select-container">
          <select v-model="selectedUmuSector">
            <option disabled value="">Please select one</option>
            <option :value="{id: -1, name: '-'}">-</option>
            <option v-for="umuSector in umuSectors" :key="umuSector.id" :value="umuSector">{{ umuSector.name }}</option>
          </select>
        </div>
      </div>

      <button class="transition save-btn" @click="editSectorHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundUmbrellaSector',
    mixins: [fundsMixin, userMixin],
    components: {
      Popup
    },
    props: {
      sector: Object
    },
    data() {
      return {
        item: {...this.sector},
        updateLoading: false,
        umuSectors: [],
        selectedUmuSector: {
          id: null,
          name: null,
        },
      }
    },
    methods: {
      // * Triggers on save button click and creates sector
      editSectorHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          sector: this.item.sector,
          other: this.item.other,
          equity: this.item.equity,
          bond: this.item.bond,
          multiAsset: this.item.multiAsset,
          moneyMarket: this.item.moneyMarket,
          commodity: this.item.commodity,
          crypto: this.item.crypto,
          property: this.item.property,
          umuSectorId: this.selectedUmuSector.id,
        }

        this.editUmbrellaSector(this.item.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },

      fetchUmuSectors(){
        this.getUmuSectors()
            .then(res => {
              this.umuSectors = res.umuSectors.map(item => ({
                id: item.id,
                name: item.translationData?.en?.name || null
              }));
            })
            .catch(error => {
              console.error("Error fetching umuSectors:", error);
            })
      },

    },

    created() {
      this.fetchUmuSectors();
      this.selectedUmuSector.id = this.item.umuSectorId;
      this.selectedUmuSector.name = this.item.umuSector;
    },
  }
</script>

<style scoped>
.umbrella-description,
.selected-benchmarks li {
  font-size: 14px;
}

.characteristics-dropdown {
  display: flex;
  align-items: center;
}

.characteristics-dropdown label {
  font-size: 18px;
  margin-right: 10px;
}

.characteristics-dropdown select {
  width: 330px;
  border: none;
  border-radius: 20px;
  padding: 9px 15px;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 0.75rem center/8px 10px;
  background-color: white;
}

.characteristics-dropdown .filter-btn {
  background: #3c6e71;
  color: #FFF;
  border: none;
  font-weight: 600;
  height: 45px;
  width: 100px;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.characteristics-dropdown .filter-btn:hover {
  background: #FFF;
  color: #3c6e71;
}

.characteristics-dropdown {
  display: flex;
  align-items: center;
}

.characteristics-dropdown label {
  width: 100px; /* Adjust the width of the labels as needed */
}

.characteristics-dropdown .select-container {
  margin-left: 10px; /* Adjust the left margin as needed */
}
  
</style>
