<template>
  <!-- Fund Market Data Page -->
  <div class="fund-securities-page">
    <h1 v-if="$route.name.includes('Etf')">ETF Suitabilities<small v-if="total">({{total}})</small></h1>
    <h1 v-else>Fund Suitabilities<small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="[]"
      page="Fund suitabilities"
      @update-table="getFundsHandler"
      @edit="editHandler"
    />
  </div>
</template>

<script>
  import securitiesMixin from '@/mixins/securities'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

  export default {
    name: 'FundSuitabilities',
    mixins: [securitiesMixin, userMixin],
    components: {
      DataTableHolder
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'ISIN',
            name: 'isin',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'CH-',
            name: 'rg6',
            sortable: false
          },
          {
            title: 'CH+',
            name: 'rg7',
            sortable: false
          },
          {
            title: 'AT-',
            name: 'rg8',
            sortable: false
          },
          {
            title: 'AT+',
            name: 'rg11',
            sortable: false
          },
          {
            title: 'DE-',
            name: 'rg9',
            sortable: false
          },
          {
            title: 'DE+',
            name: 'rg12',
            sortable: false
          },
          {
            title: 'UK-',
            name: 'rg10',
            sortable: false
          },
          {
            title: 'UK+',
            name: 'rg13',
            sortable: false
          },
          {
            title: 'NL-',
            name: 'rg17',
            sortable: false
          },
          {
            title: 'NL+',
            name: 'rg14',
            sortable: false
          },
          {
            title: 'ES-',
            name: 'rg16',
            sortable: false
          },
          {
            title: 'ES+',
            name: 'rg15',
            sortable: false
          }
        ],
        pages: 0,
        updateResults: true,
        total: null,
        fullData: null
      }
    },
    methods: {
      // * Get funds from API
      getFundsHandler(data) {
        data.overwriteSecurityType = [2,3];
        this.getSecurities(data, 2)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            // * Choose which fields to be visible in the datatable
            let securitiesFormatted = [];

            // * Returns the index of a suitability given a Rule Group Number in suitabilities
            let indexOfSuitability = (security, rgNr) => {
              let suitabilities = security.suitability_data['suitabilities'];
              for (let i = 0; i < suitabilities.length; i++) {
                if (suitabilities[i]['rule-group'].includes(rgNr)) {
                  return i;
                }
              }
            };

            // // * Returns row content for a given a Rule Group Number (a flag from 0-3)
            let getRuleResult = (security, rgNr) => {
              let index = indexOfSuitability(security, rgNr);
              let suitability = security.suitability_data['suitabilities'][index];
              return suitability['rule-result'];
            };

            // * Loop through securities and add only the needed properties
            for (const security of res.securities) {
              let securityObj = {
                id: security.id,
                isin: security.isin,
                name: security.name,
                rg6: getRuleResult(security, 'RG6'),
                rg7: getRuleResult(security, 'RG7'),
                rg8: getRuleResult(security, 'RG8'),
                rg11: getRuleResult(security, 'RG11'),
                rg9: getRuleResult(security, 'RG9'),
                rg12: getRuleResult(security, 'RG12'),
                rg10: getRuleResult(security, 'RG10'),
                rg13: getRuleResult(security, 'RG13'),
                rg17: getRuleResult(security, 'RG17'),
                rg14: getRuleResult(security, 'RG14'),
                rg16: getRuleResult(security, 'RG16'),
                rg15: getRuleResult(security, 'RG15')
              };

              // * Add formatted security to formatted securities array
              securitiesFormatted.push(securityObj);
            }
            // * Assign table rows to be the formatted securities array
            this.rows = securitiesFormatted;
            this.fullData = res.securities;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = this.fullData.find(security => {
          return security.id == item.id;
        });
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    // mounted() {
    // }
    watch: {
      '$route'() {
        this.rows = null;
        this.pages = 0;
        this.total = 0;
        this.fullData = null;
        this.getFundsHandler();
      }
    }
  }
</script>

<style scoped>


</style>
