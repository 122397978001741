<template>
  <div class="transactions-by-security">
    <h1>Transactions By Security <small v-if="total">({{total}})</small></h1>

    <div class="filter-field">
      <label for="filter">Filter:</label>
      <input type="text" id="filter" v-model="securityIdInput" placeholder="Enter Security ID">
      <button class="filter-btn transition" @click="getTransactionsHandler({ filter: [] })">Filter</button>
    </div>

    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="[]"
        :columnSearch="true"
        @update-table="getTransactionsHandler"
    />
  </div>
</template>

<script>
import portfoliosMixin from '@/mixins/portfolios.vue';
import userMixin from '@/mixins/user'
import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

export default {
  name: 'TransactionsBySecurity',
  mixins: [portfoliosMixin, userMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable: true
        },
        {
          title: 'Porfolio ID',
          name: 'portfolioId',
          sortable: true
        },
        {
          title: 'Description',
          name: 'description',
          sortable: true
        },
        {
          title: 'Units',
          name: 'units',
          sortable: true
        },
        {
          title: 'Unit Price',
          name: 'unitPrice',
          sortable: true
        },
        {
          title: 'Transaction Date',
          name: 'transactionDate',
          sortable: true
        },
      ],
      pages: 0,
      updateResults: true,
      total: null,
      securityId: null,
    }
  },
  computed: {
    securityIdInput: {
      get() {
        return this.securityId;
      },
      set(value) {
        this.securityId = value === '' ? null : Number(value);
      },
    },
  },
  methods: {
    async getTransactionsHandler(data) {
      this.addSecurityIdFilter(data);
      try {
        const res = await this.getTransactionsBySecurity(data);
        const transactionsFormatted = res.results.map(result => ({
          id: result.id,
          portfolioId: result.portfolio_id,
          description: result.description,
          units: result.units,
          unitPrice: result.unit_price,
          transactionDate: result.transaction_date,
        }));
        this.rows = transactionsFormatted;
        this.total = res.resultsCount;
        this.pages = res.pagesCount;
      } catch (err) {
        if (err === 401) {
          this.logout();
        }
      }
    },
    addSecurityIdFilter(data) {
      const securityIdFilterIndex = data.filter.findIndex(item => item.name === 'securityId');
      if (this.securityId !== null) {
        if (securityIdFilterIndex === -1) {
          data.filter.push({
            name: 'securityId',
            term: this.securityId,
          });
        } else {
          data.filter[securityIdFilterIndex].term = this.securityId;
        }
      } else if (securityIdFilterIndex !== -1) {
        data.filter.splice(securityIdFilterIndex, 1);
      }
    },
  },
  watch: {
    securityId: {
      handler() {
        this.getTransactionsHandler({ filter: [] });
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

.filter-field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-field label {
  font-size: 18px;
  margin-right: 30px;
}

.filter-field input {
  width: 400px;
  border: none;
  border-radius: 20px;
  padding: 9px 15px;
  font-size: 18px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  outline: none;
}

.filter-field input[type="filter"]::-webkit-search-decoration,
.filter-field input[type="filter"]::-webkit-search-cancel-button,
.filter-field input[type="filter"]::-webkit-search-results-button,
.filter-field input[type="filter"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.filter-field .filter-btn {
  background: #3c6e71;
  color: #FFF;
  border: none;
  font-weight: 600;
  height: 45px;
  width: 100px;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.filter-field .filter-btn:hover {
  background: #FFF;
  color: #3c6e71;
}
</style>
