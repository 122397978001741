<template>
  <!-- Inspect Fund Market Data Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Fund Market Data"
    @close="$emit('close')"
  >
    <template v-slot:content>

      <span class="info-text">
        Fund ID: {{item.id}}
      </span>

      <a :href="'https://umushroom.com/fund/' + item.urlName" target="_blank">Go to the fund on the main app</a>
      <!-- <div class="field">
        <label for="id">Fund id <small>(not editable)</small>:</label>
        <input type="number" id="id" v-model="item.id" placeholder="Fund Id..." disabled>
      </div> -->
      
      <ChartBox
        v-if="graphData"
        :graph-data="graphData"
        :item="item"
        type="fund"
        :currency="item.currencyCode"
      />

      <div class="fund-history" v-if="fundHistory && fundHistory[0]">
        <h4>Fund History (Last 2)</h4>
        <div v-for="(fund, index) in fundHistory.slice(0, 2)" :key="index">
          <span class="history-item">
            Price: {{fund.price}}
          </span>
          <span class="history-item">
            Daily Return: {{fund.modifiedDailyReturn.toFixed(2)}}%
          </span>
          <span class="history-item" v-if="fund.addedOn">
            Price Date: {{ $filters.formatDate(fund.entryDate.date) }}
          </span>
          <span class="history-item" v-if="fund.addedOn">
            Added On: {{ $filters.formatDateTime(fund.addedOn.date) }}
          </span>
          <span class="history-item">
            Modified On: 
            <template v-if="fund.updatedOn">
              {{ $filters.formatDateTime(fund.updatedOn.date) }}
            </template>
            <template v-else>n/a</template>
          </span>
          <hr v-if="index == 0">
        </div>
      </div>
      
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="fund.isActive">
          <span class="checkmark"></span>
          Is Active
        </label>
      </div>
      
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="fund.permanentDeactivation">
          <span class="checkmark"></span>
          Permanent Deactivation
        </label>
      </div>
      
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="fund.ccyOverride">
          <span class="checkmark"></span>
          CCY Override
        </label>
      </div>

      <div class="field">
        <label for="currency-id">Currency ID:</label>
        <input type="text" id="currency-id" v-model="fund.currencyId">
      </div>

      <div class="field" v-if="fund.isActive != item.isActive || fund.permanentDeactivation != item.permanentDeactivation">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder='Type "CONFIRM" to be able to change the fund status' v-model="confirm">
      </div>

      <button class="transition save-btn" @click="updateFundHandler()" :disabled="updateLoading || ((fund.isActive != item.isActive || fund.permanentDeactivation != item.permanentDeactivation) && confirm !== 'CONFIRM')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  import ChartBox from '@/components/shared/charts/ChartBox'
  
  export default {
    name: 'InspectFundMarketData',
    mixins: [fundsMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup,
      ChartBox
    },
    data() {
      return {
        selectedFund: null,
        updateLoading: false,
        graphData: null,
        fundHistory: null,
        fund: {...this.item},
        confirm: null
      }
    },
    methods: {
      // * Get fund chart data
      getFundChartDataHandler() {
        this.getFundChartData(this.item.id)
          .then(res => {
            this.graphData = res;
          })
      },
      getFundHistoryHandler() {
        this.getFundHistory(this.item.id)
          .then(res => {
            this.fundHistory = res.fundHistory;
          })
      },
      updateFundHandler() {
        let dataToSend = {
          isActive: this.fund.isActive,
          permanentDeactivation: this.fund.permanentDeactivation,
          ccyOverride: this.fund.ccyOverride,
          currencyId: this.fund.currencyId,
        }

        this.updateFund(this.fund.id, dataToSend)
          .then(() => {
            this.$emit('updated')
          })
          .catch(err => {
            if (err.code == 401) {
              this.logout();
            }
          })
      }
    },
    mounted() {
      this.getFundChartDataHandler();
      this.getFundHistoryHandler();
    }
  }
</script>

<style scoped>
  .info-text {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
  
  .history-item {
    display: block;
  }
</style>
