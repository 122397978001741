<template>
  <div>
    <apexchart v-if="showChart" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  export default {
    name: 'QuizTypesPieChart',
    props: {
      types: Array
    },
    data() {
      return {
        showChart: false,
        series: [],
        chartOptions: {
          chart: {
            type: 'pie',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: 30
          },
          labels: [],
          colors:['#ddd', '#3ed699', '#3c6e71', '#ae8a51', '#5a6671', '#89929a', '#ae8a51', '#3c6e71', '#3ed699'],
          legend: {
            position: 'bottom',
            fontFamily: 'Poppins',
            fontWeight: 500
          }
        },
        quizTypes: this.types
      }
    },
    mounted() {
      let typesSorted = this.quizTypes.sort((a, b) => {
        return a.type - b.type;
      });

      for (const typeObj of typesSorted) {
        if (typeObj.type) {
          switch(typeObj.type) {
            case 1:
              this.chartOptions.labels.push('Happy Rookie');
              break;
            case 2:
              this.chartOptions.labels.push('Happy Professor');
              break;
            case 3:
              this.chartOptions.labels.push('Scared of the World');
              break;
            case 4:
              this.chartOptions.labels.push('Do I really get it');
              break;
            case 5:
              this.chartOptions.labels.push('Future nerd');
              break;
            case 6:
              this.chartOptions.labels.push('Responsible nerd');
              break;
            case 7:
              this.chartOptions.labels.push('You only live once');
              break;
            case 8:
              this.chartOptions.labels.push('I know how to gamble');
              break;
          }
        } else {
          this.chartOptions.labels.push('None');
        }
        this.series.push(typeObj.num);
      }

      this.showChart = true;
    }
  }
</script>
