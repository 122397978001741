<template>
  <popup
    title="Edit module"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <div class="two-cols">
          <div class="field">
            <label for="module-name-en">Module Name EN:</label>
            <input type="text" id="module-name-en" v-model="module.title.en.title" placeholder="Module Name EN">
          </div>
          <div class="field">
            <label for="module-name-de">Module Name DE:</label>
            <input type="text" id="module-name-de" v-model="module.title.de.title" placeholder="Module Name DE">
          </div>
        </div>
        <div class="two-cols">
          <div class="field">
            <label for="module-description-en">Module Description EN:</label>
            <textarea id="module-description-en" v-model="module.description.en.description" placeholder="Module Description EN"></textarea>
          </div>
          <div class="field">
            <label for="module-description-de">Module Description DE:</label>
            <textarea id="module-description-de" v-model="module.description.de.description" placeholder="Module Description DE"></textarea>
          </div>
        </div>
        <div class="two-cols">
          <div class="field">
            <div class="tags-header">
              <label for="module-tags-en">Tags EN:</label>
              <button @click="addTag('en')" class="add-button">+</button>
            </div>
            <div v-if="tags && tags.en" class="tag-container">
              <div v-for="(tag, index) in tags.en" :key="index" class="tag-item">
                <input type="text" v-model="tags.en[index]" class="tag-input">
                <button @click="removeTag('en', index)" class="remove-button">-</button>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="tags-header">
              <label class="tags-label" for="module-tags-de">Tags DE:</label>
              <button @click="addTag('de')" class="add-button">+</button>
            </div>
            <div v-if="tags && tags.de" class="tag-container">
              <div v-for="(tag, index) in tags.de" :key="index" class="tag-item">
                <input type="text" v-model="tags.de[index]" class="tag-input">
                <button @click="removeTag('de', index)" class="remove-button">-</button>
              </div>
            </div>
          </div>
        </div>
        <button class="save-btn transition" @click="editModuleHandler()">
          Update
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditModule',
    mixins: [knowledgeMixin],
    components: {
      Popup
    },
    props: {
      item: Object,
      courseId: Number
    },
    data() {
      return {
        module: this.item,
        tags: this.item.tags || {}
      }
    },
    methods: {
      editModuleHandler() {
        // Remove empty tag inputs
        this.removeEmptyTags();

        const dataToSend = {
          titleData: this.module.title,
          descriptionData: this.module.description,
          tags: this.tags,
          status: 1
        }
        this.editModule(this.courseId, this.module.id, dataToSend)
          .then(() => {
            this.$emit('updated')
          })
      },
      removeTag(lang, index) {
        this.tags[lang].splice(index, 1);
      },
      addTag(lang) {
        if(!this.tags[lang]) {
          this.tags[lang] = [];
        }

        this.tags[lang].push('');
      },
      removeEmptyTags() {
        for (const lang in this.tags) {
          if(this.tags[lang]) {
            this.tags[lang] = this.tags[lang].filter(tag => tag.trim() !== '');
          }
        }
      }
    }
  }
</script>

<style scoped>
  .tag-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .tags-header {
    margin-bottom: 10px;
  }

  .tag-item {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .tag-input {
    margin-right: 4px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .remove-button {
    background-color: red;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    padding: 6px 14px;
    margin-left: 15px
  }

  .add-button {
    background-color: #3c6e71;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    padding: 6px 14px;
    margin-left: 15px
  }
</style>