<template>
  <div class="daily-genders">
    <apexchart v-if="showChart" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  export default {
    name: 'DailyGenders',
    props: {
      data: Object
    },
    data() {
      return {
        showChart: false,
        series: [],
        chartOptions: {
          chart: {
            type: 'donut',
            fontFamily: 'Poppins',
            fontWeight: 500
          },
          title: {
            text: 'Genders Demographics',
            align: 'center',
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: '#171725'
            },
          },
          labels: ['Male', 'Female', 'Diverse', 'Not Stated'],
          colors:['#C1A16F', '#3C6E71', '#61F1F9', '#003E52'],
          legend: {
            position: 'bottom',
            fontFamily: 'Poppins',
            fontWeight: 500
          }
        }
      }
    },
    mounted() {
      let mainData;
      if (this.data.totals) {
        mainData = this.data.totals.totalVisitorsDemographics.gender;
      } else {
        if (this.data.series == 0) {
          mainData = this.data.data.visitorsGuestDemographics.gender;
        } else if (this.data.series == 1) {
          mainData = this.data.data.visitorsPublicFollowerDemographics.gender;
        } else if (this.data.series == 2) {
          mainData = this.data.data.visitorsExclusiveFollowerDemographics.gender;
        }
      }

      this.series.push(mainData.male || 0);
      this.series.push(mainData.female || 0);
      this.series.push(mainData.diverse || 0);
      this.series.push(mainData['not-stated'] || 0);

      this.showChart = true;
    }
  }
</script>

<style scoped>

</style>