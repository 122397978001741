export default {
  namespaced: true,
  state: {
    openedCourses: [],
    openedModules: [],
    openedSections: [],
  },
  mutations: {
    updateOpenedCourses(state, { courseId, isOpen }) {
      if (isOpen) {
        state.openedCourses.push(courseId);
      } else {
        state.openedCourses = state.openedCourses.filter(id => id !== courseId);
      }
    },
    updateOpenedModules(state, { moduleId, isOpen }) {
      if (isOpen) {
        state.openedModules.push(moduleId);
      } else {
        state.openedModules = state.openedModules.filter(id => id !== moduleId);
      }
    },
    updateOpenedSections(state, { sectionId, isOpen }) {
      if (isOpen) {
        state.openedSections.push(sectionId);
      } else {
        state.openedSections = state.openedSections.filter(id => id !== sectionId);
      }
    },
  },
  getters: {
    getOpenedCourses: state => state.openedCourses,
    getOpenedModules: state => state.openedModules,
    getOpenedSections: state => state.openedSections,
  },
};
