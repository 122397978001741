<template>
  <popup
    title="Add new course"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <div class="field">
          <label for="course-name-en">Course Name EN:</label>
          <input type="text" id="course-name-en" v-model="course.en.title" placeholder="Course Name EN">
        </div>
        <div class="field">
          <label for="course-name-de">Course Name DE:</label>
          <input type="text" id="course-name-de" v-model="course.de.title" placeholder="Course Name DE">
        </div>

        <button class="save-btn transition" @click="createCourseHandler()">
          Create
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewCourse',
    mixins: [knowledgeMixin],
    components: {
      Popup
    },
    data() {
      return {
        course: {
          en: {
            title: null
          },
          de: {
            title: null
          }
        }
      }
    },
    methods: {
      createCourseHandler() {
        const dataToSend = {
          titleData: this.course,
          status: 1
        }
        this.createCourse(dataToSend)
          .then(() => {
            this.$emit('created')
          })
      }
    }
  }
</script>

<style scoped>

</style>