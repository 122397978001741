<template>
  <!-- Fund Characteristics Page -->
  <div class="users-utm">
    <h1>Users UTM <small v-if="total">({{ total }})</small></h1>

    <div class="filter-module">
      <div class="filter-dropdown">

        <label for="registeredAfter">Registered From:</label>
        <input type="date" v-model="registeredOnAfter">

        <label for="registeredBefore">Registered To:</label>
        <input type="date" v-model="registeredOnBefore">

        <button class="filter-btn" @click="fetchUsersUTM({filter: []})">Apply Filters</button>

        
        <span v-if="isLoading" class="loading-text">Loading...</span>
        <div v-if="isLoading" class="spinner"></div>
      </div>
    </div>
    <button class="export-btn" @click="exportToExcel()">Export to Excel</button>


    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="[]"
        @update-table="fetchUsersUTM"
        :column-search="true"
    />

  </div>
</template>

<script>
import usersMixin from "@/mixins/users.vue";
import userMixin from '@/mixins/user'
import * as XLSX from 'xlsx';
import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

export default {
  name: 'UsersUTM',
  mixins: [usersMixin, userMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          name: 'id',
          sortable: true
        },
        {
          title: 'Email',
          name: 'email',
          sortable: true
        },
        {
          title: 'Verified',
          name: 'emailVerified',
          sortable: true
        },
        {
          title: 'Source',
          name: 'source',
          sortable: true
        },
        {
          title: 'Medium',
          name: 'medium',
          sortable: true
        },
        {
          title: 'Campaign',
          name: 'campaign',
          sortable: true
        },
        {
          title: 'Registered On',
          name: 'registeredOn',
          sortable: true
        },
        
      ],
      rows: null,
      rowsToExport: null,
      editItem: null,
      updateResults: true,
      pages: 0,
      total: null,
      registeredOnBefore: null,
      registeredOnAfter: null,
      isLoading: false,
      exportData: [],

    }
  },

  methods: {
    fetchUsersUTM(data) {
      this.isLoading = true;
      this.addDateFilters(data);
      this.exportData = data;
      this.getUsersUTM(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            let rowsToAssign = [];
            res.users.forEach(user => {
              let obj = {
                id: user.id,
                email: user.email,
                emailVerified: user.email_verified,
                source: user.source,
                medium: user.medium,
                campaign: user.campaign,
                registeredOn: user.registered_on,
              };

              rowsToAssign.push(obj);
            })
            this.rows = rowsToAssign;
            this.total = res.resultsCount;
            this.isLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      ;
    },

    addDateFilters(data) {
      if (this.registeredOnBefore) {
        data.filter.push({
          name: 'registeredOnBefore',
          term: this.registeredOnBefore,
        });
      }
      if (this.registeredOnAfter) {
        data.filter.push({
          name: 'registeredOnAfter',
          term: this.registeredOnAfter,
        });
      }
    },

    exportToExcel() {
      
      this.getUsersUTMExport(this.exportData)
          .then(res => {
            let rowsToAssign = [];
            res.users.forEach(user => {
              let obj = {
                id: user.id,
                email: user.email,
                emailVerified: user.email_verified,
                source: user.source,
                medium: user.medium,
                campaign: user.campaign,
                registeredOn: user.registered_on,
              };
              rowsToAssign.push(obj);
            })
            this.rowsToExport = rowsToAssign;
            const ws = XLSX.utils.json_to_sheet(this.rowsToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Users");
      
            // Generate Excel file and trigger download
            XLSX.writeFile(wb, "UsersUTM.xlsx");
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      ;
    },

  },

  watch: {
    filterValue: {
      handler() {
        this.fetchUsersUTM({ filter: [] });
      },
      immediate: true
    }
  }

}
</script>

<style scoped>
.filter-module {
  background-color: #f7f9fc; /* Light background color for distinction */
  padding: 15px 20px; /* Padding to give space inside the module */
  border-radius: 10px; /* Rounded corners for a smoother appearance */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  margin-bottom: 25px; /* Space between the filter module and the table */
}

.filter-module-title {
  font-size: 20px;
  color: #3c6e71; /* Adjusted to match your design */
  margin-bottom: 10px; /* A bit of spacing between the title and the filters */
  font-weight: 600; /* Making the text slightly bold for emphasis */
}

.filter-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px; /* Provides consistent spacing between elements */
  flex-wrap: wrap; /* Ensures responsiveness */
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0; /* Separator */
}

.filter-dropdown label {
  font-size: 18px;
  color: #3c6e71; /* Color consistency */
  font-weight: 500;
}

.filter-dropdown input[type="text"], .filter-dropdown input[type="date"] {
  width: auto; /* Adjusted for responsive design */
  flex-grow: 1; /* Allows input to grow */
  max-width: 340px; /* Maximum width */
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  padding: 9px 15px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.filter-dropdown input[type="text"]:focus, .filter-dropdown input[type="date"]:focus {
  border-color: #3c6e71; /* Highlighting focus */
}

.filter-btn {
  background-color: #3c6e71;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
}

.filter-btn:hover {
  background-color: #295456; /* Darker shade for hover state */
  transform: translateY(-2px); /* Slight lift effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.filter-btn:active {
  transform: translateY(1px); /* Push effect on click */
  box-shadow: none; /* Remove shadow on click for depth effect */
}

.loading-text {
  margin-left: 10px;
  font-size: 16px;
  color: #3c6e71;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3c6e71;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.export-btn {
  background: #3c6e71;
  color: #FFF;
  border: none;
  font-weight: 600;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  display: block;
  margin: 0 0 25px auto;
}

.export-btn:hover {
  background: #FFF;
  color: #3c6e71;
}
</style>
