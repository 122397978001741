<template>
  <popup
    title="Edit course"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content">
        <div class="field">
          <label for="course-name-en">Course Name EN:</label>
          <input type="text" id="course-name-en" v-model="course.title.en.title" placeholder="Course Name EN">
        </div>
        <div class="field">
          <label for="course-name-de">Course Name DE:</label>
          <input type="text" id="course-name-de" v-model="course.title.de.title" placeholder="Course Name DE">
        </div>

        <button class="save-btn transition" @click="editCourseHandler()">
          Update
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditCourse',
    mixins: [knowledgeMixin],
    components: {
      Popup
    },
    props: {
      item: Object
    },
    data() {
      return {
        course: this.item
      }
    },
    methods: {
      editCourseHandler() {
        const dataToSend = {
          titleData: this.course.title,
          status: 1
        }
        this.editCourse(this.course.id, dataToSend)
          .then(() => {
            this.$emit('updated')
          })
      }
    }
  }
</script>

<style scoped>

</style>