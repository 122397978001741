<template>
  <!-- Fund Benchmark Mapping Page -->
  <div class="fund-benchmark-mapping-page">
    <h1>Fund Benchmark Mapping <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getFundBenchmarkMappingHandler"
      @edit="editHandler"
    />

    <!-- Edit Popup -->
    <EditFundBenchmarkMapping
      v-if="editItem"
      :item="editItem"
      :benchmarks="benchmarks"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundBenchmarkMapping from './popups/EditFundBenchmarkMapping'

  export default {
    name: 'FundBenchmarkMappings',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundBenchmarkMapping
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Raw Benchmark',
            name: 'rawBenchmark',
            sortable: true
          },
          {
            title: 'Benchmark ID',
            name: 'benchmarkId',
            sortable: true
          },
          {
            title: 'Benchmark',
            name: 'benchmark',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'createdOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        updateResults: true,
        addBenchmarkPopup: false,
        benchmarks: null,
        total: null
      }
    },
    methods: {
      // * Get fund advisors from API
      getFundBenchmarkMappingHandler(data) {
        this.getFundBenchmarkMappingPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.rows = res.fundBenchmarkMappings;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      },
      getBenchmarksHandler() {
        this.getFundBenchmarks()
          .then(res => {
            this.benchmarks = res.fundBenchmarks;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      }
    },
    mounted() {
      this.getBenchmarksHandler();
    }
  }
</script>

<style scoped>

</style>