<template>
  <popup
      :title="fundTitle"
      @close="$emit('close')"
  >

    <template v-slot:content>

      <span class="info-text">
        {{ fundTypeName }} ID: {{item.id}}
      </span>

      <span class="info-text">
        {{ fundTypeName }} Name: {{item.fund_name}}
      </span>

      <span class="info-text">
        Share Class ID: {{item.security_id}}
      </span>

      <span class="info-text">
        Share Class ISIN: {{item.security_isin}}
      </span>

      <span class="info-text">
        Share Class Name: {{item.security_name}}
      </span>



      <!-- Manually modify description -->
      <span>
        <a :href="getFundUrl()"
           target="_blank"
        >
          Go to {{ fundTypeName }} on main app
        </a>

      </span>

      <div class="field">
        <label for="ai-content"> General Objective: </label>
        <textarea type="text" id="ai-content" v-model="generalObjective"></textarea>
      </div>

      <div class="field">
        <label for="ai-content"> Fund Exposure: </label>
        <textarea type="text" id="ai-content" v-model="fundExposure"></textarea>
      </div>

      <div class="field">
        <label for="ai-content"> Risks: </label>
        <textarea type="text" id="ai-content-de" v-model="risks"></textarea>
      </div>

      <!-- source url -->
      <div class="field">
        <label for="ai-content-source"> Url Source: </label>
        <a :href="source" target="_blank">Open Link</a>
        <textarea type="text" id="ai-content-source" v-model="source"></textarea>
      </div>

      <!-- Paste context for chatGPT -->
      <div class="field">
        <label for="isin"><strong>Summarise with ChatGPT:</strong></label>
        <template v-if="!isUpdateLoading">
          <button class="set-field-btn"
                  @click="fetchChatGptSummary"
                  :disabled="isUpdateLoading"
                  v-if="chatContext">
            Summarise
          </button>
        </template>
        <template v-else>
          Loading...
        </template>
        <input type="text" id="isin" v-model="chatContext" placeholder="Context..." />
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="translate">
          <span class="checkmark"></span>
          Translate
        </label>
      </div>

      <!-- save button -->
      <button class="transition save-btn" @click="updateFundHandler()" :disabled="isUpdateLoading">
        Save
      </button>
    </template>
  </popup>
</template>
<script>
import Popup from "@/components/shared/Popup.vue";
import fundsMixin from "@/mixins/funds.vue";
import usersMixin from "@/mixins/users.vue";

export default {
  name: 'InspectPublicDescription',
  mixins: [fundsMixin, usersMixin],
  props: {
    item: Object
  },
  components: {
    Popup,
  },
  data() {
    return {
      fund: {...this.item},
      fundType: this.item.fund_type,
      source: this.item.source,
      generalObjective: this.item.general_objective || null,
      fundExposure: this.item.fund_exposure || null,
      risks: this.item.risks || null,

      fundTitle: null,
      fundTypeName: null,
      isUpdateLoading: false,
      chatContext: null,
      translate: false,
      isLoadingChatGptSummary: false,
    }
  },

  methods: {
    updateFundHandler(){
      this.isUpdateLoading = true;
      let dataToSend = {
        generalObjective: this.generalObjective,
        fundExposure: this.fundExposure,
        risks: this.risks,
        source: this.source,
        translate: this.translate
      }
      this.updateFund(dataToSend, this.fund.id)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code === 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
    },
    getFundUrl() {
      return "https://umushroom.com/en/" + this.getFundTypeName().toLowerCase() + "/" + this.item.security_url_name;
    },
    getFundTypeName() {
      switch (this.fundType) {
        case 0:
          return 'Fund';
        case 1:
          return 'ETF';
      }
    },
    getFundTitle() {
      return "Edit " + this.fundTypeName + " Public Page Content";
    },

    fetchChatGptSummary(){
      this.isUpdateLoading = true;
      let dataToSend = {
        context: this.chatContext,
      }

      this.getChatGptSummary(dataToSend)
          .then(response => {
            this.generalObjective = response.answer;
            this.isUpdateLoading = false;
            // this.updateLoading = false;
            // this.updateFundHandler();
          })
          .catch(err => {
            this.isUpdateLoading = false;
            if (err.code === 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
    },
  },

  computed: {

  },

  mounted() {
    this.fundTypeName = this.getFundTypeName();
    this.fundTitle = this.getFundTitle();
  }
}
</script>

<style scoped>
.info-text {
  display: block;
  color: #171725;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  text-transform: uppercase;
}

a {
  color: #3C6E71;
}

a:hover {
  text-decoration: none;
}

:deep(.set-field-btn) {
  margin-left: 3%;
  background-color: #FFF;
  color: #3C6E71;
  border: solid thin #3C6E71;
  outline: none;
  width: 30%;
  height: 30px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

:deep(.set-field-btn:not(:disabled):hover) {
  background-color: #3C6E71;
  color: #FFF;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border: none;
}

textarea#ai-content, textarea#ai-content-de {
  height: 300px;
}

textarea#ai-content-source {
  height: 80px;
}

</style>