<template>
  <!-- Fund Group Consolidations Page -->
  <div class="fund-group-consolidations-mds-page">
    <h1>Fund Group Consolidations <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getFundGroupsMdsHandler"
      @edit="editHandler"
    />

    <!-- Edit Popup -->
    <EditFundGroupConsolidationMds
      v-if="editItem"
      :item="editItem"
      :fundGroups="fundGroups"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import orgnanizationsMixin from '@/mixins/organizations'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundGroupConsolidationMds from './popups/EditFundGroupConsolidationMds'

  export default {
    name: 'GroupConsolidationsMds',
    mixins: [orgnanizationsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundGroupConsolidationMds
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Brand Name',
            name: 'brandName',
            sortable: true
          },
          {
            title: 'Preferred Name',
            name: 'preferredName',
            sortable: true
          },
          {
            title: 'URL Name',
            name: 'urlName',
            sortable: true
          },
          {
            title: 'Consolidated Id',
            name: 'consolidatedId',
            sortable: true
          },
          {
            title: 'Consolidated Name',
            name: 'consolidatedName',
            sortable: true
          },
          {
            title: 'Is AM',
            name: 'isAssetManager',
            sortable: true
          },
          {
            title: 'Funds',
            name: 'fundsCount',
            sortable: true
          },
          {
            title: 'Is Paid',
            name: 'isPaid',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'createdOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        fundGroups: null,
        updateResults: true,
        total: null
      }
    },
    methods: {
      // * Get fund groups from API
      getFundGroupsMdsHandler(data) {
        this.getOrganizationsPaginated(data, 0)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.total = res.resultsCount;
            // * Choose which fields to be visible in the datatable
            let organizations = [];
            // * Loop through groups and add only the needed properties
            for (const organization of res.organizations) {
              let organizationObj = {
                id: organization.id,
                name: organization.name,
                brandName: organization.brandName,
                preferredName: organization.preferredName,
                urlName: organization.urlName,
                consolidatedId: organization.consolidatedId,
                consolidatedName: organization.consolidatedName,
                isAssetManager: organization.isAssetManager,
                fundsCount: organization.fundsCount,
                isPaid: organization.isPaid,
                createdOn: organization.createdOn,
                updatedOn: organization.updatedOn,
              }
              // * Add formatted group to formatted groups array
              organizations.push(organizationObj);
            }
            // * Assign table rows to be the formatted groups array
            this.rows = organizations;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = {
          ...item
        };
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      // * On component (page) load get the fund groups for the autocomplete field in the edit popup
      this.getAllOrganizations(0)
        .then(res => {
          this.fundGroups = res.organizations;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>

</style>