<template>
  <div class="datatable-container">
    <DataTable
      v-if="rows"
      :columns="columns"
      :rows="rows"
      :pages="pages"
      :currentPage="currentPage"
      :itemsPerPage="localItemsPerPage"
      :sortBy="sortBy"
      :actions="actions"
      :loading="tableLoading"
      :page="page"
      :langs="langs"
      :columnSearch="columnSearch"
      @search-button="searchHandler"
      @search-enter="searchHandler"
      @search-by-col="searchByColHandler"
      @clear-column-search="clearColSearchHandler"
      @go-to-page="goToPageHandler"
      @items-per-page="setItemsPerPage"
      @sorting="sortHandler"
      @add-new="$emit('add-new')"
      @edit="editHandler"
      @upgrade="upgradeHandler"
      @pay="payHandler"
      @delete="deleteHandler"
      @view="viewHandler"
      @exportData="exportDataHandler"
      @view-answers="viewAnswersHandler"
      @show-content-data="handleShowContentData"
      @clone="handleClone"
    />

    <Loader v-else />
  </div>
</template>

<script>
  import DataTable from '@/components/shared/datatable/DataTable'
  import Loader from '@/components/core/Loader.vue'

  export default {
    name: 'DataTableHolder',
    components: {
      DataTable,
      Loader
    },
    props: {
      columns: Array,
      rows: Array,
      totalPages: Number,
      updateResults: Boolean,
      actions: Array,
      page: String,
      langs: Boolean,
      columnSearch: Boolean,
      itemsPerPage: {
          type: Number,
          default: 100
      }
    },
    data() {
      return {
        term: null,
        currentPage: 1,
        pages: this.totalPages,
        localItemsPerPage: this.itemsPerPage,
        tableLoading: false,
        sortBy: {
          column: 'id',
          order: 'DESC'
        },
        searchByColObj: []
      }
    },
    methods: {
      // * Triggered when user applies search term from the DataTable component
      searchHandler(searchTerm) {
        // * Start table loading
        this.tableLoading = true;
        
        if (searchTerm && searchTerm.length > 0) {
          this.term = searchTerm;
        } else {
          this.term = null;
        }

        // * Go back to first page
        this.currentPage = 1;
        // * Get results with search term
        this.$emit('update-table', this.dataForReq);
      },
      searchByColHandler(data) {
        // * Start table loading
        this.tableLoading = true;

        let duplicatedItem = this.searchByColObj.find(obj => {
          return obj.name == data.name;
        })
        if (duplicatedItem) {
          let duplicatedItemIndex = this.searchByColObj.indexOf(duplicatedItem);
          this.searchByColObj.splice(duplicatedItemIndex, 1);
        }
        this.searchByColObj.push(data);

        // * Go back to first page
        this.currentPage = 1;
        // * Get results with search
        this.$emit('update-table', this.dataForReq);
      },
      clearColSearchHandler() {
        this.tableLoading = true;
        this.searchByColObj = [];

        // * Go back to first page
        this.currentPage = 1;
        // * Get results without search
        this.$emit('update-table', this.dataForReq);
      },
      // * Triggered when user selects count of items per page from the DataTable component
      setItemsPerPage(items) {
        // * Start table loading
        this.tableLoading = true;
        this.localItemsPerPage = items;
        // this.$emit('update-table', { ...this.dataForReq, itemsPerPage: items });
        // * Go back to first page
        this.currentPage = 1;
        // * Get results for the new items per page
        this.$emit('update-table', this.dataForReq);
      },
      // * Triggered when user selects page from the DataTable component
      goToPageHandler(page) {
        // * Start table loading
        this.tableLoading = true;

        // * Check if page is number or keyword and update the current page
        if (typeof page == 'number') {
          this.currentPage = page;
        } else {
          switch (page) {
            case 'first':
              this.currentPage = 1;
              break;
            case 'previous':
              this.currentPage = this.currentPage - 1;
              break;
            case 'next':
              this.currentPage = this.currentPage + 1;
              break;
            case 'last':
              this.currentPage = this.pages;
              break;
          }
        }
        // * Get results for the page
        this.$emit('update-table', this.dataForReq);
      },
      // * Triggered when user sorts by a column in the DataTable component
      sortHandler(column) {
        // * Start table loading
        this.tableLoading = true;
        const sortBy = {
          column
        }

        // * Get sorting direction
        if (column != this.sortBy.column) {
          sortBy.order = 'ASC';
        } else if (this.sortBy.order == 'ASC') {
          sortBy.order = 'DESC';
        } else if (this.sortBy.order == 'DESC') {
          sortBy.order = 'ASC';
        }

        this.sortBy = sortBy;
        // * Go to first page
        this.currentPage = 1;
        // * Get sorted results
        this.$emit('update-table', this.dataForReq);
      },
      editHandler(item) {
        this.$emit('edit', item)
      },
      upgradeHandler(item) {
        this.$emit('upgrade', item)
      },
      payHandler(item) {
        this.$emit('pay', item)
      },
      handleClone(item) {
        this.$emit('clone', item)
      },
      deleteHandler(item) {
        this.$emit('delete', item)
      },
      viewHandler(item) {
        this.$emit('view', item)
      },
      exportDataHandler(item) {
        this.$emit('exportData', item)
      },
      viewAnswersHandler(item) {
        this.$emit('view-answers', item);
      },
      handleShowContentData(data) {
        this.$emit('show-content-data', data);
      },
    },
    computed: {
      dataForReq() {
        const dataToSend = {
          itemsPerPage: this.localItemsPerPage,
          page: this.currentPage,
          sortBy: this.sortBy
        }

        // * If search term is applied add it to the request
        if (this.term && this.term.length > 0) {
          dataToSend.term = this.term;
        }

        if (this.searchByColObj) {
          dataToSend.filter = this.searchByColObj;
        }

        return dataToSend;
      }
    },
    watch: {
      totalPages() {
        this.pages = this.totalPages;
      },
      rows() {
        this.tableLoading = false;
      },
      updateResults() {
        // * Start table loading
        this.tableLoading = true;
        
        this.$emit('update-table', this.dataForReq);
      }
    },
    mounted() {
      this.$emit('update-table', this.dataForReq);
    }
  }
</script>

<style scoped>

</style>