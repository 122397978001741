<template>
  <!-- New Command Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="New Command"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Command Name:</label>
        <input type="text" id="name" v-model="name" placeholder="Command Name...">
      </div>

      <!-- Short Description field -->
      <div class="field">
        <label for="shortDesc">Command Short Description:</label>
        <input type="text" id="shortDesc" v-model="shortDesc" placeholder="Command Short Description...">
      </div>

      <!-- Long Description field -->
      <div class="field">
        <label for="longDesc">Command Long Description:</label>
        <textarea id="longDesc" v-model="longDesc" placeholder="Command Long Description..."></textarea>
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Micro Service:</label>
        <vue3-simple-typeahead
          placeholder="Type micro service..."
          :items="microServices"
          :minInputLength="1"
          :itemProjection="(item) => item.name"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
      </div>

      <!-- Provider field -->
      <div class="field">
        <label for="provider">Provider:</label>
        <input type="text" id="provider" v-model="provider" placeholder="Provider...">
      </div>

      <button class="transition save-btn" @click="addCommandHandler()" :disabled="addLoading">
        <template v-if="!addLoading">
          Add
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import systemMixin from '@/mixins/system'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'NewCommand',
    mixins: [systemMixin, userMixin],
    props: {
      microServices: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        selectedMicroService: null,
        addLoading: false,
        name: null,
        shortDesc: null,
        longDesc: null,
        provider: null
      }
    },
    methods: {
      // * Triggers on add button click and cretes command
      addCommandHandler() {
        // * Start add loading (for the add button)
        this.addLoading = true;
        const dataToSend = {
          command: this.name,
          commandShortDesc: this.shortDesc,
          commandLongDesc: this.longDesc,
          provider: this.provider
        };
        
        // * If user has selected micro service from the list add it to the request data else send null
        if (this.selectedMicroService) {
          dataToSend.microService = this.selectedMicroService.id;
        } else {
          dataToSend.microService = null;
        }

        this.createCommand(dataToSend)
          .then(() => {
            this.$emit('added');
            this.addLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.addLoading = false;
          })
      },
      // * Triggered when user selects group from the autocomplete. Updates the selected group
      submit(result) {
        this.selectedMicroService = result;
      },
      // * Nulls group if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedMicroService = null;
        }
      }
    }
  }
</script>

<style scoped>
  .command-name {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
  }
</style>
