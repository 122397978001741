<template>
  <!-- Edit Fund Group Consolidation Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Edit Fund Group Consolidation"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <!-- Name field -->
      <div class="field">
        <label for="name">Name <small>(not editable)</small>:</label>
        <input type="text" id="name" v-model="fundGroup.name" placeholder="Name" disabled>
      </div>

      <!-- Autocomplete Field -->
      <div class="field autocomplete">
        <label>Consolidation Group:</label>
        <vue3-simple-typeahead
          placeholder="Type fund group..."
          :items="fundGroups"
          :minInputLength="1"
          :itemProjection="(item) => item.name"
          @selectItem="submit"
          @onInput="keyUpEvent"
        ></vue3-simple-typeahead>
        <span class="remove-warning" v-if="fundGroup.nameConsolidatedId && !selectedFundGroup">
          Are you sure you want to remove the consolidation group?
        </span>
      </div>

      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="isPaid">
          <span class="checkmark"></span>
          Is Paid
        </label>
      </div>

      <button class="transition save-btn" @click="updateFundGroupHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditFundGroupConsolidation',
    mixins: [fundsMixin, userMixin],
    props: {
      item: Object,
      fundGroups: Array
    },
    components: {
      Popup
    },
    data() {
      return {
        parentSelect: false,
        selectedFundGroup: null,
        updateLoading: false,
        isPaid: this.item.isPaid,
        fundGroup: this.item
      }
    },
    methods: {
      // * Triggers on save button click and updates advisor
      updateFundGroupHandler() {
        // * Start update loading (for the save button)
        this.updateLoading = true;
        const dataToSend = {
          name: this.fundGroup.name,
          isPaid: this.isPaid
        }
        
        // * If user has selected group from the list add it to the request data else send null
        if (this.selectedFundGroup) {
          dataToSend.nameConsolidatedId = this.selectedFundGroup.id;
        } else {
          dataToSend.nameConsolidatedId = null;
        }

        this.editFundGroup(this.fundGroup.id, dataToSend)
          .then(() => {
            this.$emit('updated');
            this.updateLoading = false;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      },
      // * Triggered when user selects fund group from the autocomplete. Updates the selected group
      submit(result) {
        this.selectedFundGroup = result;
      },
      // * Nulls group if user deletes the autocomplete field content
      keyUpEvent(value) {
        if (!value.input || value.input.length == 0) {
          this.selectedFundGroup = null;
        }
      }
    },
    mounted() {
      // * If fundGroup has consolidated group set it on component load
      if (this.fundGroup.nameConsolidatedId) {
        this.selectedFundGroup = {
          id: this.fundGroup.nameConsolidatedId,
          name: this.fundGroup.consolidatedName
        }
      }
    }
  }
</script>

<style scoped>
  
</style>
