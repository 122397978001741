<template>
  <popup title="Upload Translation File" @close="$emit('close')">
    <template v-slot:content>
      <div class="upload-translation-file-preview">
        <div v-if="currentLanguage" class="selected-language-info">
          <div class="language-switcher">
            <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">EN</button>
            <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">DE</button>
          </div>
          <div class="selected-language-text">
            Selected language: <span class="selected-language">{{ getSelectedLanguage() }}</span>
          </div>
          <div class="reset-preview">
            <button class="reset-button" @click="resetPreview">Reset</button>
          </div>
        </div>

        <div v-if="!currentLanguage">
          <h2>Please Select Language in order to continue</h2>
          <div class="language-switcher">
            <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">ENGLISH</button>
            <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">GERMAN</button>
          </div>
        </div>

        <div v-else-if="currentLanguage">
          <h2>Please choose a <b>{{ getSelectedLanguage() }}</b> translation file</h2>
          <input type="file" @change="handleFileChange" ref="fileInput" :accept="getFileAcceptType()" style="display: none" />
          <button class="upload-button" @click="openFileInput">
            <font-awesome-icon icon="file-upload" />
            <span v-if="selectedFile">{{ truncateFileName(selectedFile.name, 20) }}</span>
            <span v-else>Choose File</span>
          </button>
        </div>

        <div class="selected-file" v-if="selectedFile">
          <button class="upload-button" @click="uploadFile" :disabled="uploading">
            <font-awesome-icon icon="upload" />
            <span>Upload</span>
          </button>
          <p v-if="uploading" class="info-message">Please wait File being uploaded  <font-awesome-icon icon="spinner" spin /></p>
          <p v-if="uploadSuccess" class="success-message">File uploaded successfully</p>
          <p v-if="!uploadSuccess && errorMsg" class="error-message">{{errorMsg}}</p>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import translationsMixin from "@/mixins/translations";
import Popup from "@/components/shared/Popup";

export default {
  name: 'UploadTranslationFile',
  mixins: [translationsMixin],
  props: {
    translationType: Number,
  },
  components: {
    Popup,
  },
  data() {
    return {
      currentLanguage: null,
      selectedFile: null,
      fileContent: null,
      uploading: false,
      uploadSuccess: false,
      errorMsg: '',
    };
  },
  methods: {
    getFileAcceptType() {
      switch (this.translationType) {
        case 2:
          return ".arb";
        case 3:
          return ".js";
        case 4:
          return ".yaml, .yml";
        default:
          return "";
      }
    },
    getSelectedLanguage() {
      if (this.currentLanguage === 'en') {
        return 'ENGLISH';
      } else if (this.currentLanguage === 'de') {
        return 'GERMAN';
      }
    },
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];

      if (this.selectedFile && this.translationType == 3) {
        // sen the file content only for .js files
        const reader = new FileReader();
        reader.onload = () => {
          try {
            let fileContent = reader.result.replace(/^export\s+default\s*/, ''); // Remove "export default" from the beginning
            eval("this.fileContent = " + fileContent); // Evaluate the string as JavaScript code
            this.fileContent = JSON.stringify(this.fileContent); // Stringify the entire file content
          } catch (error) {
            console.error('Error parsing file:', error);
          }
        };
        reader.readAsText(this.selectedFile);
      }
    },
    uploadFile() {
      if (!this.selectedFile) {
        this.errorMsg = 'No file selected';
        return;
      }

      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      const dataToSend = {
        file: this.selectedFile,
        type: this.translationType,
        language: this.currentLanguage,
      };

      if (this.translationType === 3) {
        // Only send the file content if type is 3 (for .js files)
        dataToSend.fileContent = this.fileContent;
      }

      this.importTranslationFile(dataToSend)
        .then(() => {
          this.uploadSuccess = true;
          this.$emit('updated');
        })
        .catch((err) => {
          this.errorMsg = err.msg ?? 'File Upload failed';
        })
        .finally(() => {
          this.uploading = false;
          setTimeout(() => {
            this.errorMsg = '';
            this.uploadSuccess = false;
          }, 3000);
        });
    },
    resetPreview() {
      this.currentLanguage = null;
      this.selectedFile = null;
      this.fileContent = null;
    },
    truncateFileName(fileName, maxLength) {
      if (fileName.length <= maxLength) {
        return fileName;
      } else {
        return fileName.substr(0, maxLength) + '...';
      }
    },
  },
};
</script>

<style scoped>
.upload-translation-file-preview {
  text-align: center;
}

.upload-button {
  margin: 0 auto;
  padding: 10px 18px;
  font-size: 18px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

.upload-button span {
  margin-left: 5px;
}

.language-switcher {
  margin-top: 20px;
}

.language-switcher button {
  margin-right: 10px;
}

.language-switcher button b {
  font-weight: bold;
}

.selected-language-info {
  margin-top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
  flex-wrap: wrap;
  align-content: flex-end;
}

.selected-language-text {
  font-size: 18px;
  border-bottom: 1px solid black;
}

.selected-language {
  font-weight: 800;
}

.reset-preview {
  text-align: right;
}

.reset-button {
  width: 120px;
  padding: 10px 18px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.reset-button:hover {
  background-color: #bd2130;
}

.language-switcher button {
  padding: 10px 18px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.language-switcher button:hover {
  background-color: #0056b3;
}

.selected-file {
  margin-top: 10px;
}

.info-message {
  color: #17a2b8;
  font-size: 14px;
  margin-top: 10px;
}


.selected-language-info .language-switcher {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
}

.selected-language-info .language-switcher button {
  border: unset;
  border-radius: unset;
  font-weight: 600;
  margin-right: unset;
  background-color: #f0f0f0;
  color: black;
  padding: 9px 15px;
}

.selected-language-info .language-switcher button.active {
  background-color: #3c6e71;
  color: white;
}

</style>
