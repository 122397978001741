<template>
  <popup
    title="Edit User"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="two-cols">
        <div class="field">
          <label for="name">First Name:</label>
          <input type="text" id="name" v-model="user.firstName" placeholder="First Name">
        </div>
        <div class="field">
          <label for="name">Last Name:</label>
          <input type="text" id="name" v-model="user.lastName" placeholder="Last Name">
        </div>
      </div>
      <div class="field">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="user.email" placeholder="Email">
      </div>
      <div class="email-btns" v-if="!user.emailVerified">
        <button class="green-btn transition" @click="verifyUserEmailHandler()">
          Verify Email
        </button>
        <button class="green-btn transition" @click="sendVerificationEmailHandler()">
          Resend Email
        </button>
      </div>
      <div class="two-cols">
        <div class="field">
          <label for="title">Title:</label>
          <input type="text" id="title" v-model="user.title" placeholder="Title">
        </div>
        <div class="field">
          <label for="phone">Phone:</label>
          <input type="tel" id="phone" v-model="user.phone" placeholder="Phone">
        </div>
      </div>
      <div class="two-cols">
        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="user.highNetWorthIndividual">
            <span class="checkmark"></span>
            High Net Worth Individual
          </label>
        </div>
        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="user.panelsEnabled">
            <span class="checkmark"></span>
            Panels Enabled
          </label>
        </div>
      </div>

      <div class="field" v-if="subscriptionInfo">
        <label>Subscription Info:</label>
        <div class="info-row">
          <span>Status: </span>
          <span>
            <template v-if="subscriptionInfo.status === -1">
              No subscription info
            </template>
            <template v-else-if="subscriptionInfo.status === 0">
              Has subscription info
            </template>
          </span>
        </div>
        <template v-if="subscriptionInfo.data">
          <div class="info-row">
            <span>Added On: </span>
            <span>{{ $filters.formatDateTime(subscriptionInfo.data.addOn) }}</span>
          </div>
          <div class="info-row">
            <span>Next payment date: </span>
            <span>{{ $filters.formatDateTime(subscriptionInfo.data.nextPaymentDate) }}</span>
          </div>
          <div class="info-row">
            <span>Is cancelled: </span>
            <span>
              <font-awesome-icon v-if="subscriptionInfo.data.isCancelled" class="check" icon="check" />
              <font-awesome-icon v-else class="cross" icon="times" />
            </span>
          </div>
          <div class="info-row">
            <span>On trial: </span>
            <span>
              <font-awesome-icon v-if="subscriptionInfo.data.onTrial" class="check" icon="check" />
              <font-awesome-icon v-else class="cross" icon="times" />
            </span>
          </div>
          <div class="info-row" v-if="subscriptionInfo.data.cardData">
            <span>Card data: </span>
            <span>
              <span class="capitalize">{{subscriptionInfo.data.cardData.brand}}</span> | {{subscriptionInfo.data.cardData.card.replaceAll('x', '*')}} | {{subscriptionInfo.data.cardData.expiry}}
            </span>
          </div>
        </template>
      </div>

      <div class="three-cols">
        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="user.logDisabled">
            <span class="checkmark"></span>
            Log Disabled
          </label>
        </div>

        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="user.isTesting">
            <span class="checkmark"></span>
            Is Testing
          </label>
        </div>

      </div>

      <div class="filter-dropdown">
        <label for="domicile">Domicile:</label>
        <select id="domicile" v-model="selectedDomicileId">
          <option
              v-for="domicile in filteredDomiciles"
              :key="domicile.id"
              :value="domicile.id">
            {{ domicile.countryName }}
          </option>
        </select>
      </div>

      <button class="transition save-btn" @click="updateUserHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import usersMixin from '@/mixins/users'
  import userMixin from '@/mixins/user'
  import countryMixin from "@/mixins/country.vue";
  import Popup from '@/components/shared/Popup'

  export default {
    name: 'EditUser',
    mixins: [usersMixin, userMixin, countryMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        updateLoading: false,
        user: {...this.item},
        subscriptionInfo: null,
        domicilesDropdown: [],
        selectedDomicileId: null,
        filteredDomiciles: [],
      }
    },
    methods: {
      updateUserHandler() {
        this.updateLoading = true;
        const selectedDomicile = this.domicilesDropdown.find(d => d.id === this.selectedDomicileId);

        if (selectedDomicile) {
          this.user.newDomicileName = selectedDomicile.countryName;
          this.user.newRuleGroup = selectedDomicile.ruleGroupId;

          //We can also send:
          // this.user.newClientDomicileClientQualification = selectedDomicile.clientDomicileClientQualification;

          //Or we can just send domicile ID and link this id as foreign key to User in order to get link country name, rule group, everything at once...:
          // this.user.newDomicileId = selectedDomicile.id;

        } else {
          this.user.newDomicileName = null;
          this.user.newRuleGroup = null;
        }
        const dataToSend = {
          ...this.user,
        }

        this.editUser(this.user.id, dataToSend)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      getUserSubscriptionInfoHandler() {
        this.getUserSubscriptionInfo(this.user.id)
          .then(res => {
            this.subscriptionInfo = res.subscription;
          })
          .catch(err => {
            if (err.code == 401) {
              this.logout();
            }
          })
      },
      verifyUserEmailHandler() {
        this.verifyUserEmail(this.user.id)
          .then(() => {
            this.$emit('updated');
          })
          .catch(err => {
            if (err.code == 401) {
              this.logout();
            }
          })
      },
      sendVerificationEmailHandler() {
        this.sendVerificationEmail(this.user.id)
          .then(() => {
            this.$emit('updated');
          })
          .catch(err => {
            if (err.code == 401) {
              this.logout();
            }
          })
      },

      fetchDomicilesWithSuitabilities() {
        this.getDomicilesWithSuitabilities()
            .then((domiciles) => {
              if (domiciles) {
                this.domicilesDropdown = domiciles;
                this.filteredDomiciles = this.filterDomiciles();
                this.preselectUserDomicile();
              }
            })
            .catch((error) => {
              console.error("Error fetching domiciles:", error);
            });
      },
      filterDomiciles() {
        const suffix = this.user.highNetWorthIndividual ? '_EPC' : '_REC';
        return this.domicilesDropdown.filter(domicile =>
            domicile.clientDomicileClientQualification.endsWith(suffix)
        );
      },
      preselectUserDomicile() {
        const currentUserDomicile = this.filteredDomiciles.find(d =>
            d.countryName === this.user.domicile
        );
        this.selectedDomicileId = currentUserDomicile ? currentUserDomicile.id : null;
      },


    },

    watch: {
      'user.highNetWorthIndividual': function() {
        this.filteredDomiciles = this.filterDomiciles();
        this.preselectUserDomicile();
      }
    },

    created() {
      this.fetchDomicilesWithSuitabilities();
    },

    mounted() {
      this.getUserSubscriptionInfoHandler();
    }
  }
</script>

<style scoped>
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 15px;
  }

  .info-row:first-of-type {
    margin-top: 10px;
  }

  .info-row:nth-child(even) {
    background: #ececec;
  }

  .check {
    color: #3dd598;
  }

  .cross {
    color: #fc5a5a;
  }

  .email-btns {
    display: flex;
    justify-content: space-between;
  }

  .email-btns button {
    flex: 0 0 calc(50% - 10px);
  }

  .filter-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
  }

  .filter-dropdown label {
    font-size: 18px;
    margin-right: 15px;
    color: #3c6e71;
    font-weight: 500;
  }

  .filter-dropdown select {
    width: 190px;
    border: 2px solid #e0e0e0;
    border-radius: 25px;
    padding: 9px 15px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
    margin-right: 10px;
  }

  .filter-dropdown input[type="text"] {
    width: 340px;
    border: 2px solid #e0e0e0;
    border-radius: 25px;
    padding: 9px 15px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
    margin-right: 10px;
  }

  .filter-dropdown select:focus, .filter-dropdown input[type="text"]:focus {
    border-color: #3c6e71;
  }

  .filter-dropdown select {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%233c6e71" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>') no-repeat right 1rem center/8px 10px, #ffffff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .filter-dropdown .filter-btn {
    background: #3c6e71;
    color: #FFF;
    border: none;
    font-weight: 600;
    height: 45px;
    width: 100px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }

  .filter-dropdown .filter-btn:hover {
    background: #295456;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .filter-dropdown .filter-btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
</style>
