<template>
  <div class="section-item">
    <div class="section-header" @click="toggleSectionOpen()">
      <div>
        <font-awesome-icon v-if="isSectionOpened" icon="minus" />
        <font-awesome-icon v-else icon="plus" />
        <h3 class="section-header">{{ section.title.en.title }}</h3>
        <div class="new-item">
          <button @click.stop="$emit('new-session', {courseId: courseId ,moduleId: moduleId, sectionId: section.id})">
            <img class="transition" :src="$s3Url + 'svgs/icons/plus.svg'">
            <span>New Lecture</span>
          </button>
        </div>
      </div>
      <div class="actions">
        <font-awesome-icon icon="edit" @click.stop="editSectionHandler(section.id)" />
        <a @click.stop="deleteSectionHandler()">
          <font-awesome-icon icon="trash" />
        </a>
      </div>
    </div>
    <div class="section-content" v-if="isSectionOpened">
      <draggable v-model="sectionItem.sessions" group="sessions" item-key="id">
        <template #item="{ element }">
          <SessionItem
            :courseId="courseId"
            :moduleId="moduleId"
            :sectionId="section.id"
            :session="element"
            @delete-session="$emit('delete-session', $event)"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
  import knowledgeMixin from '@/mixins/knowledge'

  import draggable from "vuedraggable";
  import SessionItem from './SessionItem';

  export default {
  name: 'SectionItem',
    mixins: [knowledgeMixin],
    components: {
      draggable,
      SessionItem
    },
    props: {
      section: Object,
      moduleId: Number,
      courseId: Number,
    },
    data() {
      return {
        sectionOpen: true,
        sectionItem: this.section
      };
    },
    computed: {
      isSectionOpened() {
        const openedSections = this.$store.getters['knowledge/getOpenedSections'];
        return openedSections.includes(this.section.id);
      },
    },
    methods: {
      toggleSectionOpen() {
        this.sectionOpen = !this.isSectionOpened;

        const isOpen = this.sectionOpen;
        const sectionId = this.section.id;
        this.$store.commit('knowledge/updateOpenedSections', { sectionId, isOpen });
      },
      editSectionHandler(sectionId) {
        const sectionData = {
          moduleId: this.moduleId,
          sectionId: sectionId,
        };

        this.$emit('edit-section', sectionData);
      },
      deleteSectionHandler() {
        const deleteData = {
          'courseId': this.courseId, 
          'moduleId': this.moduleId,
          'sectionId': this.section.id
        };

        this.$emit("delete-section", deleteData);
      },
    },
    watch: {
      sectionItem: {
        handler(newSection) {
          const dataToSend = {
            items: newSection.sessions,
            parentId: newSection.id
          }

          this.reorderItems('sessions', dataToSend)
            .then()
            .catch(err => {
              console.log(err);
            })
        },
        deep: true
      }
    }
  };
</script>

<style scoped>
  .section-item {
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(146, 146, 146);
    margin-left: 30px;
  }

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
  }

  .section-header div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .section-header h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }

  .section-header > svg {
    font-size: 20px;
    cursor: pointer;
  }

  .section-content {
    margin-left: 20px;
  }

  .new-item button{
    display: flex;
    align-items: center;
    background: #FFF;
    color: #3c6e71;
    border: 1px solid #3c6e71;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }

  .new-item button:hover {
    background: #3c6e71;
    color: #FFF;
  }

  .new-item button img {
    margin-right: 5px;
    filter: invert(41%) sepia(8%) saturate(2190%) hue-rotate(135deg) brightness(89%) contrast(82%);
  }

  .new-item button:hover img {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(105%) contrast(104%);
  }

  .section-header{
    color: #007bff;
  }
</style>
