<template>
  <!-- Inspect Portfolio Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Portfolio"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <span class="portfolio-id">Portfolio ID: {{item.id}}</span>
      <span class="portfolio-id">Portfolio Name: {{item.name}}</span>

      <div class="two-cols">
        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="isHot">
            <span class="checkmark"></span>
            Is Hot
          </label>
        </div>
        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="isRecommended">
            <span class="checkmark"></span>
            Is Recommended
          </label>
        </div>
      </div>

      <button class="transition save-btn" @click="updatePortfolioHandler()" :disabled="updateLoading">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>
    </template>
  </popup>
</template>

<script>
  import portfoliosMixin from '@/mixins/portfolios'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  
  export default {
    name: 'InspectPortfolio',
    mixins: [portfoliosMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup
    },
    data() {
      return {
        updateLoading: false,
        isHot: this.item.isHot,
        isRecommended: this.item.isRecommended
      }
    },
    methods: {
      updatePortfolioHandler() {
        this.updateLoading = true;

        let dataToSend = {
          isHot: this.isHot,
          isRecommended: this.isRecommended
        }

        this.updatePortfolio(this.item.id, dataToSend)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
            this.updateLoading = false;
          })
      }
    }
  }
</script>

<style scoped>
  .portfolio-id {
    font-weight: 700;
    margin-top: 10px;
    display: block;
  }

  input:disabled {
    cursor: text !important;
  }
</style>