<script>
  import axios from 'axios'

  if (localStorage.getItem('x-auth-token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('x-auth-token');
  }

  export default {
    methods: {
      // ---------------------------------------------
      // Users
      // ---------------------------------------------
      // * Get the users with pagination from the API
      getUsersPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/users`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Get the users from the API
      getUsers() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/users`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // * Update user function
      editUser(userId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/${userId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Update user function
      deleteUser(userId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/${userId}`, {
            method: 'delete'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Get user subscription info
      getUserSubscriptionInfo(userId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/${userId}/subscription/info`, {
            method: 'get'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // * Verify user email
      verifyUserEmail(userId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/verify/user/${userId}`, {
            method: 'post'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // * Send new verification email with numbers
      sendVerificationEmail(userId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/verification-email/user/${userId}`, {
            method: 'post'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // ---------------------------------------------
      // Wealth Managers
      // ---------------------------------------------
      // * Get the wealth managers from the API
      getWealthManagers(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/wealth/managers`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Update wealth manager function
      editWealthManager(managerId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/wealth/manager/${managerId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // * Add new wealth manager function
      addWealthManager(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/wealth/manager`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // ---------------------------------------------
      // Community Groups
      // ---------------------------------------------
      // * Get community groups with pagination
      getCommunityGroupsPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/community/groups`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Create new community group
      createCommunityGroup(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/community/group`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Update community group
      editCommunityGroup(groupId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/community/group/${groupId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      // ---------------------------------------------
      // Brokers
      // ---------------------------------------------
      // * Get community groups with pagination
      getBrokersPaginated(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/brokers`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      createBroker(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/broker`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      editBroker(brokerId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/broker/${brokerId}`, {
            method: 'patch',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // ---------------------------------------------
      // Instrument Rights
      // ---------------------------------------------
      // * Get instrument rights with pagination
      getInstrumentRights(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/rights`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // * Create new instrument right
      addInstrumentRight(userId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/${userId}/right`, {
            method: 'post',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
        // * Edit an existing instrument right
        editInstrumentRight(rightId, data) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/user/right/${rightId}`, {
                    method: 'patch',
                    data: data
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.message);
                    })
            })
        },
      // * Delete an instrument right
      deleteInstrumentRight(rightId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/user/right/${rightId}`, {
            method: 'delete'
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      // ---------------------------------------------
      // Subscriptions
      // ---------------------------------------------
      getSubscriptions(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/subscribers`, {
            method: 'POST',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      updateSubscription(subscriberId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/subscribers/${subscriberId}`, {
            method: 'PATCH',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      upgradeSubscription(subscriberId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/subscribers/${subscriberId}/upgrade`, {
            method: 'PATCH',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data.message);
            })
        })
      },
      paySubscription(subscriberId, data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/subscribers/${subscriberId}/pay`, {
            method: 'PATCH',
            data: data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err.response.data);
            })
        })
      },
      getSubscribersPayments(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/payments-report`, {
            method: 'POST',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },
      // ---------------------------------------------
      // Plans
      // ---------------------------------------------
      getPlans(data) {
          return new Promise((resolve, reject) => {
              axios(`${this.$store.state.global.apiUrl}/backoffice/plans`, {
                  method: 'GET',
                  data: data
              })
                  .then(res => {
                      resolve(res.data);
                  })
                  .catch(err => {
                      reject(err.response.data.message);
                  })
          })
      },
        updatePlan(planId, data) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/plans/${planId}`, {
                    method: 'PATCH',
                    data: data
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.message);
                    })
            })
        },

        getUsersByNativeSql(data) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/users/by-native-sql`, {
                    method: 'POST',
                    data: data
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.message);
                    })
            })
        },

        getUsersByFiltersSet1(data) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/users/by-filters-1`, {
                    method: 'POST',
                    data: data
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.message);
                    })
            })
        },

        getUsersByFiltersSet1ExcelExport(data) {
            return new Promise((resolve, reject) => {
                axios(`${this.$store.state.global.apiUrl}/backoffice/users/by-filters-1`, {
                    method: 'POST',
                    data: data,
                    responseType: 'blob'
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data.message);
                    })
            })
        },

      getAllUserEmails() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/users/export-emails`, {
            method: 'GET',
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getContestOverview(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/users/contest-overview`, {
            method: 'POST',
            data: data,
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getUserPerformancePerSectionAndPerSession(userId) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/contest/session-performance/${userId}`, {
            method: 'get'
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getContestUserEmails() {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/contest-user/emails`, {
            method: 'GET',
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getUsersUTM(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/users/utm`, {
            method: 'POST',
            data: data,
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getUsersUTMExport(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/users/utm-export`, {
            method: 'POST',
            data: data,
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

      getUserPromoCodes(data) {
        return new Promise((resolve, reject) => {
          axios(`${this.$store.state.global.apiUrl}/backoffice/used-promo-codes`, {
            method: 'POST',
            data: data
          })
              .then(res => {
                resolve(res.data);
              })
              .catch(err => {
                reject(err.response.data.message);
              })
        })
      },

    }
  }
</script>
