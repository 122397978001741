<template>
    <div class="latest-unlocked-sessions">
      <h1>
        Latest Unlocked Videos <small v-if="total">({{ total }})</small>
      </h1>
  
      <div class="filter-module">
        <p class="filter-module-title">Filter by Membership Type, Module, Chapter, and Video</p>
  
        <div class="filter-dropdown">
          <label>Membership Type:</label>
          <select v-model="selectedMembershipType" @change="onMembershipTypeChange">
            <option value="" disabled>Select membership type</option>
            <option v-for="(name, id) in membershipTypes" :key="id" :value="id">
              {{ name }}
            </option>
          </select>
          <button class="filter-btn" v-if="selectedMembershipType" @click="resetFilters">Reset</button>
        </div>
  
        <div class="filter-dropdown" v-if="modules && modules.length > 0">
          <label>Module:</label>
          <select v-model="selectedModuleId" @change="onModuleChange">
            <option value="" disabled>Select a module</option>
            <option v-for="module in modules" :key="module.module_id" :value="module.module_id">
              {{ module.module_title }}
            </option>
          </select>
        </div>
  
        <div class="filter-dropdown" v-if="sections && sections.length > 0">
          <label>Chapter:</label>
          <select v-model="selectedSectionId" @change="onSectionChange">
            <option value="" disabled>Select a section</option>
            <option v-for="section in sections" :key="section.section_id" :value="section.section_id">
              {{ section.section_title }}
            </option>
          </select>
        </div>
  
        <div class="filter-dropdown" v-if="sessions && sessions.length > 0">
          <label>Video:</label>
          <select v-model="selectedSessionId" @change="fetchSessionsDataWithFilters">
            <option value="" disabled>Select a video</option>
            <option v-for="session in sessions" :key="session.session_id" :value="session.session_id">
              {{ session.session_title }}
            </option>
          </select>
        </div>
      </div>
  
      <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :actions="['edit']"
        @update-table="fetchSessionsData"
        @edit="editHandler"
      />

      <InspectUserWatchHistory
        v-if="showEditPopup"
        :user="editItem"
        @close="closeEditPopup"
      />

    </div>
  </template>
  
  <script>
  import DataTableHolder from "@/components/shared/datatable/DataTableHolder.vue";
  import InspectUserWatchHistory from "@/components/knowledge/popups/InspectUserWatchHistory.vue";
  import knowledgeMixin from "@/mixins/knowledge.vue";
  
  export default {
    name: 'LatestUnlockedSessions',
    components: {
      DataTableHolder,
      InspectUserWatchHistory
    },
    mixins: [knowledgeMixin],
    data() {
      return {
        rows: null,
        total: null,
        pages: null,
        updateResults: false,
        showEditPopup: false,
        editItem: null,
        fullData: null,
        selectedMembershipType: null,
        selectedModuleId: null,
        selectedSectionId: null,
        selectedSessionId: null,
        membershipTypes: { 1: 'Learn', 2: 'Grow' },
        modules: [],
        sections: [],
        sessions: [],
        columns: [
          { title: "User ID", name: "userId", sortable: false },
          { title: "First Name", name: "firstName", sortable: false },
          { title: "Last Name", name: "lastName", sortable: false },
          { title: "Email", name: "email", sortable: false },
          { title: "Latest Unlocked Video", name: "videoTitle", sortable: false },
          // { title: "Module ID", name: "knowledgeModuleId", sortable: false },
          // { title: "Section ID", name: "sectionId", sortable: false },
          // { title: "Video ID", name: "knowledgeSessionId", sortable: false },
          // { title: "Started On", name: "startedOn", sortable: true },
          // { title: "Status", name: "status", sortable: true },
          // { title: "Duration", name: "duration", sortable: true },
          { title: "Unlocked On", name: "unlockedOn", sortable: false },
        ],

      }
    },
    methods: {
      fetchSessionsDataWithFilters() {
        this.fetchSessionsData({
          filter: [
            { name: 'membership_type', term: this.selectedMembershipType },
            { name: 'module', term: this.selectedModuleId },
            { name: 'section', term: this.selectedSectionId },
            { name: 'session', term: this.selectedSessionId },
            
          ].filter(filter => filter.term !== null)
        });
      },
  
      fetchSessionsData(data) {
        this.filterLoading = true;
    
        this.getLatestUnlockedSessions(data)
          .then((res) => {
            this.pages = res.pagesCount;
            this.rows = res.results.map(user => ({
              userId: user.user_id,
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              videoTitle: user.video_title,
              // knowledgeModuleId: user.knowledge_module_id,
              // sectionId: user.section_id,
              // knowledgeSessionId: user.knowledge_session_id,
              // startedOn: this.formatDate(user.started_on),
              // status: user.status,
              // duration: user.duration,
              unlockedOn: this.formatDate(user.unlocked_on),
            }));
            this.total = res.resultsCount;
            this.filterLoading = false;
            this.fullData = res.results;
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              this.logout();
            }
          });
      },
  
      onMembershipTypeChange() {
        this.fetchModules();
        this.fetchSessionsDataWithFilters();
      },
  
      fetchModules() {
        if (!this.selectedMembershipType) return;
        this.getModulesTitles()
          .then(modules => {
            this.modules = modules;
            this.sections = [];
            this.sessions = [];
            this.selectedModuleId = null;
            this.selectedSectionId = null;
            this.selectedSessionId = null;
          })
          .catch(err => console.error('Failed to load modules:', err));
      },
  
      onModuleChange() {
        this.fetchSections();
        this.fetchSessionsDataWithFilters();
      },
  
      fetchSections() {
        if (!this.selectedModuleId) return;
        this.getSectionsTitles()
          .then(sections => {
            this.sections = sections;
            this.sessions = [];
            this.selectedSectionId = null;
            this.selectedSessionId = null;
          })
          .catch(err => console.error('Failed to load sections:', err));
      },
  
      onSectionChange() {
        this.fetchSessions();
        this.fetchSessionsDataWithFilters();
      },
  
      fetchSessions() {
        if (!this.selectedSectionId) return;
        this.getSessionsTitles()
          .then(sessions => {
            this.sessions = sessions;
            this.selectedSessionId = null;
          })
          .catch(err => console.error('Failed to load sessions:', err));
      },
  
      resetFilters() {
        this.selectedMembershipType = null;
        this.selectedModuleId = null;
        this.selectedSectionId = null;
        this.selectedSessionId = null;
        this.modules = [];
        this.sections = [];
        this.sessions = [];
        this.fetchSessionsDataWithFilters();
      },
  
      formatDate(timestamp) {
        const date = new Date(timestamp);
        return date.toISOString().split('T')[0];
      },

      editHandler(item) {
        this.editItem = this.fullData.find((result) => {
            return result.user_id === item.userId;
        });
        this.showEditPopup = true;
      },

      closeEditPopup() {
          this.showEditPopup = false;
      },

    },
  
    // created() {
    //   this.fetchSessionsDataWithFilters();
    // }
  }
</script>


<style scoped>

.filter-module {
  background-color: #f7f9fc;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
}

.filter-module-title {
  font-size: 20px;
  color: #3c6e71;
  margin-bottom: 10px;
  font-weight: 600;
}

.filter-dropdown {
  display: flex;
  align-items: center; /* Align items in the same vertical line */
  padding: 10px 0;
  margin-bottom: 15px;
}

.filter-dropdown label {
  font-size: 18px;
  color: #3c6e71;
  font-weight: 500;
  width: 200px; /* Ensure all labels take the same width */
  text-align: left; /* Align text to the left */
  margin-right: 15px; /* Add spacing between label and select */
}

.filter-dropdown select {
  width: 250px; /* Consistent width for dropdowns */
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
}

.filter-dropdown select:focus {
  border-color: #3c6e71;
}

.filter-dropdown .filter-btn {
  margin-left: 15px; /* Add space between dropdown and reset button */
  background: #3c6e71;
  color: #fff;
  border: none;
  font-weight: 600;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
}

.filter-dropdown .filter-btn:hover {
  background: #295456;
}

.filter-dropdown .filter-btn:active {
  transform: translateY(1px);
}

</style>