<template>
    <div class="translations-list">
      <h1>{{ getTranslationPageTitle() }}</h1>
      <div v-if="isDataLoading" class="loading-overlay">
        <div class="loading-indicator">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
  
      <div v-if="fileNames && fileNames.length > 0" class="filenames-holder">
        <div v-for="(file, index) in fileNames" :key="index">
          <button class="filenames" :class="{ 'selected': selectedFilename === file }" @click="selectFilename(file)">
            {{ file }}
          </button>
        </div>
      </div>
  
      <div class="language-switcher">
        <button @click="switchLanguage('en')" :class="{ active: currentLanguage === 'en' }">EN</button>
        <button @click="switchLanguage('de')" :class="{ active: currentLanguage === 'de' }">DE</button>
      </div>
  
      <div class="files-actions">
        <div class="upload-file">
          <button class="upload-button" @click="showUploadPopup = true">
            <font-awesome-icon icon="file-upload" />
            <span>Upload File</span>
          </button>
        </div>
        <div class="export-file">
          <button class="export-button" @click="exportFile()" :disabled="!selectedFilename || !currentLanguage" :class="{ 'disabled': !selectedFilename || !currentLanguage }">
            <font-awesome-icon icon="file-export" />
            <span>{{ exportButtonText }}</span>
          </button>
        </div>
      </div>
  
      <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="0"
        :actions="['edit']"
        @edit="editHandler"
      />
  
      <EditTranslation
        v-if="editItem"
        :item="editItem"
        @close="editItem = null"
        @updated="updatedHandler"
      />
      
      <UploadTranslationFile
        v-if="showUploadPopup"
        :translationType="translationType"
        @updated="fetchFileNames"
        @close="showUploadPopup = false"
      />  
    </div>
  </template>
    
  <script>
  import UploadTranslationFile from "./popups/UploadTranslationFile";
  import DataTableHolder from "@/components/shared/datatable/DataTableHolder";
  import translationsMixin from "@/mixins/translations";
  import EditTranslation from "./popups/EditTranslation.vue";
  
  export default {
    name: "Translations",
    mixins: [translationsMixin],
    components: {
      UploadTranslationFile,
      DataTableHolder,
      EditTranslation,
    },
    props: {
      translationType: Number
    },
    data() {
      return {
        editItem: null,
        currentLanguage: 'en',
        fileNames: [],
        selectedFilename: null,
        isDataLoading: false,
        showUploadPopup: false,
        columns: [
          {
            title: "ID",
            name: "id",
            sortable: false,
          },
          {
            title: "Type",
            name: "type",
            sortable: false,
          },
          {
            title: "Key",
            name: "key",
            sortable: false,
          },
          {
            title: "Text",
            name: "text",
            sortable: false,
          },
          {
            title: "Description",
            name: "description",
            sortable: false,
          },
          {
            title: "Updated On",
            name: "updatedOn",
            sortable: false,
          },
          {
            title: "Updated By",
            name: "updatedBy",
            sortable: false,
          },
        ],
        rows: [],
        translationRows: []
      };
    },
    methods: {
      updatedHandler() {
        this.editItem = null;
        this.updateDataTable();
      },
      exportFile() {
        const dataToSend = {
          type: this.translationType,
          fileName: this.selectedFilename,
          language: this.currentLanguage
        };
  
        this.exportTranslationFile(dataToSend)
          .then(() => {
          })
          .catch((error) => {
            console.error("Export failed:", error);
          });
      },
      switchLanguage(language) {
        if (language === this.currentLanguage) {
          return;
        }
  
        this.currentLanguage = language;
        if (this.selectedFilename) {
          this.updateDataTable();
        }
      },
      selectFilename(filename) {
        if (filename === this.selectedFilename) {
          return;
        }
  
        this.selectedFilename = filename;
        this.updateDataTable();
      },
      editHandler(item) {
        const translationItem = this.translationRows.find((row) => {
          return row.id === item.id;
        });
  
        this.editItem = translationItem;
      },
      updateDataTable() {
        this.rows = [];
        this.translationRows = [];
  
        this.isDataLoading = true;
        const dataToSend = {
          type: this.translationType,
          fileName: this.selectedFilename
        };
  
        this.getTranslationsByFileAndType(dataToSend).then((res) => {
          const translations = res.translations
          for (const translation of translations) {
            let row = {
              id: translation.id,
              type: translation.type,
              key: translation.key,
              text: translation.textData[this.currentLanguage],
              description: translation.description,
              updatedOn: translation.updatedOn,
              updatedBy: translation.updatedBy
            };
  
            this.rows.push(row);
            this.translationRows.push({
              id: translation.id,
              textData: translation.textData,
              description: translation.description,
            });
          }
        })
        .finally(() => {
          this.isDataLoading = false;
        });
      },
      fetchFileNames() {
        this.getFileNamesByType(this.translationType)
          .then((res) => {
            if(res) {
              this.fileNames = res.files;
              if (this.fileNames.length > 0) {
                this.selectFilename(this.fileNames[0]);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching file names:", error);
          });
      },
      getTranslationPageTitle() {
        if (this.translationType == 4) {
          return 'Backend Translations'
        } else if (this.translationType == 3) {
          return 'Frontend Translations'
        } else if (this.translationType == 2) {
          return 'Mobile App Translations';
        }
      }
    },
    created() {
      this.fetchFileNames();
    },
    computed: {
      exportButtonText() {
        if (this.currentLanguage === 'en') {
          return "Export English File";
        } else if (this.currentLanguage === 'de') {
          return "Export German File";
        }
  
        return '';
      }
    },
    updated() {
      // Find the target element with class "table-actions" (Coming from DataTableHolder -> DataTable)
      // const tableActionsElement = document.querySelector(".datatable-container .table-actions");
      // const filtersContainer = this.$refs.filtersContainer;
      // // Move the filters container after the tableActionsElement
      // if (tableActionsElement && filtersContainer && !this.filtersDisplayed) {
      //   tableActionsElement.parentNode.insertBefore(
      //     filtersContainer,
      //     tableActionsElement.nextSibling
      //   );
      //   this.filtersDisplayed = true;
      // }
    },
  };
  </script>
    
  <style scoped>
  .files-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  
  .export-file, .upload-file {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
  }

  .filenames-holder {
    display: block;
    max-width: 60%;
  }

  .filenames-holder div {
    margin: 0px 10px 10px 0px;
    display: inline-block;
  }
  
  .export-button, .upload-button {
    padding: 10px 18px;
    font-size: 18px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .export-button:hover, .upload-button:hover {
    background-color: #0056b3;
  }
  
  .export-button span, .upload-button span {
    margin-left: 5px;
  }
  
  .export-button.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
  
  .filenames {
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    padding: 8px 16px;
    border: 2px solid transparent;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: #333;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
  
  .filenames:hover {
    background-color: #ddd;
  }
  
  .filenames.selected {
    background: var(--dark-green);
    color: #fff;
  }
  
  .language-switcher {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px;
  }
  
  .language-switcher button {
    border: none;
    padding: 9px 15px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .language-switcher button.active {
    background-color: #3c6e71;
    color: white;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  </style>
    