<template>
  <!-- Inspect Equity market Cap Popup using the HOC (High order component) Popup (in shared folder) -->
  <popup
    title="Inspect Equity Candidate (new)"
    @close="$emit('close')"
  >
    <template v-slot:content>

      <span class="equity-id">
        Security ID: {{equity.id}}
      </span>

      <span class="equity-id">
        Valor: {{equity.valor}}
      </span>

      <div class="field">
        <label for="isin"><strong>ISIN:</strong></label>
        <template v-if="!updateLoading">
          <button v-if="!equity.isin || equity.isin === 'X_'" class="set-field-btn" @click="scrapeMissingIsin" :disabled="updateLoading">
            Scrape
          </button>
        </template>
        <div class="field" v-if="scrapedData">
          <input type="text" id="isin" v-model="scrapedData.missingIsin" placeholder="ISIN...">
          <table>
            <tbody class="group1">
              <tr><th scope="row" align="left">Source:</th><td><a :href="scrapedData.isinSource" target=”_blank”>{{ scrapedData.isinSource }}</a></td></tr>
              <tr><th scope="row" align="left">MS name:</th><td>{{ scrapedData.morningstarName }}</td></tr>
              <tr><th scope="row" align="left">DB name:</th><td>{{ equity.name }}</td></tr>
            </tbody>
          </table>
        </div>
        <input v-else type="text" id="isin" v-model="equity.isin" placeholder="ISIN..."/>
      </div>

      <div class="field">
        <label for="feed-data"><strong>FEED DATA:</strong></label><p></p>
        <table>
          <tbody class="group1" align="left">

          <tr><th scope="row">Last Fds Loader:</th><td>{{ lastFdsLoader }}</td></tr>
          <tr><th scope="row">Factset ID:</th><td>{{ factSetId }}</td></tr>
          <tr><th scope="row">Company ID:</th><td>{{ companyId }}</td></tr>
          <tr><th scope="row">Version:</th><td>{{ version }}</td></tr>

          <tr><th scope="row">Last Fds Loader Success:</th>
            <td v-if="lastFdsLoaderSuccess">
              <svg class="svg-inline--fa fa-check check" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-v-2ab393c2=""><path class="" fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
            </td>
            <td v-else>
              <svg class="svg-inline--fa fa-xmark cross" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-v-2ab393c2=""><path class="" fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path></svg>            </td>
          </tr>

          <tr><th scope="row">Factset OK:</th>
            <td v-if="factsetOk">
              <svg class="svg-inline--fa fa-check check" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-v-2ab393c2=""><path class="" fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
            </td>
            <td v-else>
              <svg class="svg-inline--fa fa-xmark cross" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-v-2ab393c2=""><path class="" fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path></svg>            </td>
          </tr>

          </tbody>
        </table>
      </div>

      <ChartBox
      v-if="graphData"
      :graph-data="graphData"
      :item="equity"
      type="equity"
      :currency="equity.code"
      />


      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="equity.is_active">
          <span class="checkmark"></span>
          Make Active
        </label>
      </div>

      <!--      Deactivate equity permanently-->
      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="equity.permanent_deactivation">
          <span class="checkmark"></span>
          Permanent Deactivation
        </label>
      </div>

      <!--      Admin comment-->
      <div class="field">
        <label for="admin-comment">Admin Comment:</label>
        <textarea id="admin-comment" v-model="equity.admin_comment" placeholder="Type comment..."></textarea>
      </div>

      <div class="field" v-if="equity.is_active === true || equity.permanent_deactivation === true">
        <label for="confirm">Confirmation:</label>
        <input type="text" id="confirm" autocomplete="off" placeholder='Type "CONFIRM" to be able to change the equity status' v-model="confirm">
      </div>


      <button class="transition save-btn" @click="updateEquityHandler()" :disabled="updateLoading
            || (equity.is_active === true && confirm !== 'CONFIRM')
            || (equity.permanent_deactivation === true && confirm !== 'CONFIRM')">
        <template v-if="!updateLoading">
          Save
        </template>
        <font-awesome-icon v-else icon="spinner" spin />
      </button>

      <span class="error-message" v-if="error">
        {{error}}
      </span>
    </template>
  </popup>
</template>

<script>
  import securitiesMixin from '@/mixins/securities'
  import userMixin from '@/mixins/user'

  import Popup from '@/components/shared/Popup'
  import ChartBox from '@/components/shared/charts/ChartBox'
  
  export default {
    name: 'InspectCandidateEquityNew',
    mixins: [securitiesMixin, userMixin],
    props: {
      item: Object
    },
    components: {
      Popup,
      ChartBox
    },
    data() {
      return {
        graphData: null,
        lastFdsLoader: this.item.feed_data.last_fds_loader,
        factSetId: this.item.feed_data.factset_id,
        companyId: this.item.feed_data.company_id,
        version: this.item.feed_data.version,
        lastFdsLoaderSuccess: this.item.feed_data.last_fds_loader_success,
        factsetOk: this.item.feed_data.factset_ok,
        updateLoading: false,
        error: null,
        confirm: null,
        equity: {...this.item},
        scrapedData: null
      }
    },
    methods: {
      // * Get equity chart data
      getEquityChartDataHandler() {
        this.getSecurityChartData(this.equity.id)
          .then(res => {
            this.graphData = res;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      updateEquityHandler() {
        this.updateLoading = true;

        const dataToSend = {
          isActive: this.equity.is_active,
          candidate: this.equity.candidate,
          permanentDeactivation: this.equity.permanent_deactivation,
          adminComment: this.equity.admin_comment,
          isin: this.equity.isin,
          factsetOk: this.factsetOk,
          lastFdsLoader: this.lastFdsLoader,
          factsetId: this.factSetId,
          companyId: this.companyId,
          version: this.version,
          lastFdsLoaderSuccess: this.lastFdsLoaderSuccess
        };

        if (this.scrapedData && (!dataToSend.isin || dataToSend.isin === 'X_')) {
          dataToSend.isin = this.scrapedData.missingIsin;
        }

        this.updateSecurity(dataToSend, this.equity.id)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code == 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
      },
      // Missing ISIN data is the array : [ISIN, web source, equity name from Morningstar]
      scrapeMissingIsin() {
        this.updateLoading = true;
        this.getMissingIsinData(this.equity.valor)
            .then(res => {
              this.updateLoading = false;
              this.scrapedData = res;
            })
            .catch(err => {
              this.updateLoading = false;
              if (err == 401) {
                this.logout();
              }
            });
      }
    },
    watch: {
      'equity.is_active': function(newVal) {
        if (newVal) {
          this.equity.permanent_deactivation = false;
          this.equity.candidate = false;
        }
      },
      'equity.candidate': function(newVal) {
        if (newVal) {
          this.equity.permanent_deactivation = false;
          this.equity.is_active = false;
        }
      },
      'equity.permanent_deactivation': function(newVal) {
        if (newVal) {
          this.equity.is_active = false;
          this.equity.candidate = false;
        }
      },
    },
    computed: {

    },
    mounted() {
      this.getEquityChartDataHandler();
    }
  }
</script>

<style scoped>
  .equity-id {
    display: block;
    color: #171725;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  a {
    color: #3C6E71;
  }

  a:hover {
    text-decoration: none;
  }
</style>
