<template>
  <!-- Fund Advisors Page -->
  <div class="fund-advisors-page">
    <h1>Fund Advisors <small v-if="total">({{total}})</small></h1>
    <!-- Table with results -->
    <DataTableHolder
      :columns="columns"
      :rows="rows"
      :totalPages="pages"
      :updateResults="updateResults"
      :actions="['edit']"
      @update-table="getFundAdvisorsHandler"
      @edit="editHandler" 
    />

    <!-- Edit Popup -->
    <EditFundAdvisor
      v-if="editItem"
      :item="editItem"
      :parentGroups="parentGroups"
      @close="editItem = null"
      @updated="updatedHandler"
    />
  </div>
</template>

<script>
  import fundsMixin from '@/mixins/funds'
  import userMixin from '@/mixins/user'

  import DataTableHolder from '@/components/shared/datatable/DataTableHolder'
  import EditFundAdvisor from './popups/EditFundAdvisor'

  export default {
    name: 'FundAdvisors',
    mixins: [fundsMixin, userMixin],
    components: {
      DataTableHolder,
      EditFundAdvisor
    },
    data() {
      return {
        rows: null,
        columns: [
          {
            title: 'ID',
            name: 'id',
            sortable: true
          },
          {
            title: 'Name',
            name: 'name',
            sortable: true
          },
          {
            title: 'Parent ID',
            name: 'parentGroupId',
            sortable: true
          },
          {
            title: 'Parent Name',
            name: 'parentGroupName',
            sortable: true
          },
          {
            title: 'Added On',
            name: 'addedOn',
            sortable: true
          },
          {
            title: 'Updated On',
            name: 'updatedOn',
            sortable: true
          }
          ,
          {
            title: 'Is Paid',
            name: 'isPaid',
            sortable: true
          }
        ],
        editItem: null,
        pages: 0,
        parentGroups: null,
        updateResults: true,
        total: null
      }
    },
    methods: {
      // * Get fund advisors from API
      getFundAdvisorsHandler(data) {
        this.getFundAdvisorsPaginated(data)
          .then(res => {
            // * After receiving response update data
            this.pages = res.pagesCount;
            this.rows = res.fundAdvisors;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err == 401) {
              this.logout();
            }
          })
      },
      // * Triggered when user click the pen icon on a row
      editHandler(item) {
        this.editItem = item;
      },
      // * Triggered when user updates item from the edit popup
      updatedHandler() {
        // * Null the editing item
        this.editItem = null;
        this.updateResults = !this.updateResults;
      }
    },
    mounted() {
      // * On component (page) load get the fund groups for the autocomplete field in the edit popup
      this.getAllFundGroups()
        .then(res => {
          this.parentGroups = res.fundGroups;
        })
        .catch(err => {
          if (err == 401) {
            this.logout();
          }
        })
    }
  }
</script>

<style scoped>


</style>