<template>
  <!-- Fund Characteristics Page -->
  <div class="funds-by-tag">
    <h1>Tags By Instruments <small v-if="total">({{ total }})</small></h1>

    <!-- Table with results -->
    <DataTableHolder
        :columns="columns"
        :rows="rows"
        :totalPages="pages"
        :updateResults="updateResults"
        :column-search="true"
        :actions="[]"
        @update-table="getTagsByInstruments"
    />

  </div>
</template>

<script>
import fundsMixin from '@/mixins/funds'
import tagsMixin from '@/mixins/tags.vue';

import userMixin from '@/mixins/user'

import DataTableHolder from '@/components/shared/datatable/DataTableHolder'

export default {
  name: 'InstrumentsByTag',
  mixins: [fundsMixin, tagsMixin, userMixin],
  components: {
    DataTableHolder,
  },
  data() {
    return {
      rows: null,
      columns: [
        {
          title: 'ID',
          name: 'tagId',
          sortable: true
        },
        {
          title: 'Name',
          name: 'name',
          sortable: true
        },
        {
          title: 'Fund ID',
          name: 'investmentFundId',
          sortable: true
        },
        {
          title: 'Article ID',
          name: 'articleId',
          sortable: true
        },
        {
          title: 'Organization ID',
          name: 'organizationId',
          sortable: true
        },
        {
          title: 'Portfolio ID',
          name: 'portfolioId',
          sortable: true
        },
        {
          title: 'Security ID',
          name: 'securityId',
          sortable: true
        },

      ],
      editItem: null,
      updateResults: true,
      pages: 0,
      total: null,
      filterType: 'investmentFund',
      filterValue: '',
      placeholderText: 'Type in a Tag ID',
      isLoading: false,

    }
  },

  methods: {
    getTagsByInstruments(data) {
      this.isLoading = true;
      this.getTagsByInstrumentsPaginated(data)
          .then(res => {
            this.pages = res.pagesCount;
            let resultsFormatted = [];

            for (const result of res.results) {
              let resultsObj = {
                id: result.id,
                name: result.name,
                investmentFundId: result.investment_fund_id,
                articleId: result.article_id,
                organizationId: result.organization_id,
                portfolioId: result.user_portfolio_id,
                securityId: result.security_id,
              }
              resultsFormatted.push(resultsObj);
            }
            this.rows = resultsFormatted;
            this.fullData = res.results;
            this.total = res.resultsCount;
          })
          .catch(err => {
            if (err === 401) {
              this.logout();
            }
          })
    },

  },


}
</script>

<style scoped>


</style>